/* eslint-disable react/prop-types */

import classNames from 'classnames';
import React, { useCallback, useContext, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';

import _ from 'lodash/fp';
import { PRODUCT_TYPE } from '../../constants/product';
import text from '../../text';
import { selectionChildren } from '../../utilities/map';
import Icon from '../icon/Icon';
import { getAddOnConfig } from '../mapView/drawingManager/utilities';
import { MapViewContext } from '../mapView/mapViewContext';
import ProductIndicator from './ProductIndicator';

const ProductMenu = ({ data, className, closeOnSelect = true }) => {
  const { actions, state } = useContext(MapViewContext);
  const isAddOnLayerSelected = !!getAddOnConfig(data.category_name);

  const focusedAddOnDataType = useMemo(() => {
    const product =
      state.selectionProductAvailability?.[data.id][data.category_name];
    const addOnConfig =
      product?.category_name && getAddOnConfig(product.category_name);
    if (addOnConfig) {
      return addOnConfig.DATA_TYPE;
    }

    return null;
  }, [data.category_name, data.id, state.selectionProductAvailability]);

  const isDisabled = useCallback(
    (product, isAddOn = false) => {
      if (!isAddOn) return !product.available;

      return (
        !product.available ||
        !product.category_name.includes(focusedAddOnDataType)
      );
    },
    [focusedAddOnDataType]
  );

  const products = useMemo(() => {
    const isAddOn = (product) => {
      return !!getAddOnConfig(product.category_name);
    };

    return _.map(
      (product) => ({
        ...product,
        key: product.product_id,
        icon: product.category_name,
        label: text(product.display_name),
        isFocused: product.category_name === data.category_name,
        isDisabled: isDisabled(product, isAddOn(product)),
      }),
      _.pickBy((product) => {
        const _isAddOn = isAddOn(product);
        if (isAddOnLayerSelected) {
          return _isAddOn;
        }

        return product.category_name !== PRODUCT_TYPE.UNKNOWN && !_isAddOn;
      }, state.selectionProductAvailability?.[data.id])
    );
  }, [
    data.category_name,
    data.id,
    isAddOnLayerSelected,
    isDisabled,
    state.selectionProductAvailability,
  ]);

  const handleProductTypeClick = (productType) => (event) => {
    event.preventDefault();

    const availableProductData = _.find(
      ['category_name', productType],
      products
    );

    const updatedSelection = () =>
      actions.changeSelectionProductType(data.id, availableProductData);

    const activeSelectionChildren = selectionChildren(
      state.selections,
      state.activeSelection?.id || data.id
    );

    if (activeSelectionChildren.length > 0) {
      actions.showChangeProductTypeConfirmation(
        productType,
        activeSelectionChildren,
        () => {
          // callback
          const newSelection = updatedSelection();
          actions.setActiveSelectionId(newSelection.id);
          actions.setHoveredData(null);
          actions.setProductSelectedOnMakeSelection(newSelection);
        }
      );
      return;
    }

    const newSelection = updatedSelection();
    if (state.activeSelection && state.activeSelection.id === newSelection.id) {
      actions.setActiveSelectionId(data.id);
      actions.setHoveredData(null);
      actions.setProductSelectedOnMakeSelection(newSelection);
    }

    // After changing type, assign newSelection to setOpenedShapeMenuData that is opened
    if (state.openedShapeMenu.data && !closeOnSelect) {
      actions.setOpenedShapeMenu((prev) => ({
        ...prev,
        data: { ...newSelection, index: data.index },
        prevCategoryName: data.category_name,
      }));
    }
  };

  return (
    <div className={classNames('product-menu', className)}>
      {products?.map((product) => {
        const { key, label, category_name, isFocused, isDisabled } = product;
        return (
          <Dropdown.Item
            key={key}
            disabled={isDisabled}
            onClick={handleProductTypeClick(category_name)}
          >
            <ProductIndicator
              className={classNames(category_name, isFocused && 'focus')}
              categoryName={category_name}
              isFocused={isFocused}
              isDisabled={isDisabled}
            />
            <span className='product-label'>{label}</span>
            <div className={classNames('product-tick', isFocused && 'focus')}>
              <Icon icon='tick' />
            </div>
          </Dropdown.Item>
        );
      })}
    </div>
  );
};

export default ProductMenu;
