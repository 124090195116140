import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRealtimeContext } from '../../../../../../RealtimeContext';
import Cell from '../../../../../../../components/table/Cell';
import { CART_TABLE } from '../../../../../../../constants/table';
import { selectionNameSchema } from '../../../../../../../validation/job';
import { PRODUCT_DATA } from '../../../../../../../components/mapView/constants';
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Modal,
} from 'react-bootstrap';
import text, { formatSqmArea } from '../../../../../../../text';
import Icon from '../../../../../../../components/icon/Icon';
import {
  flip,
  offset,
  shift,
  arrow,
  useFloating,
  useListItem,
  useMergeRefs,
  useRole,
  autoUpdate,
  useDismiss,
  useInteractions,
  useFloatingNodeId,
  FloatingPortal,
  FloatingNode,
  FloatingArrow,
} from '@floating-ui/react';
import { useCartContext } from '../../../../../../CartContext';
import ShapeMenu from '../../../../../ShapeMenu';
import { ProductMenuContext } from '..';
import { ShapeDetails } from '../../../../../../lib/shape';

const SQM_IN_SQKM = 1e6;
const ARROW_WIDTH = 24;
const ARROW_HEIGHT = 12;

const SimpleDataRow = forwardRef<
  HTMLTableRowElement,
  {
    shape: ShapeDetails;
    isFocused: boolean;
  }
>(({ shape, isFocused }, forwardedRef) => {
  const { activateShape, editShapeName, hideShape } = useRealtimeContext();
  const { cartRootRef, cartCoverage, setSelectedDataType } = useCartContext();

  const [isSuccess, setIsSuccess] = useState(false);
  const [hasInitialValueLoaded, seHasInitialValueLoaded] = useState(false);
  const [isNameFocused, setIsNameFocused] = useState(false);

  const menu = useContext(ProductMenuContext);
  const item = useListItem();
  const isActive = menu.activeIndex === item.index;

  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const nodeId = useFloatingNodeId();
  const { refs, floatingStyles, context, update } = useFloating({
    nodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left',
    middleware: [
      offset({
        mainAxis: 26,
      }),
      flip(),
      shift({
        padding: 16,
      }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
  ]);

  const handleClick = useCallback(() => {
    activateShape(shape.id);
  }, [shape.id, activateShape]);

  const selectedColour = (() => {
    return PRODUCT_DATA.entities[shape.dataType]?.display_color;
  })();

  const coverage = useMemo(() => {
    return cartCoverage?.selections[shape.id];
  }, [cartCoverage, shape.id]);

  const shapeArea = useMemo(() => {
    if (coverage?.area_in_sqm) {
      if (coverage.area_in_sqm > SQM_IN_SQKM) {
        return `${(coverage.area_in_sqm / SQM_IN_SQKM).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }
        )} km²`;
      }
      return formatSqmArea(coverage.area_in_sqm);
    }
    return '';
  }, [coverage]);

  return (
    <FloatingNode id={nodeId}>
      {isOpen ? (
        <FloatingPortal root={cartRootRef}>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 1000 }}
            {...getFloatingProps()}
          >
            <div
              className='position-relative'
              style={{
                border: '1px solid #BDBDBD',
                borderRadius: 2,
              }}
            >
              <FloatingArrow
                context={context}
                ref={arrowRef}
                width={ARROW_WIDTH}
                height={ARROW_HEIGHT}
                fill='#fff'
                stroke='#BDBDBD'
                strokeWidth={0.8}
                style={{
                  zIndex: 1,
                }}
              />

              <Modal.Dialog id='product-dropdown-menu-container'>
                {/* @ts-expect-error bad props */}
                <Modal.Header className='align-align-items-center'>
                  <div className='d-flex flex-column'>
                    <div style={{ fontSize: 16, fontWeight: '600' }}>
                      {shape.name}
                    </div>
                  </div>

                  <Button
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    variant='light'
                    className='row-action icon-button'
                  >
                    <Icon icon='close' />
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <ShapeMenu
                    myRef={forwardedRef}
                    data={shape}
                    DropdownMenuComponent={Dropdown.Menu}
                    dropdownMenuProps={{
                      show: true,
                      className: 'product-dropdown',
                    }}
                    onDataTypeChanged={(newDataType) =>
                      setSelectedDataType(newDataType)
                    }
                    showShapeName={false}
                  />
                </Modal.Body>
              </Modal.Dialog>
            </div>
          </div>
        </FloatingPortal>
      ) : null}
      <tr
        ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
        className={classNames('SimpleDataRow ', {
          isFocused,
          [shape.dataType]: shape.dataType,
        })}
        // style={{
        //   backgroundColor:
        //     state.hoveredData?.selected && state.hoveredData?.id === data.id
        //       ? `${selectedColour}44`
        //       : '#FFFFFF',
        // }}
        // onMouseEnter={(e) => {
        //   if ((e.target as HTMLElement).id !== 'ViewCell' && !shape.hidden) {
        //     actions.setHoveredData({ id: data.id, selected: true });
        //   }
        // }}
        // onMouseLeave={() => {
        //   if (data.visible) {
        //     actions.setHoveredData(null);
        //   }
        // }}
        role='menuitem'
        // tabIndex={isActive ? 0 : -1} // ACTIVE STYLING
        {...menu.getItemProps({
          onClick: () => {
            handleClick();
          },
        })}
      >
        <Cell
          {...(isNameFocused && { colSpan: 6 })}
          className={classNames(CART_TABLE.COLUMN.POINT_CLOUD, {
            expanded: isNameFocused,
          })}
          validationSchema={selectionNameSchema}
          isSuccessful={isSuccess}
          value={shape.name ?? ''}
          // disabled={isQuotingJob}
          onSubmit={(name: string) => editShapeName(shape.id, name)}
          onFocus={() => {
            setIsNameFocused(true);
          }}
          onBlur={() => setIsNameFocused(false)}
        >
          <div
            className='product-light'
            style={{
              borderRadius: '50%',
              backgroundColor: selectedColour,
            }}
          />
        </Cell>

        {!isNameFocused && (
          <>
            <td
              className={classNames(CART_TABLE.COLUMN.AREA, 'text-left pl-3')}
              colSpan={3}
            >
              {shapeArea}
            </td>

            <td className={CART_TABLE.COLUMN.VISIBILITY} align='right'>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement='top'
                delay={{
                  show: 500,
                  hide: 0,
                }}
                overlay={
                  <Tooltip id={`${shape.id}-visibility`}>
                    {text(!shape.hidden ? 'hideShape' : 'showShape')}
                  </Tooltip>
                }
              >
                <Button
                  variant='light btn-eye click-outside-excluded'
                  onClick={(ev) => {
                    ev.stopPropagation();
                    hideShape(shape.id, !shape.hidden);
                  }}
                >
                  <Icon icon={!shape.hidden ? 'eye' : 'eye-slash'} />
                </Button>
              </OverlayTrigger>
            </td>

            <td className={CART_TABLE.COLUMN.MEATBALL} align='right'>
              <Button
                data-open={isOpen ? 'true' : undefined}
                variant='light btn-more click-outside-excluded'
                {...getReferenceProps({
                  onClick: () => {
                    setIsOpen((prev) => !prev);
                  },
                })}
              >
                {/* @ts-expect-error bad props */}
                <Icon style={{ pointerEvents: 'none' }} icon='more' />
              </Button>
            </td>
          </>
        )}
      </tr>
    </FloatingNode>
  );
});
SimpleDataRow.displayName = 'SimpleDataRow';

export default SimpleDataRow;
