import React, { useMemo } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { getUser } from '../../../utilities/storage';
import { getRedirectUrl } from '../../../hooks/useRedirect';

const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  const location = useLocation();
  const to = useMemo(
    () =>
      getRedirectUrl('/login', true, {
        redirectUrl: encodeURIComponent(location.pathname),
      }),
    [location.pathname]
  );
  const user = useMemo(() => getUser(), []);
  if (!user) {
    return <Redirect to={to} />;
  }
  return <Route {...rest} component={component} />;
};

export default PrivateRoute;
