import React from 'react';

export type Metrics = 'fps' | 'cpuTimePerFrame' | 'gpuTimePerFrame';
const DebugMetrics = ({
  metrics,
}: {
  metrics: Record<Metrics, number> | null;
}) => {
  if (!metrics) return null;
  return (
    <>
      <p>FPS: {metrics.fps.toFixed(2)}</p>
      <p>CPU: {metrics.cpuTimePerFrame.toFixed(2)} ms</p>
      <p>GPU: {metrics.gpuTimePerFrame.toFixed(2)} ms</p>
    </>
  );
};
export default DebugMetrics;
