import * as turf from '@turf/turf';
import * as geohash from 'ngeohash';
import axios from 'axios';
import { Polygon, MultiPolygon, MultiPoint, BBox } from 'geojson';
import {
  DerivedDataType,
  getBaseDataType,
  isDerivedDataType,
  PointCloudDataType,
} from '../../lib/dataType';
import { geometryLib } from '../../lib/geometry';
import { captureError } from '../../../utilities/error';
import { getUser } from '../../../utilities/storage';
import { SerializedCacheResult } from './cache';
import {
  shapeBooleanIntersects,
  getBoundingBox,
  shapeIntersect,
  MapShape,
  shapeUnion,
  isDerivedShape,
  extractShapeGeometry,
} from '../../lib/shape';
import { arrayLib } from '../../lib/array';
import { groupBy, MapWithDefault } from '../../lib/object';

// TODO: use zod
export type LayerGeometries = {
  geometry: Polygon | MultiPolygon;
  bbox: BBox;
  category_name: PointCloudDataType;
  is_full: boolean;
  product_id: number;
};

export type LayerLabels = {
  label_positions: MultiPoint;
  label_positions_original: MultiPoint;
  label_priorities: number[];
  label_validities: boolean[];
  label_visibilities: boolean[];
  parts: MultiPolygon;
  acquired_at: string;
  category_name: PointCloudDataType;
};

export class Coverage {
  layers: Map<PointCloudDataType, LayerGeometries[]> = new Map();
  labels: Map<PointCloudDataType, LayerLabels[]> = new Map();

  async querySelections(
    shapes: MapShape[]
  ): Promise<
    (Omit<LayerGeometries, 'category_name'> & {
      category_name: PointCloudDataType | DerivedDataType;
    })[]
  > {
    const selections = shapes.map((shape) => {
      return {
        geometry: extractShapeGeometry(shape),
        category_names: [shape.dataType],
      };
    });
    const resp = await larkiApi.post('/engine/coverage/v2/query', {
      selections,
    });
    return resp.data.layers;
  }

  async query(hashes: Map<PointCloudDataType, Set<string>>) {
    const bboxes = new Map(
      [...hashes].map(([dataType, hashes]) => {
        const bboxes = [...hashes].map((hash) => {
          const [minLat, minLon, maxLat, maxLon] = geohash.decode_bbox(hash);
          return turf.bboxPolygon([minLon, minLat, maxLon, maxLat]);
        });
        return [
          dataType,
          bboxes.length === 0
            ? null
            : bboxes.length === 1
              ? bboxes[0]
              : geometryLib.union(bboxes, {}),
        ];
      }, hashes)
    );
    const selections = [...bboxes].map(([dataType, bbox]) => {
      if (!bbox) return null;
      return {
        geometry: bbox.geometry,
        category_names: [dataType],
      };
    });
    try {
      const resp = await larkiApi.post('/engine/coverage/v2/query', {
        selections,
      });
      const layers: LayerGeometries[] = resp.data.layers.map((l: any) => ({
        ...l,
        bbox: turf.bbox(l.geometry),
      }));
      const labels: LayerLabels[] = resp.data.layers
        .filter((l: any) => l.acquired_at)
        .map((l: any) => ({
          ...l,
          label_visibilities: l.label_validities.map(() => false),
          label_positions_original: l.label_positions,
        }));
      const layersByType = groupBy(layers, 'category_name');
      const labelsByType = groupBy(labels, 'category_name');
      this.layers = new Map([...this.layers, ...layersByType]);
      this.labels = new Map([...this.labels, ...labelsByType]);
      // this.layers = layers.reduce((acc, layer) => {
      //   if (!acc.has(layer.category_name)) acc.set(layer.category_name, []);
      //   acc.get(layer.category_name)!.push(layer);
      //   return acc;
      // }, this.layers);
      // this.labels = labels.reduce((acc, label) => {
      //   if (!acc.has(label.category_name)) acc.set(label.category_name, []);
      //   acc.get(label.category_name)!.push(label);
      //   return acc;
      // }, this.labels);
      return { layers: this.layers, labels: this.labels };
    } catch (err) {
      captureError(err);
    }
    return null;
  }
}

const transformRequest = axios.defaults.transformRequest;

const larkiApi = axios.create({
  baseURL: process.env.LARKI_API_URL + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data, headers) => {
      let sessionToken;
      try {
        sessionToken = getUser().token;
      } catch (err) {
        console.warn(err);
        sessionToken = '<not logged in>'; // trigger usual unauthenticated error path
      }
      if (headers) {
        headers['Authorization'] = 'Bearer ' + sessionToken;
        headers['Larki-Version'] = process.env.BUILD_NUMBER!;
      }

      return data;
    },
    ...(Array.isArray(transformRequest)
      ? transformRequest
      : transformRequest
        ? [transformRequest]
        : []), // don't lose axios' default JSON => string behaviour
  ],
});

export type QueryResult = Awaited<ReturnType<Coverage['query']>>;

export const serializeQueryResult = (result: QueryResult) =>
  result && {
    layers: Object.fromEntries(result.layers),
    labels: Object.fromEntries(result.labels),
  };

export type SerializedQueryResult = ReturnType<typeof serializeQueryResult>;

export const deserializeQueryResult = (
  result: SerializedQueryResult
): QueryResult =>
  result && {
    layers: new Map(
      Object.entries(result.layers).map(([dataType, layers]) => [
        dataType as PointCloudDataType,
        layers,
      ])
    ),
    labels: new Map(
      Object.entries(result.labels).map(([dataType, labels]) => [
        dataType as PointCloudDataType,
        labels,
      ])
    ),
  };

export type QueryWorkerRequest = SerializedCacheResult;

export type DataTypeAvailability = Map<
  string,
  Map<PointCloudDataType, boolean>
>;

export class CoverageFilter {
  private layers: Map<PointCloudDataType, LayerGeometries[]> | null = null;

  constructor() {
    console.log('NEW COVERAGE FILTER');
  }

  setLayers(layers: typeof this.layers) {
    this.layers = layers;
  }

  filter(
    shapes: MapShape[]
  ): { layers: LayerGeometries[]; availability: DataTypeAvailability } | null {
    if (!this.layers) return null;
    const shapesWithBbox = shapes
      .filter((shape) => !shape.hidden)
      .map((shape) => ({
        ...shape,
        bbox: getBoundingBox(shape),
      }));

    const availability = new MapWithDefault<
      string,
      Map<PointCloudDataType, boolean>
    >(() => new MapWithDefault(() => false));

    return {
      layers: [...this.layers].flatMap(([dataType, layers]) => {
        for (const layer of layers) {
          for (const shape of shapes) {
            // if already available, don't waste time checking again
            if (!availability.get(shape.id).get(dataType)) {
              if (shapeBooleanIntersects(shape, layer.geometry)) {
                availability.get(shape.id).set(dataType, true);
              }
            }
          }
        }

        const baseShapes = shapesWithBbox.filter(
          (shape) => shape.dataType === dataType
        );
        const baseUnion = shapeUnion(...baseShapes);
        if (!baseUnion) return [];

        const baseLayers = layers
          .filter((layer) =>
            // TODO: rather than the whole geometry, check (and show) the parts instead
            baseShapes.some(
              (shape) =>
                (!shape.bbox ||
                  geometryLib.bboxIntersect(shape.bbox, layer.bbox)) &&
                shapeBooleanIntersects(shape, layer.geometry)
            )
          )
          .map((layer) => {
            const intersection = shapeIntersect(baseUnion, layer.geometry);
            if (!intersection) return null;
            return {
              ...layer,
              geometry: intersection,
            };
          })
          .filter(arrayLib.filterNonNull);

        const derivedShapes = shapesWithBbox
          .map((shape) => {
            if (
              isDerivedShape(shape) &&
              getBaseDataType(shape.dataType) === dataType
            ) {
              const baseShape = shapes.find((s) => s.id === shape.baseShapeId);
              if (baseShape) {
                const intersect = shapeIntersect(baseShape, shape);
                if (intersect) {
                  return intersect;
                }
              }
            }
            return null;
          })
          .filter(arrayLib.filterNonNull);
        const derivedUnion = shapeUnion(...derivedShapes);
        if (!derivedUnion) return baseLayers;
        const derivedBoundary = shapeIntersect(derivedUnion, baseUnion);
        if (!derivedBoundary) return baseLayers;

        const derivedLayers = layers
          .map((layer) => {
            const intersection = shapeIntersect(
              derivedBoundary,
              layer.geometry
            );
            if (!intersection) return null;
            return {
              ...layer,
              geometry: intersection,
            };
          })
          .filter(arrayLib.filterNonNull);

        return [...baseLayers, ...derivedLayers];
      }),
      availability,
    };
  }
}

type FilterWorkerRequest = {
  data?: {
    layers: Map<PointCloudDataType, LayerGeometries[]>;
    labels: Map<PointCloudDataType, LayerLabels[]>;
  };
  shapesToFilterBy?: MapShape[];
};

export const serializeFilterWorkerRequest = (request: FilterWorkerRequest) => ({
  data: request.data && {
    layers: Object.fromEntries(request.data.layers),
    labels: Object.fromEntries(request.data.labels),
  },
  shapesToFilterBy: request.shapesToFilterBy,
});

export type SerializedFilterWorkerRequest = ReturnType<
  typeof serializeFilterWorkerRequest
>;

export const deserializeFilterWorkerRequest = (
  request: SerializedFilterWorkerRequest
) =>
  request && {
    data: request.data && {
      layers: new Map(
        Object.entries(request.data.layers).map(([dataType, layers]) => [
          dataType as PointCloudDataType,
          layers,
        ])
      ),
      labels: new Map(
        Object.entries(request.data.labels).map(([dataType, labels]) => [
          dataType as PointCloudDataType,
          labels,
        ])
      ),
    },
    shapesToFilterBy: request.shapesToFilterBy,
  };

export type FilterWorkerResponse = {
  layers: LayerGeometries[];
  availability: DataTypeAvailability;
};

export const serializeFilterWorkerResponse = (
  response: FilterWorkerResponse
) => ({
  layers: response.layers,
  availability: Object.fromEntries(
    [...response.availability].map(
      ([dataType, available]) =>
        [dataType, Object.fromEntries(available)] as const
    )
  ),
});

export type SerializedFilterWorkerResponse = ReturnType<
  typeof serializeFilterWorkerResponse
>;

export const deserializeFilterWorkerResponse = (
  response: SerializedFilterWorkerResponse
): FilterWorkerResponse => ({
  layers: response.layers,
  availability: new Map(
    Object.entries(response.availability).map(([shapeId, availability]) => [
      shapeId,
      new Map(
        Object.entries(availability).map(([dataType, available]) => [
          dataType as PointCloudDataType,
          available,
        ])
      ),
    ])
  ),
});
