import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CloseButton from './CloseButton';
import Icon from '../../../../../../components/icon/Icon';
import text from '../../../../../../text';

export default function Header({
  isPreviewButtonHidden,
  isScreenshotButtonHidden,
}: {
  isPreviewButtonHidden?: boolean;
  isScreenshotButtonHidden?: boolean;
}) {
  return (
    // @ts-expect-error prop
    <Modal.Header>
      <div className='d-flex flex-row' style={{ gap: 16 }}>
        {!isPreviewButtonHidden && (
          <Button variant='light' className='row-action with-text icon-button'>
            <Icon icon='preview' />
            <span>{text('preview')}</span>
          </Button>
        )}

        {!isScreenshotButtonHidden && (
          <Button variant='light' className='row-action with-text icon-button'>
            <Icon icon='camera' />
            <span>{text('screenshot')}</span>
          </Button>
        )}
      </div>

      <CloseButton />
    </Modal.Header>
  );
}
