import { useCallback, useMemo, useState } from 'react';

const useMovingAverage = <K extends string>(n: number = 10) => {
  const [values, setValues] = useState<Record<K, number[]>>();
  const updateMovingAverages = useCallback(
    (newValues: Record<K, number>) => {
      setValues((prev) =>
        map(newValues, (val, key) => [...(prev?.[key] ?? []), val].slice(-n))
      );
    },
    [n]
  );
  const movingAverages = useMemo(() => {
    if (!values) return null;
    return map(values, (arr) => {
      if (arr.length === 0) return -1; // avoid division by zero
      return arr.reduce((acc, v) => acc + v, 0) / arr.length;
    });
  }, [values]);
  return useMemo(
    () => ({ movingAverages, updateMovingAverages }),
    [updateMovingAverages, movingAverages]
  );
};
export default useMovingAverage;

const map = <K extends string, V, M>(
  obj: Record<K, V>,
  fn: (v: V, k: K) => M
) => {
  return Object.fromEntries(
    Object.entries<V>(obj).map(([k, v]) => [k, fn(v, k as K)])
  ) as Record<K, M>;
};
