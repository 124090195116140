import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PaymentMethodStep from '../../../components/wizard/PaymentMethodStep';
import {
  listUserCards,
  setPaymentMethodId,
  setSelectedPayMode,
} from '../../../redux/actions';
import StripeElements from '../../../components/stripe/StripeElements';
import {
  isUserUnderTrial,
  userHasValidMembership,
} from '../../../utilities/user';

const MembershipManagementPaymentStep = ({
  onSubmit,
  nextButtonProps,
  isAssignedPayment,
}) => {
  const dispatch = useDispatch();
  const {
    paymentMethodId,
    currentUser,
    userProfileState,
    selectedPayMode,
    membershipPriceId,
  } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
    userProfileState: state.profileReducer.userProfileState,
    selectedPayMode: state.order.selectedPayMode,
    membershipPriceId: state.order.membershipPriceId,
    paymentMethodId: state.order.paymentMethodId,
  }));
  const handleAddCard = () => dispatch(listUserCards());
  const handleSubmit = ({ payerName, payerEmail, message }) => {
    onSubmit({
      paymentMethodId,
      membershipPriceId,
      payerEmail,
      payerName,
      message,
    });
  };
  const handleSelectedPayMode = (event) =>
    dispatch(setSelectedPayMode(event.currentTarget.value));

  // commented to hide for FREE user wanted to subscribe w/o going thru TRIAL
  // isTrialDetailsVisible={
  //   !isUserUnderTrial(currentUser) &&
  //   !userHasValidMembership(currentUser) &&
  //   !currentUser.has_used_trial
  // }
  return (
    <>
      <PaymentMethodStep
        onAddCard={handleAddCard}
        onPaymentMethodChange={(paymentMethodId) =>
          dispatch(setPaymentMethodId(paymentMethodId))
        }
        handleSubmit={handleSubmit}
        currentUser={currentUser}
        userProfileState={userProfileState}
        paymentMethodId={paymentMethodId}
        selectedPayMode={selectedPayMode}
        handleSelectedPayMode={handleSelectedPayMode}
        isAssignedPayment={isAssignedPayment}
        nextButtonProps={{
          disabled: !paymentMethodId,
          ...nextButtonProps,
        }}
      />
    </>
  );
};

const MembershipManagementPaymentStepWithStripe = ({
  isAssignedPayment,
  onSubmit,
  nextButtonProps,
}) => {
  return (
    <StripeElements>
      <MembershipManagementPaymentStep
        isAssignedPayment={isAssignedPayment}
        onSubmit={onSubmit}
        nextButtonProps={nextButtonProps}
      />
    </StripeElements>
  );
};

export default MembershipManagementPaymentStepWithStripe;
