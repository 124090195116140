import invariant from 'tiny-invariant';
import { z } from 'zod';

export const GENERATED_RANDOM_COLOR_SCHEMA = z.object({
  rgb: z.array(z.number()),
  textColor: z.array(z.number()),
  hex: z.string(),
});

export type TGeneratedRandomColor = z.infer<
  typeof GENERATED_RANDOM_COLOR_SCHEMA
>;

const luminance = (r: number, g: number, b: number) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    // Ref: ChatGPT
    // values here ensures that the perceived brightness of
    // colors is calculated in a way that closely matches human vision
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const rgbToHex = (r: number, g: number, b: number) =>
  `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;

const hexToRgb = (hex: string): [number, number, number] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  invariant(result, 'not a valid hex string');
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];
};

const generateRandomColor = (
  excludedColors: number[][] = []
): TGeneratedRandomColor => {
  // REF
  const min = 100; // Ensuring colors are not too dark by setting a minimum threshold (100)
  const max = 255; // Warm colors can still be bright, so we'll allow up to 255 for some components
  const r = Math.floor(Math.random() * (max - min) + min); // Red: strong for warmth
  const g = Math.floor(Math.random() * (max - min / 2) + min / 2); // Green: midrange for warmth
  const b = Math.floor(Math.random() * min); // Blue: reduced for warmer tones

  const generatedColor = [r, g, b];
  // check if generatedColor is in the exclusions
  const isExcluded =
    excludedColors.some(
      (excludedColor) =>
        excludedColor[0] === r &&
        excludedColor[1] === g &&
        excludedColor[2] === b
    ) ||
    (r === 48 && g === 72 && b === 201); // exclude as well the Blue from figma design (`You` cursor)

  // if matches any of the excluded/existing colors, retry
  if (isExcluded) {
    return generateRandomColor(excludedColors);
  }

  const luminanceValue = luminance(r, g, b);
  const textColor = luminanceValue > 0.5 ? [0, 0, 0] : [255, 255, 255];
  const rgb = generatedColor;
  const hex = rgbToHex(r, g, b);

  return {
    rgb,
    textColor,
    hex,
  };
};

export const colorLib = { generateRandomColor, hexToRgb, rgbToHex };
