/* global google */

import React, { useRef } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import Highlighter from './Highlighter';
import classNames from 'classnames';
import { useGmMapContext } from '../GmMap';
import { captureError } from '../../../utilities/error';
import invariant from 'tiny-invariant';

type AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;
type AutocompleteService = google.maps.places.AutocompleteService;
type PlacesService = google.maps.places.PlacesService;
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type PlaceResult = google.maps.places.PlaceResult;

type PlaceComponent = {
  short: string;
  long: string;
};
export type Place = {
  geometry: PlaceResult['geometry'];
  streetNumber?: PlaceComponent;
  route?: PlaceComponent;
  postcode?: PlaceComponent;
  city?: PlaceComponent;
  state?: PlaceComponent;
  country?: PlaceComponent;
};

const PLACE_REQUIRED_FIELDS = ['geometry', 'address_components'];

const parsePlaceResult = (placeResult: PlaceResult): Place => {
  invariant(placeResult.geometry, 'geometry is required');
  invariant(placeResult.address_components, 'address_components is required');

  const place: Place = {
    geometry: placeResult.geometry,
  };
  const typeToField: Record<string, keyof Place> = {
    street_number: 'streetNumber',
    route: 'route',
    postal_code: 'postcode',
    locality: 'city',
    administrative_area_level_1: 'state',
    country: 'country',
  };
  for (const component of placeResult.address_components) {
    const type = component.types.find((type) => type in typeToField);
    if (type) {
      place[typeToField[type]] = {
        short: component.short_name,
        long: component.long_name,
      };
    }
  }
  return place;
};

/**
 * Location place (address) autocomplete using Google Maps.
 *
 * AU/NZ addresses only. Keyboard navigation enabled. Highlighting of softly-matched words i.e. typo friendly.
 *
 * Credit:
 * - https://github.com/visgl/react-google-maps/blob/main/examples/autocomplete/src/autocomplete-custom.tsx
 * - https://codesandbox.io/p/sandbox/fragrant-water-bsuirj?file=%2Fsrc%2FApp.tsx
 */
const GmPlaceAutocomplete = ({
  className,
  autoFocus,
  onSelect,
  tilesLoaded,
  defaultValue,
}: {
  className?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  tilesLoaded?: boolean;
  onSelect: (place: Place) => void;
}) => {
  const map = useMap();
  const places = useMapsLibrary('places');

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [isGetDetailsLoading, setIsGetDetailsLoading] = useState(false);
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [sessionToken, setSessionToken] = useState<AutocompleteSessionToken>();
  const [autocompleteService, setAutocompleteService] =
    useState<AutocompleteService | null>(null);
  const [placesService, setPlacesService] = useState<PlacesService | null>(
    null
  );
  const [predictionResults, setPredictionResults] = useState<
    AutocompletePrediction[]
  >([]);

  useEffect(() => {
    if (!places || !map) return () => {};
    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());
    return () => setAutocompleteService(null);
  }, [map, places]);

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }
      const response = await autocompleteService.getPlacePredictions({
        input: inputValue,
        sessionToken,
        componentRestrictions: {
          country: ['aus', 'nz'],
        },
        types: ['address'],
      });
      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);

      if (value) {
        setIsOpen(true);
        setActiveIndex(0);

        fetchPredictions(value);
      } else {
        setIsOpen(false);
      }
    },
    [fetchPredictions]
  );

  const listRef = useRef<(HTMLElement | null)[]>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });
  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );

  useEffect(() => {
    if (autoFocus && tilesLoaded) {
      // timeout hack to focus search bar immediately
      setTimeout(() => {
        refs.domReference.current?.focus();
      }, 50);
    }
  }, [autoFocus, refs.domReference, tilesLoaded]);

  const handleSelect = useCallback(
    (pred: AutocompletePrediction) => {
      setInputValue(pred.description);
      setIsOpen(false);

      setIsGetDetailsLoading(true);
      placesService?.getDetails(
        {
          placeId: pred.place_id,
          sessionToken,
          fields: PLACE_REQUIRED_FIELDS,
        },
        (placeDetails, status) => {
          setIsGetDetailsLoading(false);

          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            captureError(new Error(`Failed to get place details: ${status}`), {
              tags: {
                place: pred.place_id,
                description: pred.description,
              },
            });
          }

          setPredictionResults([]);
          if (placeDetails) {
            onSelect(parsePlaceResult(placeDetails));
          } else {
            captureError(new Error('Failed to get place details'), {
              tags: {
                place: pred.place_id,
                description: pred.description,
              },
            });
          }
        }
      );
    },
    [onSelect, placesService, sessionToken]
  );

  return (
    <div className={classNames('gm-autocomplete', className)}>
      <Form.Control
        size='lg'
        {...getReferenceProps({
          ref: refs.setReference,
          className: 'gm-autocomplete__input',
          onChange: onInputChange,
          value: inputValue,
          placeholder: 'Search site address',
          'aria-autocomplete': 'list',
          onKeyDown: (event) => {
            if (
              event.key === 'Enter' &&
              activeIndex !== null &&
              predictionResults[activeIndex]
            ) {
              handleSelect(predictionResults[activeIndex]);
              setActiveIndex(null);
            }
          },
        })}
      />
      {/* TODO: add floating portal, but currently it breaks styling */}
      {/* <FloatingPortal> */}
      {isOpen ? (
        <FloatingFocusManager
          context={context}
          initialFocus={-1}
          visuallyHiddenDismiss
        >
          <div
            {...getFloatingProps({
              className: 'gm-autocomplete__list',
              ref: refs.setFloating,
              style: floatingStyles,
            })}
          >
            {predictionResults.map((pred, index) => (
              <div
                key={pred.place_id}
                {...getItemProps({
                  className: 'gm-autocomplete__list__item',
                  role: 'option',
                  disabled: isGetDetailsLoading,
                  'aria-selected': activeIndex === index,
                  ref: (node) => {
                    listRef.current[index] = node;
                  },
                  onClick: () => {
                    handleSelect(pred);
                    refs.domReference.current?.focus();
                  },
                })}
              >
                <span className='gm-autocomplete__list__item__main-text'>
                  <Highlighter
                    text={pred.structured_formatting.main_text}
                    matchedSubstrings={
                      pred.structured_formatting.main_text_matched_substrings
                    }
                  />
                </span>
                <span className='gm-autocomplete__list__item__secondary-text'>
                  {pred.structured_formatting.secondary_text}
                </span>
              </div>
            ))}
          </div>
        </FloatingFocusManager>
      ) : null}
      {/* </FloatingPortal> */}
    </div>
  );
};

export default GmPlaceAutocomplete;
