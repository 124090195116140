import { combineReducers } from 'redux';
import { rtkApi } from '../rtk';
import activity from './activity';
import alerts from './alert';
import apiReducer from './api';
import coverageReducer from './coverage';
import jobsReducer from './jobs';
import layout from './layout';
import mapReducer from './map';
import modal from './modal';
import order from './order';
import productsReducer from './products';
import profileReducer from './profile';
import project from './project';
import tourReducer from './tour';
import mpReducer from './mp';

const rootReducer = combineReducers({
  layout,
  alerts,
  jobsReducer,
  profileReducer,
  productsReducer,
  mapReducer,
  modal,
  activity,
  order,
  project,
  coverageReducer,
  tourReducer,
  apiReducer,
  
  [rtkApi.reducerPath]: rtkApi.reducer,
  mpReducer,
});

export default rootReducer;
