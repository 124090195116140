import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MapShape, ShapeDetails } from '../../../lib/shape';
import { debounce } from 'lodash';
import { RESET_FORM_DELAY } from '../../../../pages/constants';
import classNames from 'classnames';
import { RealtimeContext, useRealtimeContext } from '../../../RealtimeContext';
import Cell from '../../../../components/table/Cell';
import { selectionNameSchema } from '../../../../validation/job';
import { usePreviousDistinct } from 'react-use';
import LayerProductMenu from './LayerProductMenu';
import LayerMeatballDropdown from './LayerMeatballDropdown';
import ProductIndicator from '../../../../components/productMenu/ProductIndicator';

export default function Layer({ shape }: { shape: ShapeDetails }) {
  const {
    activeShapeIds: activeShapeIndices,
    editShapeName,
    activateShape,
  } = useRealtimeContext();

  const inputNameRef = useRef<HTMLInputElement>(null);
  const productMenuRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const name = shape.name ?? '';
  const previousName = usePreviousDistinct(shape.name);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isNameFocused, setIsNameFocused] = useState(false);

  // wrap in a useCallback to memoize the function
  // React guarantees that setState function identity is stable
  // (i.e. won't change on re-renders) so we can omit it from the
  // dependency list
  // note: debounce() and RESET_FORM_DELAY are also stable so omitted
  // so this whole function can be cached once on mount and reused forever
  const debounceSetIsSuccess = useMemo(
    // debounce delays the given function
    // until after some wait time has elapsed
    () => debounce((value: boolean) => setIsSuccess(value), RESET_FORM_DELAY),
    []
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (
        inputNameRef.current?.contains(event.target as Node) ||
        productMenuRef.current?.contains(event.target as Node) ||
        dropdownRef.current?.contains(event.target as Node)
      )
        return;
      activateShape(shape.id);
    },
    [activateShape, shape.id]
  );

  const handleSubmitName = useCallback(
    (name: string) => {
      editShapeName(shape.id, name);
    },
    [editShapeName, shape.id]
  );

  const isFocused = useMemo(
    () => activeShapeIndices.has(shape.id),
    [activeShapeIndices, shape.id]
  );

  useEffect(() => {
    if (previousName && name !== previousName) {
      setIsSuccess(true);
    }
    debounceSetIsSuccess(false);
  }, [debounceSetIsSuccess, name, previousName]);

  return (
    <li
      className={classNames('layer', { isFocused }, shape.dataType)}
      onClick={handleClick}
    >
      {isFocused ? (
        <>
          <Cell
            ref={inputNameRef}
            className='layer-name'
            validationSchema={selectionNameSchema}
            isSuccessful={isSuccess}
            value={name}
            onSubmit={handleSubmitName}
            onBlur={() => setIsNameFocused(false)}
            onFocus={() => setIsNameFocused(true)}
          />
          {!isNameFocused ? (
            <div className='layer-actions'>
              <LayerMeatballDropdown ref={dropdownRef} data={shape} />
              <LayerProductMenu ref={productMenuRef} shape={shape} />
            </div>
          ) : null}
        </>
      ) : (
        <>
          <div className='layer-name'>
            <span className='layer-name-text'>{shape.name}</span>
          </div>
          <div className='layer-actions'>
            <div className='indicator-wrapper'>
              {/* @ts-expect-error props */}
              <ProductIndicator categoryName={shape.dataType} isSecondary />
            </div>
          </div>
        </>
      )}
    </li>
  );
}
