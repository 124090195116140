import React, { useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useRealtimeContext } from '../../RealtimeContext';
import OrderWizard from '../../../pages/billing/OrderWizard/OrderWizard';
import { useCartContext } from '../../CartContext';
import { getJob, toggleRightMenuVisibility } from '../../../redux/actions';
import CollapseButton from '../../../components/button/CollapseButton';
import text from '../../../text';
import CartTable from './CartTable';
import SmartSelectionsButton from './SmartSelectionButton';
import { PRODUCT_TYPE } from '../../../constants/product';
import AddOnButton, { useAddOnButtonProps } from './AddOnButton';
import Loading from '../../../components/loading/Loading';
import { useGetJobQuery } from '../../../redux/rtk';
import { BASE_DATA_TYPES, getBaseDataType } from '../../lib/dataType';
import ExpandableCheckoutBox from './ExpandableCheckoutBox';

const Cart = () => {
  const { jobId, shapeDetails } = useRealtimeContext();
  const { data: job } = useGetJobQuery(jobId);
  const {
    isCartLoading,
    isCartEmpty,
    cartItemsAutomatic,
    cartItemsManual,
    cartItemsAddOn,
  } = useCartContext();

  const layout = useSelector((state: any) => state.layout);
  const dispatch = useDispatch();

  // TODO: remove, we should be using useGetJobQuery
  useEffect(() => {
    dispatch(getJob(jobId));
  }, [dispatch, jobId]);

  const hasSomeBaseDataType = useMemo(() => {
    return shapeDetails?.some((shape) =>
      BASE_DATA_TYPES.some((t) => t === shape.dataType)
    );
  }, [shapeDetails]);

  const canShowSmartSelectionButton = useMemo(() => {
    const hasPlaceGeometry = !!job?.place_geometry;
    const bimAddOns = cartItemsAddOn.filter((item) =>
      [PRODUCT_TYPE.BIM_EXTERIOR, PRODUCT_TYPE.BIM_INTERIOR].includes(
        item.dataType
      )
    );
    const _2dAddOns = cartItemsAddOn.filter((item) =>
      [
        PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
        PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
      ].includes(item.dataType)
    );
    return {
      automatic: hasPlaceGeometry && cartItemsAutomatic.length === 0,
      manual: hasPlaceGeometry && cartItemsManual.length === 0,
      addOns: {
        bim: hasPlaceGeometry && bimAddOns.length === 0 && hasSomeBaseDataType,
        '2d': hasPlaceGeometry && _2dAddOns.length === 0 && hasSomeBaseDataType,
      },
    };
  }, [
    cartItemsAddOn,
    cartItemsAutomatic.length,
    cartItemsManual.length,
    hasSomeBaseDataType,
    job?.place_geometry,
  ]);

  const bimAddOnButtonProps = useAddOnButtonProps('bim');
  const _2dAddOnButtonProps = useAddOnButtonProps('2d');

  return (
    <div
      className={classnames('RightMenuV2 overflow-hidden', {
        show: layout.rightMenu.isVisible,
      })}
    >
      {!isCartLoading ? (
        <>
          <OrderWizard
            isOnlyBreadcrumbs
            defaultStep={0}
            isVisible={!isCartEmpty}
          />

          {layout.rightMenu.isVisible ? (
            <CollapseButton
              id='rightMenuCollapseButton'
              openDirection='left'
              isOpen={layout.rightMenu.isVisible}
              closeText={text('order')}
              tooltipProps={{
                text: text(
                  layout.rightMenu.isVisible ? 'collapsePanel' : 'expandPanel'
                ),
                placement: 'left',
              }}
              onClick={() => {
                dispatch(
                  toggleRightMenuVisibility(!layout.rightMenu.isVisible)
                );
              }}
            />
          ) : null}

          <div className='d-flex flex-column flex-fill overflow-hidden'>
            <div className='scrollbar-inner flex-fill overflow-auto'>
              <CartTable />

              <div className='position-relative'>
                {canShowSmartSelectionButton.automatic ? (
                  <>
                    <hr />
                    <h6 className='mt-2 text-uppercase'>{text('buyNow')}</h6>
                    <SmartSelectionsButton deliveryMethod='automatic' />
                  </>
                ) : null}

                {canShowSmartSelectionButton.manual ? (
                  <>
                    <hr />
                    <h6 className='mt-2 text-uppercase'>{text('getQuote')}</h6>
                    <SmartSelectionsButton deliveryMethod='manual' />
                  </>
                ) : null}

                {canShowSmartSelectionButton.addOns.bim ? (
                  <AddOnButton {...bimAddOnButtonProps} />
                ) : null}

                {canShowSmartSelectionButton.addOns['2d'] ? (
                  <AddOnButton {..._2dAddOnButtonProps} />
                ) : null}
              </div>
            </div>
            <ExpandableCheckoutBox />
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Cart;
