import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Loading from '../components/loading/Loading';
import { useOneTimePassword } from '../hooks/useOneTimePassword';
import { useRedirectTo } from '../hooks/useRedirect';
import routes from '../routes/constants';
import { captureError } from '../utilities/error';

const ViewerRedirect = () => {
  const { generateOtp } = useOneTimePassword();
  const redirecter = useRedirectTo();
  const { projectId } = useParams();
  useEffect(() => {
    (async () => {
      const otp = await generateOtp();
      if (!otp) throw new Error('Failed to generate OTP');
      redirecter.push(
        projectId
          ? routes.view3DExternal.project(projectId)
          : routes.view3DExternal.root,
        {
          preserveQueryParams: true,
          queryParams: { otp: encodeURIComponent(otp) },
        }
      );
    })().catch(captureError);
  }, [generateOtp, projectId, redirecter]);
  return <Loading />;
};

export default ViewerRedirect;
