import {
  CompositeLayer,
  GetPickingInfoParams,
  PickingInfo,
} from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import { svgToDataUrl } from '../svg';
import { MidpointFeature, PickingExtraInfo_ } from '../lib/shape';

export type AddVertexLayerPickingDataT_ = {
  popup: 'add';
  midpoint: MidpointFeature;
};

type AddVertexLayerState = {
  popup: AddVertexLayerPickingDataT_ | null;
};

export default class AddVertexLayer extends CompositeLayer {
  declare state: AddVertexLayerState;

  initializeState() {
    this.state = { popup: null };
  }

  _setState(_state: Partial<AddVertexLayerState>) {
    this.setState(_state);
  }

  getPickingInfo({
    info,
  }: GetPickingInfoParams<
    AddVertexLayerPickingDataT_,
    PickingExtraInfo_
  >): PickingInfo<AddVertexLayerPickingDataT_, PickingExtraInfo_> {
    if (info.object?.popup === 'add') {
      info.popup = info.object;
    }
    return info;
  }

  renderLayers() {
    return [
      new IconLayer({
        ...this.getSubLayerProps({
          id: 'add-vertex-layer', // important, errors otherwise
          pickable: true,
        }),
        data: this.state.popup ? [this.state.popup] : [],
        getSize: 36,
        getIcon: () => ({
          url: svgToDataUrl(PLUS_SVG),
          width: 36,
          height: 36,
        }),
        getPixelOffset: [0, -30],
        getPosition: (popup) =>
          popup.midpoint.geometry.coordinates as [number, number],
      }),
    ];
  }
}

const PLUS_SVG = `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="18" fill="#fff"/>
<g transform="translate(6,6)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 21V3H14V21H10Z" fill="#31A283"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21 14L3 14L3 10L21 10L21 14Z" fill="#31A283"/>
</g>
</svg>`;
