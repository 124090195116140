import React, {
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloatingNodeId,
  useFloatingTree,
  useInteractions,
  UseInteractionsReturn,
  useListNavigation,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import { useFloating } from '@floating-ui/react';
import fp from 'lodash/fp';

import { useCartContext } from '../../../../../CartContext';
import { useRealtimeContext } from '../../../../../RealtimeContext';
import { useClickOutside } from '../../../../../../hooks/useClickOutside';
import {
  DataType,
  DerivedDataType,
  PointCloudDataType,
} from '../../../../../lib/dataType';
import useEventListener from '../../../../../../hooks/useEventListener';
import { Modal } from 'react-bootstrap';
import Body from './Body';
import Header from './Header';

const HIDE_PREVIEW = true;
const HIDE_SCREENSHOT = true;
const SHOW_HEADER = !HIDE_PREVIEW || !HIDE_SCREENSHOT;

export const ProductMenuContext = createContext<{
  isOpen: boolean;
  getItemProps: UseInteractionsReturn['getItemProps'];
  activeIndex: number | null;
  setActiveIndex: (index: number | null) => void;
}>({
  isOpen: false,
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
});

const ProductMenu = forwardRef<
  HTMLDivElement,
  { dataType: PointCloudDataType | DerivedDataType; children: React.ReactNode }
>(({ dataType, children }, forwardedRef) => {
  const { deactivateAllShapes } = useRealtimeContext();
  const { cartRootRef, selectedDataType, setSelectedDataType } =
    useCartContext();

  const isOpen = useMemo(
    () => selectedDataType === dataType,
    [selectedDataType, dataType]
  );

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const elementsRef = useRef<HTMLDivElement[]>([]);
  const labelsRef = React.useRef<(string | null)[]>([]);

  const nodeId = useFloatingNodeId();

  const { refs, floatingStyles, context } = useFloating({
    nodeId,
    open: isOpen,
    onOpenChange: (open) => {
      setSelectedDataType(open ? dataType : null);
      if (!open) {
        deactivateAllShapes();
      }
    },
    placement: 'left',
    middleware: [offset({ mainAxis: 32 }), flip(), shift({ padding: 16 })],
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context, { role: 'menu' });
  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePress: false,
    bubbles: true,
  });
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: false,
    onNavigate: setActiveIndex,
  });

  const { getFloatingProps, getReferenceProps, getItemProps } = useInteractions(
    [role, click, dismiss, listNavigation]
  );

  return (
    <FloatingNode id={nodeId}>
      <div
        ref={useMergeRefs([refs.setReference, forwardedRef])}
        data-open={isOpen ? 'true' : undefined}
        {...getReferenceProps()}
      >
        {children}
      </div>
      <ProductMenuContext.Provider
        value={{ isOpen, getItemProps, activeIndex, setActiveIndex }}
      >
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {isOpen ? (
            <FloatingPortal root={cartRootRef}>
              {/* <FloatingFocusManager
                context={context}
                modal={false}
                returnFocus
              > */}
              <Modal.Dialog
                ref={refs.setFloating}
                style={{ ...floatingStyles, zIndex: 1000 }}
                {...getFloatingProps({
                  id: 'product-modal',
                })}
              >
                {SHOW_HEADER ? (
                  <Header
                    isPreviewButtonHidden={HIDE_PREVIEW}
                    isScreenshotButtonHidden={HIDE_SCREENSHOT}
                  />
                ) : null}
                <Body dataType={dataType} isHeaderShown={SHOW_HEADER} />
              </Modal.Dialog>
              {/* </FloatingFocusManager> */}
            </FloatingPortal>
          ) : null}
        </FloatingList>
      </ProductMenuContext.Provider>
    </FloatingNode>
  );
});
ProductMenu.displayName = 'ProductMenu';

export default ProductMenu;
