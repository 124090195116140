import { sumBy } from 'lodash';
import T from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import WhiteContainer from '../../../components/containers/WhiteContainer';
import { getAddOnConfig } from '../../../components/mapView/drawingManager/utilities';
import { MapViewContext } from '../../../components/mapView/mapViewContext';
import {
  BUY_NOW_DATA_TYPES,
  PRODUCT_TYPE,
  RFQ_DATA_TYPES,
  SMART_SELECTION_DELIVERY_METHOD,
} from '../../../constants/product';
import text, { formatCurrency } from '../../../text';
import { isMembershipPriceId } from '../../../utilities/membership';
import { isStaff, userHasActiveMembership } from '../../../utilities/user';

const ForQuotation = ({ numOrder = 0 }) => {
  const {
    state: { buyNowData },
  } = useContext(MapViewContext);
  const { quote, membershipPriceId, currentUser } = useSelector((state) => ({
    quote: state.jobsReducer.job?.quote,
    membershipPriceId: state.order.membershipPriceId,
    currentUser: state.profileReducer.userProfile,
  }));
  const currency = quote?.currency;
  const hasSelectedMembership = isMembershipPriceId(membershipPriceId);
  const isMember =
    (hasSelectedMembership && !isStaff(currentUser.role)) ||
    userHasActiveMembership(currentUser);

  const siteDeploymentFee = useMemo(() => {
    const fee = quote?.fees.find((fee) => fee.id === 'site_deployment_fee');
    return fee?.price.total || 0;
  }, [quote?.fees]);

  const quotableItems = useMemo(() => {
    let _quotableItems = [];
    const buyNowTypeContainsUnknown = quote?.charges?.some(
      ({ details }) =>
        [PRODUCT_TYPE.UNKNOWN].includes(details.delivery_method) &&
        BUY_NOW_DATA_TYPES.includes(details.category_name)
    );
    if (buyNowTypeContainsUnknown) {
      _quotableItems.push(text('tbcBuyNowDataInDatabase'));
    }

    const chargesContainsManual = quote?.charges?.some(
      ({ details }) =>
        [SMART_SELECTION_DELIVERY_METHOD.MANUAL].includes(
          details.delivery_method
        ) || RFQ_DATA_TYPES.includes(details.category_name)
    );
    if (chargesContainsManual) {
      _quotableItems.push(text('getQuote3dScanCaptureServices'));
    }

    const chargesContainsAddOns = quote?.charges?.some(
      ({ details }) => !!getAddOnConfig(details.category_name)
    );
    if (chargesContainsAddOns) {
      _quotableItems.push(text('addOns'));
    }

    return _quotableItems;
  }, [quote?.charges]);

  const chargesHasTbc = useMemo(
    () =>
      quote?.charges?.some(({ details }) =>
        [PRODUCT_TYPE.UNKNOWN].includes(details.delivery_method)
      ),
    [quote?.charges]
  );

  const getQuoteTotal = useMemo(() => {
    if (chargesHasTbc) {
      return text('toBeConfirmed');
    }
    return (
      sumBy(quote?.charges || [], (charge) => {
        const isManualOrUnknown = [
          PRODUCT_TYPE.UNKNOWN,
          SMART_SELECTION_DELIVERY_METHOD.MANUAL,
        ].includes(charge.details.delivery_method);

        if (isManualOrUnknown) {
          const chargeTotal = isMember
            ? charge.price?.member_total
            : charge.price?.total;

          return chargeTotal || 0;
        }

        return 0;
      }) + (siteDeploymentFee || 0)
    );
  }, [chargesHasTbc, isMember, quote?.charges, siteDeploymentFee]);

  if (quotableItems.length <= 0) {
    return null;
  }

  if (!quote) {
    return null;
  }

  return (
    <>
      {buyNowData.length > 0 && <hr className='quote-divider' />}
      <div className='d-flex flex-row align-align-items-center mb-3 get-quote-section justify-content-between'>
        <p className='text-uppercase font-16 grey-3 font-weight--600 mb-0'>
          {text('getQuote')}:
        </p>
        <p className='mb-0 text-right font--14 total'>
          {getQuoteTotal > 0
            ? `~${formatCurrency(
                getQuoteTotal,
                currency.name,
                currency.scale_factor,
                {},
                true
              )}`
            : 'TBC'}
        </p>
      </div>
      <WhiteContainer className='Invoice'>
        {quotableItems.map((headerName, index) => (
          <h6 className='grey-3 font-weight--600 mb-0 my-2' key={index}>
            <span className='mr-1'>{numOrder + (index + 1)}.</span>{' '}
            <span>{headerName}</span>
          </h6>
        ))}

        <div className='mt-2'>
          <h6
            className='grey-3 mb-0 py-2 font-weight--400 pl-3 rfq-note'
            style={{
              lineHeight: '140%',
            }}
          >
            <span key='rfq-note'>
              {text('invoiceRfqNote', {
                contact: (
                  <a
                    className='d-inline'
                    href={`${process.env.LARKI_MARKETING_URL}/contact-us`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    contact LARKI
                  </a>
                ),
              })}
            </span>
          </h6>
        </div>
      </WhiteContainer>
    </>
  );
};

ForQuotation.propTypes = {
  numOrder: T.number,
};

export default ForQuotation;
