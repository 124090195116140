import React, { useContext } from 'react';
import { Navbar as BSNavbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CartContext } from '../../CartContext';
import { toggleRightMenuVisibility } from '../../../redux/actions';
import CartButton1 from '../../../components/button/CartButton';

const CartButton = () => {
  const dispatch = useDispatch();
  const layout = useSelector((state: any) => state.layout);
  const { cartItems } = useContext(CartContext);

  return (
    <CartButton1
      as={BSNavbar.Toggle}
      className='Navbar-Toggle'
      onClick={() => {
        dispatch(toggleRightMenuVisibility(!layout.rightMenu.isVisible));
      }}
      open={layout.rightMenu.isVisible}
      count={cartItems.length}
    />
  );
};

export default CartButton;
