export const USER_COLORS = [
  '#FFBB0C',
  '#8E53EE',
  '#CC1027',
  '#157DAA',
  '#EC7100',
  '#04C28E',
  '#E55384',
  '#203088',
];
