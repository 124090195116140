import * as turf from '@turf/turf';
import * as GeoJSON from 'geojson';

import { PointCloudDataType } from '../../lib/dataType';
import { getBoundingBox, MapShape, shapeUnion } from '../../lib/shape';
import { LayerLabels } from './coverage';
import { geometryLib } from '../../lib/geometry';
import { groupBy } from '../../lib/object';

const MIN_AREA_SQM = 100;

export class CoverageLabels {
  labels: Map<PointCloudDataType, LayerLabels[]> = new Map();

  setLabels(labels: Map<PointCloudDataType, LayerLabels[]>) {
    this.labels = labels;
  }

  repositionLabels(shapes: MapShape[]) {
    const shapesByDataType = groupBy(
      shapes.filter((s) => !s.hidden),
      'dataType'
    );
    this.labels = new Map(
      [...this.labels].map(([dataType, layers]) => {
        const relevantShapes = shapesByDataType.get(dataType);
        const testPoly = relevantShapes?.length
          ? shapeUnion(...relevantShapes)
          : null;
        return [
          dataType,
          layers.map((layer) => {
            const points = turf.points(layer.label_positions.coordinates);
            const pointsWithin = points.features.map((point, i) => {
              layer.label_visibilities[i] = !!testPoly;
              if (!layer.label_validities[i]) {
                layer.label_positions_original.coordinates[i] =
                  geometryLib.visualCenter(
                    turf.polygon(layer.parts.coordinates[i])
                  ).geometry.coordinates;
                layer.label_validities[i] = true;
                point = turf.point(
                  layer.label_positions_original.coordinates[i]
                );
              }
              if (!testPoly) return point;
              const originalPoint = turf.point(
                layer.label_positions_original.coordinates[i]
              );
              if (turf.booleanPointInPolygon(originalPoint, testPoly)) {
                return originalPoint;
              }
              if (turf.booleanPointInPolygon(point, testPoly)) {
                return point;
              }
              const intersect = turf.intersect(
                turf.featureCollection<GeoJSON.Polygon | GeoJSON.MultiPolygon>([
                  turf.polygon(layer.parts.coordinates[i]),
                  turf.feature(testPoly),
                ])
              );
              if (!intersect || turf.area(intersect) < MIN_AREA_SQM) {
                layer.label_visibilities[i] = false;
                return point;
              }
              const centroid = geometryLib.visualCenter(intersect);
              return centroid;
            });
            return {
              ...layer,
              label_positions: turf.multiPoint(
                pointsWithin.map((p) => p.geometry.coordinates)
              ).geometry,
            };
          }),
        ];
      })
    );
  }
}

type UpdateLabelsWorkerRequest = {
  labels?: Map<PointCloudDataType, LayerLabels[]>;
  shapes?: MapShape[];
};

export const serializeUpdateLabelsWorkerRequest = (
  request: UpdateLabelsWorkerRequest
) => ({
  labels: request.labels && Object.fromEntries(request.labels),
  shapes: request.shapes,
});

export type SerializedUpdateLabelsWorkerRequest = ReturnType<
  typeof serializeUpdateLabelsWorkerRequest
>;

export const deserializeUpdateLabelsWorkerRequest = (
  request: SerializedUpdateLabelsWorkerRequest
): UpdateLabelsWorkerRequest => ({
  labels:
    request.labels &&
    new Map(
      Object.entries(request.labels).map(([dataType, labels]) => [
        dataType as PointCloudDataType,
        labels,
      ])
    ),
  shapes: request.shapes,
});

type UpdateLabelsWorkerResponse = {
  labels: Map<PointCloudDataType, LayerLabels[]>;
};

export const serializeUpdateLabelsWorkerResponse = (
  response: UpdateLabelsWorkerResponse
) => ({
  labels: response.labels && Object.fromEntries(response.labels),
});

export type SerializedUpdateLabelsWorkerResponse = ReturnType<
  typeof serializeUpdateLabelsWorkerResponse
>;

export const deserializeUpdateLabelsWorkerResponse = (
  response: SerializedUpdateLabelsWorkerResponse
): UpdateLabelsWorkerResponse => ({
  labels:
    response.labels &&
    new Map(
      Object.entries(response.labels).map(([dataType, labels]) => [
        dataType as PointCloudDataType,
        labels,
      ])
    ),
});
