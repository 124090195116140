import React, { forwardRef, useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { MapShape } from '@larki/mp';

import Icon from '../../../../components/icon/Icon';
import ProductIndicator from '../../../../components/productMenu/ProductIndicator';
import ProductMenu from '../../ProductMenu';
import { ShapeDetails } from '../../../lib/shape';

const LayerProductMenu = forwardRef<HTMLDivElement, { shape: ShapeDetails }>(
  ({ shape }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = useCallback(() => {
      setIsOpen(!isOpen);
    }, [isOpen]);

    return (
      <Dropdown
        ref={ref}
        show={isOpen}
        onToggle={handleToggle}
        className='layer-product-menu'
      >
        <Dropdown.Toggle variant='outline-primary' bsPrefix='toggle'>
          {/* @ts-expect-error props */}
          <ProductIndicator
            categoryName={shape.dataType}
            className={shape.dataType}
          />
          <Icon icon='caret-down' className='toggle-icon' />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <ProductMenu shape={shape} />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

LayerProductMenu.displayName = 'LayerProductMenu';

export default LayerProductMenu;
