import classnames from 'classnames';
import T from 'prop-types';
import React from 'react';

import Img from '../../components/img/Img';
import Icon from '../../components/icon/Icon';

const UserAvatar = (props) => {
  const {
    profileImage,
    className,
    avatarIcon = 'v2',
    hasBorder,
    borderStyle,
    crossOrigin,
  } = props;

  // TODO: should remove avatarIcon props when Account page will be updated/refactored
  if (!profileImage && avatarIcon === 'v2') {
    return (
      <div
        className={classnames(
          'UserAvatar d-flex flex-row justify-content-center align-items-center rounded-circle overflow-hidden pb-1',
          {
            [className]: className,
          }
        )}
        style={{
          background: '#dcdcdc',
          ...(hasBorder && {
            border: borderStyle ? borderStyle : '3px solid #3048c9',
            // if hasBorder = true w/o borderStyle passed, identifiable as the logged user to be highlighted Blue("You")
          }),
        }}
      >
        <Icon icon='avatar-no-profile' />
      </div>
    );
  }

  return (
    <Img
      className={classnames('UserAvatar', {
        [className]: className,
        bordered: hasBorder,
      })}
      img={profileImage || '/public/img/user-silhouette.png'}
      isUrl
      roundedCircle
      {...(crossOrigin && { crossOrigin: 'true' })}
      {...(hasBorder && borderStyle
        ? {
            style: {
              border: borderStyle,
            },
          }
        : null)}
    />
  );
};

UserAvatar.propTypes = {
  profileImage: T.string,
  className: T.string,
  hasBorder: T.bool,
  borderStyle: T.string,
  avatarIcon: T.string,
  crossOrigin: T.bool,
};

export default UserAvatar;
