import { useParams } from 'react-router-dom';

function useUrlParam(name: string, opts?: { int: false }): string;
function useUrlParam(name: string, opts?: { int: true }): number;
function useUrlParam<const T extends string>(
  name: T,
  opts?: { int: boolean }
): string | number {
  const params = useParams<{ [key in T]: string }>();
  const int = opts?.int ?? false;
  const value_ = params[name];
  return int ? parseInt(value_) : value_;
}

export default useUrlParam;
