import React, { createContext, useContext } from 'react';
import { crdtClient } from '@larki/mp';
import invariant from 'tiny-invariant';
import { Mode, ModeAction } from './hooks/useMode';
import { JsonState, MapShape, ShapeDetails } from './lib/shape';
import { DataType, DerivedDataType, PointCloudDataType } from './lib/dataType';
import { DataTypeAvailability } from './services/coverage/coverage';
import { SmartSelection } from '../redux/rtk';

export const RealtimeContext = createContext<{
  client: crdtClient.Client;

  canUndo: boolean;
  undo: () => void;
  canRedo: boolean;
  redo: () => void;

  mode: Mode;
  handleChangeMode: (mode: ModeAction, allowRedundant?: boolean) => void;

  shapeDetails?: ShapeDetails[];
  activeShape: ShapeDetails | null;

  deleteShape: (...ids: string[]) => void;
  deleteAllShapes: () => void;
  deleteAllShapesOfDataType: (
    dataType: PointCloudDataType | DerivedDataType
  ) => void;

  hideShape: (id: string, hide: boolean) => void;
  hideAllShapes: (hide: boolean) => void;
  hideAllShapesOfDataType: (
    dataType: PointCloudDataType | DerivedDataType,
    hide: boolean
  ) => void;
  areAllShapesShown: boolean;
  areAllShapesHidden: boolean;

  activeShapeIds: Set<string>;
  activateShape: (id: string) => void;
  deactivateAllShapes: () => void;

  getDerivedShapes: (id: string) => MapShape[];
  changeDataType: (id: string, dataType: DataType) => void;

  editShapeName: (id: string, name: string) => void;

  availability?: DataTypeAvailability;

  jobId: number;

  addSmartSelections: (
    smartSelections: SmartSelection[],
    deliveryMethod: 'manual' | 'automatic'
  ) => void;

  duplicateShape: (
    id: string,
    opts?: {
      newDataType?: DataType;
      newBaseShapeId?: string;
      inTransaction?: boolean;
    }
  ) => string | null;

  onMapChange: () => void;
}>(null!);

export const useRealtimeContext = () => {
  const ctx = useContext(RealtimeContext);
  invariant(ctx, 'missing RealtimeContext.Provider');
  return ctx;
};
