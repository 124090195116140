import * as metroMapUtils from '../../utilities/metromap';
import { mpConstants } from '../constants/mp';

import _ from 'lodash/fp';

export const loadHighResLayersMp =
  (collectionIds, bbox, currentDate = null) =>
  async (dispatch) => {
    dispatch({ type: mpConstants.LOAD_HIGH_RES_LAYERS });
    try {
      const layersArr = (
        await Promise.all(
          collectionIds.map(
            // see https://advancedweb.hu/how-to-use-async-functions-with-array-map-in-javascript/ for how this works
            async (collectionId) =>
              (await metroMapUtils.getHighResLayers(collectionId, bbox)) || []
          )
        )
      ).flat();
      const layers = metroMapUtils.groupHighResLayersByDate(layersArr);
      dispatch({
        type: mpConstants.LOAD_HIGH_RES_LAYERS_SUCCESS,
        payload: layers,
      });
      if (!currentDate || !_.has(currentDate, layers)) {
        const latestDate = _.keys(layers)?.[0];
        dispatch({
          type: mpConstants.SET_HIGH_RES_CURRENT_DATE,
          payload: latestDate,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: mpConstants.LOAD_HIGH_RES_LAYERS_FAILURE,
        payload: 'something went wrong',
      });
    }
  };

export const loadHighResCollectionsMp = () => async (dispatch) => {
  dispatch({ type: mpConstants.LOAD_HIGH_RES_COLLECTIONS });
  try {
    const collections = await metroMapUtils.getHighResCollections();
    dispatch({
      type: mpConstants.LOAD_HIGH_RES_COLLECTIONS_SUCCESS,
      payload: collections,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: mpConstants.LOAD_HIGH_RES_COLLECTIONS_FAILURE,
      payload: 'something went wrong',
    });
  }
};

export const nextHighResLayerMp = () => (dispatch) => {
  dispatch({
    type: mpConstants.NEXT_HIGH_RES_LAYER,
  });
};

export const prevHighResLayerMp = () => (dispatch) => {
  dispatch({
    type: mpConstants.PREV_HIGH_RES_LAYER,
  });
};
