import _ from 'lodash/fp';
import React, {  useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import Icon from '../../../components/icon/Icon';
import { nextHighResLayerMp, prevHighResLayerMp } from '../../../redux/actions';

export const HIGH_RES_DATE_ID = 'high-res-date-stepper-wrapper';

type HighResDateStepperProps = {
  isVisible: boolean;
};

const HighResDateStepper = ({ isVisible }: HighResDateStepperProps) => {
  const dispatch = useDispatch();
  const {
    highRes: { currentDate, layers },
  } = useSelector((state: any) => state.mpReducer);

  const dates = !layers.isLoading && layers.data ? _.keys(layers.data) : null;
  const isCurrentDateLatest = dates ? dates.indexOf(currentDate) === 0 : false;
  const isCurrentDateEarliest = dates
    ? dates.indexOf(currentDate) === dates.length - 1
    : false;
  const isNextDisabled =
    layers.isLoading || !currentDate || isCurrentDateLatest;
  const isPrevDisabled =
    layers.isLoading || !currentDate || isCurrentDateEarliest;

  const formattedDate = useMemo(() => {
    if (currentDate) {
      const date = new Date(currentDate);
      return date.toLocaleDateString('en-AU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }
    return null;
  }, [currentDate]);

  return (
    <div
      id={HIGH_RES_DATE_ID}
      className={classNames('high-res-date', isVisible && 'visible')}
    >
      {formattedDate ? (
        <>
          <button
            type='button'
            className='action left'
            disabled={isPrevDisabled}
            onClick={() => dispatch(nextHighResLayerMp())}
          >
            <Icon icon='chevron-left' />
          </button>
          <div className='date'>{formattedDate}</div>
          <button
            type='button'
            className='action right'
            disabled={isNextDisabled}
            onClick={() => dispatch(prevHighResLayerMp())}
          >
            <Icon icon='chevron-right' />
          </button>
        </>
      ) : null}
    </div>
  );
};

export default HighResDateStepper;
