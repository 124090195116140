import { z } from 'zod';

export const handshakeQuerySchema = z.object({
  clientId: z.string(),
  clientName: z.string().nullish(),
  clientImage: z.string().nullish(),
  roomId: z.string(),
});
export type HandshakeQuery = z.infer<typeof handshakeQuerySchema>;

export type AckResponse =
  | { status: 'success' }
  | { status: 'error'; message: string };
export type AckCallback = (res: AckResponse) => void;

export const isBuffer = (bytes: unknown): bytes is Uint8Array | ArrayBuffer => {
  return bytes instanceof Uint8Array || bytes instanceof ArrayBuffer;
};
export const bufferToUint8Array = (bytes: Uint8Array | ArrayBuffer) => {
  return bytes instanceof Uint8Array ? bytes : new Uint8Array(bytes);
};

export const updateRequestSchema = z.object({
  main: z
    .union([z.instanceof(Uint8Array), z.instanceof(ArrayBuffer)])
    .optional(),
  temp: z
    .union([z.instanceof(Uint8Array), z.instanceof(ArrayBuffer)])
    .optional(),
  instant: z.boolean(),
});
export type UpdateRequest = z.infer<typeof updateRequestSchema>;

export const updateResponseSchema = z.object({
  bytes: z.object({
    main: z
      .union([z.instanceof(Uint8Array), z.instanceof(ArrayBuffer)])
      .optional(),
    temp: z
      .union([z.instanceof(Uint8Array), z.instanceof(ArrayBuffer)])
      .optional(),
  }),
  senderId: z.string().optional(),
  cursor: z.string().optional(),
});
export type UpdateResponse = z.infer<typeof updateResponseSchema>;
