import React, { createElement, useMemo } from 'react';

type MatchedSubstring = { length: number; offset: number };

type Chunk = {
  text: string;
  highlight: boolean;
};

const chunkify = ({
  text,
  matchedSubstrings,
}: {
  text: string;
  matchedSubstrings: MatchedSubstring[];
}) => {
  const chunks: Chunk[] = [];
  let lastIndex = 0;
  for (const { length, offset } of matchedSubstrings) {
    if (offset > lastIndex) {
      chunks.push({
        text: text.substring(lastIndex, offset),
        highlight: false,
      });
    }
    chunks.push({
      text: text.substring(offset, offset + length),
      highlight: true,
    });
    lastIndex = offset + length;
  }
  if (lastIndex < text.length) {
    chunks.push({ text: text.substring(lastIndex), highlight: false });
  }
  return chunks;
};

const Highlighter = ({
  text,
  matchedSubstrings,
  highlightClassName = 'highlight',
}: {
  text: string;
  matchedSubstrings: MatchedSubstring[];
  highlightClassName?: string;
}) => {
  const chunks = useMemo(
    () => chunkify({ text, matchedSubstrings }),
    [text, matchedSubstrings]
  );
  return createElement(
    'span',
    {},
    chunks.map(({ text, highlight }, index) => {
      return createElement(
        'span',
        {
          key: index,
          className: highlight ? highlightClassName : undefined,
        },
        text
      );
    })
  );
};

export default Highlighter;
