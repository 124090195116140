import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import * as fp from 'lodash/fp';
import { components, SingleValueProps } from 'react-select';
import { Nav, Navbar as BSNavbar } from 'react-bootstrap';

import useUser from '../../hooks/useUser';
import { useGetUserProjectsQuery } from '../../../redux/rtk';
import Select from '../../../components/select/Select';
import { getProjectName } from '../../../utilities/project';
import text from '../../../text';

type Option = {
  id: number;
  project: {
    id: number;
    name?: string | null;
  };
};

const Label = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => <h6 className={cn('JobLabel', className)}>{children}</h6>;

const SingleValue = ({ children, ...props }: SingleValueProps) => (
  <components.SingleValue className='JobSelectControl' {...props}>
    <Label>{children}</Label>
  </components.SingleValue>
);

const ProjectSelect = ({
  jobId,
  onJobChange = fp.noop,
  filterPredicate = fp.stubTrue,
}: {
  jobId?: number;
  onJobChange?: (next: Option, prev?: Option | null) => void;
  filterPredicate?: (project: Option) => boolean;
}) => {
  const { isAdmin, isSuccess } = useUser();
  const { data, isLoading } = useGetUserProjectsQuery(
    { isAdmin },
    {
      // only fetch projects once user query has completed, since `isAdmin` is a dependency
      skip: !isSuccess,
    }
  );

  const projectId = useMemo(
    () => (jobId ? data?.jobToProjectId[jobId] : null),
    [data?.jobToProjectId, jobId]
  );
  const currentProject = useMemo(
    (): Option | null =>
      projectId ? (data?.projects[projectId] ?? null) : null,
    [data?.projects, projectId]
  );

  const [options, setOptions] = useState<Option[]>([]);

  const projectText = `${text(isAdmin ? 'administrationMap2D' : 'map2D')}:`;

  useEffect(() => {
    setOptions(
      fp.orderBy(
        ['updated_at'],
        ['desc'],
        fp.filter(filterPredicate, data?.projects)
      )
    );
  }, [data?.projects, filterPredicate]);

  return (
    <div className='Titlebar'>
      <Nav className='Title Navbar-Item'>
        <BSNavbar>
          <span>{projectText}</span>
          {jobId ? (
            <Select
              options={options}
              value={currentProject}
              onChange={(option: Option) => {
                onJobChange(option, currentProject);
              }}
              getOptionLabel={(option: Option) =>
                getProjectName(option.project)
              }
              getOptionValue={(option: Option) => option.project.id}
              formatOptionLabel={(option: Option) =>
                getProjectName(option.project)
              }
              className='JobSelect'
              components={{ SingleValue }}
              isDisabled={isLoading}
              isLoading={isLoading}
            />
          ) : null}
        </BSNavbar>
      </Nav>
    </div>
  );
};

export default ProjectSelect;
