import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import routerHistory from '../routes/history';

export function isValidHttpUrl(string) {
  // https://stackoverflow.com/a/43467144
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const getRedirectUrl = (
  to,
  preserveQueryParams = false,
  queryParams = {}
) => {
  const searchParams = new URLSearchParams(
    preserveQueryParams ? window.location.search : undefined
  );
  for (const [key, value] of Object.entries(queryParams)) {
    searchParams.append(key, value);
  }
  if (isValidHttpUrl(to)) {
    const url = new URL(to);
    for (const [key, value] of searchParams) {
      url.searchParams.append(key, value);
    }
    return url;
  } else {
    const url = new URL(`${process.env.LARKI_APP_URL}${to}`);
    for (const [key, value] of searchParams) {
      url.searchParams.append(key, value);
    }
    return url;
  }
};

export const isUrlExternal = (url) => {
  if (url.hostname === 'localhost') {
    return url.host !== window.location.host; // on localhost also check the port
  }
  return url.hostname !== window.location.hostname;
};

export const redirectTo = (
  to,
  _history = undefined,
  _preserveQueryParams = false,
  _queryParams = {}
) => {
  const history = _history ?? routerHistory;
  const url = getRedirectUrl(to, _preserveQueryParams, _queryParams);
  const external = isUrlExternal(url);
  if (external) {
    window.location.replace(url);
  } else {
    history.push({
      pathname: url.pathname,
      search: url.search,
    });
  }
};

export const useRedirectTo = () => {
  const history = useHistory();
  const push = useCallback(
    (
      to,
      { preserveQueryParams, queryParams } = {
        preserveQueryParams: false,
        queryParams: {},
      }
    ) => {
      redirectTo(to, history, preserveQueryParams, queryParams);
    },
    [history]
  );
  return useMemo(
    () => ({
      push,
    }),
    [push]
  );
};
