import { addYears } from 'date-fns';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import Button from '../../components/button/Button';
import List from '../../components/list/List';
import Loading from '../../components/loading/Loading';
import { CANCEL_MEMBERSHIP_MODAL } from '../../components/modal/CancelMembershipModal';
import { BILLING_CYCLE, MEMBERSHIP } from '../../constants/membership';
import { showModal } from '../../redux/actions/index';
import routes from '../../routes/constants';
import text from '../../text';
import { client } from '../../utilities/api';
import { formatDate } from '../../utilities/date';
import {
  getTrialResidualDays,
  isTrialMembership,
} from '../../utilities/membership';
import {
  getUserMembership,
  isUserGovernment,
  isUserUnderTrial,
} from '../../utilities/user';

const MEMBERSHIP_TEXT = {
  [MEMBERSHIP.MONTHLY.PRICE_ID]: {
    CONTRACT_MESSAGE: text('monthlyContractMessage'),
  },
  [MEMBERSHIP.YEARLY.PRICE_ID]: {
    CONTRACT_MESSAGE: text('annualContractMessage'),
  },
};

const getBillingCycleDisplayName = (membership) =>
  membership?.billing_cycle && text(BILLING_CYCLE[membership?.billing_cycle]);

const Membership = (props) => {
  const { membership, currentUser, onCancelMembership } = props;
  const history = useHistory();
  const [membershipDetailsState, getMembershipDetails] = useAsyncFn(
    async () => {
      const { data } = await client.getUserMembership();
      return data;
    }
  );

  useEffect(() => {
    if (currentUser) {
      getMembershipDetails();
    }
  }, []);

  return (
    <div className='Membership'>
      {!membershipDetailsState.loading ? (
        <>
          {membership && (
            <>
              <h4 className='mb-2'>
                {isUserUnderTrial(currentUser)
                  ? `${text('trial')}*`
                  : membership.name}
              </h4>

              <div className='mb-4'>
                <p className='mb-1'>
                  {isUserUnderTrial(currentUser)
                    ? text('trialMembershipSummary')
                    : MEMBERSHIP_TEXT[membership.price_id]?.CONTRACT_MESSAGE}
                </p>
                {isUserUnderTrial(currentUser) && (
                  <p className='m-0'>
                    {text('receiveEmailBeforeTrialExpires')}
                  </p>
                )}
              </div>

              <List isBorderless isPaddingless>
                <tbody>
                  {isTrialMembership(membership) &&
                    getTrialResidualDays(membership) >= 0 && (
                      <List.Item
                        className='pb-2'
                        isColonAppended
                        isValueSecondaryHighlight
                        value={text('numberOfDays', {
                          daysNumber: getTrialResidualDays(membership),
                        })}
                        description={text('trialExpiresIn')}
                      />
                    )}
                  <List.Item
                    className='pb-2'
                    isColonAppended
                    value={getBillingCycleDisplayName(membership)}
                    description={text('billingCycle')}
                  />
                  {membershipDetailsState.value?.next_invoice
                    .payment_attempt && (
                    <List.Item
                      className='pb-2'
                      isColonAppended
                      value={formatDate(
                        membershipDetailsState.value?.next_invoice
                          .payment_attempt
                      )}
                      description={text('nextChargeDate')}
                    />
                  )}
                  <List.Item
                    className='pb-2'
                    isColonAppended
                    value={formatDate(
                      membership.start_date &&
                        addYears(new Date(membership.start_date), 1)
                    )}
                    description={text('contractRenewalDate')}
                  />
                </tbody>
              </List>
            </>
          )}
          <p>
            {text('findOutMoreOnPage', {
              page: (
                <a
                  rel='noreferrer'
                  target='_blank'
                  href={routes.larkiWebsiteUrl.memberships}
                >
                  {text('membershipPage')}
                </a>
              ),
            })}
          </p>
          {!isUserGovernment(currentUser) && (
            <>
              <Button
                className='mb-3'
                onClick={() => history.push(routes.user.membershipManagement)}
              >
                {text('change')}
              </Button>
              <Button
                variant='link'
                className='p-0 secondary'
                onClick={() => onCancelMembership()}
              >
                {text('cancelMembership')}
              </Button>
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const MembershipWithState = () => {
  const { userProfile } = useSelector((state) => state.profileReducer);
  const dispatch = useDispatch();

  return (
    <Membership
      onCancelMembership={(props) => {
        dispatch(showModal(CANCEL_MEMBERSHIP_MODAL, props));
      }}
      membership={getUserMembership(userProfile)}
      currentUser={userProfile}
    />
  );
};

export default MembershipWithState;
