import * as fp from 'lodash/fp';

export function objectMap<K extends string, V, U>(
  iter: (value: V, key: K) => U,
  object?: { [key in K]: V }
) {
  if (!object) return undefined;
  return Object.keys(object).reduce(
    (acc, _key) => {
      const key = _key as K;
      acc[key] = iter(object[key], key);
      return acc;
    },
    {} as { [key in K]: U }
  );
}

export const es6MapMap = <K extends string, V, U>(
  iter: (value: V, key: K) => U,
  map: Map<K, V>
) =>
  [...map].reduce((acc, [key, value]) => {
    acc.set(key, iter(value, key));
    return acc;
  }, new Map<K, U>());

export const objectAssign = <T extends object, const K extends keyof T, U>(
  target: T,
  key: K,
  map: (value: T[K]) => U
) => {
  return fp.set(key, map(target[key]), target) as {
    [key in keyof T]: key extends K ? U : T[key];
  };
};

export const objectAdd = <T extends object, const K extends string, U>(
  target: T,
  key: K,
  value: U
) => {
  return fp.set(key, value, target) as T & { [key in K]: U };
};

export const groupBy = <T extends object, const K extends keyof T>(
  arr: T[],
  key: K
) => {
  const grouped = new Map<T[K], T[]>();
  for (const elem of arr) {
    const val = elem[key];
    if (!val) continue;
    if (!grouped.has(val)) grouped.set(val, []);
    grouped.get(val)!.push(elem);
  }
  return grouped;
};

export class MapWithDefault<K, V> extends Map<K, V> {
  private defaultValue: () => V;

  constructor(defaultValue: () => V, entries?: [K, V][]) {
    super(entries);
    this.defaultValue = defaultValue;
  }

  get(key: K) {
    if (!this.has(key)) {
      this.set(key, this.defaultValue());
    }
    return super.get(key)!;
  }
}
