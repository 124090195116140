import React from 'react';
import classNames from 'classnames';
import HoverableOverlay from '../../components/hoverableOverlay';
import DrawingControlsTooltip from '../../components/mapView/drawingControls/drawingControlsTooltip';
import { useRealtimeContext } from '../RealtimeContext';
import OutlinedText from '../../components/text/OutlinedText';
import text from '../../text';

const DRAWING_CONTROLS_ID = 'drawing-controls';

// use img src rather than Icon component to make the icon look crisp/sharper
const rectangleIcon = '/public/img/rectangle-tool.svg';
const polygonIcon = '/public/img/polygon-tool.svg';
const polylineIcon = '/public/img/polyline-tool.svg';
const booleanIcon = '/public/img/boolean-tool.svg';
const undoIcon = '/public/img/undo-tool.svg';
const redoIcon = '/public/img/redo-tool.svg';

const DrawingControls = () => {
  const { undo, redo, mode, handleChangeMode, activeShape, canUndo, canRedo } =
    useRealtimeContext();

  const isReadOnly = false; // TODO: replace w/ actual state
  const isBooleanToolDisabled =
    (!activeShape || activeShape?.type === 'path') &&
    mode !== 'creating_boolean';

  return (
    <div
      id={DRAWING_CONTROLS_ID}
      className={classNames('DrawingControlsMp', {
        disabled: isReadOnly,
      })}
    >
      <div className='position-relative'>
        {/* @ts-expect-error props */}
        <OutlinedText label={text('Shapes')} svgProps={{ width: '64px' }} />
        <div className='draw-menu-button-group'>
          <DrawingControlsButton
            icon={polygonIcon}
            isReadOnly={isReadOnly}
            isSelected={mode === 'creating_polygon'}
            onClick={() => handleChangeMode('start_creating_polygon')}
            tooltip='Polygon tool'
          />
          <DrawingControlsButton
            icon={rectangleIcon}
            isReadOnly={isReadOnly}
            isSelected={mode === 'creating_rectangle'}
            onClick={() => handleChangeMode('start_creating_rectangle')}
            tooltip='Rectangle tool'
          />
          <DrawingControlsButton
            icon={polylineIcon}
            isReadOnly={isReadOnly}
            isSelected={mode === 'creating_path'}
            onClick={() => handleChangeMode('start_creating_path')}
            tooltip='Path tool'
          />
          <DrawingControlsButton
            icon={booleanIcon}
            isDisabled={isBooleanToolDisabled}
            isReadOnly={isReadOnly}
            isSelected={mode === 'creating_boolean'}
            onClick={() => handleChangeMode('start_creating_boolean')}
            tooltip='Boolean tool'
          />
        </div>
      </div>
      <div className='position-relative'>
        {/* @ts-expect-error props */}
        <OutlinedText label={text('Undo')} svgProps={{ width: '64px' }} />
        <div className='draw-menu-button-group'>
          <DrawingControlsButton
            icon={undoIcon}
            tooltip='Undo'
            onClick={undo}
            isDisabled={!canUndo}
          />
          <DrawingControlsButton
            icon={redoIcon}
            tooltip='Redo'
            onClick={redo}
            isDisabled={!canRedo}
          />
        </div>
      </div>
    </div>
  );
};

type DrawingControlsButtonProps = {
  icon: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  onClick?: () => void;
  tooltip?: string;
};

const DrawingControlsButton = ({
  icon,
  isDisabled,
  isReadOnly,
  isSelected,
  onClick,
  tooltip = '',
}: DrawingControlsButtonProps) => {
  return (
    <HoverableOverlay
      placement='right-start'
      overlay={
        !isDisabled ? <DrawingControlsTooltip text={tooltip} /> : <div />
      }
    >
      <div
        className={classNames('draw-button', {
          disabled: isDisabled || isReadOnly,
          selected: isSelected,
        })}
        onClick={onClick}
      >
        <img src={icon} className='draw-icon' />
      </div>
    </HoverableOverlay>
  );
};

export default DrawingControls;
