import { Position } from "geojson";
import * as turf from "@turf/turf";

const toString = ([lng, lat]: Position): `${number};${number}` =>
  `${lng};${lat}`;
const fromString = (s: string): Position => {
  const p = s.split(";");
  return [parseFloat(p[0]), parseFloat(p[1])];
};
const along = (start: Position, end: Position, t: number): Position => {
  const line = turf.lineString([start, end]);
  const distance = turf.length(line, { units: "meters" });
  const point = turf.along(line, t * distance, { units: "meters" });
  return point.geometry.coordinates;
};
const equal = (a: Position, b: Position) => a.toString()===b.toString();

export const positionLib = { fromString, toString, along, equal };
