import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as fp from 'lodash/fp';

import { hideModal } from '../../../../redux/actions/modal';
import Icon from '../../../icon/Icon';
import text from '../../../../text';
import { Button } from '../../../button';

export const MP_DELETE_SHAPES_MODAL = 'MP_DELETE_SHAPES_MODAL';

export default function MpDeleteShapesModal({
  show,
  onOk = fp.noop,
  handleDelete,
  associatedAddOns = [],
  isMany = false,
}: {
  show: boolean;
  onOk: () => void;
  handleDelete: () => void;
  associatedAddOns?: { display_name: string }[];
  isMany?: boolean;
}) {
  const dispatch = useDispatch();
  const handleHide = () => dispatch(hideModal(MP_DELETE_SHAPES_MODAL));

  const associatedAddOnDisplayNames = fp
    .uniq(associatedAddOns.map(({ display_name }) => display_name))
    .join(', ');

  return (
    <Modal
      centered
      className='DeleteShapesModal'
      onHide={handleHide}
      show={show}
    >
      {/* @ts-expect-error untyped */}
      <Modal.Header className='centered' closeButton>
        <Icon icon='circled-exclamation' />
      </Modal.Header>
      <Modal.Body className='centered'>
        <h3 className='mb-4'>
          {text(isMany ? 'deleteShapesWarning' : 'deleteShapeWarning')}
        </h3>
        {associatedAddOnDisplayNames ? (
          <p className='mb-4 font-16 font-weight-bold'>
            {text('deleteShapesWarningAddOns', {
              addOns: associatedAddOnDisplayNames,
            })}
          </p>
        ) : null}
        <p className='m-0'>
          {text(isMany ? 'deleteShapesMessage' : 'deleteShapeMessage')}
        </p>
      </Modal.Body>
      <Modal.Footer className='flex-column justify-content-center'>
        {/* @ts-expect-error untyped */}
        <Button
          autoFocus
          onClick={() => {
            handleDelete();
            onOk();
            handleHide();
          }}
        >
          {text(isMany ? 'yesDeleteShapes' : 'yesDeleteShape')}
        </Button>
        {/* @ts-expect-error untyped */}
        <Button onClick={handleHide} variant='link'>
          {text('noCancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
