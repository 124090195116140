import {
  Position,
  GeoJsonProperties,
  Feature,
  Polygon,
  MultiPolygon,
  Point,
  BBox,
} from 'geojson';
import * as turf from '@turf/turf';
import polylabel from 'polylabel';
import invariant from 'tiny-invariant';
// @ts-expect-error no types
import * as polyclip from 'polyclip-ts';
import proj4 from 'proj4';

const squaredDistance = (a: Position, b: Position) => {
  const dx = a[0] - b[0];
  const dy = a[1] - b[1];
  return dx * dx + dy * dy;
};

const midpoint = (a: Position, b: Position) => {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
};

/**
 * turf.difference but keeps the properties of the 1st argument
 */
const difference = <P extends GeoJsonProperties>(
  a: Feature<Polygon | MultiPolygon, P>,
  b: Feature<Polygon | MultiPolygon>
): Feature<Polygon | MultiPolygon, P> | null => {
  const diff = turf.difference(turf.featureCollection([a, b]));
  if (!diff) return null;
  const diff2: Feature<Polygon | MultiPolygon, P> = {
    ...diff,
    properties: a.properties,
  };
  return diff2;
};

const getCoords = (geometry: Feature<Point>[]) => {
  return geometry.map((p) => p.geometry.coordinates);
};

const union = <P extends GeoJsonProperties>(
  features: Feature<Polygon | MultiPolygon, P>[],
  properties: P
) => {
  const union: MultiPolygon['coordinates'] | null = polyclip.union(
    ...features.map((f) => f.geometry.coordinates)
  );
  return union ? turf.multiPolygon(union, properties) : null;
};

const bboxIntersect = (bbox1: BBox, bbox2: BBox) => {
  const [minLng1, minLat1, maxLng1, maxLat1] = bbox1;
  const [minLng2, minLat2, maxLng2, maxLat2] = bbox2;
  return (
    minLng1 <= maxLng2 &&
    maxLng1 >= minLng2 &&
    minLat1 <= maxLat2 &&
    maxLat1 >= minLat2
  );
};

const PROJECTION = proj4('WGS84', 'EPSG:3857');
function visualCenter(
  feat: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>
) {
  const geom = feat.type === 'Feature' ? feat.geometry : feat;
  let poly: Feature<Polygon>;
  if (geom.type === 'MultiPolygon') {
    const largest = geom.coordinates.reduce<{
      area: number;
      poly: Feature<Polygon>;
    } | null>((max, current) => {
      const poly = turf.polygon(current);
      const area = turf.area(poly);
      if (!max || area > max.area) {
        return { area, poly };
      }
      return max;
    }, null);
    invariant(largest);
    poly = largest.poly;
  } else {
    poly = turf.feature(geom);
  }
  const points = poly.geometry.coordinates.map((coords) =>
    coords.map(PROJECTION.forward)
  );
  const p = polylabel(points);
  const [cx, cy] = [p[0], p[1]];
  const [cxt, cyt] = PROJECTION.inverse([cx, cy]);
  return turf.point([cxt, cyt]);
}

export const geometryLib = {
  squaredDistance,
  midpoint,
  difference,
  getCoords,
  union,
  bboxIntersect,
  visualCenter,
};
