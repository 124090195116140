import { Color, CompositeLayer } from '@deck.gl/core';
import { IconLayer, TextLayer } from '@deck.gl/layers';
import { Position } from 'geojson';

import { svgToDataUrl } from '../svg';
import { UserSettings } from '../lib/shape';
import { colorLib } from '../lib/color';
//
// TODO: Should save as file? I'm not sure though if it should be inside mp
const CURSOR_SVG = `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                      <path d="M19.6204 41.4616L21.3449 44.0414L26.2947 24.9495L43.7797 19.9998L43.7799 17.4998L3.87451 3.8996L19.6204 41.4616Z" fill="#2A2A2A"/>
                      <path d="M24.476 21.8674L24.1913 21.9412L24.1175 22.2259L19.54 39.8821L3.26033 1.01024L42.1322 17.2899L24.476 21.8674Z" fill="#05E1A4" stroke="#027959"/>
                    </svg>`;
const ICON_WIDTH = 24;
const ICON_HEIGHT = 24;

type CursorLayerState = {
  cursors: Map<string, Position>;
  userSettings: { [clientId: string]: UserSettings | undefined };
};

export default class CursorLayer extends CompositeLayer {
  declare state: CursorLayerState;

  initializeState() {
    this.state = {
      cursors: new Map(),
      userSettings: {},
    };
  }

  _setState(_state: Partial<CursorLayerState>) {
    if (!this.state) return;
    this.setState(_state);
  }

  renderLayers() {
    const data = [...this.state.cursors].map(([id, position]) => ({
      position,
      userSettings: this.state.userSettings[id],
    }));
    return [
      new IconLayer<(typeof data)[number]>({
        id: 'cursor-layer-arrow',
        data,
        getPosition: (c) => c.position as [number, number],
        getIcon: (c) => ({
          url: svgToDataUrl(
            CURSOR_SVG,
            colorLib.getUserColor(c.userSettings?.color_index)
          ),
          width: ICON_WIDTH,
          height: ICON_HEIGHT,
        }),
        getPixelOffset: [8, 10],
        getSize: ICON_WIDTH,
      }),
      new TextLayer<(typeof data)[number]>({
        id: 'cursor-layer-text',
        data,
        getText: (c) => c.userSettings?.name ?? '',
        getAlignmentBaseline: 'center',
        getColor: [255, 255, 255],
        getSize: 12,
        getTextAnchor: 'start',
        pickable: true,
        getPosition: (c) => c.position as [number, number],
        background: true,
        backgroundPadding: [9, 3],
        getBackgroundColor: (c) =>
          colorLib.getUserColor(c.userSettings?.color_index, true),
        fontWeight: 700,
        // Apply pixel offset, rough multiplier on x & y to position bottom right of the arrow
        getPixelOffset: [ICON_WIDTH / 0.6, ICON_HEIGHT / 1.3],
      }),
    ];
  }
}
