import React, { useCallback, useEffect, useMemo, useState } from 'react';
import T from 'prop-types';
import { MapViewContext } from '../../../mapView/mapViewContext';
import { larkiApi } from '../../../../utilities/api';
import { captureError } from '../../../../utilities/error';
import { useSelector } from 'react-redux';

export const showPreview = (productType) => productType === 'aerial';

const PreviewContext = React.createContext();

const PreviewProvider = ({ children }) => {
  const {
    state: { jobCenter },
  } = React.useContext(MapViewContext);
  const { job } = useSelector((state) => state.jobsReducer);
  const jobId = useMemo(() => job?.id, [job]);
  return (
    <PreviewContext.Provider value={{ jobCenter, jobId }}>
      {children}
    </PreviewContext.Provider>
  );
};
PreviewProvider.propTypes = {
  children: T.node.isRequired,
};

const PreviewThumbnail = ({ productType }) => {
  const { jobCenter, jobId } = React.useContext(PreviewContext);
  const [status, setStatus] = useState(null);
  const [preview, setPreview] = useState(null);
  const generatePreview = useCallback(async () => {
    if (!jobId || !productType) {
      console.warn('missing dependencies');
      return;
    }
    const { data: pcdData } = await larkiApi.post(`/engine/preview/pcd`, {
      lng: jobCenter.lng(),
      lat: jobCenter.lat(),
      product_category: productType,
    });
    if (!pcdData) {
      console.warn('no pcd data');
      return;
    }
    const {
      data: { status, preview },
    } = await larkiApi.post(`/job/${jobId}/preview/generate`, {
      pointCloudId: pcdData.id,
      pointCloudUrl: pcdData.url,
    });
    setStatus(status);
    setPreview(preview);
  }, [jobCenter, productType, jobId]);
  useEffect(() => {
    if (!jobCenter || !showPreview(productType) || !jobId) return;
    generatePreview().catch(captureError);
  }, [generatePreview, jobCenter, productType, jobId]);
  return (
    <div>
      {status === 'completed' && preview?.preview_gif_url_signed ? (
        <img
          src={preview.preview_gif_url_signed}
          alt='preview'
          className='preview__gif'
        />
      ) : status === 'processing' ? (
        <div className='preview__loading'>
          <img src='/public/img/larki-logo-preview.svg' alt='preview' />
          <p>Loading Preview...</p>
        </div>
      ) : null}
    </div>
  );
};
PreviewThumbnail.propTypes = {
  productType: T.string.isRequired,
};

const Preview = {
  Context: PreviewContext,
  Provider: PreviewProvider,
  Thumbnail: PreviewThumbnail,
};

export default Preview;
