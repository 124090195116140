import classNames from 'classnames';
import React, { Fragment, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { Wizard } from 'react-use-wizard';
import BreadcrumbStep from '../../../components/wizard/BreadcrumbStep';
import Breadcrumbs from '../../../components/wizard/Breadcrumbs';
import { MEMBERSHIP } from '../../../constants/membership';
import useBreakpoint from '../../../hooks/useBreakpoint';
import {
  setMembershipPriceId,
  setPaymentMethodId,
} from '../../../redux/actions/order';
import { profileSelectors } from '../../../redux/selectors/profile';
import routes from '../../../routes/constants';
import text from '../../../text';
import { isJobPayable } from '../../../utilities/job';
import { isMembershipPriceId } from '../../../utilities/membership';
import {
  isStaff,
  isUserGovernment,
  isUserUnderTrial,
  userHasValidMembership,
} from '../../../utilities/user';
import ConfirmationStep from './ConfirmationStep';
import MembershipStep from './MembershipStep';
import OrderWizardPaymentMethod from './OrderWizardPaymentMethod';
import ProjectDetailsStep from './ProjectDetailsStep';
import { MapViewContext } from '../../../components/mapView/mapViewContext';
import { quoteOrder } from '../../../redux/actions';

const getStartingStep = (step, steps) => {
  let startingStep = 1;
  if (step < steps.length) {
    startingStep = typeof step === 'string' ? !isNaN(+step) && +step : step;
  }
  return startingStep;
};

const RedirectBack = () => {
  const history = useHistory();
  useEffect(() => {
    history.goBack();
  }, []);
  return null;
};

const OrderWizard = ({
  isOnlyBreadcrumbs = false,
  defaultStep = 1,
  isVisible = true,
  isMp = false,
}) => {
  const initialStep = useSearchParam('step');
  const isBreakpointEnabled = useBreakpoint(['xs', 'sm']);
  const dispatch = useDispatch();
  const { job, paymentMethodId, currentUser, membershipPriceId } = useSelector(
    (state) => ({
      job: state.jobsReducer.job,
      membershipPriceId: state.order.membershipPriceId,
      paymentMethodId: state.order.paymentMethodId,
      currentUser: state.profileReducer.userProfile,
    })
  );
  const hasValidMembership = useSelector(profileSelectors.hasValidMembership);

  useEffect(() => {
    if (
      (currentUser && isStaff(currentUser.role)) ||
      (userHasValidMembership(currentUser) && !isUserUnderTrial(currentUser))
    ) {
      dispatch(setMembershipPriceId(MEMBERSHIP.NO_MEMBERSHIP));
    }
  }, [currentUser]);

  if (!job) {
    return null;
  }

  const handlePreviousStepClicked = async (previousStep) => {
    previousStep();

    if (job.id) {
      await dispatch(quoteOrder(job.id, undefined, true, {}, false));
    }
  };

  // TODO: Should we move this to PaymentProvider??
  const orderSteps = [
    {
      component: <RedirectBack />,
      name: text('cart'),
    },
    {
      component: (
        <MembershipStep
          nextButtonProps={{
            label: text(
              isBreakpointEnabled ? 'continue' : 'continueProjectDetails'
            ),
          }}
          membershipPriceId={membershipPriceId}
          onMembershipChange={(membershipPriceIdValue) =>
            dispatch(
              setMembershipPriceId(
                membershipPriceIdValue.target?.value
                  ? membershipPriceIdValue.target?.value
                  : membershipPriceIdValue
              )
            )
          }
          previousButtonProps={{
            onClick: handlePreviousStepClicked,
          }}
        />
      ),
      name: text('member'),
      filter: () => {
        return (
          !process.env.DISABLE_STRIPE &&
          !isUserGovernment(currentUser) &&
          !isStaff(currentUser.role) &&
          (!hasValidMembership || isUserUnderTrial(currentUser))
        );
      },
    },
    {
      component: <ProjectDetailsStep />,
      name: text('projectDetails'),
    },
    {
      component: (
        <OrderWizardPaymentMethod
          paymentMethodId={paymentMethodId}
          setPaymentMethodId={(paymentMethodId) =>
            dispatch(setPaymentMethodId(paymentMethodId))
          }
        />
      ),
      name: text('paymentMethod'),
      filter: () =>
        (!isStaff(currentUser.role) && isJobPayable(job)) ||
        isMembershipPriceId(membershipPriceId),
      disabled: !job?.project?.name,
    },
    {
      component: (
        <ConfirmationStep
          paymentMethodId={paymentMethodId}
          membershipPriceId={membershipPriceId}
        />
      ),
      name: text('confirmation'),
      disabled: !job?.project?.name && !paymentMethodId,
    },
  ];

  const availableSteps = orderSteps.filter((step) =>
    step.filter ? step.filter() : true
  );

  return (
    <div className={classNames('OrderWizard', isVisible && 'visible')}>
      <Wizard
        startIndex={getStartingStep(initialStep ?? defaultStep, availableSteps)}
        header={
          <Breadcrumbs>
            {availableSteps.map(({ name, disabled }, index) => (
              <BreadcrumbStep
                key={index}
                stepIndex={index}
                stepLabel={index + 1}
                name={name}
                disabled={disabled || isOnlyBreadcrumbs}
              />
            ))}
          </Breadcrumbs>
        }
      >
        {availableSteps.map(({ component }, index) => (
          <Fragment key={index}>{!isOnlyBreadcrumbs && component}</Fragment>
        ))}
      </Wizard>
    </div>
  );
};

export default OrderWizard;
