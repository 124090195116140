import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { redirectTo } from '../hooks/useRedirect';
import { alertConstants } from '../redux/constants';
import {
  CLEAR_PENDING_REQUESTS,
  REMOVE_FROM_PENDING_REQUESTS,
  SET_PENDING_REQUESTS,
} from '../redux/constants/api';
import { getUser } from './storage';

export const stripePromise = process.env.DISABLE_STRIPE
  ? null
  : loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

export const larkiApiNoAuthentication = axios.create({
  baseURL: process.env.LARKI_API_URL + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data, headers) => {
      headers['Larki-Version'] = process.env.BUILD_NUMBER;

      return data;
    },
    ...axios.defaults.transformRequest, // don't lose axios' default JSON => string behaviour
  ],
});

export const larkiApi = axios.create({
  baseURL: process.env.LARKI_API_URL + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data, headers) => {
      let sessionToken;
      try {
        sessionToken = getUser().token;
      } catch (err) {
        sessionToken = '<not logged in>'; // trigger usual unauthenticated error path
      }
      headers['Authorization'] = 'Bearer ' + sessionToken;
      headers['Larki-Version'] = process.env.BUILD_NUMBER;

      return data;
    },
    ...axios.defaults.transformRequest, // don't lose axios' default JSON => string behaviour
  ],
});

export const setupInterceptors = ({ dispatch }) => {
  larkiApi.interceptors.request.use((config) => {
    config.requestId = uuidv4();
    dispatch({
      type: SET_PENDING_REQUESTS,
      payload: {
        url: config.url,
        id: config.requestId,
      },
    });
    return config;
  });

  larkiApi.interceptors.response.use(
    (response) => {
      if (response.headers['larki-version-outdated'] === 'true') {
        dispatch({
          type: alertConstants.ALERT_VERSION_OUTDATED,
        });
      }

      dispatch({
        type: REMOVE_FROM_PENDING_REQUESTS,
        payload: response.config.requestId,
      });

      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        localStorage.clear();
        redirectTo(
          `${process.env.LARKI_APP_URL}/login${history.location.search}`
        );
      } else {
        let dispatchType = error.config?.requestId
          ? REMOVE_FROM_PENDING_REQUESTS
          : CLEAR_PENDING_REQUESTS;
        dispatch({
          type: dispatchType,
          payload: error.config?.requestId || null,
        });
        throw error;
      }
    }
  );
};

export const client = {
  // Projects
  listProjects: async ({ isAdmin, status, inviteStatus, includeDemo } = {}) =>
    larkiApi.get('/project', {
      params: { isAdmin, status, inviteStatus, includeDemo },
    }),
  getLatestProject: async ({
    isAdmin,
    inviteStatus,
    includeDemo,
    includeJobs,
    includePointCloud,
  }) =>
    larkiApi.get('/project/latest', {
      params: {
        isAdmin,
        inviteStatus,
        includeDemo,
        includeJobs,
        includePointCloud,
      },
    }),
  listProjectActivities: async (projectId, query) =>
    larkiApi.get(`/project/${projectId}/activities`, { params: { query } }),
  inviteUserToProject: async (projectId, email) =>
    larkiApi.post(`/project/${projectId}/user`, { email }),
  getProjectById: async (
    projectId,
    { includeJobs, includePointCloud, includeQuote = false }
  ) =>
    larkiApi.get(`/project/${projectId}`, {
      params: { includeJobs, includePointCloud, includeQuote },
    }),
  createProject: async (name) => larkiApi.post('/project', { name }),
  removeUserFromProject: async (projectId, email) =>
    larkiApi.delete(`/project/${projectId}/user`, {
      data: {
        email,
      },
    }),
  listProjectUsers: async (projectId) =>
    larkiApi.get(`/project/${projectId}/users`),
  updateProjectName: async (projectId, name) =>
    await larkiApi.put(`/project/${projectId}/name`, {
      name,
    }),
  registerProjectTrialMembers: async (projectId, users) =>
    larkiApi.post(`/project/${projectId}/user/register-trial`, { users }),
  updateUserProjectTrial: async (projectId, userId, newProjectRole) =>
    larkiApi.put(`/project/${projectId}/user/${userId}/project-trial`, {
      projectRole: newProjectRole,
    }),
  mergeProjects: async (projectId, targetProjectId) =>
    larkiApi.post(`/project/${projectId}/merge`, {
      targetProjectId: targetProjectId,
    }),
  generateDownloadAllUrl: async (projectId) =>
    larkiApi.get(`/project/${projectId}/asset/download-url`),
  getDownloadAllUrl: async (projectId, jobId) =>
    larkiApi.get(`/project/${projectId}/asset/download-url/poll/${jobId}`),
  toResumeDownload: async (projectId) =>
    larkiApi.get(`/project/${projectId}/asset/to-resume-download`),
  // Job
  getJobById: async (jobId) => larkiApi.get(`/job/${jobId}`),
  updateJobDetailedStatus: async (jobId, detailedStatus, sendNotification) =>
    larkiApi.put(`/job/${jobId}/detailed-status`, {
      detailedStatus,
      sendNotification,
    }),
  getJobAsset: async (projectId, assetId) =>
    larkiApi.get(`/project/${projectId}/asset/${assetId}/download-url`),
  createActivity: async (projectId, activity) =>
    larkiApi.post(`/project/${projectId}/activity`, { ...activity }),
  updatePointerraId: async (projectId, pointerraId) =>
    larkiApi.put(`/project/${projectId}/pointerra`, { pointerraId }),
  // User
  onboardUser: async (
    { fullName, phoneNumber, profession, isMarketingEnabled },
    coupon
  ) =>
    larkiApi.post(
      '/user/onboarding',
      {
        full_name: fullName,
        phone_number: phoneNumber,
        profession: profession,
        is_marketing_enabled: isMarketingEnabled,
      },
      { params: { coupon } }
    ),
  listUserCards: async () => larkiApi.get('user/cards'),
  createDiscountCode: async (discountCode) =>
    larkiApi.post('/user/discount-code', { discountCode }),
  checkout: async (jobId) => larkiApi.post(`/job/${jobId}/checkout`),
  removeCard: async (paymentMethodId) =>
    larkiApi.delete(`/user/payment-method`, { params: { paymentMethodId } }),
  createPaymentMethodSetup: async () =>
    larkiApi.post(`/user/payment-method-setup`),
  getJobReceipt: async (jobId, paymentIntentId) =>
    larkiApi.get(`/job/${jobId}/receipt`, { params: { paymentIntentId } }),
  getMembershipReceipt: async (paymentIntentId) =>
    larkiApi.get('/user/membership/receipt', { params: { paymentIntentId } }),
  copyJob: (jobId) => larkiApi.post(`/job/${jobId}/copy`),
  deleteActivity: (projectId, activityId) =>
    larkiApi.delete(`/project/${projectId}/activity/${activityId}`),
  deleteAsset: (jobId, assetId) =>
    larkiApi.delete(`/job/${jobId}/asset/${assetId}`),
  getSmartSelections: (place) =>
    larkiApi.post(`/engine/product/selections`, place),
  getSelectionFeaturesFile: (jobId, selections, fileType) =>
    larkiApi.post(`/job/${jobId}/selections/download-url`, {
      selections,
      fileType,
    }),
  getTrialMembershipDetails: () =>
    larkiApi.get('/user/membership/trial-details'),
  createMembership: (priceId, paymentMethodId, isTrial) =>
    larkiApi.post('/user/membership', { priceId, paymentMethodId, isTrial }),
  updateMembership: (priceId, paymentMethodId) =>
    larkiApi.put('/user/membership', { priceId, paymentMethodId }),
  cancelMembership: (subscriptionId) =>
    larkiApi.delete(`/user/membership/${subscriptionId}`),
  refreshCustomer: () => larkiApi.put('/user/customer-refresh'),
  sendQuote: (priceId, email, name, message, jobId) =>
    larkiApi.post('/user/send-quote', {
      priceId,
      name,
      email,
      jobId,
      message,
    }),
  acceptQuote: (quoteId, token) =>
    larkiApi.post(`/user/accept-quote/${quoteId}?token=${token}`),
  getUserMembership: () => larkiApi.get('/user/membership'),
  giveUserTrial: (userId, { priceId, paymentMethodId, days }) =>
    larkiApi.post(`/user/give-trial/${userId}`, {
      priceId,
      paymentMethodId,
      days,
    }),

  createProjectAsset: (projectId, filename, contentType) =>
    larkiApi.post(`/project/${projectId}/asset`, {
      filename,
      contentType,
    }),

  commitProjectAsset: (projectId, assetId) =>
    larkiApi.put(`/project/${projectId}/asset/${assetId}/commit`),

  updateLotAreaState: (jobId, lotAreaState) =>
    larkiApi.put(`/job/${jobId}/lot-area-state`, lotAreaState),
  sendLead: (jobId, type) =>
    larkiApi.post(`/job/${jobId}/lead`, {
      type,
    }),
  updateUserProjectVisibility: (projectId, userId, visibility) =>
    larkiApi.put(`/project/${projectId}/user-visibility`, {
      visibility,
      userId,
    }),

  createBookmark: (projectId, redirectUrl, metadata) =>
    larkiApi.post(`/project/bookmark/${projectId}`, {
      redirect_url: redirectUrl,
      metadata,
    }),
  requestAccess: (shareToken) =>
    larkiApi.post(`/project/bookmark/${shareToken}/request`),
  listRequestAccess: (projectId) =>
    larkiApi.get(`/project/${projectId}/request-access`),
  getRequestAccessCount: (projectId) =>
    larkiApi.get(`/project/${projectId}/request-access/count`),
  updateRequestAccess: (projectId, userId, status) =>
    larkiApi.put(`/project/${projectId}/request-access`, {
      user_id: userId,
      status,
    }),
  openBookmark: (shareToken) => larkiApi.get(`/ln/${shareToken}`),
  updateBookmarkRequest: (bookmarkId, data) =>
    larkiApi.put(`/project/${bookmarkId}/update-bookmark-request`, data),

  generateOtp: () => larkiApi.post('/user/otp'),
  verifyOtp: (otp) => larkiApi.post(`/user/otp/verify/${otp}`),
};

export const eventClient = {
  createPointerraEvent: async (type, point_cloud_id, detail) =>
    larkiApi.post('/event/pointerra', { type, point_cloud_id, detail }),
};

export const tourApi = {
  getTourStatus: async (modalName) =>
    larkiApi.get(`/user/tour-status/${modalName}`),
  putTourStatus: async (modalname) =>
    larkiApi.put(`/user/tour-status/${modalname}`),
};

export const quoteOrderRoute = (jobId = 0) => {
  return {
    url: `/job/${jobId}/order/quote`,
    pattern: /^\/job\/\d+\/order\/quote$/,
  };
};
