import React, { useCallback } from 'react';
import * as visGm from '@vis.gl/react-google-maps';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import NavBar from '../components/NavBar';
import Page from '../../components/page/Page';
import text from '../../text';
import {
  GM_MAP_DISABLED_OPTIONS,
  GM_MAP_INITIAL_OPTIONS,
  GM_MAP_OPTIONS,
  GmMapProvider,
  useGmMapContext,
} from '../components/GmMap';
import GmPlaceAutocomplete, { Place } from '../components/GmAutocomplete';
import { amplitudeTrack } from '../../utilities';
import { AMPLITUDE_EVENTS } from '../../constants/analytics';
import { useCreateJobMutation } from '../../redux/rtk';
import { captureError } from '../../utilities/error';
import { gmLatLngToPointGeometry } from '../lib/gm';
import { useRedirectTo } from '../../hooks/useRedirect';
import { positionLib } from '../lib/position';

const { SITE_ADDRESS_ENTERED } = AMPLITUDE_EVENTS;
const MAP_ID = 'map';

const Consumer = () => {
  const redirect = useRedirectTo();
  const [createNewJob] = useCreateJobMutation();
  const gmMapContext = useGmMapContext();

  const onSelect = useCallback(
    (place: Place) => {
      amplitudeTrack(SITE_ADDRESS_ENTERED.EVENT, {
        action: SITE_ADDRESS_ENTERED.EVENT,
      });

      const address =
        place.route && `${place.streetNumber?.short} ${place.route?.short}`;
      const location = place.geometry?.location;
      const placeGeometry = location ? gmLatLngToPointGeometry(location) : null;
      const queryParams: Record<string, string> = {};
      if (placeGeometry) {
        queryParams.place = positionLib.toString(placeGeometry.coordinates);
      }

      createNewJob({
        projectName: null,
        address,
        city: place.city?.short,
        state: place.state?.short,
        postcode: place.postcode?.short,
        country: place.country?.short,
        place_geometry: placeGeometry,
      })
        .unwrap()
        .then((job) => {
          redirect.push(`/mp/${job.id}`, {
            preserveQueryParams: true,
            queryParams,
          });
        })
        .catch(captureError);
    },
    [createNewJob, redirect]
  );

  return (
    <visGm.Map
      id={MAP_ID}
      mapTypeId='satellite'
      defaultCenter={GM_MAP_INITIAL_OPTIONS.defaultCenter}
      {...gmMapContext.eventListeners}
      {...GM_MAP_OPTIONS}
      {...GM_MAP_DISABLED_OPTIONS}
    >
      {/* TODO: don't use MapControl, it's only visible once tiles have loaded */}
      <visGm.MapControl position={visGm.ControlPosition.TOP_LEFT}>
        <GmPlaceAutocomplete
          className='realtime-map__autocomplete'
          tilesLoaded={gmMapContext.tilesLoaded}
          onSelect={onSelect}
          autoFocus
        />
      </visGm.MapControl>
    </visGm.Map>
  );
};

const NewRealtimeMapPage = () => {
  // @ts-expect-error unknown state
  const layout = useSelector((state) => state.layout);

  return (
    <>
      <NavBar.Provider>
        <NavBar.Brand />
        <NavBar.ProjectSelect />
        <NavBar.ViewModes />
        <NavBar.UserNav />
      </NavBar.Provider>
      {/* @ts-expect-error unknown props */}
      <Page
        id='RealtimeMap'
        className={cn({
          // 'read-only': isReadOnly,
          'layers-panel-visible': layout.layersPanel.isVisible,
          'right-menu-visible': layout.rightMenu.isVisible,
        })}
        showTitlebar={false}
        title={text('map2D')}
      >
        <div className='realtime-map__container'>
          <visGm.APIProvider apiKey={process.env.GCP_MAPS_API_KEY!}>
            <GmMapProvider>
              <Consumer />
            </GmMapProvider>
          </visGm.APIProvider>
        </div>
      </Page>
    </>
  );
};

export default NewRealtimeMapPage;
