import React from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useRealtimeContext } from '../../../../../RealtimeContext';
import { useCartContext } from '../../../../../CartContext';
import Icon from '../../../../../../components/icon/Icon';

export default function CloseButton() {
  const { activeShapeIds, deactivateAllShapes } = useRealtimeContext();
  const { setIsProductModalExpanded, setSelectedDataType } = useCartContext();
  return (
    <Button
      onClick={() => {
        if (activeShapeIds.size > 0) {
          setIsProductModalExpanded(false);
        } else {
          deactivateAllShapes();
        }
        setSelectedDataType(null);
      }}
      variant='light'
      className='row-action icon-button bg-transparent'
    >
      <Icon icon='close' />
    </Button>
  );
}
