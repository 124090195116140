import classnames from 'classnames';
import _ from 'lodash/fp';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  useBoolean,
  useMount,
  usePrevious,
  usePreviousDistinct,
  useUnmount,
} from 'react-use';

import { PulseLoader } from 'react-spinners';
import { MEMBERSHIP } from '../../constants/membership';
import {
  PRODUCT_TYPE,
  SMART_SELECTION_DELIVERY_METHOD,
} from '../../constants/product';
import useWorkerCallback from '../../hooks/useWorkerCallback';
import OrderWizard from '../../pages/billing/OrderWizard/OrderWizard';
import {
  clearSmartSelections,
  createJob,
  hideModal,
  quoteOrder,
  setMembershipPriceId,
  showModal,
  toggleRightMenuVisibility,
  updateJob,
} from '../../redux/actions';
import routes from '../../routes/constants';
import text from '../../text';
import { getNowDate } from '../../utilities/date';
import { isArrayEqual } from '../../utilities/lodash';
import { convertToGeoJson } from '../../utilities/map';
import { isRouteIncluded } from '../../utilities/routes';
import CollapseButton from '../button/CollapseButton';
import { PRODUCT_DATA } from '../mapView/constants';
import { convertGeometries } from '../mapView/drawingManager/utilities';
import ExpandableCheckoutBox from '../mapView/expandableCheckoutBox';
import { MapViewContext } from '../mapView/mapViewContext';
import ProductTooltip from '../mapView/productTooltip/ProductTooltip';
import { FORCE_JOB_UPDATE_MODAL } from '../modal/forceJobUpdateModal';
import { NO_DATA_MODAL } from '../modal/NoDataModal';
import { STATUS } from '../orderListing/consts';
import AddOnButton from './AddOnButton';
import CartTable from './CartTable';
import SmartSelectionsButton from './SmartSelectionsButton';
import Preview from '../modal/ProductModal/components/Preview';

export const RIGHT_MENU_WIDTH_PX = 380; // KEEP THIS in coordination with $md-right-menu-width variable

const ROUTES_CART_BUTTON_OVERLAYED = [
  routes.fileManager.root,
  routes.view3D.root,
];

const isCartButtonOverlayed = isRouteIncluded(ROUTES_CART_BUTTON_OVERLAYED);

const isRightMenuVisible = isRouteIncluded([
  routes.order.root,
  routes.order.job(),
  routes.view3D.project(),
  routes.fileManager.viewProject(),
]);

const RightMenu = () => {
  const isCartButtonOverlay = isCartButtonOverlayed(location.pathname);
  const dispatch = useDispatch();
  const history = useHistory();
  const { jobId } = useParams();
  const { search } = useLocation();
  const { state, actions } = useContext(MapViewContext);
  const [hasNoData, setHasNoData] = useBoolean(false);
  const [hasAddressChanged, setAddressChanged] = useBoolean(false);
  const { mapState, layout, undefinedAddress } = useSelector((reduxState) => ({
    mapState: reduxState.mapReducer,
    layout: reduxState.layout,
    currentUser: reduxState.profileReducer.userProfile,
    job: reduxState.jobsReducer.job,
    undefinedAddress: reduxState.jobsReducer.undefinedAddress,
  }));
  const isReadOnly =
    (!!state.job && state.job?.status !== STATUS.DRAFT) ||
    state.job?.status === STATUS.DEMO;
  const previousSelections = usePrevious(state.selections);
  const previousCoverage = usePrevious(state.coverage);
  const previousDistinctAddress = usePreviousDistinct(state.address);
  const previousDistinctJobId = usePreviousDistinct(jobId);

  useMount(() => dispatch(setMembershipPriceId(MEMBERSHIP.NO_MEMBERSHIP)));

  useEffect(() => {
    if (jobId && previousDistinctJobId && jobId !== previousDistinctJobId) {
      actions.setHasLoadedJob(false);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (jobId && previousDistinctJobId) {
      // actions.setActiveSelectionId(null);
      actions.setGeometries({});
      actions.setGeometriesToLoad({});
    }
  }, [jobId, previousDistinctJobId]);

  useUnmount(() => {
    dispatch(clearSmartSelections());
  });

  // useEffect(() => {
  //   if (state.hasLoadedJob && state.isMapVisible && state.map) {
  //     // Handle when map zoom is too small e.g. selections are far apart
  //     // Focus on latest added selection
  //     if (state.map?.zoom < PREFERRED_MAP_ZOOM && !isEmpty(state.selections)) {
  //       const latestSelection = orderBy(
  //         state.selections,
  //         ['created_at'],
  //         ['desc']
  //       )[0];
  //       state.map?.fitBounds(
  //         getRegionBounds(latestSelection.region),
  //         MAP_BOUNDS_PADDING
  //       );
  //     }
  //   }
  // }, [state.hasLoadedJob, state.isMapVisible]);

  const onHideNoData = () => {
    setHasNoData(false);
    dispatch(hideModal(NO_DATA_MODAL));
  };

  useEffect(() => {
    (async () => {
      if (state.address && !isEqual(previousDistinctAddress, state.address)) {
        setAddressChanged(true);
        if (hasAddressChanged && state.job) {
          await dispatch(
            updateJob({
              address: state.address,
              jobId: state.job.id,
              updatedAt: getNowDate(),
            })
          );
        }
      }
    })();
  }, [state.address]);

  useEffect(() => {
    if (hasAddressChanged && hasNoData) {
      dispatch(
        showModal(NO_DATA_MODAL, {
          onHide: onHideNoData,
        })
      );
    }

    if (hasNoData) {
      setHasNoData(false);
      setAddressChanged(false);
    }
  }, [hasAddressChanged, hasNoData]);

  const quoteSelections = async (selections, property, targetProjectId) => {
    let job_id = jobId;
    let createdJob = false;
    if (!jobId) {
      const job = await onSaveJob(false, targetProjectId);
      job_id = job?.id;
      createdJob = job?.createdJob;
    }

    const request = {
      selections: selections.map((selection) => ({
        id: selection.id,
        region: convertToGeoJson([selection]).features[0].geometry,
        product_id: selection.product_id,
        created_at: selection.created_at,
        ui_state: {
          type: selection.region.type,
          name: selection.name,
          line_path:
            selection.region.type === 'Polyline'
              ? selection.region.include.map((points) => [points[1], points[0]])
              : [],
        },
        parent_selection_id: selection.parent_selection_id || '',
      })),
    };
    const propertyData = !isEmpty(selections) ? property : undefined;

    const data = await dispatch(
      quoteOrder(job_id, request, true, propertyData)
    );

    if (createdJob && job_id) {
      actions.setHasLoadedJob(false);
      history.replace({
        pathname: routes.order.job(job_id),
        search: location.search,
      });
    }

    return data;
  };

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  /**
   * Quote selections when they change.
   */
  useEffect(() => {
    if (
      isReadOnly ||
      !state.isMapVisible ||
      !state.selections ||
      isArrayEqual(state.selections, previousSelections, ['focused'])
    ) {
      return;
    }
    quoteSelections(
      state.selections,
      mapState.boundaries,
      searchParams.get('projectId') || null
    );
  }, [state.selections]);

  /**
   * Update region of active selection when region selection changes.
   */
  useEffect(() => {
    if (
      !state.selections ||
      !state.coverage ||
      !state.debouncedRegionSelection ||
      !state.coverageInRegionSelection ||
      !state.activeSelection
    ) {
      return;
    }
    const region = Object.values(
      convertGeometries(state.debouncedRegionSelection, false)
    )[0].region;
    if (!region) {
      console.warn('No region selection found');
      return null;
    }
    // const layers = _.find(
    //   ['category_name', state.activeSelection.category_name],
    //   state.coverageInRegionSelection
    // )?.layers;
    const newActiveSelection = _.flow(
      _.set('region', region)
      // _.set('layers', layers)
    )(_.find(['id', state.activeSelection.id], state.selections));
    actions.setSelections(
      _.map((selection) => {
        if (selection.id !== state.activeSelection.id) {
          return selection;
        }
        return newActiveSelection;
      })(state.selections)
    );
    actions.setActiveSelectionId(newActiveSelection.id);
  }, [state.coverageInRegionSelection]);

  /**
   * Set `productAvailability` whenever the region selection changes.
   * A product is available iff there are 1 or more layers in the current region selection OR
   * there are 1 or more layers in the default region.
   */
  useEffect(() => {
    const mapped = _.map((productType) => {
      if (!_.isEmpty(state.debouncedRegionSelection)) {
        const regionSelectionLayers = _.find(
          ['category_name', productType],
          state.coverageInRegionSelection
        )?.layers;

        const available =
          (regionSelectionLayers && _.size(regionSelectionLayers) > 0) || false;
        return [productType, available];
      }

      const defaultRegionLayers = _.find(
        ['category_name', productType],
        state.coverageInDefaultRegion
      )?.layers;

      const available =
        (defaultRegionLayers && _.size(defaultRegionLayers) > 0) || false;

      return [productType, available];
    }, PRODUCT_DATA.result);

    const availability = _.fromPairs(mapped);
    actions.setProductAvailability(availability);
  }, [
    state.debouncedRegionSelection,
    state.coverageInRegionSelection,
    state.defaultRegion,
    state.coverageInDefaultRegion,
  ]);

  const getSelectionProductAvailabilityWorker = useMemo(
    () =>
      new Worker(
        new URL(
          '../../workers/getSelectionProductAvailability.worker.js',
          import.meta.url
        )
      ),
    []
  );

  const setSelectionProductAvailability = useWorkerCallback(
    getSelectionProductAvailabilityWorker,
    (data) => {
      actions.setSelectionProductAvailability(data);
    }
  );

  useEffect(() => {
    if (!window?.Worker) {
      return;
    }

    if (
      !state.selections ||
      !state.coverage ||
      (isArrayEqual(state.selections, previousSelections, [
        'focused',
        'visible',
      ]) &&
        isArrayEqual(state.coverage, previousCoverage))
    ) {
      return;
    }

    setSelectionProductAvailability({
      coverage: state.coverage,
      selections: state.selections,
    });
  }, [window?.Worker, state.selections, state.coverage]);

  const onSaveJob = async (force = false, targetProjectId) => {
    const data = {
      address: {
        ...state.address,
      },
      message: state.requestMessage,
      projectName: state.project.name,
      lot_area_state: {
        visibility: false,
        hidden: true,
        // negate default states for new order
        // for better presentation to show only when available
      },
    };
    const jobExists = !!state.job?.id;
    const job = await dispatch(
      jobExists
        ? updateJob({
            ...data,
            jobId: state.job.id,
            updatedAt: state.job.updated_at,
            force,
            onConflict: onShowForceSave,
          })
        : createJob({
            ...data,
            ...(targetProjectId ? { mergeProjectId: targetProjectId } : {}),
            lot_area_state: {
              visibility: !undefinedAddress,
              hidden: undefinedAddress,
            },
          })
    );
    return { ...job, createdJob: !jobExists };
  };

  const onForceSaveAndExit = async () => {
    const job = await onSaveJob(true);

    if (job) {
      history.replace(routes.order.job(job.id));
    }
  };

  const onShowForceSave = () => {
    dispatch(
      showModal(FORCE_JOB_UPDATE_MODAL, {
        onSubmit: onForceSaveAndExit,
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(state.job) && state.hasShadowOverlay) {
      actions.setHasShadowOverlay(false);
    }
  }, [state.job]);

  const shouldShowBuyNowButton =
    state.showAutomatic && !state.isCartEmpty && !isReadOnly;

  const shouldShowScanCaptureButton =
    state.showManual && !state.isCartEmpty && !isReadOnly;

  const noPresentAddOn =
    state.showBimAddOnButton && state.show2DFeaturePlanButton;

  const isAddOnButtonShowable =
    (state.showBimAddOnButton || state.show2DFeaturePlanButton) &&
    !state.isCartEmpty &&
    !isReadOnly;

  const shouldShowAddOnButtonSectionName =
    (noPresentAddOn && isAddOnButtonShowable) || shouldShowBuyNowButton;

  const isLoadingSmartSelections =
    mapState.isLoadingSmartSelections &&
    mapState.loadingRhsButtonIds.length === 0;

  const isStillQuotingOrCheckingSmartSelections =
    state.isCartEmpty && (isLoadingSmartSelections || state.isStillQuoting);

  return (
    <Preview.Provider>
      <div
        className={classnames('RightMenuV2 overflow-hidden', {
          show: layout.rightMenu.isVisible,
          'd-none': !isRightMenuVisible(location.pathname),
        })}
      >
        <OrderWizard
          isOnlyBreadcrumbs
          defaultStep={0}
          isVisible={!state.isCartEmpty}
        />

        {!isCartButtonOverlay || layout.rightMenu.isVisible ? (
          <CollapseButton
            id='rightMenuCollapseButton'
            openDirection='left'
            isOpen={layout.rightMenu.isVisible}
            closeText={text('order')}
            tooltipProps={{
              text: text(
                layout.rightMenu.isVisible ? 'collapsePanel' : 'expandPanel'
              ),
              placement: 'left',
            }}
            onClick={() => {
              actions.setShowProductModal(false);
              dispatch(toggleRightMenuVisibility(!layout.rightMenu.isVisible));
            }}
          />
        ) : null}

        <div className='d-flex flex-column flex-fill overflow-hidden'>
          <div className='scrollbar-inner flex-fill overflow-auto'>
            <CartTable isReadOnly={isReadOnly} />

            <div className='position-relative'>
              {/* BUY NOW section */}
              {shouldShowBuyNowButton ? (
                <>
                  <hr />
                  <h6 className='mt-2 text-uppercase'>{text('buyNow')}</h6>
                </>
              ) : null}

              {!undefinedAddress && (
                <SmartSelectionsButton
                  deliveryType='automatic'
                  show={state.showAutomatic || false}
                  isReadOnly={isReadOnly}
                  buttonId={SMART_SELECTION_DELIVERY_METHOD.AUTOMATIC}
                />
              )}

              {/* GET QUOTE section */}
              {shouldShowScanCaptureButton ? (
                <>
                  <hr />
                  <h6 className='mt-2 text-uppercase'>{text('getQuote')}</h6>
                </>
              ) : null}

              {!undefinedAddress && (
                <SmartSelectionsButton
                  deliveryType='manual'
                  show={state.showManual || false}
                  isReadOnly={isReadOnly}
                  buttonId={SMART_SELECTION_DELIVERY_METHOD.MANUAL}
                />
              )}

              {shouldShowAddOnButtonSectionName ? (
                <>
                  <hr />
                  <h6 className='mt-2'>{text('addOns')}</h6>
                </>
              ) : null}
              {!isReadOnly && (
                <AddOnButton
                  className={classnames({
                    'mt-0': !shouldShowAddOnButtonSectionName,
                  })}
                  show={state.showBimAddOnButton}
                  header={text('getQuoteBim')}
                  description={text('getQuoteBimDetails')}
                  handleClick={() => {
                    actions.createAddOnSelection(null, {}, true, {
                      target: [
                        PRODUCT_TYPE.BIM_EXTERIOR,
                        PRODUCT_TYPE.BIM_INTERIOR,
                      ],
                    });
                  }}
                  tooltip={
                    <ProductTooltip
                      productType={PRODUCT_TYPE.BIM_EXTERIOR}
                      placement='top'
                      title={text('getQuoteBim')}
                      description={text('BIMTooltip')}
                    />
                  }
                />
              )}

              {!isReadOnly && (
                <AddOnButton
                  className={classnames({
                    'mt-0': !shouldShowAddOnButtonSectionName,
                  })}
                  show={state.show2DFeaturePlanButton}
                  header={text('getQuote2dFeaturePlan')}
                  description={text('getQuote2dFeaturePlanDetails')}
                  handleClick={() => {
                    actions.createAddOnSelection(null, {}, true, {
                      target: [
                        PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
                        PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
                      ],
                    });
                  }}
                  tooltip={
                    <ProductTooltip
                      productType={PRODUCT_TYPE.TWOD_PLAN_EXTERIOR}
                      placement='top'
                      title={text('featurePlans2D')}
                      description={text('FeaturePlanToolTip')}
                    />
                  }
                />
              )}
            </div>

            {isStillQuotingOrCheckingSmartSelections ? (
              <div className='loader-container has-backdrop'>
                <PulseLoader color='#31a283' />
              </div>
            ) : null}
          </div>
          {/* INVOICE & SUBMIT BUTTONS */}
          {!state.isCartEmpty && (
            <ExpandableCheckoutBox isReadOnly={isReadOnly} />
          )}
        </div>
      </div>
    </Preview.Provider>
  );
};

export default RightMenu;
