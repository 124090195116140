import React, { useContext } from 'react';
import {
  Accordion,
  AccordionContext,
  Button as BootstrapButton,
  Card,
} from 'react-bootstrap';

import Icon from '../../../components/icon/Icon';
import text from '../../../text';

const Toggle = ({
  children,
  eventKey,
}: {
  children: React.ReactNode;
  eventKey: string;
}) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <>
      <div>{children}</div>
      <BootstrapButton variant='light' className='toggle-button'>
        <Icon icon={isCurrentEventKey ? 'caret-down' : 'caret-up'} />
      </BootstrapButton>
    </>
  );
};

const GuidePanel = () => {
  return (
    <Accordion defaultActiveKey='0'>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey='0'>
          <Toggle eventKey='0'>
            <h6>{text('guide')}</h6>
          </Toggle>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='0'>
          <Card.Body>
            {text('guideInstructions', {
              emphasizeText1: <span className='font-weight-bold'>Shapes</span>,
              emphasizeText2: <span className='font-weight-bold'>Draft</span>,
              emphasizeText3: <span className='font-weight-bold'>type</span>,
              emphasizeText4: <span className='font-weight-bold'>Cart</span>,
              emphasizeText5: (
                <span className='font-weight-bold'>Check-out</span>
              ),
            })}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default GuidePanel;
