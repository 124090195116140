import cn from 'classnames';
import React from 'react';
import { BsPrefixRefForwardingComponent } from 'react-bootstrap/esm/helpers';

import { MapShape, ShapeDetails } from '../../lib/shape';
import ShapeMenuContent from './ShapeMenuContent';
import {
  DataType,
  DerivedDataType,
  PointCloudDataType,
} from '../../lib/dataType';

const ShapeMenu = <
  R extends React.ElementType,
  T extends React.ComponentPropsWithRef<R>,
>({
  myRef,
  data,
  className,
  DropdownMenuComponent,
  dropdownMenuProps: { className: dropdownClassName, ...dropdownMenuProps },
  onDataTypeChanged,
  showShapeName,
}: {
  myRef: React.Ref<HTMLElement>;
  data: ShapeDetails;
  className?: string;
  DropdownMenuComponent: BsPrefixRefForwardingComponent<R>;
  dropdownMenuProps: T;
  onDataTypeChanged?: (dataType: PointCloudDataType | DerivedDataType) => void;
  showShapeName?: boolean;
}) => {
  return (
    // @ts-expect-error bad props
    <DropdownMenuComponent
      ref={myRef}
      className={cn(
        className,
        dropdownClassName,
        'MeatballDropdown shape-menu'
      )}
      {...dropdownMenuProps}
    >
      <ShapeMenuContent data={data} showShapeName={showShapeName} />
    </DropdownMenuComponent>
  );
};

export default ShapeMenu;
