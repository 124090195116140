/* global google */
import fp from 'lodash/fp';
import * as visGm from '@vis.gl/react-google-maps';

type GenerateTileUrlFunction = (params: {
  tileMatrix: number;
  tileRow: number;
  tileCol: number;
}) => string;

type LoadHighResMapType = (
  generateUrl: GenerateTileUrlFunction,
  name: string
) => google.maps.ImageMapType;

/**
 * Gets the date of the tile in the map bounds.
 * @param map - Google Maps map instance.
 * @returns The date in UTC format.
 */
const getTileDate = async (map: google.maps.Map) => {
  const bounds = map.getBounds()?.toJSON();
  if (bounds) {
    const bbox = [bounds.west, bounds.south, bounds.east, bounds.north];
    const response = await fetch(
      `${process.env.AEROMETREX_STAC_URL}/search?bbox=${bbox.join(',')}&limit=1`
    );
    const json = await response.json();
    // TODO: provide appropriate type
    const closestDatetime = (fp.first(json.features) as any)?.properties
      .datetime;
    return closestDatetime;
  }
  return null;
};

const loadHighResMapType: LoadHighResMapType = (generateUrl, name) => {
  console.assert(google.maps);
  return new google.maps.ImageMapType({
    getTileUrl: function (coord, zoom) {
      const tileMatrix = zoom;
      const tileRow = coord.y;
      const tileCol = coord.x;
      return generateUrl({ tileMatrix, tileRow, tileCol });
    },
    tileSize: new google.maps.Size(256, 256),
    maxZoom: 24, // without maxZoom here it crashes.
    minZoom: 0,
    name,
  });
};

const generateAUSMapTileUrl =
  (layer: string): GenerateTileUrlFunction =>
  ({ tileMatrix, tileRow, tileCol }) => {
    const format = 'png';
    const version = '1.0.0';
    const style = 'default';
    const tileMatrixSet = 'webmercator';
    return `${process.env.AEROMETREX_TILES_URL}/${process.env.AEROMETREX_TILES_KEY}/service?SERVICE=WMTS&REQUEST=GetTile&LAYER=${layer}&FORMAT=${format}&VERSION=${version}&STYLE=${style}&TileMatrixSet=${tileMatrixSet}&TileMatrix=${tileMatrix}&TileRow=${tileRow}&TileCol=${tileCol}`;
  };

export const highResLib = {
  getTileDate,
  loadHighResMapType,
  generateAUSMapTileUrl,
};
