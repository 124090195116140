import { Color, CompositeLayer } from '@deck.gl/core';
import * as GeoJSON from 'geojson';
import * as turf from '@turf/turf';
import * as fp from 'lodash/fp';
import {
  TentativeBoolean,
  MapShape,
  pathFeatureFromSequence,
  createPolygonHandle,
  createRectangleHandle,
  createPathHandle,
  createBooleanHandle,
  MapShapeFeature,
  MapShapeFeatureProperties,
  GetPickingInfoParams_,
  PickingInfo_,
  PolygonHandleProperties,
  RectangleHandleProperties,
  PathHandleProperties,
  BooleanHandleProperties,
} from '../lib/shape';
import { Corner, rectangleLib } from '../lib/rectangle';
import { GeoJsonLayer, GeoJsonLayerProps } from '@deck.gl/layers';
import { arrayLib } from '../lib/array';
import { DataFilterExtension } from '@deck.gl/extensions';

const SHAPE_FILL_COLOR: Color = [160, 160, 180, 80];
const SHAPE_LINE_COLOR: Color = [0, 0, 0, 100];
export const HANDLE_LAYER_PROPS: Partial<GeoJsonLayerProps> = {
  pointRadiusUnits: 'meters',
  getPointRadius: 2,
  getLineWidth: 1,
  pointRadiusMinPixels: 6,
  lineWidthMinPixels: 3,
  positionFormat: 'XY',
  stroked: true,
  getFillColor: SHAPE_FILL_COLOR,
  getLineColor: SHAPE_LINE_COLOR,
};
export const SHAPE_LAYER_PROPS: Partial<GeoJsonLayerProps> = {
  getLineWidth: 1,
  lineWidthMinPixels: 4,
  positionFormat: 'XY',
  stroked: true,
  getFillColor: SHAPE_FILL_COLOR,
  getLineColor: SHAPE_LINE_COLOR,
};

type MapHandle =
  | GeoJSON.Feature<GeoJSON.Point, PolygonHandleProperties>
  | GeoJSON.Feature<GeoJSON.Point, RectangleHandleProperties>
  | GeoJSON.Feature<GeoJSON.Point, PathHandleProperties>
  | GeoJSON.Feature<GeoJSON.Point, BooleanHandleProperties>;

type MapShapeLayerState = {
  shapes: MapShape[];
  tentativeBooleans: TentativeBoolean[];
  activeShapeIds: Set<string>;
};
export class MapShapeLayer extends CompositeLayer {
  declare state: MapShapeLayerState;

  initializeState() {
    this.state = {
      shapes: [],
      tentativeBooleans: [],
      activeShapeIds: new Set(),
    };
  }

  _setState(_state: Partial<MapShapeLayerState>) {
    if (!this.state) return;
    // if (
    //   !_state.shapes &&
    //   !_state.tentativeBooleans &&
    //   _state.activeShapeIds === undefined
    // )
    //   return;
    this.setState(_state);
  }

  getPickingInfo({ info }: GetPickingInfoParams_): PickingInfo_ {
    const data = info.object;
    if (data?.type === 'Feature') {
      if (data.properties?.featureType === 'handle') {
        info.handle = data.properties;
      } else if (data.properties?.featureType === 'shape') {
        info.mapShapeFeature = data.properties;
      }
    }
    return info;
  }

  // TODO: use extractFeatureFromPolygonOrRectangle instead
  private _renderPolygon<T extends GeoJSON.GeoJsonProperties>({
    exterior,
    properties,
    holes,
    active = false,
  }: {
    exterior: GeoJSON.Position[];
    properties: T;
    holes?: GeoJSON.Position[][];
    active?: boolean;
  }) {
    const exteriorPoly = turf.polygon([[...exterior, exterior[0]]], properties);
    const validHoles = holes?.filter((h) => h.length >= 3);
    const visualHoleBoundaries = active
      ? holes
          ?.filter((h) => h.length >= 2)
          .map((h) => {
            if (h.length >= 3) return turf.lineString([...h, h[0]], properties);
            return turf.lineString(h, properties);
          })
      : undefined;
    const diff = validHoles?.length
      ? (turf.difference(
          turf.featureCollection([
            exteriorPoly,
            ...validHoles.map((h) => turf.polygon([[...h, h[0]]])),
          ])
        ) ?? exteriorPoly)
      : exteriorPoly;
    return [
      turf.feature(diff.geometry, properties),
      ...(visualHoleBoundaries ?? []),
    ];
  }

  renderLayers() {
    const tentativeBooleansByParent = new Map<number, GeoJSON.Position[][]>();
    for (const bool of this.state.tentativeBooleans) {
      if (!tentativeBooleansByParent.has(bool.parent_index)) {
        tentativeBooleansByParent.set(bool.parent_index, []);
      }
      tentativeBooleansByParent.get(bool.parent_index)!.push(bool.vertices);
    }

    const shapeLayerData = this.state.shapes
      .flatMap<
        | MapShapeFeature
        | GeoJSON.Feature<GeoJSON.LineString, MapShapeFeatureProperties>
        | null
      >((shape, shapeIndex) => {
        // if (shape.hidden) return null;
        if (shape.type === 'polygon') {
          const n = shape.exterior.length;
          if (n <= 2) return null; // hack to prevent invalid polygon error, can occur if users delete two vertices at once
          return this._renderPolygon({
            exterior: shape.exterior,
            properties: {
              featureType: 'shape',
              shapeIndex,
              hidden: shape.hidden,
            },
            holes: shape.holes,
            active: this.state.activeShapeIds.has(shape.id),
          });
        } else if (shape.type === 'rectangle') {
          return this._renderPolygon({
            exterior: [
              shape.exterior[Corner.sw],
              shape.exterior[Corner.se],
              shape.exterior[Corner.ne],
              shape.exterior[Corner.nw],
              shape.exterior[Corner.sw],
            ],
            properties: {
              featureType: 'shape',
              shapeIndex,
              hidden: shape.hidden,
            },
            holes: shape.holes,
            active: this.state.activeShapeIds.has(shape.id),
          });
        } else if (shape.type === 'path') {
          return pathFeatureFromSequence(
            shape.vertices,
            { featureType: 'shape', shapeIndex, hidden: shape.hidden },
            5
          );
        }
        return null;
      })
      .filter(arrayLib.filterNonNull);
    const handleLayerData = this.state.shapes
      .flatMap<MapHandle>((shape, shapeIndex) => {
        if (!this.state.activeShapeIds.has(shape.id)) return [];
        // if (shape.hidden) return [];
        if (shape.type === 'polygon') {
          return [
            ...shape.exterior.map((coord, vertexIndex) =>
              createPolygonHandle(coord, {
                shapeIndex,
                vertexIndex,
                hidden: shape.hidden,
              })
            ),
            ...(shape.holes?.flatMap((hole, holeIndex) =>
              hole.map((coord, vertexIndex) =>
                createBooleanHandle(coord, {
                  shapeIndex,
                  holeIndex,
                  vertexIndex,
                  hidden: shape.hidden,
                })
              )
            ) ?? []),
          ];
        } else if (shape.type === 'rectangle') {
          return [
            ...fp.entries(shape.exterior).map(([corner, coord]) =>
              createRectangleHandle(coord, {
                shapeIndex,
                corner: rectangleLib.cornerFromString(corner),
                hidden: shape.hidden,
              })
            ),
            ...(shape.holes?.flatMap((hole, holeIndex) =>
              hole.map((coord, vertexIndex) =>
                createBooleanHandle(coord, {
                  shapeIndex,
                  holeIndex,
                  vertexIndex,
                  hidden: shape.hidden,
                })
              )
            ) ?? []),
          ];
        } else if (shape.type === 'path') {
          return shape.vertices.map((coord, vertexIndex) =>
            createPathHandle(coord, {
              shapeIndex,
              vertexIndex,
              hidden: shape.hidden,
            })
          );
        }
        return [];
      })
      .filter(arrayLib.filterNonNull);
    return [
      new GeoJsonLayer(
        {
          data: shapeLayerData,
          ...SHAPE_LAYER_PROPS,
        },
        this.getSubLayerProps({
          id: 'shapes',
          getFilterCategory: (d: (typeof shapeLayerData)[number]) =>
            d.properties.hidden ? 0 : 1,
          filterCategories: [1],
          extensions: [new DataFilterExtension({ categorySize: 1 })],
        })
      ),
      new GeoJsonLayer(
        {
          data: handleLayerData,
          ...HANDLE_LAYER_PROPS,
        },
        this.getSubLayerProps({
          id: 'shape-handles',
          getFilterCategory: (d: (typeof handleLayerData)[number]) =>
            d.properties.hidden ? 0 : 1,
          filterCategories: [1],
          extensions: [new DataFilterExtension({ categorySize: 1 })],
        })
      ),
    ];
  }
}
