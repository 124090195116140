import React from 'react';
import classNames from 'classnames';

export const METROMAP_LOGO_ID = 'metromap-logo-wrapper-mp';

type MetromapLogoMpType = {
  isVisible: boolean;
  mapTypeId: string | null | undefined;
};

const MetromapLogoMp = ({ isVisible, mapTypeId }: MetromapLogoMpType) => (
  <div id={METROMAP_LOGO_ID} className={classNames({ is_visible: isVisible })}>
    {mapTypeId === 'highres' ? (
      <img
        className='metromap-logo'
        src='/public/img/metromap_logo.png'
        alt='metromap_logo_red'
      />
    ) : (
      <div className='metromap-logo-datetime-container'>
        <div className='metromap-logo-datetime'>
          © <a href='//www.linz.govt.nz/linz-copyright'>LINZ CC BY 4.0</a> | ©{' '}
          <a href='//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution'>
            Imagery Basemap contributors
          </a>
        </div>
      </div>
    )}
  </div>
);

export default MetromapLogoMp;
