import React, { useMemo } from 'react';
import { MapShape } from '@larki/mp';

import { DataTypeGroup, useCartContext } from '../../../CartContext';
import text from '../../../../text';
import { ProductTableHeader } from '../../../../components/RightMenu/ProductTable';
import { Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import DataTable from '../../../../components/table/dataTable/DataTable';
import { DataType, DerivedDataType } from '../../../lib/dataType';
import ProductRow from './ProductRow';

const HEADER_TEXT: Record<DataTypeGroup, string> = {
  automatic: text('buyNowDataInDatabase'),
  manual: text('getQuoteData'),
  addOn: text('getQuoteAddOns'),
};

type ProductGroupProps = {
  headerText: string;
  cartItems: MapShape[];
  cartItemsByDataType: Map<DataType | DerivedDataType, MapShape[]>;
};

export const useProductGroupProps = (
  group: DataTypeGroup
): ProductGroupProps => {
  const layout = useSelector((state: any) => state.layout);

  const { cartItemsAutomatic, cartItemsManual, cartItemsAddOn } =
    useCartContext();

  const headerText = HEADER_TEXT[group];

  const cartItems = useMemo(() => {
    if (group === 'automatic') return cartItemsAutomatic;
    if (group === 'manual') return cartItemsManual;
    return cartItemsAddOn;
  }, [group, cartItemsAutomatic, cartItemsManual, cartItemsAddOn]);

  const cartItemsByDataType = useMemo(() => {
    const result = new Map<DataType | DerivedDataType, MapShape[]>();
    cartItems.forEach((item) => {
      const dataType = item.dataType as DataType | DerivedDataType;
      const existing = result.get(dataType) ?? [];
      result.set(dataType, existing.concat(item));
    });
    return result;
  }, [cartItems]);

  return {
    headerText,
    cartItems,
    cartItemsByDataType,
  };
};

const ProductGroup = ({
  headerText,
  cartItems,
  cartItemsByDataType,
}: ProductGroupProps) => {
  if (cartItems.length === 0) return null;

  return (
    <>
      <ProductTableHeader headerText={headerText} />
      <Collapse in={cartItems.length > 0}>
        <div>
          <DataTable className='DataTableV2'>
            {cartItems.length > 0 ? (
              <>
                <tbody>
                  {[...cartItemsByDataType].map(([dataType, shapes]) => (
                    <ProductRow
                      key={dataType}
                      dataType={dataType}
                      shapes={shapes}
                    />
                  ))}
                </tbody>
                <div />
              </>
            ) : null}
          </DataTable>
        </div>
      </Collapse>
    </>
  );
};

export default ProductGroup;
