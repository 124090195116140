import React, { createContext, useContext, useState } from 'react';
import { Navbar as BSNavbar } from 'react-bootstrap';
import cn from 'classnames';
import invariant from 'tiny-invariant';

import Link from '../../../components/Link/Link';
import Icon from '../../../components/icon/Icon';
import routes from '../../../routes/constants';
import UserNav from './UserNav';
import ProjectSelect from './ProjectSelect';
import ViewModes from './ViewModes';
import RealtimeUsers from './RealtimeUsers';
import CartButton from './CartButton';

const NavBarContext = createContext<{
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}>(null!);

export const useNavBarContext = () => {
  const ctx = useContext(NavBarContext);
  invariant(ctx, 'missing NavBarContext.Provider');
  return ctx;
};

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <NavBarContext.Provider value={{ isExpanded, setIsExpanded }}>
      <BSNavbar
        expanded={isExpanded}
        onToggle={setIsExpanded}
        expand='lg'
        variant='dark'
        className={cn('Navbar')}
      >
        {children}
      </BSNavbar>
    </NavBarContext.Provider>
  );
};

const Brand = () => {
  return (
    <BSNavbar.Brand className='Navbar-Brand'>
      <Link to={routes.home}>
        <Icon icon='larki-logo' />
      </Link>
    </BSNavbar.Brand>
  );
};

const NavBar = {
  Provider,
  Brand,
  UserNav,
  ProjectSelect,
  ViewModes,
  RealtimeUsers,
  CartButton,
};

export default NavBar;
