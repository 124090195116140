import classNames from 'classnames';
import React from 'react';
import useData from '../../useData';
import text, { formatCurrency } from '../../../../../text';
import List from '../../../../../components/list/List';

const MemberDiscounts = () => {
  const {
    isEssentialUser,
    orderTotals,
    discounts,
    memberPrice,
    currency,
    gstPercentage,
  } = useData();

  return (
    <List isPaddingless className='is-borderless mt-2'>
      <tbody>
        {orderTotals?.price?.member_automatic_discount_total ? (
          <List.Item
            isSecondaryHighlight
            className={classNames({
              pink: !isEssentialUser,
              grey: isEssentialUser,
            })}
            description={text('dataInTheDatabaseDiscount', {
              discount: 30,
            })}
            value={`-${formatCurrency(
              orderTotals.price.member_automatic_discount_total * gstPercentage,
              currency.name,
              currency.scale_factor,
              {},
              true
            )}`}
          />
        ) : null}

        {discounts.members.captureServicesDiscount > 0 ? (
          <List.Item
            isSecondaryHighlight
            className={classNames({
              pink: !isEssentialUser,
              grey: isEssentialUser,
            })}
            description={text('captureServicesDiscount', { discount: 10 })}
            value={`-${formatCurrency(
              discounts.members.captureServicesDiscount * gstPercentage,
              currency.name,
              currency.scale_factor,
              {},
              true
            )}`}
          />
        ) : null}

        {discounts.members.addOnsDiscount > 0 ? (
          <List.Item
            isSecondaryHighlight
            className={classNames({
              pink: !isEssentialUser,
              grey: isEssentialUser,
            })}
            description={text('addOnsDiscount', { discount: 10 })}
            value={`-${formatCurrency(
              discounts.members.addOnsDiscount * gstPercentage,
              currency.name,
              currency.scale_factor,
              {},
              true
            )}`}
          />
        ) : null}

        {!isEssentialUser && (
          <List.Item
            isSecondaryHighlight
            className='pink font-weight-bold'
            description={`${text('memberPriceIncGst')}`}
            value={formatCurrency(
              memberPrice,
              currency.name,
              currency.scale_factor,
              {},
              true
            )}
          />
        )}
      </tbody>
    </List>
  );
};

export default MemberDiscounts;
