/* eslint-disable react/prop-types */
import classnames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useSearchParam } from 'react-use';
import { ANONYMOUS_ORDER } from '../../../../constants/alert';
import { LEAD_CHECKOUT } from '../../../../constants/job';
import { MEMBERSHIP } from '../../../../constants/membership';
import { PRODUCT_TYPE } from '../../../../constants/product';
import { sendLead, setMembershipPriceId } from '../../../../redux/actions';
import {
  createJob,
  updateJob,
  updateProjectName,
} from '../../../../redux/actions/jobs';
import { showModal } from '../../../../redux/actions/modal';
import { profileSelectors } from '../../../../redux/selectors/profile';
import routes from '../../../../routes/constants';
import text, { formatCurrency } from '../../../../text';
import { getNowDate } from '../../../../utilities/date';
import { isMembershipPriceId } from '../../../../utilities/membership';
import {
  isStaff,
  isUserAnonymous,
  isUserUnderTrial,
  userHasValidMembership,
} from '../../../../utilities/user';
import { amplitudeTrack } from '../../../../utilities';
import { AMPLITUDE_EVENTS } from '../../../../constants/analytics';
import { useRealtimeContext } from '../../../RealtimeContext';
import { useGetJobQuery } from '../../../../redux/rtk';
import useUser from '../../../hooks/useUser';
import { useRedirectTo } from '../../../../hooks/useRedirect';
import { SAVE_DRAFT_MODAL } from '../../../../components/modal/SaveDraftModal';
import { FORCE_JOB_UPDATE_MODAL } from '../../../../components/modal/forceJobUpdateModal';
import { useCartContext } from '../../../CartContext';
import { ExpandedDetails } from './ExpandedDetails';

const { PAYMENT_FLOW } = AMPLITUDE_EVENTS;

const ExpandableCheckoutBox = () => {
  const { jobId } = useRealtimeContext();
  const { quote, isCartLoading } = useCartContext();
  const { data: latestJob } = useGetJobQuery(jobId);
  const { isAdmin } = useUser();
  const redirect = useRedirectTo();

  const dispatch = useDispatch();
  const history = useHistory();
  const [expandInvoice, setExpandInvoice] = useState(false);

  const { currentUser, membershipPriceId, mapState } = useSelector(
    (reduxState: any) => ({
      currentUser: reduxState.profileReducer.userProfile,
      membershipPriceId: reduxState.order.membershipPriceId,
      mapState: reduxState.mapReducer,
    })
  );

  const handleSaveDraft = async (
    values: { projectName?: string | null },
    force = false
  ) => {
    if (!latestJob) return null;

    if (values?.projectName) {
      await dispatch(
        updateProjectName(latestJob.project.id, values.projectName, isAdmin)
      );
    }

    return dispatch(
      updateJob({
        address: {
          address: latestJob.full_address,
          city: latestJob.city,
          postcode: latestJob.postcode,
          state: latestJob.state,
        },
        ...values,
        jobId: latestJob.id,
        force,
        onConflict: onShowForceSave,
      })
    );
  };

  const handleSubmit = async () => {
    if (!latestJob) return;
    const redirectUrl = routes.payment.job(latestJob.id);
    dispatch(sendLead(latestJob.id, LEAD_CHECKOUT));
    amplitudeTrack(PAYMENT_FLOW.EVENT, {
      action: PAYMENT_FLOW.CHECKOUT_CART,
    });
    redirect.push(redirectUrl, { preserveQueryParams: true, queryParams: {} });
  };

  const onSaveDraft = () => {
    dispatch(
      showModal(SAVE_DRAFT_MODAL, {
        onSubmit: handleSaveDraft,
        currentUser,
      })
    );
  };

  const onSaveAndExit = async () => {
    if (latestJob?.project.name) {
      const job = await handleSaveDraft({
        projectName: latestJob.project.name,
      });

      if (job) {
        redirect.push(routes.home);
      }
    } else {
      onSaveDraft();
    }
  };

  const onForceSaveAndExit = async () => {
    const job = await handleSaveDraft(
      {
        projectName: latestJob?.project.name,
      },
      true
    );

    if (job) {
      history.push(routes.dashboard);
    }
  };

  const onShowForceSave = () => {
    dispatch(
      showModal(FORCE_JOB_UPDATE_MODAL, {
        onSubmit: onForceSaveAndExit,
      })
    );
  };

  const chargesHasTbc = useMemo(() => {
    const siteDeploymentFee = quote?.fees.find(
      (fee) => fee.id === 'site_deployment_fee'
    );
    const isSiteDeploymentFeeTBC =
      siteDeploymentFee && siteDeploymentFee.price.total === 'TBC';
    return isSiteDeploymentFeeTBC;
  }, [quote?.fees]);

  const grandTotal = useMemo(() => {
    let order;
    if (quote?.cart_totals) {
      // for new orders Sprint 67
      order = quote.cart_totals;
    } else if (quote?.order) {
      // released Sprint 66
      order = quote.order;
    } else {
      // old implementation
      // used quoted and completed orders
      order = quote;
    }

    return order?.price.base && !chargesHasTbc
      ? formatCurrency(
          isMembershipPriceId(membershipPriceId) && !isStaff(currentUser.role)
            ? order?.price.member_grand_total
            : order?.price.grand_total,
          quote?.currency.name,
          quote?.currency.scale_factor,
          {},
          true
        )
      : text('toBeConfirmed');
  }, [chargesHasTbc, currentUser.role, membershipPriceId, quote]);

  // RESET membershipPriceId IF USER IS TRIAL
  useEffect(() => {
    if (
      (currentUser && isStaff(currentUser.role)) ||
      (userHasValidMembership(currentUser) && isUserUnderTrial(currentUser))
    ) {
      dispatch(setMembershipPriceId(MEMBERSHIP.NO_MEMBERSHIP));
    }
  }, [currentUser, dispatch]);

  return (
    <div
      className={classnames('ExpandedCheckoutBox', {
        // 'read-only': isReadOnly,
        expanded: expandInvoice,
      })}
    >
      {quote ? (
        <ExpandedDetails
          toggleIcon='caret-up'
          toggleInvoiceSummary={() => setExpandInvoice(!expandInvoice)}
          isInvoiceExpanded={expandInvoice}
          hasTbc={chargesHasTbc || false}
        />
      ) : null}

      <div className='mb-2' />

      {/* {!isReadOnly ? ( */}
      <div className='expandedCheckoutBoxFooter'>
        <Button
          style={{
            width: 100,
          }}
          id='dashboardButton'
          variant='outline-primary'
          onClick={onSaveAndExit}
          disabled={isCartLoading}
        >
          {text('saveAndGoToDashboard')}
        </Button>
        <Button
          id='orderDataButton'
          data-toggle='modal'
          data-target='#purchase'
          onClick={() => handleSubmit()}
          disabled={isCartLoading}
        >
          {`${grandTotal} - ${text('checkout')}`}
        </Button>
      </div>
      {/* ) : (
        <Button
          className='full-width'
          onClick={() =>
            history.push({
              pathname: isJobAwaitingPayment
                ? routes.payment.job(state.job.id)
                : routes.dashboard,
            })
          }
        >
          {text(isJobAwaitingPayment ? 'payNow' : 'goToDashboard')}
        </Button>
      )} */}
    </div>
  );
};

export default ExpandableCheckoutBox;
