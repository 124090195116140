import {
  FloatingPortal,
  flip,
  offset,
  shift,
  useClick,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { debounce } from 'lodash';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Modal } from 'react-bootstrap';
import { useClickOutside } from '../../../hooks/useClickOutside';
import useEventListener from '../../../hooks/useEventListener';
import CollapseButton from '../../button/CollapseButton';
import { MapViewContext } from '../../mapView/mapViewContext';
import Body from './components/Body';
import Footer from './components/Footer';
import Header from './components/Header';

// Temporarily hide these buttons until the underlying features are implemented
const HIDE_PREVIEW = true;
const HIDE_SCREENSHOT = true;
const SHOW_HEADER = !HIDE_PREVIEW || !HIDE_SCREENSHOT;

const ProductModal = forwardRef((_, ref) => {
  const { state, actions } = useContext(MapViewContext);
  const productModalPopupRef = useRef(null);
  const { product: productType } = state.productSelected;

  const isProductSelectedFocused = useMemo(
    () => state.activeSelection?.category_name === productType,
    [state.activeSelection, productType]
  );

  useClickOutside(
    productModalPopupRef,
    () => actions.clearProductSelected(),
    [
      '#product-dropdown-menu-container .modal-content',
      '.product-row-container',
      '.layers-panel-container .layer',
    ],
    isProductSelectedFocused
  );
  
  const {
    x: xPos,
    refs,
    floatingStyles,
    context,
    update,
  } = useFloating({
    placement: 'left',
    middleware: [flip(), offset(32), shift({ padding: 16 })],
  });

  const click = useClick(context);
  const { getFloatingProps } = useInteractions([click]);

  useEffect(() => {
    if (ref.current) {
      refs.setReference(ref.current);
      update();
    }
  }, [
    update,
    refs,
    xPos,
    state.showProductModal,
    state.isProductModalExpanded,
    state.productSelected,
    state.selections,
    ref,
  ]);

  const handleEscapePress = (event) => {
    if (event.key === 'Escape') {
      actions.closeShapeMenuModal();
      actions.clearProductSelected();
    }
  };

  useEventListener('keydown', handleEscapePress, document);
  useEventListener('resize', update);
  useEventListener('wheel', debounce(update, 500));

  return (
    <FloatingPortal>
      <div
        ref={refs.setFloating}
        style={{ ...floatingStyles, zIndex: 1000 }}
        {...getFloatingProps()}
      >
        <Modal.Dialog id='product-modal' ref={productModalPopupRef}>
          {SHOW_HEADER ? (
            <Header
              isPreviewButtonHidden={HIDE_PREVIEW}
              isScreenshotButtonHidden={HIDE_SCREENSHOT}
            />
          ) : null}
          <Body isHeaderShown={SHOW_HEADER} />
          <Footer />
          <CollapseButton
            id='productInfoCollapseButton'
            openDirection='down'
            isOpen={state.isProductModalExpanded}
            closeText='More info'
            className='flex-row-reverse '
            onClick={() =>
              actions.setIsProductModalExpanded(!state.isProductModalExpanded)
            }
          />
        </Modal.Dialog>
      </div>
    </FloatingPortal>
  );
});

ProductModal.displayName = 'ProductModal';
export default ProductModal;
