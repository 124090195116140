import proj4 from 'proj4';
import { Spline } from './spline';
import * as GeoJSON from 'geojson';
import { positionLib } from './position';

type AnimationState = 'stopped' | 'idle' | 'animating';
type Animation<T> = {
  from: number[];
  to: number[];
  start: number;
  duration: number;
  extra: T;
};
export type PositionCallback<T> = (point: number[], extra: T) => void;

export class PointInterpolator<T> {
  state: AnimationState = 'idle';
  queue: Animation<T>[] = [];
  timestamp = performance.now();
  lastRequestId = 0;
  timeoutId?: NodeJS.Timeout;
  prevPoint?: number[];
  spline = new Spline();
  cb: PositionCallback<T>;

  constructor(cb: PositionCallback<T>) {
    this.cb = cb;
  }

  addPoint(point: number[], extra: T) {
    clearTimeout(this.timeoutId);
    const now = performance.now();
    const duration = Math.min(
      now - this.timestamp,
      PointInterpolator.MAX_INTERVAL
    );
    if (!this.prevPoint) {
      this.spline.clear();
      this.prevPoint = point;
      this.spline.addPoint(point);
      this.cb(point, extra);
      this.state = 'stopped';
      return;
    }
    if (this.state === 'stopped') {
      this.spline.clear();
      this.spline.addPoint(this.prevPoint);
      this.spline.addPoint(this.prevPoint);
      this.spline.addPoint(point);
      this.state = 'idle';
    } else {
      this.spline.addPoint(point);
    }
    const animation: Animation<T> = {
      start: this.spline.points.length - 3,
      from: this.prevPoint,
      to: point,
      duration,
      extra,
    };
    this.prevPoint = point;
    this.timestamp = now;
    switch (this.state) {
      case 'idle': {
        this.state = 'animating';
        this.animateNext(animation);
        break;
      }
      case 'animating': {
        this.queue.push(animation);
        break;
      }
    }
  }

  animateNext = (animation: Animation<T>) => {
    const start = performance.now();
    const loop = () => {
      const t = (performance.now() - start) / animation.duration;
      if (t <= 1 && this.spline.points.length > 0) {
        try {
          this.cb(
            this.spline.getSplinePoint(t + animation.start),
            animation.extra
          );
        } catch (e) {
          console.warn(e);
        }
        this.lastRequestId = requestAnimationFrame(loop);
        return;
      }
      const next = this.queue.shift();
      if (next) {
        this.state = 'animating';
        this.animateNext(next);
      } else {
        this.state = 'idle';
        this.timeoutId = setTimeout(() => {
          this.state = 'stopped';
        }, PointInterpolator.MAX_INTERVAL);
      }
    };
    loop();
  };

  static MAX_INTERVAL = 300;

  dispose = () => {
    clearTimeout(this.timeoutId);
  };

  reset = () => {
    for (const anim of this.queue) {
      this.cb(anim.to, anim.extra);
    }
    this.queue = [];
    this.spline.clear();
  };
}

// INTERPOLATION
type OnInterpolateCursorsCallback = (
  otherCursors: Map<string, GeoJSON.Position>
) => void;
type OnInterpolateChangesCallback = (
  changes: { main?: Uint8Array; temp?: Uint8Array },
  senderId: string
) => void;
type PointInterpolatorProps = {
  changes?: { main?: Uint8Array; temp?: Uint8Array };
};
export class Interpolator {
  private interpolators = new Map<
    string,
    PointInterpolator<PointInterpolatorProps>
  >();
  private PROJECTION = proj4('WGS84', 'EPSG:3857');
  private onInterpolateCursors: OnInterpolateCursorsCallback;
  private onInterpolateChanges: OnInterpolateChangesCallback;
  private interpolatedCursors = new Map<string, GeoJSON.Position>();

  constructor(
    onInterpolateCursors: OnInterpolateCursorsCallback,
    onInterpolateChanges: OnInterpolateChangesCallback
  ) {
    console.log('NEW INTERPOLATOR');
    this.onInterpolateCursors = onInterpolateCursors;
    this.onInterpolateChanges = onInterpolateChanges;
  }

  private getOrCreateInterpolator(clientId: string) {
    if (!this.interpolators.has(clientId)) {
      this.interpolators.set(
        clientId,
        new PointInterpolator<PointInterpolatorProps>((coord, { changes }) => {
          const position = this.PROJECTION.inverse(coord);
          this.interpolatedCursors.set(clientId, position);
          this.onInterpolateCursors(this.interpolatedCursors);

          if (changes) {
            this.onInterpolateChanges(changes, clientId);
          }
        })
      );
    }
    return this.interpolators.get(clientId)!;
  }

  onOtherCursorsMoved(cursors: Map<string, GeoJSON.Position>) {
    for (const [clientId, position] of cursors) {
      const interpolator = this.getOrCreateInterpolator(clientId);
      const coord = this.PROJECTION.forward(position);
      interpolator.addPoint(coord, {});
    }
  }

  onUpdate(
    changes: { main?: Uint8Array; temp?: Uint8Array },
    senderId: string,
    cursor?: string
  ) {
    const interpolator = this.getOrCreateInterpolator(senderId);
    const position = cursor ? positionLib.fromString(cursor) : null;
    if (position) {
      const coord = this.PROJECTION.forward(position);
      interpolator.addPoint(coord, { changes });
    } else {
      this.onInterpolateChanges(changes, senderId);
    }
  }

  deleteCursor(clientId: string) {
    this.interpolators.delete(clientId);
    this.interpolatedCursors.delete(clientId);
  }

  reset() {
    for (const interpolator of this.interpolators.values()) {
      interpolator.reset();
    }
  }
}
