/* eslint-disable react/jsx-max-depth */

import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionContext,
  Button as BootstrapButton,
  Card,
  Dropdown,
} from 'react-bootstrap';

import Guide from './GuidePanel';
import { showModal, toggleLayersPanelVisibility } from '../../../redux/actions';
import CollapseButton from '../../../components/button/CollapseButton';
import text from '../../../text';
import Icon from '../../../components/icon/Icon';
import GuidePanel from './GuidePanel';
import { RealtimeContext, useRealtimeContext } from '../../RealtimeContext';
import Layer from './Layer';
import { MP_DELETE_SHAPES_MODAL } from '../../../components/modal/mp/DeleteShapesModal';
import DownloadShapesMenu from './DownloadsMenu';

const LayersPanel = () => {
  // @ts-expect-error unknown type
  const layout = useSelector((reduxState) => reduxState.layout);
  const dispatch = useDispatch();

  const {
    shapeDetails,
    deleteAllShapes,
    hideAllShapes,
    areAllShapesShown,
    areAllShapesHidden,
  } = useRealtimeContext();

  // const [showDownloadsMenu, setShowDownloadsMenu] = useState(false);

  const handleClickDeleteAll = useCallback(() => {
    dispatch(
      showModal(MP_DELETE_SHAPES_MODAL, {
        handleDelete: () => {
          deleteAllShapes();
        },
        isMany: true,
      })
    );
  }, [deleteAllShapes, dispatch]);

  return (
    <div
      className={classNames('layers-panel-container', {
        show: layout.layersPanel.isVisible,
      })}
    >
      <CollapseButton
        id='layersPanelCollapseButton'
        openDirection='right'
        isOpen={layout.layersPanel.isVisible}
        closeText={text('layers')}
        onClick={() =>
          dispatch(toggleLayersPanelVisibility(!layout.layersPanel.isVisible))
        }
      />

      <div className='layers-panel'>
        <div className='d-flex flex-column'>
          <div className='panel-header'>
            <div className='panel-heading'>Layers</div>
            <div className='panel-actions'>
              <BootstrapButton
                className='panel-action'
                variant='light'
                // // If all selections are visible, hide them all
                // // otherwise; show them
                onClick={() => hideAllShapes(areAllShapesShown)}
              >
                <Icon
                  className={classNames({
                    ['original']: !areAllShapesShown,
                  })}
                  icon={
                    areAllShapesShown
                      ? 'eye'
                      : areAllShapesHidden
                        ? 'eye-slash'
                        : 'eye-mixed-state'
                  }
                />
              </BootstrapButton>
              {/* <Dropdown>
                <Dropdown.Toggle variant='light' className='panel-action'>
                  <Icon icon='download' />
                </Dropdown.Toggle> */}
              {/* <Dropdown.Menu show={showDownloadsMenu}> */}
              {/* TODO: fix hard-coded jobId,  */}
              {/* <DownloadShapesMenu
                    jobId={1}
                    shapes={jsonState?.shapes ?? []}
                    handleCloseMenu={() => setShowDownloadsMenu(false)}
                    closeOnSelect={false}
                  /> */}
              {/* </Dropdown.Menu> */}
              {/* </Dropdown> */}
              <BootstrapButton
                className='panel-action'
                variant='light'
                onClick={handleClickDeleteAll}
              >
                <Icon icon='trash' />
              </BootstrapButton>
            </div>
          </div>
        </div>

        <div className='d-flex flex-fill overflow-auto'>
          <ul className='layers'>
            {shapeDetails?.length
              ? shapeDetails.map((shape) => (
                  <Layer key={shape.id} shape={shape} />
                ))
              : null}
          </ul>
        </div>

        <GuidePanel />
      </div>
    </div>
  );
};

export default LayersPanel;
