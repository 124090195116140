import PropTypes from 'prop-types';
import React from 'react';
import text from '../../text';
import { IconButton } from '../button';
import Container from '../containers/Container';
import classNames from 'classnames';

const AddOnButton = ({
  show,
  header,
  description,
  tooltip = null,
  handleClick,
  className = '',
}) => {
  if (!show) {
    return null;
  }

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames('add-on-button', className)}
    >
      <Container
        noBorder
        isSecondary
        className='d-flex justify-content-between p-3 align-items-center'
      >
        <div className='text-left'>
          <div className='d-flex flex-row align-items-center'>
            <p className='font-weight-bold m-0 mr-2'>{header}</p>
            {tooltip}
          </div>
          <p className='m-0' style={{ fontSize: '12px' }}>
            {description}
          </p>
        </div>
        <div className='d-flex flex-column add-button-container'>
          <IconButton iconProps={{ isOriginal: true }} icon='plus-filled' />
          <p className='green-1 m-0 font-weight-bold'>{text('add')}</p>
        </div>
      </Container>
    </button>
  );
};

AddOnButton.propTypes = {
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string,
  handleClick: PropTypes.func,
  className: PropTypes.string,
};

AddOnButton.defaultProps = {
  show: true,
  description: '',
};

export default AddOnButton;
