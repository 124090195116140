import React from 'react';
import classnames from 'classnames';
import T from 'prop-types';

import Button from './Button';
import classNames from 'classnames';

const oppositeDirection = {
  left: 'right',
  right: 'left',
  down: 'up',
  up: 'down',
};
const getIcon = (direction, isOpen) =>
  `caret-${isOpen ? oppositeDirection[direction] : direction}`;

const CollapseButton = (props) => {
  const {
    id,
    openDirection = 'up',
    onClick,
    openText,
    closeText,
    isOpen,
    tooltipProps,
    className,
    variant,
    disabled,
  } = props;

  return (
    <Button
      id={id}
      disabled={disabled}
      className={classnames('CollapseButton', {
        isOpen: isOpen,
        [className]: className,
        'flex-row-reverse': openDirection === 'right',
      })}
      onClick={onClick}
      icon={getIcon(openDirection, isOpen)}
      tooltipProps={tooltipProps}
      variant={variant}
    >
      {!isOpen && (
        <span
          className={classNames({
            'mr-2': !isOpen && openDirection === 'left',
            'ml-2': !isOpen && openDirection === 'right',
          })}
        >
          {isOpen ? openText : closeText}
        </span>
      )}
    </Button>
  );
};

CollapseButton.propTypes = {
  id: T.string,
  openDirection: T.string,
  onClick: T.func,
  openText: T.string,
  closeText: T.string,
  isOpen: T.bool,
  tooltipProps: T.shape(),
};

export default CollapseButton;
