import isEmpty from 'lodash/fp/isEmpty';
import React, { useMemo } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import text, { formatCurrency } from '../../../../../text';
import useData from '../../useData';
import { IconButton } from '../../../../../components/button';
import List from '../../../../../components/list/List';
import MemberDiscounts from './MemberDiscounts';

export const ExpandedDetails = ({
  toggleIcon = 'caret-down',
  toggleInvoiceSummary = () => {},
  isInvoiceExpanded,
  hasTbc,
}: {
  toggleIcon: string;
  toggleInvoiceSummary?: () => void;
  isInvoiceExpanded: boolean;
  hasTbc: boolean;
}) => {
  const {
    quote,
    currentUser,
    isEssentialUser,
    orderTotals,
    discounts,
    memberTax,
    nonMemberTax,
    otherDiscountsTotal,
    currency,
    fullPriceWithSalesHelpIncGst,
    gstPercentage,
  } = useData();

  const removeConcatenatedDiscountAmout = (str: string) =>
    str.replace(/\s*\(.*?\)\s*/g, '');

  const shownSubtotal = useMemo(() => {
    const x = isEssentialUser
      ? orderTotals?.price?.member_subtotal
      : orderTotals?.price?.subtotal;
    if (!x) return null;
    return x - otherDiscountsTotal.subtotal;
  }, [
    isEssentialUser,
    orderTotals?.price?.member_subtotal,
    orderTotals?.price?.subtotal,
    otherDiscountsTotal.subtotal,
  ]);

  return !isEmpty(quote) && !isEmpty(currentUser) ? (
    // Collapse clickable header
    <div className='InvoiceTotalSumArea w-100'>
      {!hasTbc && (
        <div
          className={classNames('price-estimate-header', {
            'pb-2': isInvoiceExpanded,
          })}
          onClick={toggleInvoiceSummary}
        >
          <div
            className='label text-uppercase green-1'
            style={{ fontWeight: 600, fontSize: '12px' }}
          >
            {text('priceEstimate')}
          </div>

          <div className='value'>
            <div
              className={classNames('font-weight-bold mr-1 spans-container', {
                isEssentialUser,
              })}
              style={{ fontSize: '12px' }}
            >
              (
              <span className='mr-1'>
                {text(isEssentialUser ? 'memberSaved' : 'membersSave')}:{' '}
              </span>
              <span>
                {orderTotals?.price?.member_discount_total
                  ? formatCurrency(
                      (orderTotals?.price?.member_discount_total +
                        (isEssentialUser ? otherDiscountsTotal.subtotal : 0)) *
                        gstPercentage,
                      currency.name,
                      currency.scale_factor,
                      {},
                      true
                    )
                  : null}
              </span>
              )
            </div>

            <IconButton
              // @ts-expect-error prop
              iconProps={{ isOriginal: true }}
              icon={toggleIcon}
              onClick={toggleInvoiceSummary}
              className='p-0'
            />
          </div>
        </div>
      )}

      <Collapse in={isInvoiceExpanded} timeout={50}>
        <div id='ExpandedCheckoutBoxWrapper'>
          {!isEssentialUser && (
            <>
              <MemberDiscounts />
              <hr className='divider mx-0' />
            </>
          )}

          <List isPaddingless className='is-borderless mt-2'>
            <tbody>
              <List.Item
                description={text('fullPriceWithSalesHelpInGst')}
                value={
                  hasTbc ? (
                    <span className='grey-3'>{text('toBeConfirmed')}</span>
                  ) : (
                    formatCurrency(
                      fullPriceWithSalesHelpIncGst,
                      currency.name,
                      currency.scale_factor,
                      {},
                      true
                    )
                  )
                }
                className='font-weight-bold'
              />
            </tbody>
          </List>

          {isEssentialUser ? <MemberDiscounts /> : null}

          <List isPaddingless className='is-borderless'>
            <tbody>
              {discounts.selfServiceDiscountIncGst > 0 && !isEssentialUser ? (
                <List.Item
                  description={text('inAppSelfServeDiscount', {
                    discount: 10,
                  })}
                  value={`-${formatCurrency(
                    discounts.selfServiceDiscountIncGst,
                    currency.name,
                    currency.scale_factor,
                    {},
                    true
                  )}`}
                  className='grey-3'
                />
              ) : null}

              {/* --OTHER DISCOUNTS-- */}
              {orderTotals?.discounts.map((discount, index) => (
                <List.Item
                  key={index}
                  description={`${removeConcatenatedDiscountAmout(
                    discount.display_name
                  )} (inc GST)`}
                  value={`-${formatCurrency(
                    discount.price.discount,
                    currency.name,
                    currency.scale_factor,
                    {},
                    true
                  )}`}
                  className='grey-3'
                />
              ))}
            </tbody>
          </List>

          <hr className='divider mx-0' />
          <List isPaddingless className='is-borderless mt-2 mb-2'>
            <tbody>
              <List.Item
                description={text('subtotal')}
                value={formatCurrency(
                  shownSubtotal,
                  currency.name,
                  currency.scale_factor,
                  {},
                  true
                )}
              />
              <List.Item
                description={text('gst')}
                value={formatCurrency(
                  (isEssentialUser ? memberTax : nonMemberTax) -
                    otherDiscountsTotal.gst,
                  currency.name,
                  currency.scale_factor,
                  {},
                  true
                )}
              />
            </tbody>
          </List>
        </div>
      </Collapse>
    </div>
  ) : null;
};
