import { CompositeLayer, Color } from '@deck.gl/core';
import { GeoJsonLayer, TextLayer } from '@deck.gl/layers';
import * as turf from '@turf/turf';
import * as GeoJSON from 'geojson';
import * as dateFns from 'date-fns';

import { LayerGeometries, LayerLabels } from '../services/coverage/coverage';
import { PointCloudDataType } from '../lib/dataType';
import { CollisionFilterExtension } from '@deck.gl/extensions';
import { arrayLib } from '../lib/array';

type CoverageLayerState = {
  layers: LayerGeometries[];
  labels: Map<PointCloudDataType, LayerLabels[]>;
};

type CoverageLayerFeature = GeoJSON.Feature<
  GeoJSON.Polygon | GeoJSON.MultiPolygon,
  { dataType: PointCloudDataType }
>;

type Rgb = [number, number, number];
type Rgba = [number, number, number, number];
const DATA_TYPE_TO_COLOR: Record<PointCloudDataType, Rgb> = {
  streetscape: [48, 72, 201],
  aerial: [21, 125, 170],
  drone: [176, 135, 243],
  exterior: [229, 83, 132],
  interior: [244, 118, 1],
};

export class CoverageLayer extends CompositeLayer {
  declare state: CoverageLayerState;

  initializeState() {
    this.state = {
      layers: [],
      labels: new Map(),
    };
  }

  _setState(_state: Partial<CoverageLayerState>) {
    if (!this.state) return;
    this.setState(_state);
  }

  renderLayers() {
    const displayLabels = [...this.state.labels.values()].flatMap((labels) =>
      labels.flatMap((l) =>
        l.label_positions.coordinates
          .map((p, i) => {
            if (!l.label_visibilities[i]) return null;
            return {
              position: p,
              text: dateFns.format(new Date(l.acquired_at), 'MMM yyyy'),
              priority: l.label_priorities[i],
              dataType: l.category_name,
            };
          })
          .filter(arrayLib.filterNonNull)
      )
    );

    return [
      new GeoJsonLayer<CoverageLayerFeature['properties']>(
        this.getSubLayerProps({ id: 'coverage' }),
        {
          data: this.state.layers.map(
            (layer): CoverageLayerFeature =>
              turf.feature(layer.geometry, { dataType: layer.category_name })
          ),

          getFillColor: (feat) => [
            ...DATA_TYPE_TO_COLOR[feat.properties.dataType],
            128,
          ],
          getLineColor: (feat) => [
            ...DATA_TYPE_TO_COLOR[feat.properties.dataType],
            255,
          ],

          positionFormat: 'XY',
          stroked: true,
          getLineWidth: 1,
          filled: true,
        }
      ),
      new TextLayer<(typeof displayLabels)[number]>(
        this.getSubLayerProps({ id: 'labels' }),
        {
          data: displayLabels,

          getPosition: (label) => [label.position[0], label.position[1]],
          getText: (label) => label.text,

          getBackgroundColor: (label) => [
            ...DATA_TYPE_TO_COLOR[label.dataType],
            255,
          ],

          extensions: [new CollisionFilterExtension()],
          // @ts-expect-error prop
          collisionTestProps: { sizeScale: 3 },
          getCollisionPriority: (label: (typeof displayLabels)[number]) =>
            label.priority,
          maskByInstance: true,

          fontFamily: 'monospace',
          fontWeight: 'bold',
          getSize: 10,
          getColor: [255, 255, 255, 255],
          background: true,
          backgroundPadding: [2, 1],
        }
      ),
    ];
  }
}
