const filterNonNull = <T>(x: T | null): x is T => {
  return x !== null;
};
const filterNonUndefined = <T>(x: T | undefined): x is T => {
  return x !== undefined;
};
const filterNonNullAndNonUndefined = <T>(x: T | null | undefined): x is T => {
  return x !== null && x !== undefined;
};
export const arrayLib = {
  filterNonNull,
  filterNonUndefined,
  filterNonNullAndNonUndefined,
};
