import { client, larkiApi } from '../../utilities/api';
import { captureError } from '../../utilities/error';
import { getUserMembership } from '../../utilities/user';
import { alertConstants, profileConstants } from '../constants';

export const startProductTrial =
  ({ productId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: profileConstants.FETCH_USER_DETAILS_LOADING,
      });
      const { data } = await larkiApi.post('/user/products/trial', {
        productId,
      });
      dispatch({
        type: profileConstants.FETCH_USER_DETAILS_SUCCESS,
        userProfile: data,
      });
    } catch (error) {
      dispatch({
        type: profileConstants.FETCH_USER_DETAILS_FAILURE,
      });
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response.data.message },
      });
    }
  };

export const updateMapType =
  (mapTypeId, skipDispatch = false) =>
  async (dispatch) => {
    try {
      await larkiApi.put('/user/map-type', {
        map_type: mapTypeId,
      });
      if (!skipDispatch) {
        dispatch({
          type: profileConstants.UPDATE_MAP_TYPE,
          payload: mapTypeId,
        });
      }
    } catch (error) {
      if (!skipDispatch) {
        dispatch({
          type: alertConstants.ALERT_ERROR,
          payload: { message: error.response.data.message },
        });
      }
    }
  };

export const getMembershipPrices = () => async (dispatch) => {
  try {
    dispatch({
      type: profileConstants.GET_MEMBERSHIP_PRICES_LOADING,
    });
    const { data } = await larkiApi.get('/user/prices');
    dispatch({
      type: profileConstants.GET_MEMBERSHIP_PRICES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

export const getMembershipReceipt = (paymentIntentId) => async (dispatch) => {
  try {
    dispatch({
      type: profileConstants.GET_MEMBERSHIP_RECEIPT,
    });
    const { data } = await client.getMembershipReceipt(paymentIntentId);
    dispatch({
      type: profileConstants.GET_MEMBERSHIP_RECEIPT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

export const refreshCustomer = () => async (dispatch) => {
  try {
    const { data } = await client.refreshCustomer();
    dispatch({
      type: profileConstants.REFRESH_CUSTOMER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    captureError(error);
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: {
        message: error.response ? error.response.data.message : error.message,
      },
    });
  }
};

export const getMembershipDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: profileConstants.GET_MEMBERSHIP_DETAILS,
      payload: data,
    }); // Loader = true

    const { data } = await client.getUserMembership();

    dispatch({
      type: profileConstants.GET_MEMBERSHIP_DETAILS_SUCCESS,
      payload: data,
    }); // Loader = false
  } catch (error) {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

/**
 * Subscribes a user to a trial/recurring membership.
 * When subscribing to a recurring membership **without** a trial, returned object has payment_intent_secret
 *  which is used in stripe.confirmCardPayment to finalize creation of the membership.
 *
 * @param {object} user with customer.memberships field
 * @param {string} priceId in Stripe
 * @param {string} [paymentMethodId] in Stripe - required if isTrial is false
 * @param {boolean} isTrial Start a trial?
 * @returns {*} Membership object
 */
export const subscribe =
  ({ user, priceId, paymentMethodId, isTrial }) =>
  async (dispatch) => {
    try {
      if (isTrial && user.has_used_trial) {
        throw Error('requesting new trial but user has trialed before');
      }

      if (!isTrial && !paymentMethodId) {
        throw Error(
          'requesting recurring membership but payment method ID not provided'
        );
      }

      dispatch({ type: profileConstants.UPSERT_USER_MEMBERSHIP });

      const existingMembership = getUserMembership(user);

      let newMembership;
      if (!existingMembership) {
        const { data } = await client.createMembership(
          priceId,
          paymentMethodId,
          isTrial // possible combinations (all valid): !is_trial !has_used_trial, !is_trial has_used_trial, is_trial !has_used_trial
        );
        // newMembership = { id, name, status, ... payment_intent_secret?: ... }
        newMembership = data;

        if (!newMembership?.payment_intent_secret && !isTrial) {
          throw Error(
            'requesting recurring membership but createMembership did not return payment intent secret'
          );
        }
      } else {
        if (isTrial) {
          throw Error('requesting new trial but user has existing membership');
        }

        const { data } = await client.updateMembership(
          priceId,
          paymentMethodId
        );
        // newMembership = { id, name, status, ... }
        newMembership = data;
      }

      if (newMembership?.payment_intent_secret && isTrial) {
        throw Error(
          'requesting new trial but create/updateMembership returned payment intent secret'
        );
      }
      await dispatch(refreshCustomer());

      if (isTrial) {
        dispatch(setHasUsedTrial(true));
      }

      dispatch({
        type: profileConstants.UPSERT_USER_MEMBERSHIP_SUCCESS,
        payload: { membership: newMembership },
      });

      return newMembership;
    } catch (error) {
      captureError(error);
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: {
          message: error.response
            ? error.response.data.message // Axios error
            : error.request
              ? error.request.data.message // Axios error
              : error.message, // User error
        },
      });
    } finally {
      // we need to set loading to false even if things fail or return early
      dispatch({
        type: profileConstants.UPSERT_USER_MEMBERSHIP_DONE,
      });
    }
  };

export const setHasUsedTrial = (status) => async (dispatch) => {
  try {
    dispatch({
      type: profileConstants.SET_HAS_USED_TRIAL,
      payload: status,
    });
  } catch (error) {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};
