import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useRef } from 'react';
import fp from 'lodash/fp';
import { Polygon, MultiPolygon } from 'geojson';

import { DEFAULT_CURRENCY } from '../../../../../../../constants/price';
import { useCartContext } from '../../../../../../CartContext';
import useUser from '../../../../../../hooks/useUser';
import { USER_TYPE } from '../../../../../../../constants/user';
import { DataType, DerivedDataType } from '../../../../../../lib/dataType';
import { formatDate, formatMonth } from '../../../../../../../utilities/date';
import text, { formatCurrency, formatSqmArea } from '../../../../../../../text';
import {
  BUY_NOW_DATA_TYPES,
  PRODUCT_TYPE,
  SALES_HELP_DISCOUNT,
} from '../../../../../../../constants/product';
import {
  computeArea,
  computeUnion,
} from '../../../../../../../components/mapView/geometry';
import { roundToNearest } from '../../../../../../../utilities/number';
import { isMembershipPriceId } from '../../../../../../../utilities/membership';
import { isStaff } from '../../../../../../../utilities/user';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { IconWrapper } from '../../../../../../../components/RightMenu/Product';
import ProductTooltip from '../../../../../../../components/mapView/productTooltip/ProductTooltip';
import CloseButton from '../CloseButton';
import WithLoader from '../../../../../../../components/modal/ProductModal/components/WithLoader';
import DataTable from '../../../../../../../components/table/dataTable/DataTable';
import SimpleDataRow from './SimpleDataRow';
import { useRealtimeContext } from '../../../../../../RealtimeContext';
import { shapeUnion } from '../../../../../../lib/shape';

const SQM_IN_SQKM = 1e6;

function useProduct({ dataType }: { dataType: DataType | DerivedDataType }) {
  const {
    quote,
    cartItems: allCartItems,
    chargesByDataType,
  } = useCartContext();

  const currency = quote?.currency || DEFAULT_CURRENCY;

  const currentUser = useUser();
  const { membershipPriceId } = useSelector((reduxState: any) => ({
    membershipPriceId: reduxState.order.membershipPriceId,
  }));

  const isEssentialUser = useMemo(() => {
    if (currentUser.actualType === USER_TYPE.GOVERNMENT) return true;
    return currentUser.hasValidMembership || currentUser.isUserUnderTrial;
  }, [currentUser]);

  const cartItems = useMemo(
    () => allCartItems.filter((item) => item.dataType === dataType),
    [allCartItems, dataType]
  );

  const charge = useMemo(
    () => chargesByDataType?.get(dataType),
    [chargesByDataType, dataType]
  );

  const age = useMemo(() => {
    if (!charge) {
      return null;
    }

    const acquiredDates = charge.items.reduce<string[]>((acc, next) => {
      if (!next.details.acquired_at) {
        return []; // to avoid undefined
      }
      return [...acc, formatDate(next.details.acquired_at)];
    }, []);

    if (acquiredDates.length <= 0) {
      return null;
    }

    const sortedAcquiredDates = Array.from(new Set(acquiredDates)).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );

    const [startDate, endDate] = [
      sortedAcquiredDates[0],
      sortedAcquiredDates[sortedAcquiredDates.length - 1],
    ];

    if (startDate !== endDate) {
      return text('age', {
        dates: `${formatMonth(startDate)} - ${formatMonth(endDate)}`,
      });
    }

    return text('age', { dates: formatMonth(startDate) });
  }, [charge]);

  const totalArea = useMemo(() => {
    if (!charge) {
      return {
        formatted: '',
        value: 0,
      };
    }

    if (charge.details?.area_in_sqm) {
      if (charge.details.area_in_sqm > SQM_IN_SQKM) {
        return {
          formatted: `${(
            charge.details.area_in_sqm / SQM_IN_SQKM
          ).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })} km²`,
          value: charge.details.area_in_sqm / SQM_IN_SQKM,
        };
      }

      return {
        formatted: formatSqmArea(charge.details.area_in_sqm),
        value: charge.details.area_in_sqm,
      };
    }

    return {
      formatted: '',
      value: 0,
    };
  }, [charge]);

  // Only calculate for Interior shapes union since
  // it's the only shape that computes area linearly
  // other shapes area is equal to union by default
  const shapesUnionArea = useMemo(() => {
    if (
      ![
        PRODUCT_TYPE.STREETSCAPE,
        PRODUCT_TYPE.INTERIOR,
        PRODUCT_TYPE.BIM_INTERIOR,
        PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
      ].includes(dataType)
    ) {
      return {
        formatted: '',
        value: 0,
      };
    }

    // TODO:

    // const union = shapeUnion(cartItems);

    // let unionArea =
    //   !fp.isEmpty(unionCoordinates) && computeArea(unionCoordinates);
    let unionArea = 0;

    if (unionArea) {
      const diff = Math.abs(Math.floor(unionArea) - totalArea.value);
      unionArea = roundToNearest(unionArea, 1);

      if (diff <= 1) {
        // deny shape union if difference between area & union is <= 1,
        // this happens due to rouding off of area
        unionArea = 0;
      }

      if (unionArea > SQM_IN_SQKM) {
        return {
          formatted: `${(unionArea / SQM_IN_SQKM).toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })} km²`,
          value: unionArea / SQM_IN_SQKM,
        };
      }

      return {
        formatted: unionArea > 0 ? formatSqmArea(unionArea) : '',
        value: unionArea,
      };
    }
  }, [cartItems, dataType, totalArea.value]);

  const price = useMemo(() => {
    if (!charge) {
      return {
        formatted: '',
        value: 0,
      };
    }

    if (!charge.price) {
      return {
        formatted: text('notAvailable'),
        value: null,
      };
    }
    if (!charge.price.total) {
      return {
        formatted: text('toBeConfirmed'),
        value: null,
      };
    }

    const price = isEssentialUser
      ? charge.price.member_total
      : charge.price.total;

    return {
      formatted: formatCurrency(
        price,
        currency.name,
        currency.scale_factor,
        {},
        true
      ),
      value: price,
    };
  }, [charge, currency.name, currency.scale_factor, isEssentialUser]);

  const isFreeOrTrial = useMemo(() => {
    if (!charge) return false;
    return (
      !isMembershipPriceId(membershipPriceId) &&
      !isStaff(currentUser.profile?.role)
    );
  }, [charge, currentUser?.profile?.role, membershipPriceId]);

  const hasMemberDiscount = useMemo(() => {
    if (!charge?.price?.member_discount_total) return false;
    return charge.price.member_discount_total > 0;
  }, [charge]);

  const memberDiscount = useMemo(() => {
    const totalAmount = charge ? charge.price?.member_total : 0;
    const savingAmount = charge ? charge.price?.member_discount_total : 0;

    const saving = formatCurrency(
      savingAmount,
      currency.name,
      currency.scale_factor,
      {},
      true
    );

    if (!isEssentialUser || isStaff(currentUser.profile?.role)) {
      return text('memberPriceDiscount', {
        totalAmount: formatCurrency(
          totalAmount,
          currency.name,
          currency.scale_factor,
          {},
          true
        ),
        saving,
      });
    }

    return (
      <>
        <span className='text-strikethrough'>
          {text('fullPrice', {
            totalAmount: formatCurrency(
              charge?.price?.subtotal,
              currency.name,
              currency.scale_factor,
              {},
              true
            ),
          })}
        </span>
        {', '}
        {text('savingAmount', { saving })}
      </>
    );
  }, [
    charge,
    currency.name,
    currency.scale_factor,
    isEssentialUser,
    currentUser.profile?.role,
  ]);

  const priceWithSalesHelp = useMemo(() => {
    if (!charge) {
      return {
        formatted: '',
        value: 0,
      };
    }
    const userType = isEssentialUser ? USER_TYPE.ESSENTIALS : USER_TYPE.FREE;
    const salesHelpDiscount = SALES_HELP_DISCOUNT[dataType][userType];

    if (salesHelpDiscount <= 0) {
      return text('notAvailable');
    }

    if (!charge.price) {
      return text('notAvailable');
    }
    if (!charge.price.total) {
      return text('toBeConfirmed');
    }
    const salesHelpSaving = charge.price.subtotal * (salesHelpDiscount / 100);
    return {
      total: formatCurrency(
        (price.value ?? 0) + salesHelpSaving,
        currency.name,
        currency.scale_factor,
        {},
        true
      ),
      saved: formatCurrency(
        salesHelpSaving,
        currency.name,
        currency.scale_factor,
        {},
        true
      ),
      value: salesHelpSaving,
    };
  }, [
    charge,
    currency.name,
    currency.scale_factor,
    dataType,
    isEssentialUser,
    price.value,
  ]);

  return {
    cartItems,
    charge,
    age,
    totalArea,
    shapesUnionArea,
    price,
    isEssentialUser,
    isFreeOrTrial,
    hasMemberDiscount,
    memberDiscount,
    priceWithSalesHelp,
    dataType,
    isStaff: isStaff(currentUser.profile?.role),
  };
}

const Body = ({
  dataType,
  isHeaderShown,
}: {
  dataType: DataType | DerivedDataType;
  isHeaderShown: boolean;
}) => {
  const { activeShapeIds } = useRealtimeContext();
  const { isProductModalExpanded } = useCartContext();
  const {
    charge,
    priceWithSalesHelp,
    price,
    totalArea,
    isEssentialUser,
    isFreeOrTrial,
    hasMemberDiscount,
    memberDiscount,
    cartItems,
    shapesUnionArea,
    age,
    isStaff,
  } = useProduct({
    dataType,
  });

  const isFreeUser = !isEssentialUser;
  const priceSalesHelpTemplate = fp.isObject(priceWithSalesHelp) &&
    priceWithSalesHelp.value > 0 && (
      <div
        className={classNames('discount', {
          'mt-1': isEssentialUser,
          'mb-1': isFreeUser,
        })}
      >
        <span
          className={classNames({
            'text-strikethrough': isFreeUser,
          })}
        >
          {isFreeUser
            ? text('salesHelp', {
                amount: priceWithSalesHelp.total,
              })
            : fp.capitalize(text('buyNow'))}
        </span>
        {', '}
        <span
          className={classNames({
            'text-strikethrough': isEssentialUser,
          })}
        >
          {isFreeUser
            ? text('savingAmount', {
                saving: priceWithSalesHelp.saved,
              })
            : `or ${text('salesHelp', {
                amount: priceWithSalesHelp.total,
              })}`}
        </span>
      </div>
    );

  return (
    <Modal.Body
      className={classNames('pt-0 px-0', {
        'pb-4': !isProductModalExpanded,
      })}
    >
      <div
        className={classNames(
          'd-flex flex-row product align-items-center p-3',
          dataType
        )}
      >
        <IconWrapper icon={dataType} className='mr-1' />
        <div className='text-truncate' style={{ width: '100%' }}>
          {text(`${dataType}3D`)}
        </div>
        <div className='d-flex flex-row'>
          <ProductTooltip productType={dataType} placement='top' />
          {!isHeaderShown && <CloseButton />}
        </div>
      </div>

      <div className='px-3 pt-3 overflow-auto' style={{ maxHeight: '60vh' }}>
        <div
          className={classNames('d-flex flex-row', {
            'pb-1': fp.isObject(priceWithSalesHelp),
          })}
        >
          <WithLoader hasCharges={!!charge} className=''>
            <div className={classNames('price')}>{price.formatted}</div>
          </WithLoader>

          <div
            className={classNames(
              'grey-1 font-weight-bold font-12 d-flex flex-row flex-fill align-items-center justify-content-end'
            )}
          >
            {text('productAreaTotal', {
              delimiter: ':',
              productTotalArea: (
                <WithLoader
                  key={dataType}
                  hasCharges={!!charge}
                  showLoaderText={false}
                  className='ml-3'
                  fullWidth={false}
                >
                  <span
                    key={new Date().getTime()}
                    className={`${dataType}-product-area mb-0 ml-1 px-1 font-14`}
                  >
                    {totalArea.formatted}
                  </span>
                </WithLoader>
              ),
            })}
          </div>
        </div>

        <hr className='mx-0 emphasis' />

        {isFreeUser ? priceSalesHelpTemplate : null}

        {/* SHOW MEMBERSHIP DISCOUNT IF NOT MEMBER */}
        <WithLoader hasCharges={!!charge} className=''>
          {(isFreeOrTrial && hasMemberDiscount) || isStaff ? (
            <div
              className={classNames('discount', {
                'font-pink': !isEssentialUser || isStaff,
              })}
            >
              {memberDiscount}{' '}
            </div>
          ) : null}
        </WithLoader>

        {isEssentialUser ? priceSalesHelpTemplate : null}

        {isProductModalExpanded ? (
          <>
            {hasMemberDiscount ? <hr className='mx-0' /> : null}
            <DataTable className='product-selected-shapes mb-0'>
              <tbody>
                <tr>
                  <td className='pointCloudColumn' />
                  <td
                    colSpan={3}
                    className='areaColumn product-area text-left pl-3'
                  >
                    <span className='border-bottom'>
                      {`${text('productArea')}s`}
                    </span>
                  </td>
                </tr>
                {cartItems.map((selection, index) => {
                  return (
                    <SimpleDataRow
                      key={selection.id}
                      isFocused={activeShapeIds.has(selection.id)}
                      shape={selection}
                    />
                  );
                })}
              </tbody>
            </DataTable>

            {/* SHAPES UNION TOTAL */}
            {shapesUnionArea && shapesUnionArea.value > 0 ? (
              <>
                <div className='grey-3 font-12 mt-3'>
                  {text('shapesUnion', {
                    union: ![
                      PRODUCT_TYPE.INTERIOR,
                      PRODUCT_TYPE.BIM_INTERIOR,
                      PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
                    ].includes(dataType)
                      ? '(union) '
                      : '',
                    shapesUnionArea: shapesUnionArea.formatted,
                  })}
                </div>
                <hr className='mx-0' />
              </>
            ) : null}

            <div
              className={classNames('d-flex flex-column', {
                'mt-3': shapesUnionArea && shapesUnionArea.value <= 0,
              })}
            >
              <div className='grey-3 font-12'>{age}</div>
              <div
                className='grey-2'
                style={{
                  fontSize: 12,
                }}
              >
                {text('turnAroundTime', {
                  eta: BUY_NOW_DATA_TYPES.includes(dataType)
                    ? '2 hours'
                    : '2 weeks',
                })}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Modal.Body>
  );
};

export default Body;
