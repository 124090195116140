import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';
import classNames from 'classnames';

import useEventListener from '../../hooks/useEventListener';
import {
  PRODUCT_DELIVERY_METHOD,
  PRODUCT_PROS_CONS,
} from '../../constants/product';
import OutlinedText from '../../components/text/OutlinedText';
import text from '../../text';
import Icon from '../../components/icon/Icon';
import AddOnIcon from '../../components/icon/common/AddOnIcon';
import Tooltip from '../../components/Tooltip';
import ProductCard from '../../components/mapView/productCard/ProductCard';
import { get } from 'lodash';
import {
  canChangeDataType,
  DataType,
  dataTypeEnum,
  getBaseDataType,
  isDerivedDataType,
} from '../lib/dataType';
import { useRealtimeContext } from '../RealtimeContext';

const productsByDeliveryMethod = {
  draft: [
    {
      productType: dataTypeEnum.enum.unknown,
    },
  ],
  database: [
    {
      productType: dataTypeEnum.enum.aerial,
    },
    {
      productType: dataTypeEnum.enum.streetscape,
    },
  ],
  capture: [
    {
      productType: dataTypeEnum.enum.drone,
    },
    {
      productType: dataTypeEnum.enum.exterior,
    },
    {
      productType: dataTypeEnum.enum.interior,
    },
  ],
  addOns: [
    {
      productType: dataTypeEnum.enum.bim_exterior,
    },
    {
      productType: dataTypeEnum.enum.bim_interior,
    },
    {
      productType: dataTypeEnum.enum.twod_plan_exterior,
    },
    {
      productType: dataTypeEnum.enum.twod_plan_interior,
    },
  ],
};

type IconWrapperProps = {
  isAddOn: boolean;
  icon: string;
  className: string;
};
const IconWrapper = ({
  isAddOn = false,
  icon,
  className,
}: IconWrapperProps) => {
  if (!isAddOn) {
    return <Icon icon={icon} className={className} />;
  }

  return <AddOnIcon icon={icon} className={className} />;
};

const ProductControls = () => {
  const { activeShape, changeDataType, availability } = useRealtimeContext();

  const [isScrollable, setIsScrollable] = useState(false);
  const productControlsRef = useRef<HTMLDivElement>(null);

  const isElementHeightScrollable = (element: HTMLDivElement) => {
    const { clientHeight, scrollHeight } = element;
    return setIsScrollable(scrollHeight > clientHeight);
  };

  const setScrollableOnResize = useCallback(() => {
    if (!productControlsRef.current) return;
    isElementHeightScrollable(productControlsRef.current);
  }, [productControlsRef]);

  useEventListener('resize', setScrollableOnResize);

  const handleClick = useCallback(
    (dataType: DataType) => {
      if (activeShape) changeDataType(activeShape.id, dataType);
    },
    [activeShape, changeDataType]
  );

  const isActive = useCallback(
    (dataType: DataType) => {
      return activeShape?.dataType === dataType;
    },
    [activeShape]
  );

  const isDisabled = useCallback(
    (dataType: DataType) => {
      if (!activeShape) return false;
      if (dataType === 'unknown') return false;
      if (!canChangeDataType(activeShape.dataType, dataType)) return true;
      const baseDataType = isDerivedDataType(dataType)
        ? getBaseDataType(dataType)
        : dataType;
      const isAvailable = availability?.get(activeShape.id)?.get(baseDataType);
      return !isAvailable;
    },
    [activeShape, availability]
  );

  const setHoveredDataType = useCallback((dataType: DataType | null) => {}, []);

  const handleHover = useCallback(
    (dataType: DataType) => () => {
      setHoveredDataType(dataType);
    },
    [setHoveredDataType]
  );

  const handleUnhover = useCallback(() => {
    setHoveredDataType(null);
  }, [setHoveredDataType]);

  return (
    <div
      className={classNames('ProductControlsMp', {
        scrollable: isScrollable,
      })}
      ref={(element) => {
        // necessary to add callback to set ref on load of ProductControl component
        // especially on smaller screen
        if (!element) return;
        // @ts-expect-error cannot assign to current
        productControlsRef.current = element;
        isElementHeightScrollable(element);
      }}
    >
      {map(productsByDeliveryMethod, (products, deliveryMethod) => {
        return (
          <React.Fragment key={deliveryMethod}>
            {/* @ts-expect-error props */}
            <OutlinedText
              label={text(PRODUCT_DELIVERY_METHOD[deliveryMethod])}
              svgProps={{
                width: '60px', // best fit width for labels draft, database, ...
              }}
            />
            <div className='product-control-button-group'>
              {products.map(({ productType }) => {
                const isAddOn = isDerivedDataType(productType);
                const withExplanation =
                  productType === dataTypeEnum.enum.unknown || isAddOn;
                return (
                  <ProductControlsButton
                    key={productType}
                    productType={productType}
                    variant={
                      productType === dataTypeEnum.enum.unknown
                        ? 'tertiary'
                        : 'default'
                    }
                    withExplanation={withExplanation}
                    deliveryMethod={deliveryMethod}
                    disabled={isDisabled(productType)}
                    handleClick={handleClick}
                    handleHover={handleHover(productType)}
                    handleUnhover={handleUnhover}
                    isAddOn={isAddOn}
                    isActive={isActive(productType)}
                  />
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const ProductControlsButton = ({
  productType,
  variant,
  withExplanation,
  deliveryMethod,
  handleClick,
  handleHover,
  handleUnhover,
  disabled,
  isAddOn,
  isActive,
}: {
  productType: DataType;
  variant: 'default' | 'secondary' | 'tertiary';
  withExplanation: boolean;
  deliveryMethod: keyof typeof PRODUCT_DELIVERY_METHOD;
  handleClick: (productType: DataType) => void; // TODO: I don't like this prop drilling, let's find another state management solution
  handleHover: () => void;
  handleUnhover: () => void;
  disabled: boolean;
  isAddOn: boolean;
  isActive: boolean;
}) => {
  const pros = get(PRODUCT_PROS_CONS, [productType, 'pros']);
  const cons = get(PRODUCT_PROS_CONS, [productType, 'cons']);
  const [open, setOpen] = useState(false);

  const renderIcon = () => {
    if (isAddOn && disabled) {
      return `${productType}_disabled`;
    }

    return variant === 'secondary'
      ? 'question'
      : isAddOn && isActive
        ? `${productType}_white`
        : productType;
  };

  return (
    <Tooltip.Provider
      open={open}
      setOpen={setOpen}
      placement='right-start'
      keepOpenOnHover
    >
      {/* @ts-expect-error props */}
      <Tooltip.Trigger onClick={() => setOpen((v) => !v)}>
        <button
          type='button'
          className={cn(productType, {
            ProductBadge: variant !== 'secondary',
            'btn-icon': variant === 'secondary',
            tertiary: variant === 'tertiary',
            deactivated: disabled && !isActive,
            focus: isActive,
          })}
          onClick={() => handleClick(productType)}
          onMouseEnter={handleHover}
          onMouseLeave={handleUnhover}
          disabled={disabled}
        >
          <IconWrapper
            isAddOn={isAddOn}
            className={cn({
              enabled: true,
            })}
            icon={renderIcon()}
          />
        </button>
      </Tooltip.Trigger>
      {/* @ts-expect-error props */}
      <Tooltip.Content>
        <ProductCard
          productType={productType}
          pros={pros}
          cons={cons}
          isDisabled={disabled}
          handleClick={() => handleClick(productType)}
          variant={variant}
          withExplanation={withExplanation}
        />
      </Tooltip.Content>
    </Tooltip.Provider>
  );
};

const map = <K extends string, V, T>(
  obj: Record<K, V>,
  // eslint-disable-next-line no-unused-vars
  fn: (v: V, k: K) => T
) => {
  const entries = Object.entries(obj) as [K, V][];
  return entries.map(([k, v]) => fn(v, k));
};

export default ProductControls;
