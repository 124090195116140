import React, { useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';

import { useClickOutside } from '../../../hooks/useClickOutside';
import Icon from '../../../components/icon/Icon';
import UserAvatar from '../../../pages/profile/UserAvatar';
import { UserSettings } from '../../lib/shape';
import { colorLib } from '../../lib/color';

const RealtimeUsers = ({
  users,
  currentId,
}: {
  users: UserSettings[];
  currentId: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(6), flip(), shift()],
    whileElementsMounted: autoUpdate, // Automatically updates position when needed
    placement: 'bottom-end',
  });

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  useClickOutside(
    refs.reference,
    () => setIsOpen(false),
    ['.realtime-users-dropdown-container'],
    false
  );

  const { first, rest } = useMemo(() => {
    const N = 3;
    const otherUsers = users.filter((user) => user.id !== currentId);
    const first = otherUsers.slice(0, N);
    const rest = otherUsers.slice(N);
    return { first, rest };
  }, [currentId, users]);

  return (
    <div className='RealTimeUsers d-flex flex-row align-items-center'>
      {rest.length > 0 ? (
        <div
          className='dropdown-trigger d-flex flex-row px-2 mr-2'
          ref={refs.setReference}
          onClick={toggleDropdown}
        >
          <span className='font-12'>+{rest.length} Users</span>
          <Icon icon='solid-caret-down' />
        </div>
      ) : null}

      {isOpen ? (
        <FloatingPortal>
          <div
            className='realtime-users-dropdown-container'
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
            }}
          >
            {/* TODO: fix code dup */}
            <div className='realtime-users-wrapper px-0'>
              {rest.map((user) => {
                const color = colorLib.getUserColor(user.color_index);
                return (
                  <div className='realtime-user' key={user.id}>
                    <UserAvatar
                      profileImage={user.image}
                      hasBorder={true}
                      borderStyle={`3px solid ${color}`}
                    />
                    <Badge
                      className='free text-truncate'
                      style={{
                        background: color,
                        color: '#FFF',
                      }}
                    >
                      {user.name}
                    </Badge>
                  </div>
                );
              })}
            </div>
          </div>
        </FloatingPortal>
      ) : null}

      <div className='realtime-users-wrapper px-0'>
        {first.map((user) => {
          const color = colorLib.getUserColor(user.color_index);
          return (
            <div className='realtime-user' key={user.id}>
              <UserAvatar
                profileImage={user.image}
                hasBorder={true}
                borderStyle={`3px solid ${color}`}
              />
              <Badge
                className='free text-truncate'
                style={{
                  background: color,
                  color: '#FFF',
                }}
              >
                {user.name}
              </Badge>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RealtimeUsers;
