/**
 * A one-time password (OTP) is a token that is valid for only login session or transaction.
 * Our system generates OTP's automatically, we use OTPs for the following use case.
 *
 * The 3D viewer is hosted on viewer.p.app.larki.com.au, whereas the platform is hosted on app.larki.com.au.
 * We use local storage to store the current user's token, this is not shared between different subdomains.
 * For a seamless experience, we could just send the token in the URL ?token=... but this is susceptible to phishing attacks.
 * It is also possible users will share the URL with others, which would log them into the viewer as the sharing user.
 * Instead, we generate an OTP when a link to the 3D viewer is clicked, and then send the OTP in the URL ?otp=....
 * The OTP is verified upon entering the 3D viewer, and the user is logged in.
 */

import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { alertError } from '../redux/actions';
import { client } from '../utilities/api';
import { captureError } from '../utilities/error';

export const useOneTimePassword = () => {
  const dispatch = useDispatch();
  const [generateOtpState, generateOtp] = useAsyncFn(async () => {
    const { data } = await client.generateOtp();
    return data.otp.otp;
  }, []);
  const [verifyOtpState, verifyOtp] = useAsyncFn(async (otp) => {
    const { data } = await client.verifyOtp(otp);
    return data.user;
  }, []);
  useEffect(() => {
    if (generateOtpState.error) {
      captureError(generateOtpState.error);
      dispatch(alertError(generateOtpState.error.message));
    }
  }, [dispatch, generateOtpState]);
  useEffect(() => {
    if (verifyOtpState.error) {
      captureError(verifyOtpState.error);
      dispatch(alertError(verifyOtpState.error.message));
    }
  }, [dispatch, verifyOtpState]);
  return useMemo(
    () => ({ generateOtpState, generateOtp, verifyOtpState, verifyOtp }),
    [generateOtp, generateOtpState, verifyOtp, verifyOtpState]
  );
};
