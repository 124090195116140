import React from 'react';
import { Wizard } from 'react-use-wizard';
import StartTrialStep from './StartTrialStep';
import StartTrialStatusStep from './StartTrialStatusStep';
import T from 'prop-types';

const StartTrialWizard = ({ onCancel, onStartTrial, isHighResClicked }) => {
  return (
    <Wizard>
      <StartTrialStep onCancel={onCancel} isHighResClicked={isHighResClicked} />
      <StartTrialStatusStep onNext={onStartTrial ?? onCancel} />
    </Wizard>
  );
};

StartTrialWizard.propTypes = {
  onCancel: T.func,
  onStartTrial: T.func,
  isHighResClicked: T.bool,
};

export default StartTrialWizard;
