import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { uniq } from 'lodash';
import { hideModal } from '../../../../redux/actions';
import text from '../../../../text';
import Icon from '../../../icon/Icon';
import { Button } from '../../../button';

export const MP_UPDATE_SHAPES_MODAL = 'MP_UPDATE_SHAPES_MODAL';

export default function MpUpdateShapesModal({
  onOk,
  show,
  associatedAddOns = [],
  isNonTripodTarget = false,
  removeFromCart = false,
  toDraft = false,
}: {
  onOk: () => void;
  show: boolean;
  associatedAddOns?: { display_name: string }[];
  isNonTripodTarget?: boolean;
  removeFromCart?: boolean;
  toDraft?: boolean;
}) {
  const dispatch = useDispatch();

  const handleHide = () => dispatch(hideModal(MP_UPDATE_SHAPES_MODAL));

  const handleOk = () => {
    onOk();
    handleHide();
  };

  const associatedAddOnDisplayNames = uniq([
    ...associatedAddOns.map(({ display_name }) => display_name),
  ]).join(', ');

  const dialogHeader = useMemo(() => {
    if (!removeFromCart && !toDraft) {
      return text('updateShapesWarning');
    }

    if (removeFromCart) {
      return text('removeFromCartWarning');
    }

    if (toDraft) {
      return text('toDraftWarning');
    }
  }, [removeFromCart, toDraft]);

  return (
    <Modal
      centered
      className='UpdateShapeModal'
      onHide={handleHide}
      show={show}
    >
      {/* @ts-expect-error untyped */}
      <Modal.Header className='centered' closeButton>
        <Icon icon='circled-exclamation' />
      </Modal.Header>
      <Modal.Body className='centered'>
        <h3 className='mb-4'>{dialogHeader}</h3>

        {associatedAddOnDisplayNames ? (
          <p className='mb-4 font-16 font-weight-bold'>
            {text('updateShapesWarningAddOns', {
              addOns: associatedAddOnDisplayNames,
              caption:
                isNonTripodTarget || removeFromCart
                  ? 'will be deleted'
                  : 'will also be updated',
            })}
          </p>
        ) : null}
        <p className='m-0'>{text('updateShapesMessage')}</p>
      </Modal.Body>
      <Modal.Footer className='flex-column justify-content-center'>
        {/* @ts-expect-error untyped */}
        <Button onClick={handleOk} autoFocus>
          {text(removeFromCart ? 'yesRemove' : 'yesChangeShapeType')}
        </Button>
        {/* @ts-expect-error untyped */}
        <Button onClick={handleHide} variant='link'>
          {text('noCancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
