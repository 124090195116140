import { z } from 'zod';
import * as turf from '@turf/turf';
import * as GeoJSON from 'geojson';
import * as fp from 'lodash/fp';
import invariant from 'tiny-invariant';
import { crdtClient } from '@larki/mp';

import { positionLib } from './position';
import { Corner, cornerEnum, rectangleLib } from './rectangle';
import { geometryLib } from './geometry';
import { objectAdd, objectAssign, objectMap } from './object';
import { GetPickingInfoParams, PickingInfo } from '@deck.gl/core';
import { DeleteVertexLayerPickingDataT_ } from '../layers/deleteVertexLayer';
import { AddVertexLayerPickingDataT_ } from '../layers/addVertexLayer';
import {
  dataTypeEnum,
  DERIVED_DATA_TYPES,
  DerivedDataType,
  isDerivedDataType,
  POINT_CLOUD_DATA_TYPES,
} from './dataType';
import { arrayLib } from './array';
import { mapTypeEnum } from './mapType';

const _baseMapShapeDataTypeSchema = z.union([
  z.object({
    dataType: z.enum([...POINT_CLOUD_DATA_TYPES, 'unknown']).default('unknown'),
  }),
  z.object({
    dataType: z.enum(DERIVED_DATA_TYPES),
    baseShapeId: z.string(),
  }),
]);
const _baseMapShapeSchema = z
  .object({
    id: z.string(),
    name: z.string().optional(),
    hidden: z.boolean().default(false),
  })
  .and(_baseMapShapeDataTypeSchema);
export const positionSchema = z
  .string()
  .transform((val) => positionLib.fromString(val));
const _baseMapPolygonSchema = z.object({
  type: z.literal('polygon'),
  exterior: z.array(positionSchema),
  holes: z.array(z.array(positionSchema)).optional(),
});
const mapPolygonSchema = _baseMapShapeSchema.and(_baseMapPolygonSchema);
const _baseMapRectangleSchema = z.object({
  type: z.literal('rectangle'),
  exterior: z.object({
    [Corner.sw]: positionSchema,
    [Corner.se]: positionSchema,
    [Corner.ne]: positionSchema,
    [Corner.nw]: positionSchema,
  }),
  holes: z.array(z.array(positionSchema)).optional(),
});
const mapRectangleSchema = _baseMapShapeSchema.and(_baseMapRectangleSchema);
const _baseMapPathSchema = z.object({
  type: z.literal('path'),
  vertices: z.array(positionSchema),
});
const mapPathSchema = _baseMapShapeSchema.and(_baseMapPathSchema);
// const mapShapeSchema = z.discriminatedUnion('type', [
//   mapPolygonSchema,
//   mapRectangleSchema,
//   mapPathSchema,
// ]);
const innerMapShapeSchema = z.union([
  mapPolygonSchema,
  mapRectangleSchema,
  mapPathSchema,
]);
const _baseTentativeShapeSchema = z.object({
  id: z.string(),
});
const tentativePolygonSchema = _baseTentativeShapeSchema.extend({
  type: z.literal('polygon'),
  exterior: z.array(positionSchema),
});
const tentativeRectangleSchema = _baseTentativeShapeSchema.extend({
  type: z.literal('rectangle'),
  initial: positionSchema,
  final: positionSchema.optional(),
});
const tentativePathSchema = _baseTentativeShapeSchema.extend({
  type: z.literal('path'),
  vertices: z.array(positionSchema),
});
const tentativeBooleanSchema = _baseTentativeShapeSchema.extend({
  type: z.literal('boolean'),
  parent_index: z.number(),
  vertices: z.array(positionSchema),
});
const tentativeShapeSchema = z.discriminatedUnion('type', [
  tentativePolygonSchema,
  tentativeRectangleSchema,
  tentativePathSchema,
  tentativeBooleanSchema,
]);

const userSettingsSchema = z.object({
  id: z.string(),
  name: z.string().nullish(),
  image: z.string().nullish(),
  color_index: z.number().nonnegative(),
  map_type: mapTypeEnum.default('roadmap'),
});
export type UserSettings = z.infer<typeof userSettingsSchema>;

export const stateSchema = z.object({
  shapes: z.array(innerMapShapeSchema).optional(),
});
const tempStateSchema = z.object({
  hidden_map: z.record(z.string(), z.boolean()).optional(),
  tentative_shapes: z.record(z.string(), tentativeShapeSchema).optional(),
  clients: z
    .record(
      z.string(),
      z.object({
        drag_shape_index: z.number().optional(),
        drag_hole_index: z.number().optional(),
        drag_vertex_index: z.number().optional(),
        drag_corner: cornerEnum.optional(),
        user_settings: userSettingsSchema,
      })
    )
    .optional(),
});

const mapShapeInputSchema = z.union([
  _baseMapShapeDataTypeSchema.and(_baseMapPolygonSchema),
  _baseMapShapeDataTypeSchema.and(_baseMapRectangleSchema),
  _baseMapShapeDataTypeSchema.and(_baseMapPathSchema),
]);
export type MapShapeInput = z.infer<typeof mapShapeInputSchema>;
export type DerivedMapShapeInput = MapShapeInput & {
  dataType: DerivedDataType;
  baseShapeId: string;
};

export type TentativePolygon = z.infer<typeof tentativePolygonSchema>;
export type TentativeRectangle = z.infer<typeof tentativeRectangleSchema>;
export type TentativePath = z.infer<typeof tentativePathSchema>;
export type TentativeBoolean = z.infer<typeof tentativeBooleanSchema>;
export type TentativeHandle =
  | GeoJSON.Feature<GeoJSON.Point, TentativePolygonHandleProperties>
  | GeoJSON.Feature<GeoJSON.Point, TentativeRectangleHandleProperties>
  | GeoJSON.Feature<GeoJSON.Point, TentativePathHandleProperties>
  | GeoJSON.Feature<GeoJSON.Point, TentativeBooleanHandleProperties>;
export type TentativeShape = z.infer<typeof tentativeShapeSchema>;
export type MapPolygon = z.infer<typeof mapPolygonSchema>;
export type MapRectangle = z.infer<typeof mapRectangleSchema>;
export type MapPath = z.infer<typeof mapPathSchema>;
export type MapShape = MapPolygon | MapRectangle | MapPath;
export type DerivedMapShape = MapShape & {
  dataType: DerivedDataType;
  baseShapeId: string;
};

export const pathFeatureFromSequence = <T extends object>(
  seq: GeoJSON.Position[],
  properties: T,
  sectorSteps = 5
) => {
  invariant(seq.length >= 2);
  const PATH_WIDTH_METERS = 20;
  const MIN_DISTANCE_TO_SHOW_FINAL_SECTOR_METERS = 1;

  const elements: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon, T>[] =
    [];
  for (let i = 0; i < seq.length - 1; i++) {
    const p1 = turf.point(seq[i]);
    const p2 = turf.point(seq[i + 1]);
    if (i == seq.length - 2) {
      const dst = turf.distance(p1, p2, { units: 'meters' });
      if (dst < MIN_DISTANCE_TO_SHOW_FINAL_SECTOR_METERS) {
        continue;
      }
    }
    const bearing = turf.bearing(p1, p2);
    const p0a = turf.destination(p1, PATH_WIDTH_METERS, bearing + 90, {
      units: 'meters',
    });
    const p0b = turf.destination(p1, PATH_WIDTH_METERS, bearing - 90, {
      units: 'meters',
    });
    const p1a = turf.destination(p2, PATH_WIDTH_METERS, bearing + 90, {
      units: 'meters',
    });
    const p1b = turf.destination(p2, PATH_WIDTH_METERS, bearing - 90, {
      units: 'meters',
    });
    const rect = turf.polygon(
      [
        [
          turf.getCoord(p0a),
          turf.getCoord(p0b),
          turf.getCoord(p1b),
          turf.getCoord(p1a),
          turf.getCoord(p0a),
        ],
      ],
      properties
    );
    // buffer slightly to ensure overlap happens
    let rectBuffered = turf.buffer(rect, 0.001, {
      units: 'meters',
    }) as GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon, T>;
    invariant(rectBuffered);
    rectBuffered = { ...rectBuffered, properties };

    elements.push(rectBuffered);
    if (i > 0) {
      const p0 = turf.point(seq[i - 1]);
      const priorBearing = turf.bearing(p0, p1);
      // get the signed-angle difference in [-180,180] https://stackoverflow.com/a/61901403
      const diff = ((bearing - priorBearing + 360 + 180) % 360) - 180;
      const _to360 = (angle: number) => (angle + 360) % 360;
      const _to180 = (angle: number) =>
        angle > 180 ? angle - 360 : angle < -180 ? angle + 360 : angle;
      const angle0 = _to360(priorBearing);
      const angle1 = _to360(bearing);
      const a0 = _to180(_to360(diff >= 0 ? angle0 - 90 : angle1 + 90));
      const a1 = _to180(_to360(diff >= 0 ? angle1 - 90 : angle0 + 90));
      let controlPointSector = turf.sector(p1, PATH_WIDTH_METERS, a0, a1, {
        units: 'meters',
        steps: sectorSteps,
        properties,
      }) as GeoJSON.Feature<GeoJSON.Polygon, T>;
      // let controlPointSectorBuffered = turf.buffer(controlPointSector, 0.001, {
      //   units: 'meters',
      // }) as GeoJSON.Feature<GeoJSON.Polygon, T>;
      // invariant(controlPointSectorBuffered);
      // controlPointSectorBuffered = {
      //   ...controlPointSectorBuffered,
      //   properties,
      // };
      controlPointSector = {
        ...controlPointSector,
        properties,
      };
      elements.push(controlPointSector);
    }
  }
  if (elements.length === 0) return null;
  let surrounding: GeoJSON.Feature<
    GeoJSON.Polygon | GeoJSON.MultiPolygon,
    T
  > | null;
  try {
    surrounding =
      elements.length === 1
        ? elements[0]
        : geometryLib.union(elements, properties);
    invariant(surrounding);
    surrounding.properties = properties;
  } catch (e) {
    console.error(e);
    for (const element of elements) {
      console.log(element, turf.booleanValid(element));
    }
    console.error(seq);
    throw e;
  }
  return surrounding;
};

export function oppositeCornersToRectangle(
  initial: GeoJSON.Position,
  final: GeoJSON.Position
): Record<Corner, GeoJSON.Position> {
  const finalEastEdge = final[0] > initial[0];
  // const finalNorthEdge = final[1] > initial[1];
  let [left, right] = [initial, final];
  if (!finalEastEdge) {
    [left, right] = [final, initial];
  }
  invariant(left[0] <= right[0]);
  let [sw, nw, ne, se]: GeoJSON.Position[] = [];
  if (left[1] <= right[1]) {
    // left is south-west, right is north-east
    sw = left;
    ne = right;
    nw = [sw[0], ne[1]];
    se = [ne[0], sw[1]];
  } else {
    // left is north-west, right is south-east
    nw = left;
    se = right;
    sw = [nw[0], se[1]];
    ne = [se[0], nw[1]];
  }
  invariant(sw && nw && ne && se);
  return {
    [Corner.sw]: sw,
    [Corner.nw]: nw,
    [Corner.ne]: ne,
    [Corner.se]: se,
  };
}

export const convertStateToJson = (
  state: crdtClient.LoroDoc,
  tempState: crdtClient.LoroDoc
) => {
  const stateJson = stateSchema.parse(state.toJSON());
  const tempStateJson = tempStateSchema.parse(tempState.toJSON());
  return {
    ...objectAssign(stateJson, 'shapes', (shapes) =>
      shapes?.map((shape) =>
        objectAdd(
          shape,
          'hidden',
          tempStateJson.hidden_map?.[shape.id] ?? false
        )
      )
    ),
    ...tempStateJson,
  };
};
export type JsonState = ReturnType<typeof convertStateToJson>;
export type ShapeDetails = Pick<
  Required<JsonState>['shapes'][number],
  'id' | 'type' | 'name' | 'dataType' | 'hidden'
>;

export const checkStateIsValid = (
  state: crdtClient.LoroDoc,
  tempState: crdtClient.LoroDoc
) =>
  stateSchema.safeParse(state.toJSON()).success &&
  tempStateSchema.safeParse(tempState.toJSON()).success;

type _BaseMapFeatureProperties = {
  featureType: 'shape' | 'handle';
};
export interface _BaseTentativeShapeHandleProperties
  extends _BaseMapFeatureProperties {
  featureType: 'handle';
  handleType: 'tentative';
  shapeId: string;
}
export interface TentativePolygonHandleProperties
  extends _BaseTentativeShapeHandleProperties {
  shapeType: 'polygon';
}
export interface TentativeRectangleHandleProperties
  extends _BaseTentativeShapeHandleProperties {
  shapeType: 'rectangle';
}
export interface TentativePathHandleProperties
  extends _BaseTentativeShapeHandleProperties {
  shapeType: 'path';
}
export interface TentativeBooleanHandleProperties
  extends _BaseTentativeShapeHandleProperties {
  shapeType: 'boolean';
  parentIndex: number;
}
export const createTentativePolygonHandle = (
  coordinate: GeoJSON.Position,
  properties: Omit<
    TentativePolygonHandleProperties,
    'featureType' | 'handleType' | 'shapeType'
  >
) =>
  turf.point<TentativePolygonHandleProperties>(coordinate, {
    ...properties,
    featureType: 'handle',
    handleType: 'tentative',
    shapeType: 'polygon',
  });
export const createTentativeRectangleHandle = (
  coordinate: GeoJSON.Position,
  properties: Omit<
    TentativeRectangleHandleProperties,
    'featureType' | 'handleType' | 'shapeType'
  >
) =>
  turf.point<TentativeRectangleHandleProperties>(coordinate, {
    ...properties,
    featureType: 'handle',
    handleType: 'tentative',
    shapeType: 'rectangle',
  });
export const createTentativePathHandle = (
  coordinate: GeoJSON.Position,
  properties: Omit<
    TentativePathHandleProperties,
    'featureType' | 'handleType' | 'shapeType'
  >
) =>
  turf.point<TentativePathHandleProperties>(coordinate, {
    ...properties,
    featureType: 'handle',
    handleType: 'tentative',
    shapeType: 'path',
  });
export const createTentativeBooleanHandle = (
  coordinate: GeoJSON.Position,
  properties: Omit<
    TentativeBooleanHandleProperties,
    'featureType' | 'handleType' | 'shapeType'
  >
) =>
  turf.point<TentativeBooleanHandleProperties>(coordinate, {
    ...properties,
    featureType: 'handle',
    handleType: 'tentative',
    shapeType: 'boolean',
  });

export interface _BaseShapeHandleProperties extends _BaseMapFeatureProperties {
  featureType: 'handle';
  handleType: 'shape';
  shapeIndex: number;
  hidden: boolean;
}
export interface PolygonHandleProperties extends _BaseShapeHandleProperties {
  shapeType: 'polygon';
  vertexIndex: number;
}
export interface RectangleHandleProperties extends _BaseShapeHandleProperties {
  shapeType: 'rectangle';
  corner: Corner;
}
export interface PathHandleProperties extends _BaseShapeHandleProperties {
  shapeType: 'path';
  vertexIndex: number;
}
export interface BooleanHandleProperties extends _BaseShapeHandleProperties {
  shapeType: 'boolean';
  holeIndex: number;
  vertexIndex: number;
}
export const createPolygonHandle = (
  coordinate: GeoJSON.Position,
  properties: Omit<
    PolygonHandleProperties,
    'featureType' | 'handleType' | 'shapeType'
  >
) =>
  turf.point<PolygonHandleProperties>(coordinate, {
    ...properties,
    featureType: 'handle',
    handleType: 'shape',
    shapeType: 'polygon',
  });
export const createRectangleHandle = (
  coordinate: GeoJSON.Position,
  properties: Omit<
    RectangleHandleProperties,
    'featureType' | 'handleType' | 'shapeType'
  >
) =>
  turf.point<RectangleHandleProperties>(coordinate, {
    ...properties,
    featureType: 'handle',
    handleType: 'shape',
    shapeType: 'rectangle',
  });
export const createPathHandle = (
  coordinate: GeoJSON.Position,
  properties: Omit<
    PathHandleProperties,
    'featureType' | 'handleType' | 'shapeType'
  >
) =>
  turf.point<PathHandleProperties>(coordinate, {
    ...properties,
    featureType: 'handle',
    handleType: 'shape',
    shapeType: 'path',
  });
export const createBooleanHandle = (
  coordinate: GeoJSON.Position,
  properties: Omit<
    BooleanHandleProperties,
    'featureType' | 'handleType' | 'shapeType'
  >
) =>
  turf.point<BooleanHandleProperties>(coordinate, {
    ...properties,
    featureType: 'handle',
    handleType: 'shape',
    shapeType: 'boolean',
  });

export interface MapShapeFeatureProperties extends _BaseMapFeatureProperties {
  featureType: 'shape';
  shapeIndex: number;
  hidden: boolean;
}
export type MapShapeFeature = GeoJSON.Feature<
  GeoJSON.Polygon | GeoJSON.MultiPolygon,
  MapShapeFeatureProperties
>;

export function interpolateState(
  state: JsonState,
  otherCursors: Map<string, GeoJSON.Position>,
  myCursor: GeoJSON.Position | null,
  myClientId: string
): JsonState {
  function getCursor(clientId: string) {
    return clientId === myClientId ? myCursor : otherCursors.get(clientId);
  }

  const shapeIndexToDragClients = new Map<
    number,
    (Required<JsonState>['clients'][string] & { id: string })[]
  >();
  for (const [clientId, client] of fp.entries(state.clients ?? {})) {
    if (client.drag_shape_index !== undefined) {
      if (!shapeIndexToDragClients.has(client.drag_shape_index)) {
        shapeIndexToDragClients.set(client.drag_shape_index, []);
      }
      shapeIndexToDragClients
        .get(client.drag_shape_index)!
        .push({ ...client, id: clientId });
    }
  }

  return {
    tentative_shapes: objectMap((shape, clientId) => {
      const cursor = getCursor(clientId);
      if (!cursor) return shape;
      if (shape.type === 'polygon') {
        const { type, id, exterior } = shape;
        return {
          type,
          id,
          exterior: [...exterior, cursor],
        };
      } else if (shape.type === 'rectangle') {
        const { type, id, initial } = shape;
        return {
          type,
          id,
          initial,
          final: cursor,
        };
      } else if (shape.type === 'path') {
        const { type, id, vertices } = shape;
        return {
          type,
          id,
          vertices: [...vertices, cursor],
        };
      } else if (shape.type === 'boolean') {
        const { type, id, parent_index, vertices } = shape;
        return {
          type,
          id,
          parent_index,
          vertices: [...vertices, cursor],
        };
      }
      return shape;
    }, state.tentative_shapes ?? {}),
    shapes: state.shapes?.map((shape, shapeIndex) => {
      const dragClients = shapeIndexToDragClients.get(shapeIndex);
      if (!dragClients) return shape;

      if (shape.type === 'polygon') {
        const { exterior, holes, ...rest } = shape;

        for (const client of dragClients) {
          const cursor = getCursor(client.id);
          if (!cursor) continue;
          if (client.drag_hole_index !== undefined) {
            if (holes && client.drag_vertex_index !== undefined) {
              holes[client.drag_hole_index][client.drag_vertex_index] = cursor;
            }
          } else if (client.drag_vertex_index !== undefined) {
            exterior[client.drag_vertex_index] = cursor;
          }
        }

        return {
          exterior,
          holes,
          ...rest,
        };
      } else if (shape.type === 'rectangle') {
        const { exterior, holes, ...rest } = shape;

        for (const client of dragClients) {
          const cursor = getCursor(client.id);
          if (!cursor) continue;

          if (client.drag_hole_index !== undefined) {
            if (holes && client.drag_vertex_index !== undefined) {
              holes[client.drag_hole_index][client.drag_vertex_index] = cursor;
            }
          } else if (client.drag_corner) {
            const [cornerH, cornerV] = rectangleLib.getAdjacentCorners(
              client.drag_corner
            );
            exterior[client.drag_corner] = cursor;
            exterior[cornerH] = [exterior[cornerH][0], cursor[1]];
            exterior[cornerV] = [cursor[0], exterior[cornerV][1]];
          }
        }

        return {
          exterior,
          holes,
          ...rest,
        };
      } else if (shape.type === 'path') {
        const { vertices, ...rest } = shape;

        for (const client of dragClients) {
          const cursor = getCursor(client.id);
          if (!cursor) continue;
          if (client.drag_vertex_index !== undefined) {
            vertices[client.drag_vertex_index] = cursor;
          }
        }

        return {
          vertices,
          ...rest,
        };
      }

      return shape;
    }),
    clients: state.clients,
  };
}

export type PolygonHandleFeature = GeoJSON.Feature<
  GeoJSON.Point,
  PolygonHandleProperties
>;
export type RectangleHandleFeature = GeoJSON.Feature<
  GeoJSON.Point,
  RectangleHandleProperties
>;
export type PathHandleFeature = GeoJSON.Feature<
  GeoJSON.Point,
  PathHandleProperties
>;
export type BooleanHandleFeature = GeoJSON.Feature<
  GeoJSON.Point,
  BooleanHandleProperties
>;
export type DeletableHandleFeature =
  | PolygonHandleFeature
  | PathHandleFeature
  | BooleanHandleFeature;

export type MidpointProperties = {
  shapeIndex: number;
  vertexIndexBefore: number;
} & (
  | {
      shapeType: 'polygon' | 'path';
    }
  | {
      shapeType: 'boolean';
      holeIndex: number;
    }
);
export type MidpointFeature = GeoJSON.Feature<
  GeoJSON.Point,
  MidpointProperties
>;

export type PickingDataT_ =
  | GeoJSON.Feature<GeoJSON.Point, TentativePolygonHandleProperties>
  | GeoJSON.Feature<GeoJSON.Point, TentativeRectangleHandleProperties>
  | GeoJSON.Feature<GeoJSON.Point, TentativePathHandleProperties>
  | GeoJSON.Feature<GeoJSON.Point, TentativeBooleanHandleProperties>
  | PolygonHandleFeature
  | RectangleHandleFeature
  | PathHandleFeature
  | BooleanHandleFeature
  | MapShapeFeature;
export type PickingExtraInfo_ = {
  handle?:
    | TentativePolygonHandleProperties
    | TentativeRectangleHandleProperties
    | TentativePathHandleProperties
    | TentativeBooleanHandleProperties
    | PolygonHandleProperties
    | RectangleHandleProperties
    | PathHandleProperties
    | BooleanHandleProperties;
  mapShapeFeature?: MapShapeFeatureProperties;
  popup?: DeleteVertexLayerPickingDataT_ | AddVertexLayerPickingDataT_;
};
export type GetPickingInfoParams_ = GetPickingInfoParams<
  PickingDataT_,
  PickingExtraInfo_
>;
export type PickingInfo_ = PickingInfo<PickingDataT_, PickingExtraInfo_>;

export interface ClosestResult<T> {
  xyDistance: number;
  coordinate: GeoJSON.Position;
  result: T;
}

export function getClosestPosition<T>({
  targetCoord,
  points,
  // PROJECTION,
  parseResult,
}: {
  targetCoord: GeoJSON.Position;
  points: GeoJSON.Position[];
  // PROJECTION: proj4.Converter;
  parseResult: ({
    coordinate,
    i,
  }: {
    coordinate: GeoJSON.Position;
    i: number;
  }) => T;
}) {
  // const targetXy = PROJECTION.forward(targetCoord);
  let current: ClosestResult<T> | null = null;
  for (let i = 0; i < points.length; i++) {
    // const pointXy = PROJECTION.forward(points[i]);
    // const xyDistance = geometryLib.squaredDistance(targetXy, pointXy);
    const xyDistance = turf.distance(targetCoord, points[i], {
      units: 'meters',
    });
    if (!current || xyDistance < current.xyDistance) {
      const coordinate = points[i];
      current = {
        xyDistance,
        coordinate,
        result: parseResult({ coordinate, i }),
      };
    }
  }
  return current;
}

export function getClosestPositionNested<T>({
  targetCoord,
  points,
  // PROJECTION,
  parseResult,
}: {
  targetCoord: GeoJSON.Position;
  points: GeoJSON.Position[][];
  // PROJECTION: proj4.Converter;
  parseResult: ({
    coordinate,
    i,
    j,
  }: {
    coordinate: GeoJSON.Position;
    i: number;
    j: number;
  }) => T;
}) {
  // const targetXy = PROJECTION.forward(targetCoord);
  let current: ClosestResult<T> | null = null;
  for (let i = 0; i < points.length; i++) {
    for (let j = 0; j < points[i].length; j++) {
      // const pointXy = PROJECTION.forward(points[i][j]);
      // const xyDistance = geometryLib.squaredDistance(targetXy, pointXy);
      const xyDistance = turf.distance(targetCoord, points[i][j], {
        units: 'meters',
      });
      if (!current || xyDistance < current.xyDistance) {
        const coordinate = points[i][j];
        current = {
          xyDistance,
          coordinate,
          result: parseResult({ coordinate, i, j }),
        };
      }
    }
  }
  return current;
}

export function getClosestOverall<T>(
  ...closestResults: (ClosestResult<T> | null)[]
) {
  return closestResults.reduce(
    (prev, curr) =>
      curr && (!prev || curr.xyDistance < prev.xyDistance) ? curr : prev,
    null
  );
}

export function getMidpoints(points: GeoJSON.Position[]) {
  const midpoints: GeoJSON.Position[] = [];
  for (let i = 0; i < points.length; i++) {
    const before = points[i];
    const after = points[(i + 1) % points.length];
    midpoints.push(turf.midpoint(before, after).geometry.coordinates);
  }
  return midpoints;
}

export function createPolygonMidpointFeature(
  coordinate: GeoJSON.Position,
  shapeIndex: number,
  vertexIndexBefore: number
): MidpointFeature {
  return turf.point(coordinate, {
    shapeType: 'polygon',
    shapeIndex,
    vertexIndexBefore,
  });
}

export function createPathMidpointFeature(
  coordinate: GeoJSON.Position,
  shapeIndex: number,
  vertexIndexBefore: number
): MidpointFeature {
  return turf.point(coordinate, {
    shapeType: 'path',
    shapeIndex,
    vertexIndexBefore,
  });
}

export function createBooleanMidpointFeature(
  coordinate: GeoJSON.Position,
  shapeIndex: number,
  holeIndex: number,
  vertexIndexBefore: number
): MidpointFeature {
  return turf.point(coordinate, {
    shapeType: 'boolean',
    shapeIndex,
    holeIndex,
    vertexIndexBefore,
  });
}

export function isDerivedShape(shape: MapShape): shape is DerivedMapShape {
  return isDerivedDataType(shape.dataType);
}

export function isDerivedShapeInput(
  shape: MapShapeInput
): shape is DerivedMapShapeInput {
  return isDerivedDataType(shape.dataType);
}

export function getBoundingBox(shape: MapShape) {
  if (shape.type === 'polygon') {
    return turf.bbox(turf.polygon([[...shape.exterior, shape.exterior[0]]]));
  } else if (shape.type === 'rectangle') {
    return turf.bbox(
      turf.polygon([
        [
          shape.exterior.sw,
          shape.exterior.se,
          shape.exterior.ne,
          shape.exterior.nw,
          shape.exterior.sw,
        ],
      ])
    );
  } else if (shape.type === 'path') {
    const feat = pathFeatureFromSequence(shape.vertices, {}, 3);
    if (!feat) return null;
    return turf.bbox(feat);
  }
  return null;
}

type ShapeInput =
  | MapShape
  | GeoJSON.Polygon
  | GeoJSON.MultiPolygon
  | GeoJSON.Feature<GeoJSON.Polygon>
  | GeoJSON.Feature<GeoJSON.MultiPolygon>;

function extractFeatureFromPolygonOrRectangle<T>(
  shape: MapPolygon | MapRectangle,
  properties?: T
) {
  const exterior =
    shape.type === 'polygon'
      ? [...shape.exterior, shape.exterior[0]]
      : [
          shape.exterior.sw,
          shape.exterior.se,
          shape.exterior.ne,
          shape.exterior.nw,
          shape.exterior.sw,
        ];
  const exteriorPoly = turf.polygon(
    [[...exterior, exterior[0]]],
    properties ?? undefined
  );
  const validHoles = shape.holes?.filter((h) => h.length >= 3);
  const diff = validHoles?.length
    ? (turf.difference(
        turf.featureCollection([
          exteriorPoly,
          ...validHoles.map((h) => turf.polygon([[...h, h[0]]])),
        ])
      ) ?? exteriorPoly)
    : exteriorPoly;
  return diff.geometry;
}

export function extractShapeGeometry(shape: ShapeInput) {
  if (shape.type === 'Feature') return shape.geometry;
  if (shape.type === 'Polygon' || shape.type === 'MultiPolygon') return shape;
  if (shape.type === 'path')
    return pathFeatureFromSequence(shape.vertices, {}, 3)?.geometry ?? null;
  return extractFeatureFromPolygonOrRectangle(shape);
}

export function shapeBooleanIntersects(a: ShapeInput, b: ShapeInput) {
  const aGeom = extractShapeGeometry(a);
  const bGeom = extractShapeGeometry(b);
  if (!aGeom || !bGeom) {
    console.warn('failed to extract underlying geometry');
    return false;
  }
  return turf.booleanIntersects(aGeom, bGeom);
}

export function shapeIntersect(...features: ShapeInput[]) {
  const geoms = features
    .map((f) => extractShapeGeometry(f))
    .filter(arrayLib.filterNonNull);
  if (geoms.length === 0) return null;
  if (geoms.length === 1) return geoms[0];
  return turf.intersect(
    turf.featureCollection(geoms.map((g) => turf.feature(g)))
  )?.geometry;
}

export function shapeUnion(...features: ShapeInput[]) {
  const geoms = features
    .map((f) => extractShapeGeometry(f))
    .filter(arrayLib.filterNonNull);
  if (geoms.length === 0) return null;
  if (geoms.length === 1) return geoms[0];
  return geometryLib.union(
    geoms.map((g) => turf.feature(g)),
    {}
  )?.geometry;
}
