import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';
import { UserSettings } from '../../lib/shape';
import { colorLib } from '../../lib/color';

type Props = {
  classNames?: string;
  caption: string;
  isSelected?: boolean;
  users?: UserSettings[];
  onClick?: () => void;
  suffixIcon?: ReactNode | null;
  isDisabled?: boolean;
};

const MapTypeButton = ({
  classNames,
  caption,
  isSelected,
  users = [],
  onClick,
  suffixIcon = null,
  isDisabled,
}: Props) => {
  return (
    <div className='position-relative MapTypeButtonWrapper'>
      {users.length > 0 ? (
        <div className='UserAvatarContainer'>
          {users.map((user) => (
            <div
              className='rounded-circle avatar avatar--mini'
              key={user.id}
              style={{
                background: colorLib.getUserColor(user.color_index),
                color: '#FFF',
              }}
            >
              {user.name?.charAt(0).toUpperCase()}
            </div>
          ))}
        </div>
      ) : null}
      <Button
        disabled={isDisabled}
        className={cn('MapTypeButton', {
          ...(classNames && {
            [classNames]: classNames,
          }),
          selected: isSelected,
        })}
        data-label={caption}
        onClick={onClick}
      >
        {caption}
        {suffixIcon}
      </Button>
    </div>
  );
};

export default MapTypeButton;
