import { useReducer } from 'react';

export type Mode =
  | 'idle'
  | 'creating_polygon'
  | 'creating_rectangle'
  | 'creating_path'
  | 'creating_boolean';
type CreatingMode = Exclude<Mode, 'idle'>;
export type ModeAction =
  | 'start_creating_polygon'
  | 'start_creating_rectangle'
  | 'start_creating_path'
  | 'start_creating_boolean'
  | 'stop_creating';
export const modeReducer = (mode: Mode, action: ModeAction): Mode => {
  if (action === 'start_creating_polygon') {
    return 'creating_polygon';
  } else if (action === 'start_creating_rectangle') {
    return 'creating_rectangle';
  } else if (action === 'start_creating_path') {
    return 'creating_path';
  } else if (action === 'start_creating_boolean') {
    return 'creating_boolean';
  } else if (action === 'stop_creating') {
    return 'idle';
  }
  return mode;
};
export const isModeCreating = (mode: Mode): mode is CreatingMode =>
  mode !== 'idle';

const useMode = () => useReducer(modeReducer, 'idle');

export default useMode;
