import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../components/button/Button';
import { cardsBrand } from '../../components/card/constants';
import Icon from '../../components/icon/Icon';
import List from '../../components/list/List';
import Loading from '../../components/loading/Loading';
import {
  getJobReceipt,
  getMembershipReceipt,
  getUserProfile,
  showModal,
} from '../../redux/actions';

import text, { formatCurrency } from '../../text';
import { formatDate } from '../../utilities/date';
import TextDivider from '../../components/text/TextDivider';
import { PROJECT_TRIAL_MEMBERS_MODAL } from '../../components/modal/ProjectTrialMembers/ProjectTrialMembers';
import useCurrentProject from '../../hooks/useCurrentProject';
import routes from '../../routes/constants';

export const ReceiptItem = ({ receipt, description }) => {
  return (
    <>
      <tbody className='ReceiptItem'>
        <List.Item
          description={<h3>{description}</h3>}
          value={
            <h3>
              {formatCurrency(
                receipt.value?.amount,
                receipt.value?.currency.name,
                receipt.value?.currency.scale_factor,
                {},
                true
              )}
            </h3>
          }
        />
      </tbody>
      <Link
        to={{ pathname: receipt.value?.receipt_url }}
        className='mb-3'
        href='#'
        target='_blank'
      >
        {text('viewReceipt', { receiptNumber: receipt.value?.receipt_number })}
      </Link>
    </>
  );
};

const PaymentSuccess = (props) => {
  const { jobId, orderPaymentIntent, membershipPaymentIntent } = props;
  const dispatch = useDispatch();
  useCurrentProject({ enableJob: true });
  const { currentProject, orderReceipt, membershipReceipt } = useSelector(
    (state) => ({
      orderReceipt: state.jobsReducer.receipt,
      membershipReceipt: state.profileReducer.membershipReceipt,
      currentProject: state.project.currentProject,
    })
  );

  useEffect(() => {
    if (orderPaymentIntent?.value?.id) {
      dispatch(getJobReceipt(jobId, orderPaymentIntent?.value.id));
    }
  }, [jobId]);

  useEffect(() => {
    if (currentProject) {
      dispatch(getJobReceipt(jobId, orderPaymentIntent?.value.id));
    }
  }, [jobId]);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (membershipPaymentIntent?.value?.id) {
      dispatch(getMembershipReceipt(jobId, membershipPaymentIntent?.value.id));
    }
  }, [membershipPaymentIntent]);

  return (
    <div className='PaymentSuccess'>
      {!orderReceipt.isLoading || !membershipReceipt.isLoading ? (
        <>
          <Icon icon='circled-tick_filled' />
          <div className='w-100'>
            <h3 className='text-center'>{text('thanksForPayment')}</h3>
            <TextDivider isComplete isSecondary />
          </div>
          <List>
            {orderPaymentIntent && orderReceipt && (
              <ReceiptItem
                receipt={orderReceipt}
                description={text('survey')}
              />
            )}
            {membershipPaymentIntent.value && membershipReceipt && (
              <ReceiptItem
                receipt={membershipReceipt}
                description={text('membership')}
              />
            )}
          </List>
          <div>{text('receiptEmailSent')}</div>
          <TextDivider isComplete isSecondary />
          <div className='full-width'>
            <List isPaddingless>
              <List.Item
                description={text('paymentDate')}
                value={
                  orderReceipt.value &&
                  formatDate(orderReceipt.value?.payment_date)
                }
              />
              <List.Item
                description={text('paymentMethod')}
                value={cardsBrand[orderReceipt.value?.card.brand]}
              />
            </List>
          </div>
          <TextDivider isComplete isSecondary />
          <p>{text('sharePlatformWithTeam')}</p>
          <Button
            autoFocus
            className='full-width'
            onClick={() =>
              dispatch(
                showModal(PROJECT_TRIAL_MEMBERS_MODAL, {
                  projectId: currentProject.id,
                })
              )
            }
          >
            {text('proceedToShare')}
          </Button>
          <a className='mt-3' name='cancel' href={routes.dashboard}>
            {text('goToDashboard')}
          </a>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default PaymentSuccess;
