import '@stripe/stripe-js';
import React from 'react';
import { render } from 'react-dom'; // todo: fix, this is deprecated; see https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import '../public/scss/main.scss';
import PublicApp from './PublicApp/PublicApp';
import { MapViewProvider } from './components/mapView/mapViewContext';
import { startListener } from './events/serviceWorkerEventListener';
import App from './pages/App';
import history from './routes/history';
import registerServiceWorker from './serviceWorkers/registerServiceWorker';
import { store } from './utilities';
import { setupInterceptors } from './utilities/api';
import PrivateRoute from './mp/components/PrivateRoute';
import NewRealtimeMapPage from './mp/pages/new';
import RealtimeMapPage from './mp/pages/mp';

setupInterceptors(store);

render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        {/* testing of realtime multiplayer; TODO: remove */}
        <PrivateRoute exact path='/mp/:jobId' component={RealtimeMapPage} />
        <PrivateRoute exact path='/mp' component={NewRealtimeMapPage} />
        <MapViewProvider>
          <Route path='/embed' component={PublicApp} />
          <Route path='/' component={App} />
        </MapViewProvider>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

if (process.env.ENABLE_SERVICE_WORKER) {
  startListener();
  registerServiceWorker();
}
