import { getNextSequenceItem } from '../../utilities/sequence';

// TODO: fix
//  * should be category specific
//  * should first extract an index from the name, then find the shape with the largest index, and increment on top of that
const getNextShapeName = (shapeNames: string[]): string => {
  return getNextSequenceItem(shapeNames);
};

export const shapeNameLib = {
  getNextShapeName,
};
