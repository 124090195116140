import React, { useCallback } from 'react';
import classNames from 'classnames';

import text from '../../../text';
import ProductTooltip from '../../../components/mapView/productTooltip/ProductTooltip';
import { PRODUCT_TYPE } from '../../../constants/product';
import { IconButton } from '../../../components/button';
import Container from '../../../components/containers/Container';
import {
  useCreateSmartSelectionsMutation,
  useGetJobQuery,
} from '../../../redux/rtk';
import { useRealtimeContext } from '../../RealtimeContext';
import Loading from '../../../components/loading/Loading';

const SMART_SELECTIONS_HEADING = {
  manual: text('getQuote3dScanCaptureServices'),
  automatic: text('buyNow3dDataInDatabase'),
};

const SMART_SELECTIONS_DETAILS = {
  manual: text('getQuote3dScanCaptureServicesDetails'),
  automatic: text('buyNow3dDataInDatabaseDetails'),
};

const SmartSelectionButton = ({
  deliveryMethod,
}: {
  deliveryMethod: 'automatic' | 'manual';
}) => {
  const { jobId } = useRealtimeContext();
  const { data: job } = useGetJobQuery(jobId);

  const { addSmartSelections } = useRealtimeContext();
  const [createSmartSelections, { isLoading, isSuccess }] =
    useCreateSmartSelectionsMutation();

  const handleClick = useCallback(async () => {
    if (!job?.place_geometry) {
      console.warn('No job place geometry');
      return;
    }
    const smartSelections = await createSmartSelections({
      location: {
        lng: job.place_geometry.coordinates[0],
        lat: job.place_geometry.coordinates[1],
      },
      address: job.full_address,
      city: job.city,
      state: job.state,
      postcode: job.postcode,
    }).unwrap();
    addSmartSelections(
      [
        ...smartSelections.property_vicinity,
        smartSelections.neighbourhood,
        ...smartSelections.roads,
        ...smartSelections.buildings,
      ],
      deliveryMethod
    );
  }, [addSmartSelections, createSmartSelections, deliveryMethod, job]);

  return (
    <button
      type='button'
      onClick={handleClick}
      disabled={isLoading || isSuccess} // isSuccess condition so this cannot be done twice with rapid clicks
      className='add-on-button position-relative'
    >
      <Container
        noBorder
        isSecondary
        className={classNames(
          'd-flex justify-content-between p-3 align-items-center',
          {
            'is-readonly': false, // TODO:
          }
        )}
      >
        <div className='text-left'>
          <div className='d-flex flex-row align-items-center'>
            <p className='font-weight-bold m-0 mr-2'>
              {SMART_SELECTIONS_HEADING[deliveryMethod]}
            </p>
            <ProductTooltip
              productType={
                deliveryMethod === 'manual'
                  ? PRODUCT_TYPE.ADDON_SCAN
                  : PRODUCT_TYPE.ADDON_DATABASE
              }
              placement='top'
            />
          </div>
          <p className='m-0' style={{ fontSize: '12px' }}>
            {SMART_SELECTIONS_DETAILS[deliveryMethod]}
          </p>
        </div>
        <div
          className={classNames('d-flex flex-column add-button-container', {
            'read-only': false, // TODO:
          })}
        >
          <IconButton
            // @ts-expect-error unknown props
            iconProps={{ isOriginal: true }}
            // icon={isReadOnly ? 'plus-filled-grey' : 'plus-filled'} // TODO
            icon='plus-filled'
          />
          <p
            className={classNames('m-0 font-weight-bold', {
              'green-1': true, // TODO
              'grey-3': false, // TODO
            })}
          >
            {text('add')}
          </p>
        </div>

        {isLoading ? (
          <div className='loader-container'>
            <Loading />
          </div>
        ) : null}
      </Container>
    </button>
  );
};

export default SmartSelectionButton;
