import classnames from 'classnames';
import T from 'prop-types';
import React, { useMemo } from 'react';

import { PRODUCT_EXPLANATION } from '../../../constants/product';
import text from '../../../text';
import { IconButton } from '../../button';

/* eslint-disable react/prop-types */

const List = ({ items, type }) => {
  return (
    items?.length > 0 && (
      <ul className={type}>
        {items.map((item, i) => {
          return (
            <li className={``} key={i}>
              {item}
            </li>
          );
        })}
      </ul>
    )
  );
};

List.propTypes = {
  type: T.string.isRequired,
  items: T.array.isRequired,
};

const ProductCardHeader = ({ children, isSecondary, onClick, ...props }) => {
  return !isSecondary ? (
    <button
      className='BadgeCardHeader'
      aria-disabled={props.isDisabled ? 'true' : undefined}
      onClick={() => !props.isDisabled && onClick()}
      {...props}
    >
      {children}
    </button>
  ) : (
    <div className='BadgeCardHeader'>{children}</div>
  );
};

ProductCardHeader.propTypes = {
  children: T.node.isRequired,
};

const ProductCardBody = ({
  pros,
  cons,
  withExplanation = false,
  productType,
  description,
}) => {
  const explanation = useMemo(() => {
    if (withExplanation && PRODUCT_EXPLANATION[productType] && !description) {
      return PRODUCT_EXPLANATION[productType];
    }

    return description;
  }, []);

  return (
    <div className='BadgeCardBody'>
      {explanation && <p className='explanation'>{explanation}</p>}
      <div className='pros-and-cons'>
        <List items={pros} type='pros' />
        <List items={cons} type='cons' />
      </div>
    </div>
  );
};

ProductCardBody.propTypes = {
  productType: T.string.isRequired,
  cons: T.array,
  pros: T.array,
  withExplanation: T.bool,
};

const ProductCard = ({
  pros,
  cons,
  productType,
  isDisabled,
  handleClick,
  variant = 'default',
  withExplanation = false,
  title = '',
  description = '',
}) => {
  return (
    <div
      className={classnames('ProductBadgeCard', {
        secondary: variant === 'secondary',
        tertiary: variant === 'tertiary',
      })}
    >
      <ProductCardHeader onClick={handleClick} isDisabled={isDisabled}>
        <h6>{title || text(`${productType}3D`)}</h6>
        {variant !== 'secondary' && (
          <IconButton disabled={isDisabled} icon='add' />
        )}
      </ProductCardHeader>
      <ProductCardBody
        pros={pros}
        cons={cons}
        productType={productType}
        withExplanation={withExplanation}
        description={description}
      />
    </div>
  );
};

ProductCard.propTypes = {
  productType: T.string.isRequired,
  pros: T.array,
  cons: T.array,
  isDisabled: T.bool.isRequired,
  handleClick: T.func.isRequired,
  variant: T.oneOf(['default', 'secondary', 'tertiary']),
  withExplanation: T.bool,
  title: T.string,
  description: T.string,
};

export default ProductCard;
