/* global google */
import React, { createContext, useContext, useMemo, useState } from 'react';
import type * as GeoJSON from 'geojson';
import * as visGm from '@vis.gl/react-google-maps';

export const GM_MAP_OPTIONS: visGm.MapProps = {
  style: { width: '100%', height: '100%' },
  defaultZoom: 18,
  disableDefaultUI: true,
  isFractionalZoomEnabled: true,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ],
  tilt: 0,
};

export const GM_MAP_INITIAL_OPTIONS = {
  defaultCenter: { lng: 144.9623, lat: -37.8124 },
  defaultZoom: 18,
};

export const GM_MAP_DISABLED_OPTIONS = {
  zoomControl: false,
  gestureHandling: 'none',
};

const GmMapContext = createContext<{
  tilesLoaded: boolean;
  setTilesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}>(null!);

export const GmMapProvider = ({ children }: { children: React.ReactNode }) => {
  const [tilesLoaded, setTilesLoaded] = useState(false);
  return (
    <GmMapContext.Provider value={{ tilesLoaded, setTilesLoaded }}>
      {children}
    </GmMapContext.Provider>
  );
};

export const useGmMapContext = () => {
  const ctx = useContext(GmMapContext);
  // invariant(ctx, 'missing GmMapContext.Provider');

  const eventListeners = useMemo(() => {
    return {
      onTilesLoaded: () => ctx.setTilesLoaded(true),
    };
  }, [ctx]);

  return useMemo(
    () => ({ tilesLoaded: ctx.tilesLoaded, eventListeners }),
    [ctx.tilesLoaded, eventListeners]
  );
};
