/* eslint-disable react/jsx-max-depth */
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Loading from '../../components/loading/Loading';
import Page from '../../components/page/Page';
import { profileSelectors } from '../../redux/selectors/profile';

import routes from '../../routes/constants';
import text from '../../text';

import { MapViewContext } from '../../components/mapView/mapViewContext';
import { clearJob, getJob } from '../../redux/actions';
import { isMembershipPriceId } from '../../utilities/membership';
import { isStaff } from '../../utilities/user';
import ForQuotation from './Invoice/ForQuotation';
import { InvoiceTotal } from './Invoice/InvoiceTotal';
import MembershipInvoice from './Invoice/MembershipInvoice';
import ProductInvoice from './Invoice/ProductInvoice';
import OrderWizard from './OrderWizard/OrderWizard';
import PaymentProvider from './PaymentContext';

const Payment = () => {
  const { jobId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const hasValidMembership = useSelector(profileSelectors.hasValidMembership);
  const {
    state: { buyNowData },
  } = useContext(MapViewContext);
  const {
    userProfileState,
    currentUser,
    job,
    isGettingJob,
    membershipPriceId,
    membershipPrices,
  } = useSelector((state) => ({
    userProfileState: state.profileReducer.userProfileState,
    currentUser: state.profileReducer.userProfile,
    job: state.jobsReducer.job,
    isGettingJob: state.jobsReducer.isGettingJob,
    membershipPriceId: state.order.membershipPriceId,
    membershipPrices: state.profileReducer.membershipPrices,
  }));

  useEffect(() => {
    if (jobId && isEmpty(job) && !isGettingJob) {
      dispatch(getJob(jobId));
    }
  }, [dispatch, isGettingJob, job, jobId]);

  useEffect(() => {
    return () => {
      dispatch(clearJob());
    };
  }, [dispatch]);

  const selectedMembership = useMemo(() => {
    if (membershipPrices.value) {
      return membershipPrices.value.find(
        (membership) => membership.id === membershipPriceId
      );
    }

    return null;
  }, [membershipPriceId, membershipPrices]);

  const numList = [buyNowData.length > 0, selectedMembership].filter(
    (value) => !!value
  ).length;

  if (userProfileState === 'loading' || isGettingJob) {
    return (
      <Page className='PageCenter'>
        <Loading />
      </Page>
    );
  }
  return (
    <Page className='Payment'>
      <div className='main-wrapper'>
        <div className='invoice-wrapper'>
          <div className='invoice-section'>
            <div className='d-flex'>
              <Link
                onClick={() => history.goBack()}
                className='d-flex align-items-center'
                href='#'
              >
                <Icon icon='caret-left' />
                {text('backTo2DMap')}:
              </Link>
            </div>

            {buyNowData.length > 0 && (
              <>
                <p className='invoice-title text-uppercase font-16 mb-3'>
                  {text('buyNow')}:
                </p>
                <ProductInvoice
                  jobId={parseInt(jobId)}
                  hasValidMembership={hasValidMembership}
                  numOrder={numList - (numList > 1)}
                />
              </>
            )}

            {!hasValidMembership && !isStaff(currentUser.role) && (
              <MembershipInvoice numOrder={numList} isFromOrderWizard={true} />
            )}

            {buyNowData.length > 0 ||
            (!hasValidMembership &&
              !isStaff(currentUser.role) &&
              isMembershipPriceId(membershipPriceId)) ? (
              <InvoiceTotal
                quote={job?.quote}
                currentUser={currentUser}
                membershipPriceId={membershipPriceId}
                selectedMembership={selectedMembership}
              />
            ) : (
              <div className='mt-3' />
            )}

            <ForQuotation numOrder={numList} />
          </div>
        </div>

        <div className='order-wizard'>
          <div className='section'>
            <OrderWizard />
          </div>
        </div>
      </div>
    </Page>
  );
};

const PaymentWithContext = () => {
  return (
    <PaymentProvider>
      <Payment />
    </PaymentProvider>
  );
};

export default PaymentWithContext;
