import React from 'react';

import useQueryParam from '../hooks/useQueryParam';
import useUrlParam from '../hooks/useUrlParam';
import RealtimeMap from './mp-crdt';
import { positionLib } from '../lib/position';

const RealtimeMapPage = () => {
  const jobId = useUrlParam('jobId', { int: true });
  const initialPlace = useQueryParam('place', {
    parse: positionLib.fromString,
  });

  return (
    <RealtimeMap
      // https://react.dev/learn/preserving-and-resetting-state#option-2-resetting-state-with-a-key
      key={jobId}
      jobId={jobId}
      initialPlace={initialPlace}
    />
  );
};

export default RealtimeMapPage;
