import cn from 'classnames';
import React, { useContext, useState } from 'react';
import { Navbar as BSNavbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CartButton from '../../../../../components/button/CartButton';
import Icon from '../../../../../components/icon/Icon';
import Link from '../../../../../components/Link/Link';
import { MapViewContext } from '../../../../../components/mapView/mapViewContext';
import useMembershipButton from '../../../../../hooks/useMembershipButton';
import { useRedirectTo } from '../../../../../hooks/useRedirect';
import useShareLinkParams, {
  useNew3dBookmark,
  useShareLinkMetadata,
} from '../../../../../hooks/useShareLinkParams';
import {
  toggleRightMenuVisibility,
  toggleSidenavVisibility,
} from '../../../../../redux/actions/layout';
import { profileSelectors } from '../../../../../redux/selectors/profile';
import routes from '../../../../../routes/constants';
import text from '../../../../../text';
import { jobHasPointCloud } from '../../../../../utilities/job';
import {
  getProjectLatestJob,
  isDemoProject,
} from '../../../../../utilities/project';
import { isRouteIncluded } from '../../../../../utilities/routes';
import { isImpersonating } from '../../../../../utilities/storage';
import MarketingNav from './MarketingNav';
import ShareLink from './ShareLink';
import Titlebar from './Titlebar';
import UserNav from './UserNav';

const ROUTES_MARKETING_NAV_ENABLED = [
  routes.password.reset,
  routes.password.create,
  routes.password.forgot,
  routes.logout,
  routes.login,
  routes.signUp,
];

const ROUTES_VIEW_MODE_BUTTON_VISIBLE = [
  routes.order.root,
  routes.fileManager.root,
  routes.view3D.root,
];

const isMarketingNavEnabled = isRouteIncluded(ROUTES_MARKETING_NAV_ENABLED);

const isViewModeButtonVisible = isRouteIncluded(
  ROUTES_VIEW_MODE_BUTTON_VISIBLE
);
const is2DMap = isRouteIncluded([routes.order.root]);
const is3DViewer = isRouteIncluded([routes.view3D.root]);
const isFileManager = isRouteIncluded([routes.fileManager.root]);

const isCartButtonVisible = (pathname) =>
  isRouteIncluded([
    routes.view3D.root,
    routes.order.root,
    routes.fileManager.viewProject(),
  ])(pathname);

const Navbar = () => {
  const location = useLocation();
  // const history = useHistory();
  const redirect = useRedirectTo();
  const { layout, project } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMarketingNavVisible = isMarketingNavEnabled(location.pathname);
  const { actions } = useContext(MapViewContext);
  const {
    state: { numCartItems },
  } = useContext(MapViewContext);

  const isAdmin = useSelector(profileSelectors.getIsAdmin);

  const { handleMembershipClick } = useMembershipButton();

  const latestJob =
    project.currentProject?.id && getProjectLatestJob(project.currentProject);

  const is3dViewerAccessibleToUser = useSelector(
    profileSelectors.get3dViewerEnabled
  );

  const hasPointCloud = jobHasPointCloud(latestJob);
  const isDemoProj = isDemoProject(project.currentProject);

  const is3dViewerLocked =
    hasPointCloud && !is3dViewerAccessibleToUser && !isDemoProj;

  const is3dViewerDisabled =
    !hasPointCloud || (!is3dViewerAccessibleToUser && !isDemoProj);

  const _3dViewerRoute = isAdmin
    ? `${routes.view3D.project(project.currentProject?.id)}?admin=true`
    : `${routes.view3D.project(project.currentProject?.id)}`;

  const viewModes = [
    {
      icon: '2d-map',
      toggleIcon: '2d-map',
      text: text('twoD'),
      onClick: () =>
        !is2DMap(location.pathname) &&
        redirect.push(
          latestJob?.id
            ? latestJob.lga
              ? routes.order.root
              : routes.order.job(latestJob.id)
            : routes.order.root
        ),
      tooltipProps: { text: text('map2D'), placement: 'bottom' },
      enabled: is2DMap(location.pathname),
    },
    {
      icon: 'file',
      toggleIcon: 'file',
      text: text('file'),
      onClick: () =>
        !isFileManager(location.pathname) &&
        redirect.push(
          project.currentProject?.id
            ? routes.fileManager.viewProject(project.currentProject.id)
            : routes.fileManager.root
        ),
      tooltipProps: { text: text('fileManager'), placement: 'bottom' },
      enabled: isFileManager(location.pathname),
    },
    {
      icon: '3D',
      toggleIcon: '3D',
      disabled: is3dViewerDisabled && !is3dViewerLocked,
      text: text('threeD'),
      onClick: () =>
        is3dViewerLocked
          ? handleMembershipClick({
              redirectTo: {
                path: _3dViewerRoute,
                label: text('navigateToRoute', {
                  navigation: 'Continue',
                  routeName: text('viewer3D'),
                }),
              },
            })
          : !is3DViewer(location.pathname) &&
            redirect.push(routes.view3D.project(project.currentProject.id)),
      tooltipProps: { text: text('viewer3D'), placement: 'bottom' },
      enabled: is3DViewer(location.pathname),
      locked: is3dViewerLocked,
    },
  ];

  const { isAvailable: isShareLinkAvailable, ...shareLinkParams } =
    useShareLinkParams();
  const metadata = useShareLinkMetadata();
  const new3dBookmark = useNew3dBookmark();

  return (
    <BSNavbar
      expanded={isMenuOpen}
      className={cn('Navbar', { impersonate: isImpersonating() })}
      variant={isMarketingNavVisible ? 'light' : 'dark'}
      expand='lg'
    >
      {isMarketingNavVisible ? (
        <BSNavbar.Toggle
          id='menuButton'
          aria-controls='basic-navbar-nav'
          className='Navbar-Toggle'
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <Icon icon='close-box' /> : <Icon icon='menu' />}
          {text('menu')}
        </BSNavbar.Toggle>
      ) : (
        <BSNavbar.Toggle
          id='menuButton'
          aria-controls='basic-navbar-nav'
          className='Navbar-Toggle'
          onClick={(evt) => {
            dispatch(toggleSidenavVisibility(!layout.sideNav.isVisible));
            evt.stopPropagation();
          }}
        >
          {layout.sideNav.isVisible ? (
            <Icon icon='close-box' />
          ) : (
            <Icon icon='menu' />
          )}
          {text('menu')}
        </BSNavbar.Toggle>
      )}
      <BSNavbar.Brand className='Navbar-Brand'>
        {isMarketingNavVisible ? (
          <a href={process.env.LARKI_MARKETING_URL}>
            <Icon icon='larki-logo' />
          </a>
        ) : (
          <Link to={routes.home}>
            <Icon icon='larki-logo' />
          </Link>
        )}
      </BSNavbar.Brand>
      <Titlebar />

      {!isMarketingNavVisible && (
        <div
          className={cn('ViewModeButtonGroup', {
            visible: isViewModeButtonVisible(location.pathname),
          })}
        >
          {viewModes.map((viewMode, index) => (
            <BSNavbar.Toggle
              key={index}
              aria-controls='basic-navbar-nav'
              disabled={viewMode.disabled}
              className={cn('Navbar-Toggle position-relative', {
                enabled: viewMode.enabled,
                disabled: viewMode.locked,
              })}
              onClick={viewMode.onClick}
            >
              <Icon icon={viewMode.toggleIcon} />
              {viewMode.locked ? (
                <div
                  className='lock-badge-container transparent-bg'
                  style={{ top: '-4px' }}
                >
                  <Icon
                    icon='lock'
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </div>
              ) : null}
              {viewMode.text}
            </BSNavbar.Toggle>
          ))}
        </div>
      )}
      {isMarketingNavVisible && <MarketingNav />}
      {isShareLinkAvailable ? (
        <div className='share-link__container'>
          <ShareLink
            {...shareLinkParams}
            metadata={metadata}
            new3dBookmark={new3dBookmark}
          />
        </div>
      ) : null}
      {!isMarketingNavVisible && (
        <UserNav
          className={cn({
            viewModeVisible: isViewModeButtonVisible(location.pathname),
          })}
        />
      )}

      {isCartButtonVisible(location.pathname) && (
        // <BSNavbar.Toggle
        //   id='cartButton'
        //   aria-controls='basic-navbar-nav'
        //   className={cn('Navbar-Toggle')}
        //   onClick={() =>
        //     dispatch(toggleRightMenuVisibility(!layout.rightMenu.isVisible))
        //   }
        // >
        //   {layout.rightMenu.isVisible ? (
        //     <Icon icon='close-box' />
        //   ) : (
        //     <Icon icon='shopping-cart' />
        //   )}
        //   {text('cart')}
        // </BSNavbar.Toggle>
        <CartButton
          as={BSNavbar.Toggle}
          className='Navbar-Toggle'
          onClick={() => {
            actions.setShowProductModal(false);
            dispatch(toggleRightMenuVisibility(!layout.rightMenu.isVisible));
          }}
          open={layout.rightMenu.isVisible}
          count={numCartItems}
        />
      )}
    </BSNavbar>
  );
};

export default Navbar;
