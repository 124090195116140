import {
  CompositeLayer,
  GetPickingInfoParams,
  PickingInfo,
} from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import { svgToDataUrl } from '../svg';
import { PickingExtraInfo_, DeletableHandleFeature } from '../lib/shape';

export type DeleteVertexLayerPickingDataT_ = {
  popup: 'delete';
  handle: DeletableHandleFeature;
};

type DeleteVertexLayerState = {
  popup: DeleteVertexLayerPickingDataT_ | null;
};

export default class DeleteVertexLayer extends CompositeLayer {
  declare state: DeleteVertexLayerState;

  initializeState() {
    this.state = { popup: null };
  }

  _setState(_state: Partial<DeleteVertexLayerState>) {
    this.setState(_state);
  }

  getPickingInfo({
    info,
  }: GetPickingInfoParams<
    DeleteVertexLayerPickingDataT_,
    PickingExtraInfo_
  >): PickingInfo<DeleteVertexLayerPickingDataT_, PickingExtraInfo_> {
    if (info.object?.popup === 'delete') {
      info.popup = info.object;
    }
    return info;
  }

  renderLayers() {
    return [
      new IconLayer({
        ...this.getSubLayerProps({
          id: 'delete-vertex-layer', // important, errors otherwise
          pickable: true,
        }),
        data: this.state.popup ? [this.state.popup] : [],
        getSize: 36,
        getIcon: () => ({
          url: svgToDataUrl(TRASH_SVG),
          width: 36,
          height: 36,
        }),
        getPixelOffset: [0, -30],
        getPosition: (popup) =>
          popup.handle.geometry.coordinates as [number, number],
      }),
    ];
  }
}

const TRASH_SVG = `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="18" fill="#fff"/>
<g transform="translate(6,6)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.69275 17.2346C9.11397 17.2346 9.4569 16.896 9.46297 16.4749L9.46298 10.9484C9.46298 10.5229 9.11822 10.1782 8.69275 10.1782C8.26738 10.1782 7.92172 10.5228 7.92172 10.9484L7.92171 16.4737L7.92173 16.4749C7.92781 16.896 8.27079 17.2346 8.69275 17.2346Z" fill="red"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.0005 17.2346C12.4215 17.2346 12.7655 16.8961 12.7707 16.4747L12.7707 10.9484C12.7707 10.5229 12.4259 10.1782 12.0005 10.1782C11.575 10.1782 11.2302 10.5229 11.2302 10.9484L11.2302 16.4737L11.2302 16.4747C11.2354 16.8961 11.5794 17.2346 12.0005 17.2346Z" fill="red"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.3078 17.2346C15.7297 17.2346 16.0727 16.896 16.0788 16.4749L16.0788 10.9484C16.0788 10.5228 15.7331 10.1782 15.3078 10.1782C14.8823 10.1782 14.5375 10.5229 14.5375 10.9484L14.5375 16.4737L14.5375 16.4749C14.5436 16.8959 14.8865 17.2346 15.3078 17.2346Z" fill="red"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0406 6.07321C21.0397 5.8703 20.957 5.67688 20.8114 5.53656C20.6863 5.41528 20.524 5.34238 20.3523 5.32761V5.32493H20.3001C20.2914 5.32478 20.2828 5.32478 20.2741 5.32493H15.9298L15.3807 3.48677C15.1128 2.6057 14.303 2.00134 13.3823 1.99487L10.6207 1.99488C9.70003 2.00133 8.89062 2.60609 8.62274 3.48718L8.07449 5.32493H3.73098C3.30549 5.32493 2.95996 5.67046 2.95996 6.09595C2.95996 6.52157 3.30561 6.86619 3.73098 6.86619H4.46686L4.46686 20.0253C4.47414 21.1141 5.35845 21.9924 6.44633 21.9949H17.5761C18.6688 21.9924 19.5537 21.1074 19.5562 20.0146V6.8438H20.2703C20.6953 6.84338 21.0406 6.49855 21.0406 6.07321ZM20.2993 5.40761L20.3046 5.40773C20.4726 5.41206 20.633 5.47865 20.754 5.59609C20.6246 5.47051 20.4503 5.40315 20.2696 5.40781L20.2748 5.40761C20.283 5.40746 20.2912 5.40746 20.2993 5.40761ZM9.66919 5.32497L10.0699 4.05853L10.0717 4.06029L10.112 3.92883C10.1814 3.70249 10.3866 3.54479 10.6227 3.53615L13.4025 3.53615C13.6439 3.53414 13.8565 3.69508 13.9196 3.92922L14.3307 5.3257L9.66919 5.32497ZM18.015 20.0822H17.9907L17.9715 20.1379C17.91 20.3156 17.7442 20.436 17.5565 20.4403H6.44712C6.20276 20.436 6.00656 20.2374 6.00514 19.9918V6.8436H18.0149L18.015 20.0822Z" fill="red"/>
</g>
</svg>`;
