import { colorLib } from './lib/color';

export function svgToDataUrl(svg: string, color: string | null = null) {
  let encodedSvg;
  if (!color) {
    encodedSvg = encodeURIComponent(svg);
  } else {
    let matchCount = 0;
    const strokeColor = colorLib.darkenHexColor(color);
    const coloredSvg = svg.replace(
      /fill="[^"]*"|stroke="[^"]*"/g,
      (match) => {
        matchCount++;
        // matchCount 1 and 2 is for the first <path>
        if (matchCount > 2 && matchCount <= 4) {
          // if matchCount is 3 and 4, replace values
          if (match.startsWith('fill=')) return `fill="${color}"`;
          if (match.startsWith('stroke=')) return `stroke="${strokeColor}"`;
        }
        return match;
      }
    );
    encodedSvg = encodeURIComponent(coloredSvg);
  }

  return `data:image/svg+xml;charset=utf-8,${encodedSvg}`;
}
