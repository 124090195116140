import classnames from 'classnames';
import React from 'react';
import { FormCheck } from 'react-bootstrap';

const Checkbox = (props) => {
  const { id, label, children, disabled, ...rest } = props;
  return (
    <FormCheck bsPrefix='Checkbox'>
      <FormCheck.Input id={id} disabled={disabled} type='checkbox' {...rest} />
      <FormCheck.Label
        className={classnames({ disabled: disabled })}
        htmlFor={id}
      >
        {label || children}
      </FormCheck.Label>
    </FormCheck>
  );
};

export default Checkbox;
