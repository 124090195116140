import T from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { PRODUCT_TYPE } from '../../constants/product';
import text from '../../text';
import { IconButton } from '../button';
import Container from '../containers/Container';
import Loading from '../loading/Loading';
import { MapViewContext } from '../mapView/mapViewContext';
import ProductTooltip from '../mapView/productTooltip/ProductTooltip';
import useGeocoder from './useGeocoder';
import useSmartSelections from './useSmartSelections';

const SMART_SELECTIONS_HEADING = {
  manual: text('getQuote3dScanCaptureServices'),
  automatic: text('buyNow3dDataInDatabase'),
};

const SMART_SELECTIONS_DETAILS = {
  manual: text('getQuote3dScanCaptureServicesDetails'),
  automatic: text('buyNow3dDataInDatabaseDetails'),
};

// eslint-disable-next-line react/prop-types
const SmartSelectionsButton = ({
  show,
  deliveryType,
  isReadOnly,
  buttonId = null,
}) => {
  const { state } = useContext(MapViewContext);
  const { mapState } = useSelector((reduxState) => ({
    mapState: reduxState.mapReducer,
  }));

  const { geocode, geocodeReady } = useGeocoder();

  const addSmartSelectionsInPlace = useSmartSelections();

  const handleClick = useCallback(async () => {
    if (isReadOnly) {
      console.warn('tried to add smart selections on read-only order');
      return;
    }

    if (!state.job?.full_address) {
      console.error('tried to add smart selections with no job or job address');
      return;
    }
    if (!geocodeReady) {
      console.error('tried to add smart selections without geocoder ready');
      return;
    }
    const place = await geocode(state.job.full_address);

    await addSmartSelectionsInPlace(
      {
        address: state.job.address,
        city: state.job.city,
        state: state.job.state,
        postcode: state.job.postcode,
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      },
      deliveryType,
      buttonId
    );
  }, [state.job, geocodeReady]);

  if (!show) {
    return null;
  }

  const isLoading =
    mapState.isLoadingSmartSelections &&
    mapState.smartSelectionsDeliveryMethod === deliveryType;

  return isReadOnly ? null : (
    <button
      type='button'
      onClick={handleClick}
      disabled={isLoading}
      className='add-on-button position-relative'
    >
      <Container
        noBorder
        isSecondary
        className={classNames(
          'd-flex justify-content-between p-3 align-items-center',
          {
            'is-readonly': isReadOnly,
          }
        )}
      >
        <div className='text-left'>
          <div className='d-flex flex-row align-items-center'>
            <p className='font-weight-bold m-0 mr-2'>
              {SMART_SELECTIONS_HEADING[deliveryType]}
            </p>
            <ProductTooltip
              productType={
                deliveryType === 'manual'
                  ? PRODUCT_TYPE.ADDON_SCAN
                  : PRODUCT_TYPE.ADDON_DATABASE
              }
              placement='top'
            />
          </div>
          <p className='m-0' style={{ fontSize: '12px' }}>
            {SMART_SELECTIONS_DETAILS[deliveryType]}
          </p>
        </div>
        <div
          className={classNames('d-flex flex-column add-button-container', {
            'read-only': isReadOnly,
          })}
        >
          <IconButton
            iconProps={{ isOriginal: true }}
            icon={isReadOnly ? 'plus-filled-grey' : 'plus-filled'}
          />
          <p
            className={classNames('m-0 font-weight-bold', {
              'green-1': !isReadOnly,
              'grey-3': isReadOnly,
            })}
          >
            {text('add')}
          </p>
        </div>

        {mapState.isLoadingSmartSelections &&
        mapState.loadingRhsButtonIds.includes(buttonId) ? (
          <div className='loader-container'>
            <Loading />
          </div>
        ) : null}
      </Container>
    </button>
  );
};

SmartSelectionsButton.propTypes = {
  show: T.bool,
  deliveryType: T.oneOf(['manual', 'automatic']).isRequired,
  isReadOnly: T.bool,
  buttonId: T.string,
};

SmartSelectionsButton.defaultProps = {
  show: false,
};

export default SmartSelectionsButton;
