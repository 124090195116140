import { mpConstants } from '../constants/mp';
import _ from 'lodash/fp';

const defaultMapState = {
  highRes: {
    currentDate: null,
    layers: {
      data: null,
      isLoading: false,
      error: null,
    },
    collections: {
      data: null,
      isLoading: false,
      error: null,
    },
  },
};

const mpReducer = (state = defaultMapState, action) => {
  switch (action.type) {
    case mpConstants.LOAD_HIGH_RES_COLLECTIONS:
      return _.set('highRes.collections.isLoading', true)(state);
    case mpConstants.LOAD_HIGH_RES_COLLECTIONS_SUCCESS:
      return _.set('highRes.collections', {
        data: action.payload,
        isLoading: false,
        error: null,
      })(state);
    case mpConstants.LOAD_HIGH_RES_COLLECTIONS_FAILURE:
      return _.set('highRes.collections', {
        data: null,
        isLoading: false,
        error: action.payload,
      })(state);

    case mpConstants.LOAD_HIGH_RES_LAYERS:
      return _.set('highRes.layers.isLoading', true)(state);
    case mpConstants.LOAD_HIGH_RES_LAYERS_SUCCESS:
      return _.set('highRes.layers', {
        data: action.payload,
        isLoading: false,
        error: null,
      })(state);
    case mpConstants.SET_HIGH_RES_CURRENT_DATE:
      return _.set('highRes.currentDate', action.payload)(state);
    case mpConstants.LOAD_HIGH_RES_LAYERS_FAILURE:
      return _.set('highRes.layers', {
        data: null,
        isLoading: false,
        error: action.payload,
      })(state);

    case mpConstants.NEXT_HIGH_RES_LAYER: {
      const { currentDate, layers } = state.highRes;
      const dates = _.keys(layers.data);
      if (!dates) {
        console.warn('cannot change date');
        return state;
      }
      const currentIndex = dates.indexOf(currentDate);
      console.assert(currentIndex !== -1, 'currentDate not found in layers');
      if (currentIndex === dates.length - 1) {
        console.warn('currentDate is already the latest date');
        return state;
      }
      const nextDate = dates[currentIndex + 1];
      return _.set('highRes.currentDate', nextDate)(state);
    }
    case mpConstants.PREV_HIGH_RES_LAYER: {
      const { currentDate, layers } = state.highRes;
      const dates = _.keys(layers.data);
      if (!dates) {
        console.warn('cannot change date');
        return state;
      }
      const currentIndex = dates.indexOf(currentDate);
      console.assert(currentIndex !== -1, 'currentDate not found in layers');
      if (currentIndex === 0) {
        console.warn('currentDate is already the earliest date');
        return state;
      }
      const prevDate = dates[currentIndex - 1];
      return _.set('highRes.currentDate', prevDate)(state);
    }

    default:
      return state;
  }
};

export default mpReducer;
