import React, { useMemo } from 'react';
import { Navbar as BSNavbar } from 'react-bootstrap';
import cn from 'classnames';
import * as fp from 'lodash/fp';
import { useLocation } from 'react-router-dom';

import Icon from '../../../components/icon/Icon';
import routes from '../../../routes/constants';
import text from '../../../text';
import { isRouteIncluded } from '../../../utilities/routes';
import useUser from '../../hooks/useUser';
import { useGetUserProjectsQuery } from '../../../redux/rtk';
import * as jobUtils from '../../../utilities/job';
import { useRedirectTo } from '../../../hooks/useRedirect';
import useMembershipButton from '../../../hooks/useMembershipButton';
import invariant from 'tiny-invariant';

type ViewMode = '2d' | '3d' | 'file';

type ViewModeProps = {
  disabled: boolean;
  locked: boolean;
  handleClick: () => void;
};

const VIEW_MODES: Record<
  ViewMode,
  {
    icon: string;
    text: string;
    tooltipText: string;
    routeMatches: (pathname: string) => boolean;
  }
> = {
  '2d': {
    icon: '2d-map',
    text: text('twoD'),
    tooltipText: text('map2D'),
    routeMatches: isRouteIncluded(['/mp']),
  },
  file: {
    icon: 'file',
    text: text('file'),
    tooltipText: text('fileManager'),
    routeMatches: isRouteIncluded([routes.fileManager.root]),
  },
  '3d': {
    icon: '3D',
    text: text('threeD'),
    tooltipText: text('viewer3D'),
    routeMatches: isRouteIncluded([routes.view3D.root]),
  },
};

const ViewModes = ({ jobId }: { jobId?: number }) => {
  const location = useLocation();
  const redirect = useRedirectTo();
  const currentUser = useUser();
  const { handleMembershipClick } = useMembershipButton();
  const { project } = useGetUserProjectsQuery(
    { isAdmin: currentUser.isAdmin },
    {
      skip: !currentUser.isSuccess || !jobId,
      selectFromResult: (res) => {
        if (!jobId) return { project: null };
        const projectId = res.data?.jobToProjectId[jobId];
        const project = projectId ? res.data?.projects[projectId] : null;
        return { project };
      },
    }
  );

  const job = useMemo(
    () =>
      jobId
        ? project?.jobs.find((job: { id: number }) => job.id === jobId)
        : null,
    [jobId, project?.jobs]
  );
  const projectHasPointCloud = useMemo(
    () => fp.has('point_clouds.0.pointerra_id', project),
    [project]
  );
  const isDemoJob = useMemo(() => jobUtils.isDemoJob(job), [job]);

  const is3dViewerLocked = useMemo(
    () => projectHasPointCloud && !currentUser.is3dViewerEnabled && !isDemoJob,
    [currentUser.is3dViewerEnabled, projectHasPointCloud, isDemoJob]
  );
  const is3dViewerDisabled = useMemo(
    () =>
      !is3dViewerLocked &&
      (!projectHasPointCloud || (!currentUser.is3dViewerEnabled && !isDemoJob)),
    [
      currentUser.is3dViewerEnabled,
      projectHasPointCloud,
      is3dViewerLocked,
      isDemoJob,
    ]
  );

  const _3dViewerRoute = useMemo(() => {
    let url = project
      ? routes.view3DExternal.project(project.project.id)
      : null;
    if (url && currentUser.isAdmin) url += '?admin=true';
    return url;
  }, [currentUser.isAdmin, project]);
  const fileManagerRoute = useMemo(() => {
    return project
      ? routes.fileManager.viewProject(project.project.id)
      : routes.fileManager.root;
  }, [project]);

  const viewProps = useMemo(
    (): Record<ViewMode, ViewModeProps> => ({
      '2d': {
        disabled: false,
        locked: false,
        handleClick: () =>
          redirect.push(job?.id ? (job.lga ? '/mp' : `/mp/${job.id}`) : '/mp'),
      },
      file: {
        disabled: false,
        locked: false,
        handleClick: () => redirect.push(fileManagerRoute),
      },
      '3d': {
        disabled: is3dViewerDisabled,
        locked: is3dViewerLocked,
        handleClick: () =>
          is3dViewerLocked
            ? _3dViewerRoute &&
              handleMembershipClick({
                redirectTo: {
                  path: _3dViewerRoute,
                  label: text('navigateToRoute', {
                    navigation: 'Continue',
                    routeName: text('viewer3D'),
                  }),
                },
              })
            : _3dViewerRoute && redirect.push(_3dViewerRoute),
      },
    }),
    [
      _3dViewerRoute,
      fileManagerRoute,
      handleMembershipClick,
      is3dViewerDisabled,
      is3dViewerLocked,
      job?.id,
      job?.lga,
      redirect,
    ]
  );

  return (
    <div className='ViewModeButtonGroup visible'>
      {fp
        .entries(VIEW_MODES)
        .map(([viewMode, { icon, text, routeMatches }]) => {
          const { disabled, locked, handleClick } =
            viewProps[viewMode as ViewMode];
          const enabled = routeMatches(location.pathname);
          return (
            <BSNavbar.Toggle
              key={viewMode}
              aria-controls='basic-navbar-nav'
              disabled={disabled}
              className={cn('Navbar-Toggle position-relative', {
                enabled,
                disabled: locked,
              })}
              onClick={handleClick}
            >
              <Icon icon={icon} />
              {locked ? (
                <div
                  className='lock-badge-container transparent-bg'
                  style={{ top: '-4px' }}
                >
                  <Icon
                    icon='lock'
                    // @ts-expect-error prop
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </div>
              ) : null}
              {text}
            </BSNavbar.Toggle>
          );
        })}
    </div>
  );
};

export default ViewModes;
