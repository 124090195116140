/* global google */
import type * as GeoJSON from 'geojson';

export const gmLatLngToPointGeometry = (
  latLng: google.maps.LatLng
): GeoJSON.Point => {
  return {
    type: 'Point',
    coordinates: [latLng.lng(), latLng.lat()],
  };
};
