/* eslint-disable react/jsx-max-depth */
import { isEmpty, upperCase } from 'lodash';
import T from 'prop-types';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import WhiteContainer from '../../../components/containers/WhiteContainer';
import List from '../../../components/list/List';
import { softwares } from '../../../components/mapView/constants';
import Activity from '../../../components/timeline/Activity';
import { DEFAULT_CURRENCY, GST } from '../../../constants/price';
import { SMART_SELECTION_DELIVERY_METHOD } from '../../../constants/product';
import text, { formatCurrency } from '../../../text';
import { isMembershipPriceId } from '../../../utilities/membership';
import { userHasActiveMembership } from '../../../utilities/user';
import ChargeItem from '../../billing/Invoice/ChargeItem';
import { InvoiceTotal } from '../../billing/Invoice/InvoiceTotal';

const Invoice = ({ job }) => {
  const { quote } = job;
  const { currency, gst } = quote || {
    currency: DEFAULT_CURRENCY,
    gst: GST,
  };
  const buyNowItems = quote.charges.filter(
    ({ type, details }) =>
      type === 'data' &&
      details.delivery_method === SMART_SELECTION_DELIVERY_METHOD.AUTOMATIC
  );
  const { currentUser, membershipPriceId } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
    membershipPriceId: state.order.membershipPriceId,
  }));

  const membership = useMemo(() => {
    if (quote.membership) {
      const { name, amount } = quote.membership;
      const extra = Math.round(amount * (gst / 100));
      return {
        name,
        taxes: [
          {
            display_name: 'GST',
            price: {
              extra,
            },
          },
        ],
        subtotal: amount,
        grand_total: Math.round(amount + extra),
        currency: {
          name: currency.name,
          scale_factor: currency.scale_factor,
        },
      };
    }

    return null;
  }, [gst, currency, quote.membership]);
  const software = softwares.entities[job.customer_software]?.name || null;

  return (
    <div className='InvoiceActivity'>
      <div className='mb-2 font-weight--600 font-18'>
        <Activity.Card.Type type='QUOTE CREATED' />
      </div>
      <p className='mb-2 font-weight--400 font-16'>
        {text('buyItems', {
          title: upperCase(text('buyNow')),
        })}
      </p>
      <Row>
        <Col lg={12} xl={6} className='quote-card mb-3 pr-xl-0'>
          <WhiteContainer className='p-3 charge-item'>
            <p className='title mb-2'>1. {text('dataInDabase')}</p>
            <div className='px-3'>
              <List isPaddingless className='is-borderless'>
                {buyNowItems.map(
                  (
                    {
                      discounts,
                      details,
                      price,
                      fees,
                      member_discounts_preview,
                    },
                    index
                  ) => {
                    return (
                      <ChargeItem
                        key={index}
                        displayName={text(`${details.category_name}3D`)}
                        discounts={
                          isMembershipPriceId(membershipPriceId)
                            ? member_discounts_preview
                            : discounts
                        }
                        details={details}
                        price={price}
                        fees={fees}
                        quote={quote}
                        isIndividualDiscountVisible={
                          !userHasActiveMembership(currentUser) &&
                          !isMembershipPriceId(membershipPriceId)
                        }
                        hideArea={true}
                      />
                    );
                  }
                )}

                {!isEmpty(quote.discounts) &&
                  quote.discounts.map((discount, i) => (
                    <List.Item
                      className='discount font-pink pl-3 pt-1 font-italic'
                      key={i}
                      description={discount.display_name}
                      value={`-${formatCurrency(
                        discount.price?.subtotal,
                        currency.name,
                        currency.scale_factor,
                        {},
                        true
                      )}`}
                    />
                  ))}
              </List>
            </div>
          </WhiteContainer>
        </Col>
        {membership ? (
          <Col lg={12} xl={6} className='quote-card mb-3'>
            <WhiteContainer className='p-3 charge-item'>
              <p className='title mb-2'>2. {text('membership')}</p>
              <List isPaddingless className='is-borderless'>
                <List.Item
                  className='item price px-3'
                  value={formatCurrency(
                    membership.subtotal,
                    currency.name,
                    currency.scale_factor,
                    {},
                    true
                  )}
                  description={membership.name}
                />
              </List>
            </WhiteContainer>
          </Col>
        ) : null}
      </Row>
      <hr className='totals-divider m-0' />
      <Row>
        <Col lg={12} xl={6} className='pr-xl-0'>
          <InvoiceTotal
            quote={job?.quote}
            currentUser={currentUser}
            membershipPriceId={membershipPriceId}
            selectedMembership={membership}
            subtotalText={text('subtotal')}
            grandTotalText={text('total')}
          />
        </Col>
      </Row>

      <div className='mb-3 mt-2'>
        <span className='font-weight-bold'>{text('software')}: </span>
        <span>{software} </span>
      </div>

      <div className='terms-and-conditions'>
        {text('orderSubjectAgreement', {
          link: (
            <Link
              key='urlTerms'
              to={{ pathname: process.env.LARKI_URL_TERMS }}
              target='_blank'
            >
              Customer {text('termsAndConditions')}
            </Link>
          ),
        })}
      </div>
    </div>
  );
};

Invoice.propTypes = {
  job: T.object,
};

export default Invoice;
