/* eslint-disable react/jsx-max-depth */
/* global google */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import * as visGm from '@vis.gl/react-google-maps';
import {
  GM_MAP_DISABLED_OPTIONS,
  GM_MAP_INITIAL_OPTIONS,
  GM_MAP_OPTIONS,
} from '../components/GmMap';
import { DeckGL, DeckGLProps } from '@deck.gl/react';
import { crdtClient, idLib, HandshakeQuery } from '@larki/mp';
import type * as GeoJSON from 'geojson';
import * as fp from 'lodash/fp';
import * as lodash from 'lodash';
import { MjolnirGestureEvent } from 'mjolnir.js';
import invariant from 'tiny-invariant';
import microdiff from 'microdiff';
import * as dateFns from 'date-fns';

import DebugMetrics, { Metrics } from '../DebugMetrics';
import useMovingAverage from '../hooks/useMovingAverage';
import { positionLib } from '../lib/position';
import { useThrottle } from '../hooks/useThrottle';
import { Interpolator } from '../lib/interpolator';
import useMode, {
  isModeCreating,
  ModeAction,
  modeReducer,
} from '../hooks/useMode';
import { useKeyPress } from '../hooks/useKeyPress';
import { Corner, rectangleLib } from '../lib/rectangle';
import {
  convertStateToJson,
  JsonState,
  checkStateIsValid,
  TentativePolygonHandleProperties,
  TentativePathHandleProperties,
  TentativeBooleanHandleProperties,
  positionSchema,
  MapShapeFeatureProperties,
  interpolateState,
  PickingInfo_,
  oppositeCornersToRectangle,
  getClosestPosition,
  getClosestPositionNested,
  ClosestResult,
  createPolygonHandle,
  createBooleanHandle,
  createPathHandle,
  DeletableHandleFeature,
  MidpointFeature,
  getMidpoints,
  createPolygonMidpointFeature,
  createPathMidpointFeature,
  createBooleanMidpointFeature,
  ShapeDetails,
  MapShape,
  stateSchema,
  isDerivedShape,
  MapPolygon,
  MapRectangle,
  MapShapeInput,
  isDerivedShapeInput,
  UserSettings,
} from '../lib/shape';
import { es6MapMap, groupBy, objectMap } from '../lib/object';
import { TentativeShapeLayer } from '../layers/tentativeShapeLayer';
import { MapShapeLayer } from '../layers/mapShapeLayer';
import { SimpleCursorLayer } from '../layers/simpleCursorLayer';
import { RealtimeContext } from '../RealtimeContext';
import DeleteVertexLayer from '../layers/deleteVertexLayer';
import AddVertexLayer from '../layers/addVertexLayer';
import DrawingControls from '../components/DrawingControls';
import LayersPanel from '../components/LayersPanel';
import Page from '../../components/page/Page';
import classNames from 'classnames';
import text from '../../text';
import { useDispatch, useSelector } from 'react-redux';
import ModalManager from '../../components/modal/ModalManager';
import { shapeNameLib } from '../lib/shapeName';
import { arrayLib } from '../lib/array';
import {
  canChangeDataType,
  DataType,
  DerivedDataType,
  getBaseDataType,
  isAddOnDataType,
  isAutomaticDataType,
  isBaseDataType,
  isDerivedDataType,
  isManualDataType,
  PointCloudDataType,
} from '../lib/dataType';
import ProductControls from '../components/ProductControls';
import {
  alertError,
  getUserProfile,
  loadHighResCollectionsMp,
  loadHighResLayersMp,
  showModal,
  toggleRightMenuVisibility,
  updateMapType,
} from '../../redux/actions';
import { MP_UPDATE_SHAPES_MODAL } from '../../components/modal/mp/UpdateShapesModal';
import { MapViewState } from '@deck.gl/core';
import { SmartSelection, useGetJobQuery } from '../../redux/rtk';
import {
  CacheWorkerRequest,
  SerializedCacheResult,
} from '../services/coverage/cache';
import {
  Coverage,
  CoverageFilter,
  DataTypeAvailability,
  deserializeFilterWorkerResponse,
  deserializeQueryResult,
  FilterWorkerResponse,
  QueryWorkerRequest,
  SerializedFilterWorkerRequest,
  SerializedFilterWorkerResponse,
  SerializedQueryResult,
  serializeFilterWorkerRequest,
} from '../services/coverage/coverage';
import { CoverageLayer } from '../layers/coverageLayer';
import {
  deserializeUpdateLabelsWorkerResponse,
  SerializedUpdateLabelsWorkerResponse,
  serializeUpdateLabelsWorkerRequest,
} from '../services/coverage/labels';
import NavBar from '../components/NavBar';
import { useRedirectTo } from '../../hooks/useRedirect';
import { CartContext } from '../CartContext';
import {
  CartCoverage,
  Quote,
  QuoteWorkerRequest,
  QuoteWorkerResponse,
} from '../services/coverage/workers/quote.worker';
import { getUser } from '../../utilities/storage';
import Cart from '../components/Cart';
import GmPlaceAutocomplete from '../components/GmAutocomplete';
import CursorLayer from '../layers/cursorLayer';
import { MAP_TYPES, MapType, mapTypeEnum } from '../lib/mapType';
import MapTypeButton from '../components/map-type-button';
import { profileSelectors } from '../../redux/selectors/profile';
import useMembershipButton from '../../hooks/useMembershipButton';
import { profileConstants } from '../../redux/constants';
import { MAP_TYPE } from '../../components/mapView/constants';

import '../styles/main.scss';
import HighResDateStepper from '../components/high-res-date-stepper';
import MetromapLogoMp from '../components/metromap-logo';
import { highResLib } from '../lib/highRes';
import { convertGMBoundsToBBox } from '../../utilities/map';
import { getHighResCollectionsContainsBBox } from '../../utilities/metromap';
import useIsTabActive from '../hooks/useIsTabActive';
import { usePrevious } from 'react-use';

const MAP_ID = 'map';
const CONTROLLER_OPTIONS: DeckGLProps['controller'] = {
  doubleClickZoom: false, // fixes delay
  scrollZoom: {
    smooth: true,
    speed: 0.005,
  },
  dragRotate: false,
  touchRotate: false,
};
const CLIENT_ID = idLib.shortId();

export type RealtimeClientConnectionState =
  | {
      status: 'connecting';
    }
  | {
      status: 'connected';
    }
  | {
      status: 'error';
      error: Error;
    }
  | {
      status: 'disconnected';
    };
const realtimeClientReducer = (
  state: RealtimeClientConnectionState,
  action:
    | { type: 'connect_error'; payload: Error }
    | { type: 'connect' }
    | { type: 'disconnect' }
): RealtimeClientConnectionState => {
  switch (action.type) {
    case 'connect_error':
      return { status: 'error', error: action.payload };
    case 'connect':
      return { status: 'connected' };
    case 'disconnect':
      return { status: 'disconnected' };
    default:
      return state;
  }
};

const useClient = (query: HandshakeQuery) => {
  const [connectionState, dispatch] = useReducer(realtimeClientReducer, {
    status: 'connecting',
  });
  const [client] = useState(() => {
    console.log('INITIALIZING CLIENT');
    return new crdtClient.Client({
      url: process.env.MP_SERVER_URL!,
      query,
      autoOn: false,
      eventListeners: {
        connect: () => dispatch({ type: 'connect' }),
        connectError: (err) =>
          dispatch({ type: 'connect_error', payload: err }),
        disconnect: () => dispatch({ type: 'disconnect' }),
      },
    });
  });
  useEffect(() => {
    client.on();
    return () => {
      client.io.disconnect();
      client.off();
    };
  }, [client]);
  return useMemo(
    () => ({ client, connectionState }),
    [client, connectionState]
  );
};

const Consumer = ({
  jobId,
  initialPlace,
}: {
  jobId: number;
  initialPlace?: GeoJSON.Position | null;
}) => {
  // --- STATE/MEMOS ---

  const {
    data: job,
    isLoading: isLoadingJob,
    isError: isErrorJob,
  } = useGetJobQuery(jobId);

  const redirect = useRedirectTo();

  const { client, connectionState } = useClient({
    clientId: CLIENT_ID,
    roomId: `${jobId}`,
    clientName: getUser()!.full_name,
    clientImage: getUser()!.image,
  });

  const isTabActive = useIsTabActive();
  const prevIsTabActive = usePrevious(isTabActive);
  const shouldResyncNow = useMemo(
    () => prevIsTabActive !== null && !prevIsTabActive && isTabActive,
    [isTabActive, prevIsTabActive]
  );

  const { movingAverages: metrics, updateMovingAverages: updateMetrics } =
    useMovingAverage<Metrics>(3);

  /**
   * `jsonState` is the latest state that is drawn to the screen.
   * NOTE: Since it is a ref, it should never be used in a `useEffect` or `useMemo`.
   * We use a ref since it is updated as often as every frame, calling `setState` this often can bottleneck performance.
   */
  const jsonState = useRef<JsonState | null>(null);
  // TODO: `setShapeDetails` is being called too often, it is currently a performance bottleneck
  const [shapeDetails, setShapeDetails] = useState<ShapeDetails[]>();
  const [userSettings, setUserSettings] = useState<Map<string, UserSettings>>(
    new Map()
  );
  const myCursor = useRef<GeoJSON.Position | null>(null);
  const otherCursorsInterpolated = useRef<Map<string, GeoJSON.Position>>(
    new Map()
  );

  // NOTE: don't call `dispatchMode` directly, use `handleChangeMode` instead for consistent behavior
  const [mode, dispatchMode] = useMode();

  const [activeShapeIds, _setActiveShapeIds] = useState<Set<string>>(new Set());

  const vertexPopup = useRef<
    | {
        type: 'delete';
        info: ClosestResult<DeletableHandleFeature>;
      }
    | { type: 'add'; info: ClosestResult<MidpointFeature> }
    | null
  >(null);

  const [canUndo, _setCanUndo] = useState(false);
  const [canRedo, _setCanRedo] = useState(false);

  const activeShape = useMemo(() => {
    return shapeDetails?.find((shape) => activeShapeIds.has(shape.id)) ?? null;
  }, [activeShapeIds, shapeDetails]);

  const areAllShapesShown =
    jsonState.current?.shapes?.every((shape) => !shape.hidden) ?? true;

  const areAllShapesHidden =
    jsonState.current?.shapes?.every((shape) => shape.hidden) ?? false;

  // @ts-expect-error unknown state
  const layout = useSelector((reduxState) => reduxState.layout);
  const dispatch = useDispatch();

  const [initialViewState, setInitialViewState] = useState({
    longitude: GM_MAP_INITIAL_OPTIONS.defaultCenter.lng,
    latitude: GM_MAP_INITIAL_OPTIONS.defaultCenter.lat,
    zoom: GM_MAP_INITIAL_OPTIONS.defaultZoom,
  });

  const [availability, _setAvailability] = useState<DataTypeAvailability>();

  const myUserSettings = useMemo(
    () => userSettings.get(CLIENT_ID),
    [userSettings]
  );
  const otherUserSettingsByMapType = useMemo(
    () =>
      groupBy(
        [...userSettings.values()].filter((user) => user.id !== CLIENT_ID),
        'map_type'
      ),
    [userSettings]
  );

  const isHighResEnabled = useSelector(profileSelectors.getHighResEnabled);
  const { handleMembershipClick } = useMembershipButton();
  const visGmMap = visGm.useMap(MAP_ID);
  const [tileDate, setTileDate] = useState('');
  const { highRes } = useSelector((state: any) => state.mpReducer);

  // --- LAYERS ---

  const _tentativeShapeLayer = useMemo(
    () =>
      new TentativeShapeLayer({
        id: 'tentative',
        pickable: true,
      }),
    []
  );
  const _shapeLayer = useMemo(
    () => new MapShapeLayer({ id: 'shape', pickable: true }),
    []
  );
  const _cursorLayer = useMemo(() => new CursorLayer(), []);
  const _deleteVertexLayer = useMemo(
    () => new DeleteVertexLayer({ id: 'delete-vertex', pickable: true }),
    []
  );
  const _addVertexLayer = useMemo(
    () => new AddVertexLayer({ id: 'add-vertex', pickable: true }),
    []
  );
  const _coverageLayer = useMemo(
    () => new CoverageLayer({ id: 'coverage' }),
    []
  );

  // --- COVERAGE WORKERS ---

  const cacheWorker = useMemo(
    () =>
      new Worker(
        new URL('../services/coverage/workers/cache.worker.ts', import.meta.url)
      ),
    []
  );
  const queryWorker = useMemo(
    () =>
      new Worker(
        new URL('../services/coverage/workers/query.worker.ts', import.meta.url)
      ),
    []
  );
  const filterWorker = useMemo(
    () =>
      new Worker(
        new URL(
          '../services/coverage/workers/filter.worker.ts',
          import.meta.url
        )
      ),
    []
  );
  const labelsWorker = useMemo(
    () =>
      new Worker(
        new URL(
          '../services/coverage/workers/labels.worker.ts',
          import.meta.url
        )
      ),
    []
  );
  const quoteWorker = useMemo(
    () =>
      new Worker(
        new URL('../services/coverage/workers/quote.worker.ts', import.meta.url)
      ),
    []
  );

  // --- INTERPOLATION ---

  const draw = useCallback(() => {
    if (!client.state || !client.tempState) return; // we haven't synced yet
    jsonState.current = interpolateState(
      convertStateToJson(client.state, client.tempState),
      otherCursorsInterpolated.current,
      myCursor.current,
      CLIENT_ID
    );
    _tentativeShapeLayer._setState({
      shapes: fp.values(jsonState.current.tentative_shapes),
    });
    // TODO: only re-render shapes that have changed
    _shapeLayer._setState({
      shapes: fp.values(jsonState.current.shapes),
      tentativeBooleans: fp.filter(
        (tentative) => tentative.type === 'boolean',
        jsonState.current.tentative_shapes
      ),
      activeShapeIds,
    });
  }, [
    client.state,
    client.tempState,
    _tentativeShapeLayer,
    _shapeLayer,
    activeShapeIds,
  ]);
  const drawPopups = useCallback(() => {
    _deleteVertexLayer._setState({
      popup:
        vertexPopup.current?.type === 'delete'
          ? { popup: 'delete', handle: vertexPopup.current.info.result }
          : null,
    });
    _addVertexLayer._setState({
      popup:
        vertexPopup.current?.type === 'add'
          ? { popup: 'add', midpoint: vertexPopup.current.info.result }
          : null,
    });
  }, [_deleteVertexLayer, _addVertexLayer]);

  const [interpolator] = useState(
    () =>
      new Interpolator(
        (otherCursors) => {
          otherCursorsInterpolated.current = otherCursors;
          _cursorLayer._setState({
            cursors: otherCursors,
          });
          draw();
        },
        (changes) => {
          if (!client.state || !client.tempState) return; // we haven't synced yet
          if (changes.main) client.state.import(changes.main);
          if (changes.temp) client.tempState.import(changes.temp);
          draw();
          onShapesChange();
        }
      )
  );

  // --- COVERAGE ---

  useEffect(() => {
    labelsWorker.onmessage = (
      ev: MessageEvent<SerializedUpdateLabelsWorkerResponse>
    ) => {
      const response = deserializeUpdateLabelsWorkerResponse(ev.data);
      if (response) {
        _coverageLayer._setState({
          labels: response.labels,
        });
      }
    };
  }, [_coverageLayer, labelsWorker]);

  useEffect(() => {
    filterWorker.onmessage = (
      ev: MessageEvent<SerializedFilterWorkerResponse>
    ) => {
      const response = deserializeFilterWorkerResponse(ev.data);
      if (response) {
        _coverageLayer._setState({
          layers: response.layers,
        });
        _setAvailability(response.availability);
      }
    };
  }, [_coverageLayer, filterWorker]);

  useEffect(() => {
    queryWorker.onmessage = (ev: MessageEvent<SerializedQueryResult>) => {
      if (ev.data) {
        const data = deserializeQueryResult(ev.data);
        if (data) {
          filterWorker.postMessage(
            serializeFilterWorkerRequest({
              data,
              shapesToFilterBy: jsonState.current?.shapes,
            })
          );
          labelsWorker.postMessage(
            serializeUpdateLabelsWorkerRequest({
              labels: data.labels,
              shapes: jsonState.current?.shapes,
            })
          );
        }
      }
    };
  }, [filterWorker, labelsWorker, queryWorker]);

  useEffect(() => {
    cacheWorker.onmessage = (ev: MessageEvent<SerializedCacheResult>) => {
      if (ev.data) {
        queryWorker.postMessage(ev.data satisfies QueryWorkerRequest);
      }
    };
  }, [cacheWorker, queryWorker]);

  const queryCoverage = useMemo(
    () =>
      lodash.throttle(() => {
        const shapes = jsonState.current?.shapes;
        if (!shapes) return;
        cacheWorker.postMessage([
          shapes,
          activeShapeIds,
        ] satisfies CacheWorkerRequest);
        filterWorker.postMessage(
          serializeFilterWorkerRequest({
            shapesToFilterBy: shapes,
          })
        );
        labelsWorker.postMessage(
          serializeUpdateLabelsWorkerRequest({
            shapes,
          })
        );
      }, 200),
    [activeShapeIds, cacheWorker, filterWorker, labelsWorker]
  );

  const quoteOrder = useMemo(
    () =>
      lodash.debounce(() => {
        const shapes = jsonState.current?.shapes;
        if (!shapes) return;
        quoteWorker.postMessage({
          jobId,
          shapes,
          sessionToken: getUser().token,
        } satisfies QuoteWorkerRequest);
      }, 200),
    [jobId, quoteWorker]
  );

  // --- FUNCTIONS ---

  const onShapesChange = useCallback(() => {
    setShapeDetails(jsonState.current?.shapes);
    queryCoverage();
    quoteOrder();
  }, [queryCoverage, quoteOrder]);

  const onUserSettingsChange = useCallback(() => {
    const newSettings = objectMap(
      (client) => client.user_settings,
      jsonState.current?.clients
    );
    setUserSettings(new Map(fp.entries(newSettings ?? {})));
    _cursorLayer._setState({
      userSettings: newSettings,
    });
  }, [_cursorLayer]);

  /**
   * ALL transactions that write data should have call `onMapChange`.
   * Draws to the map and broadcasts changes to other clients.
   * Call this whenever `client.state` is modified.
   * TODO: rename to `commitMapChange`
   */
  const onMapChange = useCallback(() => {
    if (!client.state || !client.tempState) return; // we haven't synced yet

    // client prediction
    // invariant(checkStateIsValid(client.state), 'state is invalid');
    if (!checkStateIsValid(client.state, client.tempState)) {
      console.error(client.state.toJSON());
      stateSchema.parse(client.state.toJSON());
      throw new Error('state is invalid');
    }
    draw();
    onShapesChange();
    onUserSettingsChange();

    // broadcast to other clients
    client.signalUpdate();

    _setCanUndo(client.undo?.canUndo() ?? false);
    _setCanRedo(client.undo?.canRedo() ?? false);
  }, [client, draw, onShapesChange, onUserSettingsChange]);

  const throttledSignalCursorMove = useThrottle(
    (position: GeoJSON.Position) =>
      client.signalCursorMove(positionLib.toString(position)),
    { delay: 50, ensure: true }
  );

  // --- MODE ---

  const isRedundantModeAction = useCallback(
    (action: ModeAction) => modeReducer(mode, action) === mode,
    [mode]
  );

  const handleChangeMode = useCallback(
    (action: ModeAction, allowRedundant = false) => {
      if (!allowRedundant && isRedundantModeAction(action)) return;

      {
        if (client.tempState) {
          // transaction
          {
            const tentative = client.tempState.getMap('tentative_shapes');
            tentative.delete(CLIENT_ID);
          }

          onMapChange();
        }
      }

      dispatchMode(action);
    },
    [isRedundantModeAction, dispatchMode, client.tempState, onMapChange]
  );

  // --- UNDO/REDO ---

  const undo = useCallback(() => {
    client.undo?.undo();
    // TODO: this can cause rectangles to go into an invalid state
    //  - rectangles should be defined by 2 points only, not 4 - preventing this from happening
    onMapChange();
  }, [client.undo, onMapChange]);

  const redo = useCallback(() => {
    client.undo?.redo();
    onMapChange();
  }, [client.undo, onMapChange]);

  // --- DEACTIVATE ---

  const deactivateShape = useCallback((id: string) => {
    // NOTE: we ahve create a new Set(), otherwise if we `.delete()` shallow comparison will not trigger the `useEffect` below
    _setActiveShapeIds((prev) => new Set([...prev].filter((s) => s !== id)));
  }, []);

  const deactivateAllShapes = useCallback(() => {
    _setActiveShapeIds(new Set());
  }, []);

  // ---- HIDE/SHOW ----

  const hideShape = useCallback(
    (id: string, hide: boolean) => {
      if (!client.state || !client.tempState) return;
      const hiddenMap = client.tempState.getMap('hidden_map');
      // transaction
      {
        hiddenMap.set(id, hide);
      }
      onMapChange();

      // deactivate
      if (hide) {
        deactivateShape(id);
      }
    },
    [client.state, client.tempState, deactivateShape, onMapChange]
  );

  const hideAllShapes = useCallback(
    (hide: boolean) => {
      if (!client.state || !client.tempState) return;
      const shapes = client.state.getMovableList('shapes');
      const hiddenMap = client.tempState.getMap('hidden_map');
      for (let i = 0; i < shapes.length; i++) {
        const shape = shapes.get(i);
        invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');
        const id = shape.get('id');
        invariant(typeof id === 'string', 'invalid id');
        // transaction
        {
          hiddenMap.set(id, hide);
        }
      }
      onMapChange();

      deactivateAllShapes();
    },
    [client.state, client.tempState, deactivateAllShapes, onMapChange]
  );

  const hideAllShapesOfDataType = useCallback(
    (dataType: PointCloudDataType | DerivedDataType, hide: boolean) => {
      if (!client.state || !client.tempState) return;
      const shapesContainer = client.state.getMovableList('shapes');
      const hiddenMap = client.tempState.getMap('hidden_map');
      const shapes = stateSchema.shape['shapes'].parse(
        shapesContainer.toJSON()
      );
      if (shapes?.length) {
        for (const shape of shapes) {
          if (shape.dataType === dataType) {
            {
              hiddenMap.set(shape.id, hide);
            }
          }
        }
        onMapChange();
      }
    },
    [client.state, client.tempState, onMapChange]
  );

  // --- ACTIVATE ---

  const activateShape = useCallback(
    (shapeId: string) => {
      // NOTE: the order here is important:
      //  if it were in the reverse order, then `hideShape()` is done after `_setActiveShapeIds(...)`
      //  state updates don't happen instantaneously, so `activeShapeIds` won't have `shapeId` but `_setActiveShapeIds(...)` will still eventually be called
      // `hideShape` will call `onMapChange()` using `activeShapeIds` at the time when `hideShape` was called originally
      //  i.e. when it did not have `shapeId`, so the shape won't be active

      hideShape(shapeId, false);
      _setActiveShapeIds(new Set([shapeId]));
    },
    [hideShape]
  );

  // ---- NAME ----

  const getNextShapeName = useCallback(() => {
    const shapeNames =
      jsonState.current?.shapes
        ?.map((shape) => shape.name)
        .filter(arrayLib.filterNonUndefined) ?? [];
    return shapeNameLib.getNextShapeName(shapeNames);
  }, []);

  const editShapeName = useCallback(
    (id: string, name: string) => {
      if (!client.state) return;
      const shapes = client.state.getMovableList('shapes');
      // TODO: fix this inefficient way of doing it
      const index = (shapes.toJSON() as MapShape[]).findIndex(
        (shape: MapShape) => shape.id === id
      );
      if (index !== -1) {
        // transaction
        {
          const shape = shapes.get(index);
          invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');
          shape.set('name', name);
        }
        onMapChange();
      }
    },
    [client.state, onMapChange]
  );

  // --- GET BY ID ---

  const getShapeById = useCallback(
    (id: string): crdtClient.LoroMap | null => {
      if (!client.state) return null;
      const shapes = client.state.getMovableList('shapes');
      const index = (shapes.toJSON() as MapShape[]).findIndex(
        (shape: MapShape) => shape.id === id
      );
      if (index !== -1) {
        return shapes.get(index) as crdtClient.LoroMap;
      }
      return null;
    },
    [client.state]
  );

  // --- DUPLICATE ---

  const createShape = useCallback(
    (
      input: MapShapeInput,
      opts: { inTransaction?: boolean; name?: string } = {}
    ) => {
      const inTransaction = opts.inTransaction ?? false;

      if (!client.state) return null;
      const newShapeId = idLib.shortId();

      // transaction
      {
        const shapesList = client.state.getMovableList('shapes');
        const shapeContainer = shapesList.pushContainer(
          new crdtClient.LoroMap()
        );

        shapeContainer.set('type', input.type);
        shapeContainer.set('id', newShapeId);
        shapeContainer.set('name', opts.name ?? getNextShapeName());
        shapeContainer.set('dataType', input.dataType);

        if (isDerivedShapeInput(input)) {
          shapeContainer.set('baseShapeId', input.baseShapeId);
        }

        if (input.type === 'polygon') {
          const exteriorContainer = shapeContainer.setContainer(
            'exterior',
            new crdtClient.LoroMovableList()
          );
          for (const [i, vertex] of input.exterior.entries()) {
            exteriorContainer.insert(i, positionLib.toString(vertex));
          }
        } else if (input.type === 'rectangle') {
          const exterior = shapeContainer.setContainer(
            'exterior',
            new crdtClient.LoroMap()
          );
          exterior.set(Corner.sw, positionLib.toString(input.exterior.sw));
          exterior.set(Corner.se, positionLib.toString(input.exterior.se));
          exterior.set(Corner.ne, positionLib.toString(input.exterior.ne));
          exterior.set(Corner.nw, positionLib.toString(input.exterior.nw));
        } else if (input.type === 'path') {
          const verticesContainer = shapeContainer.setContainer(
            'vertices',
            new crdtClient.LoroMovableList()
          );
          for (const [i, vertex] of input.vertices.entries()) {
            verticesContainer.insert(i, positionLib.toString(vertex));
          }
        }

        if (
          (input.type === 'polygon' || input.type === 'rectangle') &&
          input.holes
        ) {
          const holesContainer = shapeContainer.setContainer(
            'holes',
            new crdtClient.LoroMovableList()
          );
          for (const [i, hole] of input.holes.entries()) {
            const holeList = holesContainer.insertContainer(
              i,
              new crdtClient.LoroMovableList()
            );
            for (const [j, vertex] of hole.entries()) {
              holeList.insert(j, positionLib.toString(vertex));
            }
          }
        }
      }

      if (!inTransaction) onMapChange();

      return newShapeId;
    },
    [client.state, getNextShapeName, onMapChange]
  );

  const duplicateShape = useCallback(
    (
      id: string,
      opts: {
        newDataType?: DataType;
        newBaseShapeId?: string;
        inTransaction?: boolean;
      } = {}
    ) => {
      const inTransaction = opts.inTransaction ?? false;

      if (!client.state) return null;

      const shapesList = client.state.getMovableList('shapes');
      const shapes = stateSchema.shape['shapes'].parse(shapesList.toJSON());
      const prevShape = shapes?.find((shape) => shape.id === id);

      if (!prevShape) {
        console.warn('failed to find prev shape to duplicate');
        return null;
      }

      if (opts.newDataType) {
        prevShape.dataType = opts.newDataType;

        if (isDerivedShapeInput(prevShape)) {
          invariant(
            opts.newBaseShapeId,
            'baseShapeId is required if newDataType is a derived data type'
          );
          prevShape.baseShapeId = opts.newBaseShapeId;
        }
      }

      return createShape(prevShape, { inTransaction });
    },
    [client.state, createShape]
  );

  // --- DATA TYPE ---

  const getDerivedShapes = useCallback(
    (id: string) => {
      if (!client.state) return [];
      const shapesContainer = client.state.getMovableList('shapes');
      const shapes = stateSchema.shape['shapes'].parse(
        shapesContainer.toJSON()
      );
      return (
        shapes?.filter(
          (shape) => isDerivedShape(shape) && shape.baseShapeId === id
        ) ?? []
      );
    },
    [client.state]
  );

  const changeDataType = useCallback(
    (id: string, dataType: DataType) => {
      if (!client.state) return;

      const shape = getShapeById(id);
      if (!shape) return;

      if (isDerivedDataType(dataType)) {
        // if it is a derived type, it's not enough to set the `dataType` we also need to set the `baseShapeId`
        //  if we already have a `baseShapeId`, it is assumed that the base data type is the same - so we don't have to do anything to it
        //  otherwise, create a duplicate of the current shape  with the base data type
        const prevBaseShapeId = shape.get('baseShapeId');
        if (!prevBaseShapeId) {
          // transaction
          {
            const baseShapeId = duplicateShape(id, {
              newDataType: getBaseDataType(dataType),
              inTransaction: true,
            });
            if (baseShapeId) {
              shape.set('baseShapeId', baseShapeId);
              shape.set('dataType', dataType);
            }
          }
        } else {
          // transaction
          {
            shape.set('dataType', dataType);
          }
        }
        onMapChange();
        return;
      }

      // if was a base data type, set all it's derived shape types to it
      const prevDataType = shape.get('dataType') as string;
      if (isBaseDataType(prevDataType)) {
        const shapesContainer = client.state.getMovableList('shapes');
        const shapes = stateSchema.shape['shapes'].parse(
          shapesContainer.toJSON()
        );
        const derivedShapes = shapes?.filter(
          (shape) => isDerivedShape(shape) && shape.baseShapeId === id
        );
        if (derivedShapes?.length) {
          dispatch(
            showModal(MP_UPDATE_SHAPES_MODAL, {
              onOk: () => {
                // transaction
                {
                  shape.set('dataType', dataType);
                  shape.delete('baseShapeId');
                  for (const derivedShape of derivedShapes) {
                    const shapeContainer = getShapeById(derivedShape.id);
                    if (shapeContainer) {
                      shapeContainer.set('dataType', prevDataType);
                      shapeContainer.delete('baseShapeId');
                    }
                  }
                }
                onMapChange();
              },
              associatedAddOns: derivedShapes.map((s) => ({
                display_name: s.name,
              })),
              isNonTripodTarget: false,
              removeFromCart: false,
              toDraft: false,
            })
          );
          return;
        }
      }

      // transaction
      {
        shape.set('dataType', dataType);
        shape.delete('baseShapeId');
      }
      onMapChange();
    },
    [client.state, dispatch, duplicateShape, getShapeById, onMapChange]
  );

  // --- TENTATIVE ---

  /**
   * A tentative `shape` is "finished" when it is deleted and a permanent shape takes its place.
   * Whenever that happens, excluding booleans, calls this method.
   */
  const onFinishTentativeShape = useCallback(
    (shapeId: string) => {
      handleChangeMode('stop_creating');
      activateShape(shapeId);
    },
    [activateShape, handleChangeMode]
  );

  // ---- VERTEX POPUP ----

  const getClosestDeletableActiveShapeHandle = useCallback(
    (
      targetCoord: GeoJSON.Position
    ): ClosestResult<DeletableHandleFeature> | null => {
      if (!jsonState.current?.shapes) return null;
      const { shapes } = jsonState.current;

      let closest: ClosestResult<DeletableHandleFeature> | null = null;

      function setClosest(result: typeof closest) {
        if (result && (!closest || result.xyDistance < closest.xyDistance)) {
          closest = result;
        }
      }

      for (let shapeIndex = 0; shapeIndex < shapes.length; shapeIndex++) {
        const shape = shapes[shapeIndex];
        if (shape.hidden) continue;
        if (!activeShapeIds.has(shape.id)) continue; // we cannot filter as that would mess up the `shapeIndex`
        if (shape.type === 'polygon') {
          setClosest(
            getClosestPosition({
              targetCoord,
              points: shape.exterior,
              parseResult: ({ coordinate, i }) =>
                createPolygonHandle(coordinate, {
                  shapeIndex,
                  vertexIndex: i,
                  hidden: shape.hidden,
                }),
            })
          );
        } else if (shape.type === 'path') {
          setClosest(
            getClosestPosition({
              targetCoord,
              points: shape.vertices,
              parseResult: ({ coordinate, i }) =>
                createPathHandle(coordinate, {
                  shapeIndex,
                  vertexIndex: i,
                  hidden: shape.hidden,
                }),
            })
          );
        }
        if (shape.type === 'polygon' || shape.type === 'rectangle') {
          setClosest(
            shape.holes
              ? getClosestPositionNested({
                  targetCoord,
                  points: shape.holes,
                  parseResult: ({ coordinate, i, j }) =>
                    createBooleanHandle(coordinate, {
                      shapeIndex,
                      holeIndex: i,
                      vertexIndex: j,
                      hidden: shape.hidden,
                    }),
                })
              : null
          );
        }
      }

      return closest;
    },
    [activeShapeIds]
  );

  const getClosestActiveShapeMidpoint = useCallback(
    (targetCoord: GeoJSON.Position): ClosestResult<MidpointFeature> | null => {
      if (!jsonState.current?.shapes) return null;
      const { shapes } = jsonState.current;

      let closest: ClosestResult<MidpointFeature> | null = null;

      function setClosest(result: typeof closest) {
        if (result && (!closest || result.xyDistance < closest.xyDistance)) {
          closest = result;
        }
      }

      for (let shapeIndex = 0; shapeIndex < shapes.length; shapeIndex++) {
        const shape = shapes[shapeIndex];
        if (shape.hidden) continue;
        if (!activeShapeIds.has(shape.id)) continue; // we cannot filter as that would mess up the `shapeIndex`
        if (shape.type === 'polygon') {
          setClosest(
            getClosestPosition({
              targetCoord,
              points: getMidpoints(shape.exterior),
              parseResult: ({ coordinate, i }) =>
                createPolygonMidpointFeature(coordinate, shapeIndex, i),
            })
          );
        } else if (shape.type === 'path') {
          setClosest(
            getClosestPosition({
              targetCoord,
              points: getMidpoints(shape.vertices),
              parseResult: ({ coordinate, i }) =>
                createPathMidpointFeature(coordinate, shapeIndex, i),
            })
          );
        }
        if (shape.type === 'polygon' || shape.type === 'rectangle') {
          setClosest(
            shape.holes
              ? getClosestPositionNested({
                  targetCoord,
                  points: shape.holes.map((hole) => getMidpoints(hole)),
                  parseResult: ({ coordinate, i, j }) =>
                    createBooleanMidpointFeature(coordinate, shapeIndex, i, j),
                })
              : null
          );
        }
      }

      return closest;
    },
    [activeShapeIds]
  );

  // ---- DELETE ----

  const deleteShape = useCallback(
    (...ids: string[]) => {
      if (!client.state) return;
      const shapesContainer = client.state.getMovableList('shapes');
      const shapes = stateSchema.shape['shapes'].parse(
        shapesContainer.toJSON()
      );
      if (shapes) {
        for (const id of ids) {
          const index = shapes.findIndex((shape) => shape.id === id);
          if (index !== -1) {
            // transaction
            {
              shapesContainer.delete(index, 1);
            }
          }
        }
        onMapChange();
      }
    },
    [client.state, onMapChange]
  );

  const deleteAllShapes = useCallback(() => {
    if (!client.state) return;
    client.state.getMovableList('shapes').clear();
    onMapChange();
  }, [client.state, onMapChange]);

  const deleteAllShapesOfDataType = useCallback(
    (dataType: PointCloudDataType | DerivedDataType) => {
      if (!client.state) return;
      const shapesContainer = client.state.getMovableList('shapes');
      const shapes = stateSchema.shape['shapes'].parse(
        shapesContainer.toJSON()
      );
      if (shapes?.length) {
        for (let i = shapes.length - 1; i >= 0; i--) {
          const shape = shapes[i];
          if (shape.dataType === dataType) {
            shapesContainer.delete(i, 1);
          }
        }
        onMapChange();
      }
    },
    [client.state, onMapChange]
  );

  // ---- EVENTS ----

  const onClickMapBackground = useCallback(
    (coordinate: GeoJSON.Position) => {
      if (!client.state || !client.tempState) return; // we haven't synced yet
      if (!isModeCreating(mode)) return;

      const tentativeShapes = client.tempState.getMap('tentative_shapes');

      if (mode === 'creating_polygon') {
        if (tentativeShapes.get(CLIENT_ID)) {
          // we already have a tentative shape, so add to it

          const shape = tentativeShapes.get(CLIENT_ID);
          invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');
          const exterior = shape.get('exterior');
          invariant(
            exterior instanceof crdtClient.LoroMovableList,
            'invalid exterior'
          );

          // transaction
          {
            exterior.push(positionLib.toString(coordinate));
          }
        } else {
          // we don't have a tentative shape, so create a new one

          // transaction
          {
            const shape = tentativeShapes.setContainer(
              CLIENT_ID,
              new crdtClient.LoroMap()
            );
            shape.set('type', 'polygon');
            shape.set('id', CLIENT_ID);
            const exterior = shape.setContainer(
              'exterior',
              new crdtClient.LoroMovableList()
            );
            exterior.push(positionLib.toString(coordinate));
          }
        }

        onMapChange();
      } else if (mode === 'creating_rectangle') {
        const tentative = tentativeShapes.get(CLIENT_ID) as
          | crdtClient.LoroMap
          | undefined;
        if (tentative) {
          // finish existing
          const initial = positionSchema.parse(tentative.get('initial'));

          // transaction
          {
            const shapes = client.state.getMovableList('shapes');
            const shape = shapes.pushContainer(new crdtClient.LoroMap());
            const newId = idLib.shortId();
            shape.set('type', 'rectangle');
            shape.set('id', newId);
            shape.set('name', getNextShapeName());
            const exterior = shape.setContainer(
              'exterior',
              new crdtClient.LoroMap()
            );
            const vertices = oppositeCornersToRectangle(initial, coordinate);
            exterior.set(Corner.sw, positionLib.toString(vertices.sw));
            exterior.set(Corner.se, positionLib.toString(vertices.se));
            exterior.set(Corner.ne, positionLib.toString(vertices.ne));
            exterior.set(Corner.nw, positionLib.toString(vertices.nw));

            tentativeShapes.delete(CLIENT_ID);
            onFinishTentativeShape(newId);
          }
        } else {
          // create new

          // transaction
          {
            const shape = tentativeShapes.setContainer(
              CLIENT_ID,
              new crdtClient.LoroMap()
            );
            shape.set('type', 'rectangle');
            shape.set('id', CLIENT_ID);
            shape.set('initial', positionLib.toString(coordinate));
          }
        }

        onMapChange();
      } else if (mode === 'creating_path') {
        if (tentativeShapes.get(CLIENT_ID)) {
          // we already have a tentative shape, so add to it

          const shape = tentativeShapes.get(CLIENT_ID);
          invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');
          const vertices = shape.get('vertices');
          invariant(
            vertices instanceof crdtClient.LoroMovableList,
            'invalid vertices'
          );

          // transaction
          {
            vertices.push(positionLib.toString(coordinate));
          }
        } else {
          // we don't have a tentative shape, so create a new one

          // transaction
          {
            const shape = tentativeShapes.setContainer(
              CLIENT_ID,
              new crdtClient.LoroMap()
            );
            shape.set('type', 'path');
            shape.set('id', CLIENT_ID);
            const vertices = shape.setContainer(
              'vertices',
              new crdtClient.LoroMovableList()
            );
            vertices.push(positionLib.toString(coordinate));
          }
        }

        onMapChange();
      } else if (mode === 'creating_boolean') {
        if (!client.state || !client.tempState) return;

        const tentative = tentativeShapes.get(CLIENT_ID);

        if (tentative) {
          invariant(tentative instanceof crdtClient.LoroMap, 'invalid shape');

          // check
          {
            const type = tentative.get('type');
            invariant(type === 'boolean', 'invalid tentative type');
          }

          const parentShapeIndex = tentative.get('parent_index');
          const parentShape = client.state
            .getMovableList('shapes')
            .get(parentShapeIndex) as crdtClient.LoroMap | undefined;

          if (parentShape) {
            const type = parentShape.get('type') as string;
            invariant(type !== 'path', 'cannot create boolean in path');
          }

          const vertices = tentative.get('vertices');
          invariant(
            vertices instanceof crdtClient.LoroMovableList,
            'invalid vertices'
          );

          // transaction
          {
            vertices.push(positionLib.toString(coordinate));
          }

          onMapChange();
        }
      }
    },
    [
      client.state,
      client.tempState,
      mode,
      onMapChange,
      getNextShapeName,
      onFinishTentativeShape,
    ]
  );

  const onClickTentativePolygonVertex = useCallback(
    (handle: TentativePolygonHandleProperties) => {
      if (!client.state || !client.tempState) return; // we haven't synced yet

      const tentativeShapes = client.tempState.getMap('tentative_shapes');

      if (handle.shapeId === CLIENT_ID) {
        const tentativeShape = tentativeShapes.get(handle.shapeId);
        invariant(
          tentativeShape instanceof crdtClient.LoroMap,
          'invalid shape'
        );
        const tentativeExterior = tentativeShape.get('exterior');
        invariant(
          tentativeExterior instanceof crdtClient.LoroMovableList,
          'invalid exterior'
        );
        if (tentativeExterior.length >= 3) {
          // transaction: create shape & delete tentative
          {
            const shapes = client.state.getMovableList('shapes');
            const newId = idLib.shortId();
            const shape = shapes.pushContainer(new crdtClient.LoroMap());
            shape.set('type', 'polygon');
            shape.set('id', newId);
            shape.set('name', getNextShapeName());
            const exterior = shape.setContainer(
              'exterior',
              new crdtClient.LoroMovableList()
            );
            for (let i = 0; i < tentativeExterior.length; i++) {
              const vertex = tentativeExterior.get(i);
              invariant(typeof vertex === 'string', 'invalid vertex');
              exterior.insert(i, vertex);
            }

            tentativeShapes.delete(handle.shapeId);
            onFinishTentativeShape(newId);
          }

          onMapChange();
        }
      }
    },
    [
      client.state,
      client.tempState,
      getNextShapeName,
      onFinishTentativeShape,
      onMapChange,
    ]
  );

  const onClickTentativePathVertex = useCallback(
    (handle: TentativePathHandleProperties) => {
      if (!client.state || !client.tempState) return; // we haven't synced yet
      const tentativeShapes = client.tempState.getMap('tentative_shapes');

      if (handle.shapeId === CLIENT_ID) {
        const tentativeShape = tentativeShapes.get(handle.shapeId);
        invariant(
          tentativeShape instanceof crdtClient.LoroMap,
          'invalid shape'
        );
        const tentativeVertices = tentativeShape.get('vertices');
        invariant(
          tentativeVertices instanceof crdtClient.LoroMovableList,
          'invalid vertices'
        );
        if (tentativeVertices.length >= 2) {
          // transaction: create shape & delete tentative
          {
            const shapes = client.state.getMovableList('shapes');
            const newId = idLib.shortId();
            const shape = shapes.pushContainer(new crdtClient.LoroMap());
            shape.set('type', 'path');
            shape.set('id', newId);
            shape.set('name', getNextShapeName());
            const vertices = shape.setContainer(
              'vertices',
              new crdtClient.LoroMovableList()
            );
            for (let i = 0; i < tentativeVertices.length; i++) {
              const vertex = tentativeVertices.get(i);
              invariant(typeof vertex === 'string', 'invalid vertex');
              vertices.insert(i, vertex);
            }

            tentativeShapes.delete(handle.shapeId);
            onFinishTentativeShape(newId);
          }

          onMapChange();
        }
      }
    },
    [
      client.state,
      client.tempState,
      getNextShapeName,
      onFinishTentativeShape,
      onMapChange,
    ]
  );

  const onClickTentativeBooleanVertex = useCallback(
    (handle: TentativeBooleanHandleProperties) => {
      if (handle.shapeId !== CLIENT_ID) return;
      if (!client.state || !client.tempState) return; // we haven't synced yet

      const tentativeShapes = client.tempState.getMap('tentative_shapes');
      const tentativeShape = tentativeShapes.get(handle.shapeId);
      invariant(tentativeShape instanceof crdtClient.LoroMap, 'invalid shape');
      const tentativeVertices = tentativeShape.get('vertices');
      invariant(
        tentativeVertices instanceof crdtClient.LoroMovableList,
        'invalid vertices'
      );
      if (tentativeVertices.length >= 3) {
        // transaction: create shape & delete tentative
        {
          const shapes = client.state.getMovableList('shapes');
          const shape = shapes.get(handle.parentIndex);
          invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');
          const parentId = shape.get('id');
          invariant(typeof parentId === 'string', 'invalid parent id');
          const holes = shape.getOrCreateContainer(
            'holes',
            new crdtClient.LoroMovableList()
          );
          const hole = holes.pushContainer(new crdtClient.LoroMovableList());

          for (let i = 0; i < tentativeVertices.length; i++) {
            const vertex = tentativeVertices.get(i);
            invariant(typeof vertex === 'string', 'invalid vertex');
            hole.insert(i, vertex);
          }

          tentativeShapes.delete(handle.shapeId);
          onFinishTentativeShape(parentId);
        }

        onMapChange();
      }
    },
    [client.state, client.tempState, onFinishTentativeShape, onMapChange]
  );

  const onClickInsideShape = useCallback(
    (
      coordinate: GeoJSON.Position,
      mapShapeFeature: MapShapeFeatureProperties
    ) => {
      if (mode === 'creating_boolean') {
        if (!client.state || !client.tempState) return;

        const parentShapeIndex = mapShapeFeature.shapeIndex;
        const parentShape = client.state
          .getMovableList('shapes')
          .get(parentShapeIndex) as crdtClient.LoroMap | undefined;
        if (parentShape) {
          const type = parentShape.get('type') as string;
          if (type === 'path') return; // cannot create boolean in path
        }

        const tentativeShapes = client.tempState.getMap('tentative_shapes');
        let tentative = tentativeShapes.get(CLIENT_ID) as
          | crdtClient.LoroMap
          | undefined;

        if (tentative) {
          // check
          {
            const type = tentative.get('type');
            invariant(type === 'boolean', 'invalid tentative type');
          }

          const vertices = tentative.get('vertices');
          invariant(
            vertices instanceof crdtClient.LoroMovableList,
            'invalid vertices'
          );

          // transaction
          {
            vertices.push(positionLib.toString(coordinate));
          }
        } else {
          // transaction
          {
            tentative = tentativeShapes.setContainer(
              CLIENT_ID,
              new crdtClient.LoroMap()
            );
            tentative.set('type', 'boolean');
            tentative.set('id', CLIENT_ID);
            tentative.set('parent_index', parentShapeIndex);
            const vertices = tentative.setContainer(
              'vertices',
              new crdtClient.LoroMovableList()
            );
            vertices.push(positionLib.toString(coordinate));
          }
        }

        onMapChange();
      } else {
        if (isModeCreating(mode)) {
          onClickMapBackground(coordinate);
        } else {
          if (!client.state) return;

          const parentShape = client.state
            .getMovableList('shapes')
            .get(mapShapeFeature.shapeIndex);
          if (parentShape) {
            invariant(
              parentShape instanceof crdtClient.LoroMap,
              'invalid parent shape'
            );
            const parentId = parentShape.get('id');
            invariant(typeof parentId === 'string', 'invalid parent id');
            activateShape(parentId);
          }
        }
      }
    },
    [
      activateShape,
      client.state,
      client.tempState,
      mode,
      onClickMapBackground,
      onMapChange,
    ]
  );

  const onClickDeleteVertex = useCallback(
    (handle: DeletableHandleFeature['properties']) => {
      if (!client.state || !client.tempState) return; // we haven't synced yet

      const shapes = client.state.getMovableList('shapes');
      const shape = shapes.get(handle.shapeIndex);
      invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');

      if (handle.shapeType === 'polygon') {
        const exterior = shape.get('exterior');
        invariant(
          exterior instanceof crdtClient.LoroMovableList,
          'invalid exterior'
        );

        if (exterior.length > 3) {
          // transaction
          {
            exterior.delete(handle.vertexIndex, 1);
          }
        } else {
          // transaction
          {
            shapes.delete(handle.shapeIndex, 1);
          }
        }
      } else if (handle.shapeType === 'path') {
        const vertices = shape.get('vertices');
        invariant(
          vertices instanceof crdtClient.LoroMovableList,
          'invalid vertices'
        );

        if (vertices.length > 2) {
          // transaction
          {
            vertices.delete(handle.vertexIndex, 1);
          }
        } else {
          // transaction
          {
            shapes.delete(handle.shapeIndex, 1);
          }
        }
      } else if (handle.shapeType === 'boolean') {
        const holes = shape.get('holes');
        invariant(holes instanceof crdtClient.LoroMovableList, 'invalid holes');
        const hole = holes.get(handle.holeIndex);
        invariant(hole instanceof crdtClient.LoroMovableList, 'invalid hole');

        if (hole.length > 3) {
          // transaction
          {
            hole.delete(handle.vertexIndex, 1);
          }
        } else {
          // transaction
          {
            holes.delete(handle.holeIndex, 1);
          }
        }
      }

      vertexPopup.current = null;
      drawPopups();
      onMapChange();
    },
    [client.state, client.tempState, drawPopups, onMapChange]
  );

  const onClickAddVertex = useCallback(
    ({ geometry: { coordinates }, ...midpoint }: MidpointFeature) => {
      if (!client.state || !client.tempState) return;
      const { shapeIndex, shapeType, vertexIndexBefore } = midpoint.properties;
      const newVertexIndex = vertexIndexBefore + 1;

      const shapes = client.state.getMovableList('shapes');
      const shape = shapes.get(shapeIndex);
      invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');

      if (shapeType === 'polygon') {
        const exterior = shape.get('exterior');
        invariant(
          exterior instanceof crdtClient.LoroMovableList,
          'invalid exterior'
        );

        // transaction
        {
          exterior.insert(newVertexIndex, positionLib.toString(coordinates));
        }
      } else if (shapeType === 'path') {
        const vertices = shape.get('vertices');
        invariant(
          vertices instanceof crdtClient.LoroMovableList,
          'invalid vertices'
        );

        // transaction
        {
          vertices.insert(newVertexIndex, positionLib.toString(coordinates));
        }
      } else if (shapeType === 'boolean') {
        const holes = shape.get('holes');
        invariant(holes instanceof crdtClient.LoroMovableList, 'invalid holes');
        const hole = holes.get(midpoint.properties.holeIndex);
        invariant(hole instanceof crdtClient.LoroMovableList, 'invalid hole');

        // transaction
        {
          hole.insert(newVertexIndex, positionLib.toString(coordinates));
        }
      }

      vertexPopup.current = null;
      drawPopups();
      onMapChange();
    },
    [client.state, client.tempState, drawPopups, onMapChange]
  );

  const handleMapClick = useCallback(
    (info: PickingInfo_) => {
      if (!info.coordinate) return;

      if (info.popup) {
        if (info.popup.popup === 'delete') {
          onClickDeleteVertex(info.popup.handle.properties);
        } else if (info.popup.popup === 'add') {
          onClickAddVertex(info.popup.midpoint);
        }
      } else if (info.handle) {
        if (info.handle.handleType === 'tentative') {
          if (info.handle.shapeType === 'polygon') {
            onClickTentativePolygonVertex(info.handle);
          } else if (info.handle.shapeType === 'path') {
            onClickTentativePathVertex(info.handle);
          } else if (info.handle.shapeType === 'boolean') {
            onClickTentativeBooleanVertex(info.handle);
          }
        } else if (info.handle.handleType === 'shape') {
          //
        }
      } else if (info.mapShapeFeature) {
        onClickInsideShape(info.coordinate, info.mapShapeFeature);
      } else {
        onClickMapBackground(info.coordinate);
      }
    },
    [
      onClickDeleteVertex,
      onClickAddVertex,
      onClickTentativePolygonVertex,
      onClickTentativePathVertex,
      onClickTentativeBooleanVertex,
      onClickInsideShape,
      onClickMapBackground,
    ]
  );

  const handleMapHover = useCallback(
    (info: PickingInfo_, dragging: boolean) => {
      if (!info.coordinate) return;

      // client prediction
      myCursor.current = info.coordinate;
      draw();

      // broadcast to other clients
      throttledSignalCursorMove(info.coordinate);

      // --- handle showing/hiding of vertex/midpoint popups ---

      // if we're already hovering on a popup, do nothing
      //  this prevents us from switching to another vertex when one is already hovered
      if (info.popup) {
        return;
      }

      // if we're dragging, don't show it
      if (dragging) {
        if (vertexPopup.current) {
          vertexPopup.current = null;
          drawPopups();
        }
        return;
      }

      let popup: typeof vertexPopup.current = null;

      const MIN_DISTANCE_METERS = 20;

      const closestDelete = getClosestDeletableActiveShapeHandle(
        info.coordinate
      );
      if (closestDelete && closestDelete.xyDistance < MIN_DISTANCE_METERS) {
        popup = { type: 'delete', info: closestDelete };
      }

      const closestAdd = getClosestActiveShapeMidpoint(info.coordinate);
      if (
        closestAdd &&
        closestAdd.xyDistance < MIN_DISTANCE_METERS &&
        (!closestDelete || closestAdd.xyDistance < closestDelete.xyDistance)
      ) {
        popup = { type: 'add', info: closestAdd };
      }

      if (popup) {
        if (
          !vertexPopup.current ||
          microdiff(
            popup.info.result.properties,
            vertexPopup.current.info.result.properties
          )?.length
        ) {
          vertexPopup.current = popup;
          drawPopups();
        }
      } else if (vertexPopup.current) {
        vertexPopup.current = null;
        drawPopups();
      }
    },
    [
      draw,
      throttledSignalCursorMove,
      getClosestDeletableActiveShapeHandle,
      getClosestActiveShapeMidpoint,
      drawPopups,
    ]
  );

  const handleMapDragStart = useCallback(
    (info: PickingInfo_) => {
      if (!info.coordinate) return;
      if (!client.state || !client.tempState) return; // we haven't synced yet

      if (info.handle?.handleType !== 'shape') return;

      // transaction
      {
        const me = client.tempState
          .getMap('clients')
          .getOrCreateContainer(CLIENT_ID, new crdtClient.LoroMap());
        me.set('drag_shape_index', info.handle.shapeIndex);

        if (
          info.handle.shapeType === 'polygon' ||
          info.handle.shapeType === 'path'
        ) {
          me.set('drag_vertex_index', info.handle.vertexIndex);
        } else if (info.handle.shapeType === 'rectangle') {
          me.set('drag_corner', info.handle.corner);
        } else if (info.handle.shapeType === 'boolean') {
          me.set('drag_hole_index', info.handle.holeIndex);
          me.set('drag_vertex_index', info.handle.vertexIndex);
        }
      }

      onMapChange();
    },
    [client.state, client.tempState, onMapChange]
  );

  const handleMapDrag = useCallback(
    (info: PickingInfo_, event: MjolnirGestureEvent) => {
      if (!info.coordinate) return;
      if (!client.state || !client.tempState) return; // we haven't synced yet

      if (info.handle?.handleType !== 'shape') return;

      event.stopImmediatePropagation();
      handleMapHover(info, true);
    },
    [client, handleMapHover]
  );

  const handleMapDragEnd = useCallback(
    (info: PickingInfo_) => {
      if (!info.coordinate) return;
      if (!client.state || !client.tempState) return; // we haven't synced yet

      if (info.handle?.handleType !== 'shape') return;

      // transaction: end dragging & update final position
      {
        const me = client.tempState
          .getMap('clients')
          .getOrCreateContainer(CLIENT_ID, new crdtClient.LoroMap());
        me.delete('drag_shape_index');

        if (info.handle.shapeType === 'polygon') {
          me.delete('drag_vertex_index');
          const shape = client.state
            .getMovableList('shapes')
            .get(info.handle.shapeIndex);
          invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');
          const exterior = shape.get('exterior');
          invariant(
            exterior instanceof crdtClient.LoroMovableList,
            'invalid exterior'
          );
          exterior.set(
            info.handle.vertexIndex,
            positionLib.toString(info.coordinate)
          );
        } else if (info.handle.shapeType === 'rectangle') {
          me.delete('drag_corner');
          const [cornerH, cornerV] = rectangleLib.getAdjacentCorners(
            info.handle.corner
          );
          const shape = client.state
            .getMovableList('shapes')
            .get(info.handle.shapeIndex);
          invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');
          const exterior = shape.get('exterior');
          invariant(exterior instanceof crdtClient.LoroMap, 'invalid exterior');
          const vertexH = positionSchema.parse(exterior.get(cornerH));
          const vertexV = positionSchema.parse(exterior.get(cornerV));
          exterior.set(
            info.handle.corner,
            positionLib.toString(info.coordinate)
          );
          exterior.set(
            cornerH,
            positionLib.toString([vertexH[0], info.coordinate[1]])
          );
          exterior.set(
            cornerV,
            positionLib.toString([info.coordinate[0], vertexV[1]])
          );
        } else if (info.handle.shapeType === 'path') {
          me.delete('drag_vertex_index');
          const shape = client.state
            .getMovableList('shapes')
            .get(info.handle.shapeIndex);
          invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');
          const vertices = shape.get('vertices');
          invariant(
            vertices instanceof crdtClient.LoroMovableList,
            'invalid vertices'
          );
          vertices.set(
            info.handle.vertexIndex,
            positionLib.toString(info.coordinate)
          );
        } else if (info.handle.shapeType === 'boolean') {
          me.delete('drag_hole_index');
          me.delete('drag_vertex_index');

          const shape = client.state
            .getMovableList('shapes')
            .get(info.handle.shapeIndex);
          invariant(shape instanceof crdtClient.LoroMap, 'invalid shape');
          const holes = shape.get('holes');
          invariant(
            holes instanceof crdtClient.LoroMovableList,
            'invalid holes'
          );
          const hole = holes.get(info.handle.holeIndex);
          invariant(hole instanceof crdtClient.LoroMovableList, 'invalid hole');
          hole.set(
            info.handle.vertexIndex,
            positionLib.toString(info.coordinate)
          );
        }
      }

      onMapChange();
    },
    [client.state, client.tempState, onMapChange]
  );

  const handleSetMapType = useCallback(
    (mapType: MapType) => {
      if (!client.tempState) return;

      if (mapType === 'highres') {
        if (!isHighResEnabled) {
          handleMembershipClick({
            redirectTo: {
              path: `/mp/${jobId}`,
              label: text('navigateToRoute', {
                navigation: 'Back',
                routeName: text('map2D'),
              }),
              dispatch: {
                type: profileConstants.UPDATE_MAP_TYPE,
                payload: MAP_TYPE.HIGH_RES,
              },
            },
          });
          return;
        }
      }

      const clients = client.tempState.getMap('clients');
      const me = clients.get(CLIENT_ID);

      if (!me) return;
      invariant(me instanceof crdtClient.LoroMap, 'invalid me');

      const settings = me.get('user_settings');
      invariant(settings instanceof crdtClient.LoroMap, 'invalid settings');

      // transaction
      {
        settings.set('map_type', mapType);
      }

      console.log(mapType);
      console.log(settings.toJSON());

      onMapChange();
      dispatch(updateMapType(mapType, true));
    },
    [
      client.tempState,
      dispatch,
      handleMembershipClick,
      isHighResEnabled,
      jobId,
      onMapChange,
    ]
  );

  /**
   * TODO: handle load appropriate highRes layers if from bookmark
   * reference: mapView.js line 900 setting initial state based on the urlParams
   */
  const populateHighResLayers = useCallback(() => {
    if (!visGmMap) return;
    const { collections, currentDate } = highRes;
    if (collections.data) {
      const viewportBoundingBox = convertGMBoundsToBBox(visGmMap.getBounds());
      const collectionIds = fp.map(
        'id',
        getHighResCollectionsContainsBBox(collections.data, viewportBoundingBox)
      );
      if (!collectionIds || collectionIds.length === 0) {
        console.warn('No high res collection contains the current viewport');
        return;
      }
      dispatch(
        loadHighResLayersMp(collectionIds, viewportBoundingBox, currentDate)
      );
    }
  }, [dispatch, highRes, visGmMap]);

  // --- EFFECTS ---

  useEffect(() => {
    if (shouldResyncNow) {
      interpolator.reset();
    }
  }, [client, interpolator, shouldResyncNow]);

  // whenever the job's `place_geometry` changes, update the view state
  useEffect(() => {
    if (!job?.place_geometry) return;
    const coords = job.place_geometry.coordinates;
    setInitialViewState({
      longitude: coords[0],
      latitude: coords[1],
      zoom: GM_MAP_INITIAL_OPTIONS.defaultZoom,
    });
  }, [job?.place_geometry]);

  useEffect(() => {
    if (isErrorJob) {
      dispatch(alertError(`Error loading job: ${jobId}`));
    }
  }, [dispatch, isErrorJob, jobId]);

  useEffect(() => {
    client.setEventListener('sync', (state, tempState) => {
      jsonState.current = convertStateToJson(state, tempState);
      console.log(jsonState.current);
      draw();
      onShapesChange();
      onUserSettingsChange();
    });

    client.setEventListener('update', (changes, senderId, cursor) => {
      if (senderId) {
        interpolator.onUpdate(changes, senderId, cursor);
      } else {
        if (!client.state || !client.tempState) return; // we haven't synced yet
        if (changes.main) client.state.import(changes.main);
        if (changes.temp) client.tempState.import(changes.temp);
        draw();
        onShapesChange();
        onUserSettingsChange();
      }
    });

    client.setEventListener('cursors', (cursors) => {
      interpolator.onOtherCursorsMoved(
        es6MapMap(positionLib.fromString, cursors)
      );
    });

    client.setEventListener('deleteCursor', (clientId) => {
      console.log('cursor deleted', clientId);
      interpolator.deleteCursor(clientId);
      otherCursorsInterpolated.current.delete(clientId);
      _cursorLayer._setState({
        cursors: otherCursorsInterpolated.current,
      });
    });
  }, [
    client,
    interpolator,
    draw,
    _cursorLayer,
    onShapesChange,
    onUserSettingsChange,
  ]);

  useEffect(() => {
    draw();
    onShapesChange();
  }, [activeShapeIds, draw, onShapesChange]);

  useEffect(() => {
    if (!visGmMap) return () => {};
    const mapIdleListener = visGmMap.addListener('idle', async () => {
      populateHighResLayers();
      const date = await highResLib.getTileDate(visGmMap);
      setTileDate(date);
      const highResMapType = highResLib.loadHighResMapType(
        highResLib.generateAUSMapTileUrl('Australia_latest'),
        'High-res 2D Map'
      );
      visGmMap.mapTypes.set(MAP_TYPE.HIGH_RES, highResMapType);
    });
    return () => {
      if (mapIdleListener) google.maps.event.removeListener(mapIdleListener);
    };
  }, [populateHighResLayers, visGmMap]);

  useEffect(() => {
    if (!highRes.currentDate) return;
    const currentDate = highRes.currentDate;
    const currentLayer = highRes.layers.data?.[currentDate]?.[0];
    if (
      currentLayer &&
      !dateFns.isSameDay(new Date(tileDate), new Date(highRes.currentDate))
      // check dates to skip loadingHigResMapType and attaching to map.mapTypes if same date when loaded
    ) {
      const { layername } = currentLayer.properties;
      const highResMapType = highResLib.loadHighResMapType(
        highResLib.generateAUSMapTileUrl(layername),
        'High-res 2D Map'
      );
      if (visGmMap) {
        visGmMap.mapTypes.set(MAP_TYPE.HIGH_RES, highResMapType);
      }
      setTileDate(highRes.currentDate);
    }
  }, [highRes.currentDate, highRes.layers.data, tileDate, visGmMap]);

  // --- KEYPRESS ---
  // TODO: issue that key presses are being captured even if in an input

  useKeyPress(['0'], () => handleChangeMode('stop_creating'));
  useKeyPress(['1'], () => handleChangeMode('start_creating_polygon'));
  useKeyPress(['2'], () => handleChangeMode('start_creating_rectangle'));
  useKeyPress(['3'], () => handleChangeMode('start_creating_path'));
  useKeyPress(['4'], () => handleChangeMode('start_creating_boolean'));
  useKeyPress(['z'], undo);
  useKeyPress(['y'], redo);
  useKeyPress(['Escape'], () => deactivateAllShapes());

  // --- CART ---

  const cartRootRef = useRef<HTMLDivElement>(null);

  const [isProductModalExpanded, setIsProductModalExpanded] = useState(false);

  const isCartLoading = useMemo(
    () => connectionState.status === 'connecting' || isLoadingJob,
    [connectionState.status, isLoadingJob]
  );

  const cartItems = useMemo(
    () => shapeDetails?.filter((shape) => shape.dataType !== 'unknown') ?? [],
    [shapeDetails]
  );
  const isCartEmpty = useMemo(() => fp.isEmpty(cartItems), [cartItems]);
  const cartItemsAutomatic = useMemo(
    () => [...cartItems].filter((item) => isAutomaticDataType(item.dataType)),
    [cartItems]
  );
  const cartItemsManual = useMemo(
    () => [...cartItems].filter((item) => isManualDataType(item.dataType)),
    [cartItems]
  );
  const cartItemsAddOn = useMemo(
    () => [...cartItems].filter((item) => isAddOnDataType(item.dataType)),
    [cartItems]
  );

  const [selectedDataType, setSelectedDataType] = useState<
    PointCloudDataType | DerivedDataType | null
  >(null);

  const [quote, setQuote] = useState<Quote | null>(null);
  const [cartCoverage, setCartCoverage] = useState<CartCoverage | null>(null);
  const chargesByDataType = useMemo(
    () =>
      quote &&
      new Map(
        quote.charges.map((charge) => [
          charge.details?.category_name as DataType | DerivedDataType,
          charge,
        ])
      ),
    [quote]
  );

  const addSmartSelections = useCallback(
    (
      smartSelections: SmartSelection[],
      deliveryMethod: 'manual' | 'automatic'
    ) => {
      if (!client.state) return;
      let lastName: string | undefined = undefined;
      for (const smartSelection of smartSelections) {
        const {
          coverage: { product },
          selection,
        } = smartSelection;
        if (!product || product.delivery_method !== deliveryMethod) continue;
        let shape: MapShapeInput | null = null;
        if (selection.raw?.type === 'LineString') {
          shape = {
            type: 'path',
            dataType: product.category_name,
            vertices: selection.raw.coordinates,
          };
        } else if (selection.geometry.type === 'Polygon') {
          shape = {
            type: 'polygon',
            dataType: product.category_name,
            exterior: selection.geometry.coordinates[0].slice(0, -1),
            holes: selection.geometry.coordinates
              .slice(1)
              .map((hole) => hole.slice(0, -1)),
          };
        }
        if (shape) {
          const newId = createShape(shape, {
            inTransaction: true,
            name: lastName
              ? shapeNameLib.getNextShapeName([lastName])
              : undefined,
          });
          if (newId) {
            const newShape = getShapeById(newId);
            if (newShape) {
              const newName = newShape.get('name') as string | undefined;
              if (newName) lastName = newName;
            }
          }
        }
      }
      onMapChange();
    },
    [client.state, createShape, getShapeById, onMapChange]
  );

  useEffect(() => {
    quoteWorker.onmessage = (event: MessageEvent<QuoteWorkerResponse>) => {
      setQuote(event.data.quote);
      setCartCoverage(event.data.coverage);
    };
  }, [quoteWorker]);

  // open the cart
  useEffect(() => {
    dispatch(toggleRightMenuVisibility(true));
    return () => {
      dispatch(toggleRightMenuVisibility(false));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(loadHighResCollectionsMp());
  }, [dispatch]);

  return (
    <RealtimeContext.Provider
      value={{
        client,
        canUndo,
        undo,
        canRedo,
        redo,
        mode,
        handleChangeMode,
        shapeDetails,
        activeShape,
        deleteShape,
        deleteAllShapes,
        deleteAllShapesOfDataType,
        hideShape,
        hideAllShapes,
        hideAllShapesOfDataType,
        areAllShapesHidden,
        areAllShapesShown,
        activeShapeIds,
        activateShape,
        deactivateAllShapes,
        getDerivedShapes,
        changeDataType,
        editShapeName,
        availability,
        jobId,
        addSmartSelections,
        duplicateShape,
        onMapChange,
      }}
    >
      <CartContext.Provider
        value={{
          cartRootRef,
          isCartLoading,
          isCartEmpty,
          cartItems,
          cartItemsAutomatic,
          cartItemsManual,
          cartItemsAddOn,
          selectedDataType,
          setSelectedDataType,
          quote,
          chargesByDataType,
          cartCoverage,
          isProductModalExpanded,
          setIsProductModalExpanded,
        }}
      >
        <NavBar.Provider>
          <NavBar.Brand />
          <NavBar.ProjectSelect
            jobId={jobId}
            onJobChange={(next) => redirect.push(`/mp/${next.id}`)}
          />
          <NavBar.ViewModes jobId={jobId} />
          <NavBar.RealtimeUsers
            // TODO: in principle, we should not be using ref `jsonState` to render to the screen
            users={fp
              .values(jsonState.current?.clients)
              .map((client) => client.user_settings)}
            currentId={CLIENT_ID}
          />
          <NavBar.UserNav />
          <NavBar.CartButton />
        </NavBar.Provider>
        {/* @ts-expect-error Page props */}
        <Page
          id='RealtimeMap'
          className={classNames({
            // 'read-only': isReadOnly,
            'layers-panel-visible': layout.layersPanel.isVisible,
            'right-menu-visible': layout.rightMenu.isVisible,
          })}
          showTitlebar={false}
          title={text('map2D')}
          // title={text(
          //   state.currentMapTypeId === MAP_TYPE.HIGH_RES ? 'highResMap2D' : 'map2D'
          // )}
        >
          <div className='realtime-map__container'>
            <LayersPanel />
            <Cart />
            <DeckGL
              controller={CONTROLLER_OPTIONS}
              initialViewState={initialViewState}
              _onMetrics={updateMetrics}
              onClick={handleMapClick}
              onHover={(info) => handleMapHover(info, false)}
              onDragStart={handleMapDragStart}
              onDrag={handleMapDrag}
              onDragEnd={handleMapDragEnd}
              layers={[
                _shapeLayer,
                _tentativeShapeLayer,
                _coverageLayer,
                _cursorLayer,
                _deleteVertexLayer,
                _addVertexLayer,
              ]}
              // onViewStateChange={visGm.limitTiltRange}
              // viewState={viewState}
              // onViewStateChange={(params) => {
              //   setViewState(params.viewState as MapViewState);
              //   visGm.limitTiltRange(params);
              // }}
            >
              <visGm.Map
                id={MAP_ID}
                mapTypeId={myUserSettings?.map_type}
                defaultCenter={GM_MAP_INITIAL_OPTIONS.defaultCenter}
                {...GM_MAP_OPTIONS}
                {...GM_MAP_DISABLED_OPTIONS}
              >
                <visGm.MapControl position={visGm.ControlPosition.TOP_LEFT}>
                  {job?.full_address ? (
                    <GmPlaceAutocomplete
                      className='realtime-map__autocomplete'
                      onSelect={() => {}}
                      defaultValue={job?.full_address}
                    />
                  ) : null}
                </visGm.MapControl>
              </visGm.Map>
              <div className='debug-metrics'>
                <DebugMetrics metrics={metrics} />
              </div>

              <div className='map-type-container'>
                <MapTypeButton
                  caption='Map'
                  users={otherUserSettingsByMapType.get('roadmap')}
                  isSelected={myUserSettings?.map_type === 'roadmap'}
                  onClick={() => {
                    handleSetMapType('roadmap');
                  }}
                />
                <MapTypeButton
                  caption='Satellite'
                  isSelected={myUserSettings?.map_type === 'satellite'}
                  users={otherUserSettingsByMapType.get('satellite')}
                  onClick={() => {
                    handleSetMapType('satellite');
                  }}
                />
                <MapTypeButton
                  caption='High-res'
                  isSelected={myUserSettings?.map_type === 'highres'}
                  users={otherUserSettingsByMapType.get('highres')}
                  onClick={() => handleSetMapType('highres')}
                  classNames={classNames({
                    'high-res disabled': !isHighResEnabled,
                  })}
                  suffixIcon={
                    !isHighResEnabled ? (
                      <img
                        style={{
                          margin: '0 -5px 0 5px',
                          height: '18px',
                          width: '18px',
                        }}
                        className='icon-img'
                        src='/public/img/lock.svg'
                        alt='star'
                      />
                    ) : null
                  }
                />
              </div>

              <div
                id='metromap-controls-container'
                className={classNames('d-none', {
                  'd-flex flex-column align-items-center':
                    myUserSettings?.map_type === MAP_TYPE.HIGH_RES,
                })}
              >
                <HighResDateStepper isVisible={isHighResEnabled} />
                <MetromapLogoMp
                  isVisible={isHighResEnabled}
                  mapTypeId={myUserSettings?.map_type}
                />
              </div>

              {/* LEFT HAND SIDE */}
              <DrawingControls />
              <ProductControls />
            </DeckGL>
          </div>
        </Page>
      </CartContext.Provider>
      {/* TODO: remove ModalManager once surrounded with <App /> */}
      <ModalManager />
    </RealtimeContext.Provider>
  );
};

const Producer = ({
  jobId,
  initialPlace,
}: {
  jobId: number;
  initialPlace?: GeoJSON.Position | null;
}) => {
  return (
    <visGm.APIProvider apiKey={process.env.GCP_MAPS_API_KEY!}>
      <Consumer jobId={jobId} initialPlace={initialPlace} />
    </visGm.APIProvider>
  );
};

export default Producer;
