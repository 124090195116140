import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { MapShape, ShapeDetails } from '../../lib/shape';
import { useRealtimeContext } from '../../RealtimeContext';
import DropdownItemLabel from '../../../components/dropdown/DropdownItemLabel';
import text from '../../../text';
import Submenu from '../../../components/dropdown/Submenu';
import ProductMenu from '../ProductMenu';
import { MP_DELETE_SHAPES_MODAL } from '../../../components/modal/mp/DeleteShapesModal';
import { showModal } from '../../../redux/actions';
import { useDispatch } from 'react-redux';

const ShapeMenuContent = ({
  data,
  showShapeName = true,
}: {
  data: ShapeDetails;
  showShapeName?: boolean;
}) => {
  const dispatch = useDispatch();

  const { hideShape, deleteShape, changeDataType, getDerivedShapes } =
    useRealtimeContext();
  const isDraft = data.dataType === 'unknown';

  const handleClickDelete = useCallback(() => {
    dispatch(
      showModal(MP_DELETE_SHAPES_MODAL, {
        handleDelete: () => {
          deleteShape(data.id, ...getDerivedShapes(data.id).map((s) => s.id));
        },
        associatedAddOns: getDerivedShapes(data.id).map((shape) => ({
          display_name: shape.name,
        })),
        isMany: false,
      })
    );
  }, [data.id, deleteShape, dispatch, getDerivedShapes]);

  return (
    <>
      {showShapeName ? (
        <Dropdown.Header>
          <div className='shape-name'>
            <p className='font-weight-bold mb-0 font-16'>{data.name}</p>
          </div>
          {/* TODO: area and perimeter */}
        </Dropdown.Header>
      ) : null}
      <Dropdown.Item
        onClick={() => {
          hideShape(data.id, !data.hidden);
        }}
      >
        <DropdownItemLabel
          className=''
          label={data.hidden ? text('showShape') : text('hideShape')}
          icon={data.hidden ? 'eye' : 'eye-slash'}
        />
      </Dropdown.Item>
      <Submenu
        className='customPadded'
        label={text(isDraft ? 'addToCart' : 'changeTypeInCart')}
        menuIcon={isDraft ? 'add-on' : 'change-type'}
        // collapsed={shouldCollapsedProductSubmenu}
      >
        <ProductMenu shape={data} />
      </Submenu>
      {!isDraft ? (
        <Dropdown.Item
          onClick={() => {
            changeDataType(data.id, 'unknown');
          }}
        >
          <DropdownItemLabel
            className=''
            icon='unknown'
            label={text('removeFromCart')}
          />
        </Dropdown.Item>
      ) : null}
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleClickDelete}>
        <DropdownItemLabel
          label={text('deleteShape')}
          icon='trash'
          className='danger-button'
        />
      </Dropdown.Item>
    </>
  );
};

export default ShapeMenuContent;
