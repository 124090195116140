import T from 'prop-types';
import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import Icon from '../../../../../components/icon/Icon';
import UserAvatar from '../../../../profile/UserAvatar';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import { useClickOutside } from '../../../../../hooks/useClickOutside';

const RealTimeUsers = ({ userPool }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(6), flip(), shift()],
    whileElementsMounted: autoUpdate, // Automatically updates position when needed
    placement: 'bottom-end',
  });

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  useClickOutside(
    refs.reference,
    () => setIsOpen(false),
    ['.realtime-users-dropdown-container'],
    false
  );

  return (
    <div className='RealTimeUsers d-flex flex-row align-items-center'>
      {userPool.VISIBLE.length > 0 ? (
        <div
          className='dropdown-trigger d-flex flex-row px-2 mr-2'
          ref={refs.setReference}
          onClick={toggleDropdown}
        >
          <span className='font-12'>+{userPool.IN_DROPDOWN.length} Users</span>
          <Icon icon='solid-caret-down' />
        </div>
      ) : null}

      {isOpen ? (
        <FloatingPortal>
          <div
            className='realtime-users-dropdown-container'
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
            }}
          >
            <div
              className='realtime-users-wrapper px-0'
            >
              {userPool.IN_DROPDOWN.map((player) => (
                <div className='realtime-user' key={player.id}>
                  <UserAvatar
                    profileImage={null}
                    hasBorder={true}
                    borderStyle={`3px solid ${player.hex}`}
                  />
                  <Badge
                    className='free text-truncate'
                    style={{
                      background: player.hex,
                      color: player.textColor,
                    }}
                  >
                    {player.name}
                  </Badge>
                </div>
              ))}
            </div>
          </div>
        </FloatingPortal>
      ) : null}

      <div className='realtime-users-wrapper px-0'>
        {userPool.VISIBLE.map((player) => (
          <div className='realtime-user' key={player.id}>
            <UserAvatar
              profileImage={null}
              hasBorder={true}
              borderStyle={`3px solid ${player.hex}`}
            />
            <Badge
              className='free text-truncate'
              style={{
                background: player.hex,
                color: player.textColor,
              }}
            >
              {player.name}
            </Badge>
          </div>
        ))}
      </div>
    </div>
  );
};

RealTimeUsers.propTypes = {
  userPool: T.shape({
    VISIBLE: T.array,
    IN_DROPDOWN: T.array,
  }),
};

export default RealTimeUsers;
