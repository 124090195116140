import * as socketIoClient from 'socket.io-client';
import {
  AckCallback,
  bufferToUint8Array,
  HandshakeQuery,
  isBuffer,
  UpdateRequest,
  UpdateResponse,
} from './lib/communication';
import {
  CursorsMessage,
  ERROR_RESPONSE_SCHEMA,
  ErrorResponse,
  MoveCursor,
} from './protocol';
import { LoroDoc, UndoManager, VersionVector } from 'loro-crdt';
import invariant from 'tiny-invariant';

export * from 'loro-crdt';

type ClientEventListeners = {
  connect?: () => void;
  connectError?: (err: Error) => void;
  disconnect?: () => void;
  error?: (err: ErrorResponse) => void;
  sync?: (state: LoroDoc, tempState: LoroDoc) => void;
  update?: (
    bytes: { main?: Uint8Array; temp?: Uint8Array },
    senderId?: string,
    cursor?: string
  ) => void;
  /**
   * `cursors` is for other users only
   */
  cursors?: (cursors: Map<string, string>) => void;
  deleteCursor?: (clientId: string) => void;
};

type ClientOpts = {
  query: HandshakeQuery;
  url: string;
  autoOn: boolean;
  eventListeners?: ClientEventListeners;
};

export class Client {
  id: string;
  io: socketIoClient.Socket;
  lastMainVersion?: VersionVector;
  lastTempVersion?: VersionVector;
  state: LoroDoc | null = null;
  tempState: LoroDoc | null = null;
  undo?: UndoManager;
  eventListeners: ClientEventListeners;

  constructor(opts: ClientOpts) {
    this.id = opts.query.clientId;
    this.io = socketIoClient.io(opts.url, {
      transports: ['websocket'],
      path: '/ws',
      upgrade: false,
      secure: true,
      query: opts.query,
    });
    if (opts.autoOn) {
      this.on();
    }
    this.eventListeners = opts.eventListeners ?? {};
  }

  on() {
    this.io.on('connect', () => this.eventListeners?.connect?.());
    this.io.on('connect_error', (err) =>
      this.eventListeners?.connectError?.(err)
    );
    this.io.on('disconnect', () => this.eventListeners?.disconnect?.());
    this.io.on('error', (err_) => {
      const err = ERROR_RESPONSE_SCHEMA.parse(err_);
      console.error('from server:', err.message);
      this.eventListeners?.error?.(err);
    });
    this.io.on('sync', ({ main, temp }, cb: AckCallback) => {
      invariant(isBuffer(main), '[sync] `bytes` must be a buffer');
      main = bufferToUint8Array(main);
      invariant(isBuffer(temp), '[sync] `temp` must be a buffer');
      temp = bufferToUint8Array(temp);
      this.state = LoroDoc.fromSnapshot(main);
      this.tempState = LoroDoc.fromSnapshot(temp);
      // TODO: should we set versions here?
      this.undo = new UndoManager(this.state, {
        mergeInterval: 0,
      });
      cb({ status: 'success' });
      this.eventListeners?.sync?.(this.state, this.tempState);
    });
    this.io.on('update', ({ bytes, senderId, cursor }: UpdateResponse) => {
      if (!this.state) {
        console.warn(`[update] tried to handle update before sync`);
        return;
      }
      const main = bytes.main ? bufferToUint8Array(bytes.main) : undefined;
      const temp = bytes.temp ? bufferToUint8Array(bytes.temp) : undefined;
      this.eventListeners?.update?.({ main, temp }, senderId, cursor);
    });
    this.io.on('cursors', (message: CursorsMessage) => {
      const cursors = new Map(Object.entries(message.cursors));
      cursors.delete(this.id);
      if (cursors.size > 0) this.eventListeners?.cursors?.(cursors);
    });
    this.io.on('delete_cursor', (clientId: string) => {
      this.eventListeners?.deleteCursor?.(clientId);
    });
  }

  off() {
    this.io.off('connect');
    this.io.off('connect_error');
    this.io.off('disconnect');
    this.io.off('error');
    this.io.off('sync');
    this.io.off('update');
  }

  signalUpdate(opts: { instant?: boolean } = {}) {
    const instant = opts.instant ?? false;

    if (!this.state || !this.tempState) {
      // we haven't synced yet
      console.warn('[updateState] tried to update state before sync');
      return;
    }
    const main = this.state.export({
      mode: 'update',
      from: this.lastMainVersion,
    });
    const temp = this.tempState.export({
      mode: 'update',
      from: this.lastTempVersion,
    });
    this.lastMainVersion = this.state.version();
    this.lastTempVersion = this.tempState.version();
    this.io.emit('update', { main, temp, instant } satisfies UpdateRequest);
  }

  signalCursorMove(position: string) {
    this.io.emit('move_cursor', { position } satisfies MoveCursor);
  }

  setEventListener<K extends keyof ClientEventListeners>(
    event: K,
    listener: ClientEventListeners[K]
  ) {
    this.eventListeners[event] = listener;
  }
}
