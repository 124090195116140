import { useMemo } from 'react';
import { useQuery } from '../../hooks/useQuery';

function useQueryParam(
  name: string,
  opts?: { parse?: undefined }
): string | null;
function useQueryParam<T>(
  name: string,
  opts?: { parse?: (value: string) => T }
): T | null;
function useQueryParam<T>(
  name: string,
  opts?: { parse?: (value: string) => T }
): string | T | null {
  const query = useQuery();
  const value = useMemo(() => {
    const value_ = query.get(name);
    if (!value_) return null;
    return opts?.parse ? opts.parse(value_) : value_;
  }, [query, name, opts]);
  return value;
}

export default useQueryParam;
