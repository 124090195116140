import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import rootReducer from '../redux/reducers';
import { rtkApi } from '../redux/rtk';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const m = getDefaultMiddleware({
      // TODO:
      /**
       * Temporarily set to false to avoid non-serializable error on console
       * need to refactor and resolve issues on redux actions payload that contains callback functions
       *
       * Ticket ref: https://larki.atlassian.net/browse/LP-2686
       */
      serializableCheck: false,
      immutableCheck: false,
    }).concat(rtkApi.middleware);
    if (process.env.ENABLE_REDUX_LOG) {
      m.push(logger);
    }
    return m;
  },
  devTools: !!process.env.ENABLE_REDUX_DEVTOOLS,
});
