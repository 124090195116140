import { useMemo } from 'react';
import fp from 'lodash/fp';
import { useGetUserProfileQuery } from '../../redux/rtk';
import { useSelector } from 'react-redux';
import { profileSelectors } from '../../redux/selectors/profile';
import * as userUtils from '../../utilities/user';
import { USER_TYPE } from '../../constants/user';
import { ENTITLEMENTS } from '../../constants/membership';

/**
 * Hook that provides user profile and related data.
 *
 * Relies upon `useGetUserProfileQuery` hook that automatically caches its data,
 *  so you can use `useUser` as many times as you want without worrying about making too many requests.
 *
 * Example usage:
 * ```
 * const currentUser = useUser();
 *
 * return (
 *   <div>
 *     {currentUser.isAdmin && <div>Admin</div>}
 *     {currentUser.profile?.full_name}
 *   </div>
 * );
 * ```
 */
const useUser = () => {
  const { data: userProfile, isLoading, isSuccess } = useGetUserProfileQuery();
  const pretendType: string | null = useSelector(
    profileSelectors.getPretendType
  );
  const actualType = useMemo(
    () =>
      userProfile
        ? userUtils.isUserStaff(userProfile)
          ? USER_TYPE.LARKI_SUPPORT
          : userUtils.isUserGovernment(userProfile)
            ? USER_TYPE.GOVERNMENT
            : userUtils.userHasValidMembership(userProfile)
              ? userUtils.isUserUnderTrial(userProfile)
                ? USER_TYPE.TRIAL
                : USER_TYPE.ESSENTIALS
              : USER_TYPE.FREE
        : null,
    [userProfile]
  );
  const isAdmin = useMemo(
    () =>
      userProfile
        ? userUtils.isAdministrator(userProfile.role) &&
          (!pretendType || pretendType === USER_TYPE.LARKI_SUPPORT)
        : false,
    [pretendType, userProfile]
  );
  const activeMemberships = useMemo(
    () =>
      userProfile?.customer?.memberships.filter(
        (membership) => membership.status === 'active'
      ) ?? [],
    [userProfile]
  );
  const activeMembership = useMemo(
    () => activeMemberships?.[0],
    [activeMemberships]
  );
  const hasValidMembership = useMemo(
    () => activeMemberships?.length > 0,
    [activeMemberships]
  );
  const entitlements = useMemo(
    () => fp.uniq(activeMemberships.flatMap((m) => m.entitlements)),
    [activeMemberships]
  );
  const hasHighResEntitlement = useMemo(
    () => entitlements.includes(ENTITLEMENTS.HIGH_RES_MAP),
    [entitlements]
  );
  const isHighResEnabled = useMemo(
    () =>
      pretendType
        ? [
            USER_TYPE.TRIAL,
            USER_TYPE.ESSENTIALS,
            USER_TYPE.GOVERNMENT,
            USER_TYPE.LARKI_SUPPORT,
          ].includes(pretendType)
        : hasHighResEntitlement ||
          (userProfile
            ? [
                'superadministrator',
                'administrator',
                'staff',
                'government',
              ].includes(userProfile.role)
            : false),
    [hasHighResEntitlement, pretendType, userProfile]
  );
  const has3dViewerEntitlement = useMemo(
    () => entitlements.includes(ENTITLEMENTS._3D_VIEWER),
    [entitlements]
  );
  const is3dViewerEnabled = useMemo(
    () =>
      pretendType
        ? [
            USER_TYPE.TRIAL,
            USER_TYPE.ESSENTIALS,
            USER_TYPE.GOVERNMENT,
            USER_TYPE.LARKI_SUPPORT,
          ].includes(pretendType)
        : has3dViewerEntitlement ||
          (userProfile
            ? [
                'superadministrator',
                'administrator',
                'staff',
                'government',
              ].includes(userProfile.role)
            : false),
    [has3dViewerEntitlement, pretendType, userProfile]
  );
  const canDeleteAnyProject = useMemo(
    () =>
      pretendType
        ? [USER_TYPE.LARKI_SUPPORT].includes(pretendType)
        : userProfile
          ? userUtils.isAdministrator(userProfile.role)
          : false,
    [pretendType, userProfile]
  );
  const isStaff = useMemo(
    () =>
      userProfile
        ? userUtils.isStaff(userProfile.role) &&
          (!pretendType || pretendType === USER_TYPE.LARKI_SUPPORT)
        : false,
    [pretendType, userProfile]
  );
  const canUserStartTrial = useMemo(
    () =>
      userProfile
        ? !userUtils.isUserGovernment(userProfile) &&
          !userUtils.isUserUnderTrial(userProfile) &&
          !userUtils.userHasValidMembership(userProfile) &&
          !userProfile.has_used_trial &&
          (!userUtils.isStaff(userProfile.role) ||
            (pretendType ? [USER_TYPE.FREE].includes(pretendType) : false))
        : false,
    [pretendType, userProfile]
  );
  const isUserUnderTrial = useMemo(
    () =>
      userProfile
        ? (userUtils.isUserUnderTrial(userProfile) as boolean) &&
          (!userUtils.isStaff(userProfile.role) ||
            (pretendType ? [USER_TYPE.FREE].includes(pretendType) : false))
        : false,
    [pretendType, userProfile]
  );
  const canUserSeeTrialExpired = useMemo(
    () =>
      userProfile
        ? !userUtils.isStaff(userProfile.role) ||
          (pretendType ? pretendType === USER_TYPE.FREE : false)
        : false,
    [pretendType, userProfile]
  );
  const canUserUpgrade = useMemo(
    () =>
      userProfile
        ? !userUtils.isUserGovernment(userProfile) &&
          !(userUtils.userHasActiveMembership(userProfile) as boolean) &&
          (userProfile.has_used_trial ||
            userUtils.isUserUnderTrial(userProfile)) &&
          (!userUtils.isStaff(userProfile.role) ||
            (pretendType ? [USER_TYPE.FREE].includes(pretendType) : false))
        : false,
    [pretendType, userProfile]
  );
  const isUpgradeRecommended = useMemo(
    () =>
      userProfile && canUserUpgrade
        ? isUserUnderTrial || userProfile.has_used_trial
        : false,
    [canUserUpgrade, isUserUnderTrial, userProfile]
  );
  return useMemo(
    () => ({
      isLoading,
      isSuccess,
      profile: userProfile,
      isAdmin,
      actualType,
      pretendType,
      is3dViewerEnabled,
      hasValidMembership,
      isUserUnderTrial,
    }),
    [
      actualType,
      isAdmin,
      is3dViewerEnabled,
      isLoading,
      isSuccess,
      pretendType,
      userProfile,
      hasValidMembership,
      isUserUnderTrial,
    ]
  );
};

export default useUser;
