import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import {
  Card,
  Button as BSButton,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import {
  DataType,
  DerivedDataType,
  isAddOnDataType,
  PointCloudDataType,
} from '../../../../lib/dataType';
import { useRealtimeContext } from '../../../../RealtimeContext';
import { useCartContext } from '../../../../CartContext';
import Icon from '../../../../../components/icon/Icon';
import text, { formatCurrency } from '../../../../../text';
import { IconWrapper } from '../../../../../components/RightMenu/Product';
import { getAddOnConfig } from '../../../../../components/mapView/drawingManager/utilities';
import useUser from '../../../../hooks/useUser';
import ProductModal from './ProductModal';
import ProductMenu from './ProductModal';
import { ShapeDetails } from '../../../../lib/shape';

const ProductRow = ({
  dataType,
  shapes,
}: {
  dataType: PointCloudDataType | DerivedDataType;
  shapes: ShapeDetails[];
}) => {
  const currentUser = useUser();
  const cardRef = useRef<HTMLDivElement>(null);

  const { hideAllShapesOfDataType, deleteAllShapesOfDataType } =
    useRealtimeContext();

  const {
    selectedDataType,
    setSelectedDataType,
    quote,
    chargesByDataType,
    isProductModalExpanded,
    setIsProductModalExpanded,
  } = useCartContext();

  const isExpanded = isProductModalExpanded && selectedDataType === dataType;
  const activeClass = useMemo(
    () => (selectedDataType === dataType ? `active-${dataType}` : ''),
    [dataType, selectedDataType]
  );

  const isSomeShapesHidden = useMemo(
    () => shapes.some((shape) => shape.hidden),
    [shapes]
  );
  const isAllShapesHidden = useMemo(
    () => shapes.every((shape) => shape.hidden),
    [shapes]
  );
  const isAllShapesVisible = useMemo(
    () => shapes.every((shape) => !shape.hidden),
    [shapes]
  );
  const eyeIcon = useMemo(
    () =>
      isAllShapesVisible
        ? 'eye'
        : isAllShapesHidden
          ? 'eye-slash'
          : 'eye-mixed-state',
    [isAllShapesVisible, isAllShapesHidden]
  );

  const charge = useMemo(() => {
    return chargesByDataType?.get(dataType);
  }, [chargesByDataType, dataType]);

  const price = useMemo(() => {
    if (!charge || !charge.price) {
      return text('notAvailable');
    }
    if (!charge.price.total) {
      return text('toBeConfirmed');
    }

    const price =
      currentUser.hasValidMembership || currentUser.isUserUnderTrial
        ? charge.price.member_total
        : charge.price.total;

    return formatCurrency(
      price,
      quote?.currency.name,
      quote?.currency.scale_factor,
      {},
      true
    );
  }, [
    charge,
    currentUser.hasValidMembership,
    currentUser.isUserUnderTrial,
    quote?.currency.name,
    quote?.currency.scale_factor,
  ]);

  const handleClick = useCallback(() => {
    setSelectedDataType(dataType);
    setIsProductModalExpanded(true);
  }, [dataType, setSelectedDataType, setIsProductModalExpanded]);

  const getProductSubtitle = () => {
    if (isAddOnDataType(dataType)) {
      return dataType.match(/\b(bim_\w+)/)
        ? 'Building Information Model'
        : '2D Feature Plan';
    }
    return text('pointCloud3D');
  };

  return (
    <tr>
      <td className='p-0 pb-2'>
        <ProductMenu dataType={dataType}>
          <Card
            className={classNames({
              [activeClass]: !!activeClass,
            })}
            onClick={handleClick}
            ref={cardRef}
          >
            <Card.Body className='product-row-container'>
              <div className='product-row d-flex flex-row align-items-center flex-fill'>
                <BSButton
                  className='row-action icon-button'
                  variant='light'
                  onClick={(ev) => {
                    hideAllShapesOfDataType(dataType, isAllShapesVisible);
                    ev.stopPropagation();
                  }}
                  type='button'
                >
                  <Icon
                    className={classNames({
                      original: isSomeShapesHidden,
                    })}
                    icon={eyeIcon}
                  />
                </BSButton>

                <div className='w-100 overflow-hidden'>
                  <div className='d-flex flex-row align-items-center'>
                    <div
                      className={classNames(`product-icon-container`, {
                        [activeClass]: !!activeClass,
                      })}
                    >
                      <IconWrapper icon={dataType} isActive={activeClass} />
                    </div>

                    <div className='product-info w-50 ml-2 d-flex flex-column'>
                      <p className='mb-0 font-12 text-truncate'>
                        {text(`${dataType}`)}
                      </p>
                      <p className='mb-0 font-12 font-weight-normal text-truncate'>
                        {getProductSubtitle()}
                      </p>
                    </div>
                    <div className='text-right product-info w-50 font-12 font-weight-normal'>
                      {price}
                    </div>
                  </div>
                </div>
                <div>
                  <BSButton
                    className='row-action icon-button'
                    variant='light'
                    onClick={() => deleteAllShapesOfDataType(dataType)}
                  >
                    <Icon icon='trash' />
                  </BSButton>
                </div>
              </div>
            </Card.Body>
          </Card>
        </ProductMenu>

        {/* {isExpanded ? <ProductModal ref={cardRef} dataType={dataType} /> : null} */}
      </td>
    </tr>
  );
};

export default ProductRow;
