export const mpConstants = {
  LOAD_HIGH_RES_COLLECTIONS: 'LOAD_HIGH_RES_COLLECTIONS',
  LOAD_HIGH_RES_COLLECTIONS_SUCCESS: 'LOAD_HIGH_RES_COLLECTIONS_SUCCESS',
  LOAD_HIGH_RES_COLLECTIONS_FAILURE: 'LOAD_HIGH_RES_COLLECTIONS_FAILURE',
  LOAD_HIGH_RES_LAYERS: 'LOAD_HIGH_RES_LAYERS',
  LOAD_HIGH_RES_LAYERS_SUCCESS: 'LOAD_HIGH_RES_LAYERS_SUCCESS',
  LOAD_HIGH_RES_LAYERS_FAILURE: 'LOAD_HIGH_RES_LAYERS_FAILURE',
  SET_HIGH_RES_CURRENT_DATE: 'SET_HIGH_RES_CURRENT_DATE',
  NEXT_HIGH_RES_LAYER: 'NEXT_HIGH_RES_LAYER',
  PREV_HIGH_RES_LAYER: 'PREV_HIGH_RES_LAYER',
};
