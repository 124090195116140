import type * as GeoJSON from 'geojson';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { createApi } from '@reduxjs/toolkit/query/react';

import { larkiApi } from '../../utilities/api';
import { processJobList } from '../actions';
import { PointCloudDataType } from '../../mp/lib/dataType';

type ErrorResponse = { status?: number; data: any };

function larkiApiBaseQuery<T>(): BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  T,
  ErrorResponse
> {
  return async ({ url, method, data, params, headers }) => {
    try {
      // TODO: interceptors don't work here
      const result = await larkiApi({
        url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (err) {
      if (err instanceof AxiosError) {
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
      return {
        error: {
          data: err,
        },
      };
    }
  };
}

export const RTK_API_REDUCER_PATH = 'rtk';

export const rtkApi = createApi({
  reducerPath: RTK_API_REDUCER_PATH,
  baseQuery: larkiApiBaseQuery(),
  tagTypes: ['Project'],
  endpoints: (build) => ({
    /**
     * Don't use directly, use `useUser` instead.
     */
    getUserProfile: build.query<
      {
        profile_image?: string;
        has_used_trial: boolean;
        role: string;
        customer: {
          memberships: { status: string; entitlements: string[] }[];
        };
      },
      void
    >({
      query: () => ({ url: 'user/profile' }),
      // TODO: use user ID to query to invalidate cache on change user
    }),

    /**
     * Get projects associated with the current user.
     *
     * `getUserProjects` should have to wait until the user has been fetched
     *  so the `isAdmin` value is up to date.
     *
     * Usage:
     * ```
     * const currentUser = ();
     * const {...} = useGetUserProjectsQuery({skip: !currentUser.isSuccess})
     * ```
     */
    getUserProjects: build.query<
      {
        jobToProjectId: Record<number, number>;
        projects: Record<
          number,
          {
            id: number;
            project: { id: number; name?: string | null };
            jobs: { id: number; lga: boolean }[];
          }
        >;
      },
      { isAdmin: boolean }
    >({
      query: ({ isAdmin }) => ({
        url: 'project',
        params: {
          isAdmin,
          includeDemo: false,
          inviteStatus: ['accepted'],
        },
      }),
      providesTags: ['Project'],
      transformResponse: (data: any) => {
        return {
          jobToProjectId: data.projects?.reduce((acc: any, project: any) => {
            if (project.jobs.length === 0) {
              return acc;
            }
            for (const job of project.jobs) {
              acc[job.id] = project.id;
            }
            return acc;
          }, {}),
          projects: processJobList(data),
          counts: data.statusCounts,
        };
      },
    }),

    /**
     * Create an new job.
     */
    createJob: build.mutation<
      { id: number },
      {
        address?: string | null;
        city?: string | null;
        state?: string | null;
        postcode?: string | null;
        country?: string | null;
        message?: string | null;
        customer_software?: string | null;
        point_cloud_format?: string | null;
        projectName: string | null;
        mergeProjectId?: number;
        place_geometry?: GeoJSON.Point | null;
      }
    >({
      query: (data) => ({
        url: 'job',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Project'],
    }),

    /**
     * Get a job by ID.
     */
    getJob: build.query<
      {
        id: number;
        full_address: string | null;
        city: string | null;
        state: string | null;
        postcode: string | null;
        place_geometry: GeoJSON.Point | null;
        project: { id: number; name: string | null };
      },
      number
    >({
      query: (id) => ({ url: `job/${id}` }),
      providesTags: ['Project'],
    }),

    /**
     * Get smart selections for a job.
     */
    createSmartSelections: build.mutation<
      {
        property_vicinity: SmartSelection[];
        neighbourhood: SmartSelection;
        buildings: SmartSelection[];
        roads: SmartSelection[];
      },
      {
        location: { lat: number; lng: number };
        address?: string | null;
        city?: string | null;
        state?: string | null;
        postcode?: string | null;
      }
    >({
      query: (data) => ({
        url: 'engine/product/selections',
        method: 'POST',
        data,
      }),
    }),
  }),
});

// todo: move this out
export type SmartSelection = {
  coverage: {
    product?: {
      product_id: number;
      category_name: PointCloudDataType;
      display_name: string;
      delivery_method: string;
    };
  };
  selection: {
    geometry: GeoJSON.Polygon;
    raw?: GeoJSON.Polygon | GeoJSON.LineString;
  };
};

export const {
  useGetUserProfileQuery,
  useGetUserProjectsQuery,
  useCreateJobMutation,
  useGetJobQuery,
  useCreateSmartSelectionsMutation,
} = rtkApi;
