import cn from 'classnames';
import T from 'prop-types';
import React, { useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMount } from 'react-use';

import NavProjectSelect from '../../../../../components/select/jobSelect/NavProjectSelect';
import useProjectsList from '../../../../../hooks/useProjectsList';
import { useNew3dBookmark } from '../../../../../hooks/useShareLinkParams';
import {
  getProjectById,
  getUserProfile,
  setCurrentProject,
} from '../../../../../redux/actions';
import { alertConstants } from '../../../../../redux/constants';
import { profileSelectors } from '../../../../../redux/selectors/profile';
import routes from '../../../../../routes/constants';
import text from '../../../../../text';
import { jobHasPointCloud } from '../../../../../utilities/job';
import { isDemoProject } from '../../../../../utilities/project';
import ShareLinkNav from './ShareLinkNav';

const Viewer3DNav = ({ className, showShareLink }) => {
  const isAdmin = useSelector(profileSelectors.getIsAdmin);
  const { userProfileState } = useSelector((state) => state.profileReducer);
  const is3dViewerAccessibleToUser = useSelector(
    profileSelectors.get3dViewerEnabled
  );
  const { currentProject } = useSelector((state) => ({
    currentProject: state.project.currentProject,
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const new3dBookmark = useNew3dBookmark();

  const { projects, isLoading } = useProjectsList({
    isAdmin,
    filterPredicate: (project) =>
      (is3dViewerAccessibleToUser || isDemoProject(project)) &&
      jobHasPointCloud(project),
  });

  useMount(() => {
    dispatch(getUserProfile());
  });

  useEffect(() => {
    if (projectId) {
      dispatch(
        getProjectById(projectId, {
          includeJobs: true,
          includePointCloud: true,
        })
      );
    }

    // if current project is a demo, reset the current project state so returning to the file manager
    return () => {
      if (currentProject?.id && isDemoProject(currentProject))
        dispatch(setCurrentProject(null));
    };
  }, [projectId]);

  return (
    <Nav className={cn('Title', className)}>
      <Navbar>
        <span>{`${text(
          isAdmin ? 'administrationViewer3D' : 'viewer3D'
        )}:`}</span>
        {userProfileState !== 'loading' && (
          <NavProjectSelect
            projects={projects}
            isLoading={isLoading}
            onProjectChange={(nextProject, prevProject) => {
              if (!prevProject || nextProject.id !== prevProject.id) {
                history.replace(routes.view3D.project(nextProject.id));
                dispatch({
                  type: alertConstants.ALERT_RESET,
                });
              }
            }}
          />
        )}
        <ShareLinkNav show={showShareLink} new3dBookmark={new3dBookmark} />
      </Navbar>
    </Nav>
  );
};

Viewer3DNav.propTypes = {
  className: T.string,
};

export default Viewer3DNav;
