import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useWizard } from 'react-use-wizard';

import classNames from 'classnames';
import { AMPLITUDE_EVENTS } from '../../../../constants/analytics';
import { MEMBERSHIP } from '../../../../constants/membership';
import { subscribe } from '../../../../redux/actions';
import text from '../../../../text';
import { amplitudeTrack } from '../../../../utilities';
import Button from '../../../button/Button';
import Icon from '../../../icon/Icon';
import Lottie from '../../../lottie/Lottie';
import { MAP_TYPE } from '../../../../components/mapView/constants';
import { profileConstants } from '../../../../redux/constants';

const FEATURES = [
  {
    icon: '2d-map',
    text: text('trialFeature2D'),
  },
  {
    icon: 'file',
    text: text('trialFeatureFileManager'),
  },
  {
    icon: '3D',
    text: text('trialFeature3D'),
  },
];

const DATA_FEATURES = [
  {
    icon: 'streetscape',
    text: text('trialFeature2D'),
    iconClassName: 'streetscape enabled',
  },
  {
    icon: 'aerial',
    text: text('trialFeature3D'),
    iconClassName: 'aerial enabled',
  },
];

const { ACCEPTED_INTRO_OFFER } = AMPLITUDE_EVENTS;

const StartTrialStep = ({ onCancel, isHighResClicked }) => {
  const { nextStep } = useWizard();
  const dispatch = useDispatch();
  const { currentUser, upsertMembership } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
    upsertMembership: state.profileReducer.upsertMembership,
  }));

  const handleClick = async () => {
    dispatch(
      subscribe({
        user: currentUser,
        priceId: MEMBERSHIP.MONTHLY.PRICE_ID,
        isTrial: true,
      })
    );
    if (isHighResClicked) {
      dispatch({
        type: profileConstants.UPDATE_MAP_TYPE,
        payload: MAP_TYPE.HIGH_RES,
      });
    }
    nextStep();
  };

  const trackCloseTrial = () => {
    amplitudeTrack(ACCEPTED_INTRO_OFFER.EVENT, {
      action: ACCEPTED_INTRO_OFFER.CLOSE_TRIAL,
    });
  };

  return (
    <>
      <Modal.Header
        className='center-content flex-column'
        onHide={() => trackCloseTrial()}
        close
        closeButton
      >
        <Lottie lottie='free-gift' />
        <h3>{text('freeTrialPlatform')}</h3>
      </Modal.Header>
      <Modal.Body>
        <h5 className='mt-2 center-content'>
          {text('measureCollaboratePlatform')}:
        </h5>
        <ul className='p-0'>
          {FEATURES.map((item, i) => {
            return (
              <li key={i} className='m-2 center-content'>
                <Icon
                  className='mr-3 framed outline-primary'
                  icon={item.icon}
                />
                <h6>{item.text}</h6>
              </li>
            );
          })}
        </ul>

        <h5 className='mt-4 center-content'>
          {text('getFreeDataDiscount', {
            amount: '49.50',
          })}
          :
        </h5>
        <ul className='p-0'>
          {DATA_FEATURES.map((item, i) => {
            return (
              <li key={i} className='m-2 center-content'>
                <Icon
                  className={classNames('mr-3 framed outline-primary', {
                    [item.iconClassName]: item.iconClassName,
                  })}
                  icon={item.icon}
                />
                <h6>{item.text}</h6>
              </li>
            );
          })}
        </ul>
      </Modal.Body>
      <Modal.Footer className='d-flex flex-column centered'>
        <Button
          className='full-width'
          loading={upsertMembership.isLoading}
          onClick={handleClick}
          autoFocus
        >
          {text('startTrial')}
        </Button>
        <Button
          variant='link'
          className='mt-2'
          onClick={() => {
            trackCloseTrial();
            onCancel();
          }}
          disabled={upsertMembership.isLoading}
        >
          {text('maybeLater')}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default StartTrialStep;
