import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import * as yup from 'yup';

import FormFooter from '../../../components/form/FormikFormFooter';
import { softwares } from '../../../components/mapView/constants';
import { FORCE_JOB_UPDATE_MODAL } from '../../../components/modal/forceJobUpdateModal';
import Step from '../../../components/wizard/Step';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { getJob, showModal, updateJob } from '../../../redux/actions';
import text from '../../../text';
import { client } from '../../../utilities/api';
import { getNowDate } from '../../../utilities/date';
import { isJobPayable } from '../../../utilities/job';
import { isMembershipPriceId } from '../../../utilities/membership';
import { getProjectName } from '../../../utilities/project';
import { isStaff } from '../../../utilities/user';
import ProjectDetailsForm from '../ProjectDetailsForm/Form';

const validationSchema = yup.object({
  customer_software: yup
    .string()
    .oneOf([
      'autodesk_revit',
      'autodesk_autocad',
      'graphisoft_archicad',
      'bentley',
      'vectorworks',
      'rhinoceros_rhino',
      'sketchup_scan_plugin',
    ])
    .required(text('required')),
  message: yup.string().notRequired(),
  projectId: yup.number(),
  projectName: yup
    .string()
    .required(text('pleaseSelectOrCreateProject'))
    .typeError(text('pleaseTypeProjectName')),
});

const ProjectDetailsStep = () => {
  const isBreakpointEnabled = useBreakpoint(['xs', 'sm']);
  const { jobId } = useParams();
  const { job, currentUser, membershipPriceId } = useSelector((state) => ({
    job: state.jobsReducer.job,
    membershipPriceId: state.order.membershipPriceId,
    currentUser: state.profileReducer.userProfile,
  }));
  const dispatch = useDispatch();
  const { nextStep } = useWizard();

  const onUpdateJob = async (values) => {
    const { customer_software, message, force, projectId } = values;
    const updatedJob = await updateJob({
      customer_software,
      point_cloud_format: softwares.entities[customer_software].format,
      mergeProjectId: projectId,
      message,
      jobId,
      updatedAt: getNowDate(),
      force,
      onConflict: () => {
        onShowForceUpdateModal(values);
      },
    })(dispatch);

    if (updatedJob) {
      nextStep();
    }
  };

  const onShowForceUpdateModal = (values) => {
    dispatch(
      showModal(FORCE_JOB_UPDATE_MODAL, {
        onSubmit: async () => {
          await onUpdateJob({ ...values, force: true });
        },
      })
    );
  };

  const handleSubmit = async (values) => {
    const { projectName } = values;
    let projectId;
    if (values.projectId) {
      projectId = values.projectId;
    } else if (job.project?.id) {
      const { data } = await client.updateProjectName(
        job.project.id,
        projectName
      );
      projectId = data.project.id;
    } else {
      console.warn('no associated project found, creating new project...');
      const { data } = await client.createProject(projectName);
      projectId = data.project.id;
    }
    console.assert(projectId);
    await onUpdateJob({
      force: false,
      projectId,
      ...values,
    });

    await getJob(jobId);
  };

  return (
    <Formik
      validateOnMount
      validateOnChange
      initialValues={{
        projectId: job.project.id,
        projectName: getProjectName(job.project),
        customer_software: job.customer_software || '', // TODO: use camelCase
        message: job.message,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => {
        return (
          <>
            <Step>
              <ProjectDetailsForm />
            </Step>
            <FormFooter
              disabled={!isValid}
              label={text(
                isBreakpointEnabled
                  ? 'continue'
                  : isStaff(currentUser.role) ||
                    (!isJobPayable(job) &&
                      !isMembershipPriceId(membershipPriceId))
                  ? 'continueToConfirm'
                  : 'continueToPaymentMethod'
              )}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ProjectDetailsStep;
