/**
 * SNAPSHOT INTERPOLATION
 */

import invariant from 'tiny-invariant';
import { z } from 'zod';

import { idLib } from './lib/id';
import { setLib } from './lib/set';

const MAP_STATE_PROPERTY_SCHEMA = z.object({
  value: z.string().nullable(),
  t: z.number(),
});
const LIST_STATE_PROPERTY_SCHEMA = z.object({
  __list__: z.literal(true),
  __head__: z.boolean(),
  value: z.string().nullable(),
  t: z.number(),
  next: z.string(),
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STATE_PROPERTY_SCHEMA = z.union([
  MAP_STATE_PROPERTY_SCHEMA,
  LIST_STATE_PROPERTY_SCHEMA,
]);
const STATE_PROPERTY_INPUT_SCHEMA = z.object({
  value: z.string(),
  t: z.number(),
});

export type MapStateProperty = z.infer<typeof MAP_STATE_PROPERTY_SCHEMA>;
export type ListStateProperty = z.infer<typeof LIST_STATE_PROPERTY_SCHEMA>;
export type StateProperty = z.infer<typeof STATE_PROPERTY_SCHEMA>;

export type StateNode = Map<string, StateNode | StateProperty>;
export type StateLeaf<T extends StateProperty> = Map<string, T>;

export type StatePropertyInput = z.infer<typeof STATE_PROPERTY_INPUT_SCHEMA>;

type SerializedStateProperty = {
  __property__: true;
  data: StateProperty;
};
export type SerializedStateNode = {
  [key: string]: SerializedStateNode | SerializedStateProperty;
};

class NodeMissingError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NodeMissingError';
  }
}

/**
 * The `State` class is a data structure intended to be synchronized in distributed environment.
 */
export class State {
  private state: StateNode;

  constructor(state?: StateNode) {
    this.state = state ?? new Map();
  }

  getNode(path: string[]) {
    let node: StateNode = this.state;
    for (const key of path) {
      const child = node.get(key);
      if (!child || isProperty(child)) return null;
      node = child;
    }
    return node;
  }

  get(path: string[], opts?: { throwIfMissing?: false }): StateProperty | null;
  get(path: string[], opts?: { throwIfMissing?: true }): StateProperty;
  get(
    path: string[],
    opts: { throwIfMissing?: boolean } = {}
  ): StateProperty | null {
    invariant(path.length > 0, 'path should be at least ');
    const node = this.getNode(path.slice(0, -1));
    if (!node) {
      if (opts.throwIfMissing) throw new NodeMissingError('missing');
      return null;
    }
    const key = path[path.length - 1];
    const property = node.get(key);
    if (!property || !isProperty(property)) {
      if (opts.throwIfMissing) throw new NodeMissingError('missing');
      return null;
    }
    return property;
  }

  getListElement(path: string[]): ListStateProperty | null {
    const p = this.get(path, { throwIfMissing: false });
    if (p && State.isListProperty(p)) return p;
    return null;
  }

  traverse(
    path: string[],
    opts: { createIfMissing: boolean; throwIfMissing: true }
  ): StateNode;
  traverse(
    path: string[],
    opts: { createIfMissing: boolean; throwIfMissing: false }
  ): StateNode | null;
  traverse(
    path: string[],
    opts: { createIfMissing: boolean; throwIfMissing: boolean }
  ): StateNode | null {
    let node: StateNode = this.state;
    for (const key of path) {
      let child = node.get(key);
      if (!child) {
        if (opts.throwIfMissing) {
          if (!opts.createIfMissing) {
            throw new Error('missing');
          }
        } else {
          return null;
        }
        child = new Map();
        node.set(key, child);
      } else {
        invariant(!isProperty(child));
      }
      node = child;
    }
    return node;
  }

  set(path: string[], { value, t }: MapStateProperty) {
    invariant(path.length > 0);
    const node = this.traverse(path.slice(0, -1), {
      createIfMissing: true,
      throwIfMissing: true,
    });
    const key = path[path.length - 1];
    const existing = node.get(key);
    if (existing) {
      invariant(isProperty(existing));
      node.set(key, { ...existing, value, t });
    } else {
      node.set(key, { value, t });
    }
  }

  setNode(path: string[], node: StateNode) {
    const parent = this.traverse(path.slice(0, -1), {
      createIfMissing: true,
      throwIfMissing: true,
    });
    const key = path[path.length - 1];
    parent.set(key, node);
  }

  insert(path: string[], value: StatePropertyInput, prevKey: string | null) {
    invariant(path.length > 0);
    const [parentPath, key] = [path.slice(0, -1), path[path.length - 1]];
    const node = this.traverse(parentPath, {
      createIfMissing: true,
      throwIfMissing: true,
    });

    invariant(State.isValidListNode(node));
    invariant(!node.has(key));

    if (prevKey) {
      // inserting after a specific node
      invariant(node.size > 0);
      const prev = node.get(prevKey);
      invariant(prev);
      const prevNext = prev.next;
      node.set(prevKey, { ...prev, next: key });
      node.set(key, this.createListNode(prevNext, value));
    } else {
      // inserting head
      invariant(node.size === 0);
      node.set(key, this.createHead(key, value));
    }

    invariant(State.isValidListNode(node));
  }

  delete(path: string[], t: number) {
    const [parentPath, key] = [path.slice(0, -1), path[path.length - 1]];
    const node = this.traverse(parentPath, {
      createIfMissing: false,
      throwIfMissing: false,
    });
    if (!node) return;
    const property = node.get(key);
    if (!property) return;
    invariant(isProperty(property));
    if (State.isListProperty(property)) {
      this.deleteListElement(path);
    } else {
      this.set(path, { value: null, t });
    }
  }

  deleteNode(path: string[], t: number) {
    function* _iterate(node: StateNode, path: string[]): Generator<string[]> {
      for (const [key, child] of node) {
        // TODO: if child is a list node we need to delete them in a specific order?
        if (isProperty(child)) {
          yield path.concat(key);
        } else {
          yield* _iterate(child, path.concat(key));
        }
      }
    }
    const node = this.traverse(path, {
      createIfMissing: false,
      throwIfMissing: false,
    });
    if (!node) {
      // console.log("failed to find node");
      return;
    }
    for (const subpath of _iterate(node, [])) {
      this.delete(path.concat(subpath), t);
    }
  }

  deletePossiblyNode(path: string[], t: number) {
    const [parentPath, key] = [path.slice(0, -1), path[path.length - 1]];
    const parent = this.traverse(parentPath, {
      createIfMissing: false,
      throwIfMissing: false,
    });
    if (!parent) return;
    const child = parent.get(key);
    if (!child) return;
    if (isProperty(child)) {
      this.delete(path, t);
    } else {
      this.deleteNode(path, t);
    }
  }

  deleteListElement(path: string[]) {
    const [parentPath, key] = [path.slice(0, -1), path[path.length - 1]];
    const node = this.traverse(parentPath, {
      createIfMissing: false,
      throwIfMissing: false,
    });
    if (!node) return;
    const originalNode = new Map(node);
    if (!State.isValidListNode(node)) {
      console.error('invalid node:', node);
      throw new Error('invalid list node');
    }
    const curr = node.get(key);
    invariant(curr);
    if (curr.value === null) return;
    const prevNode = [...node].find(
      ([, p]) => p.next === key && p.value !== null
    );
    if (!prevNode) {
      console.error('prevNode not found', node, key);
      throw new Error('prevNode not found');
    }
    const [prevKey, prev] = prevNode;

    // skip this element in the circular list
    //  note that this list could have length==1, so this would do nothing
    node.set(prevKey, { ...prev, next: curr.next });

    // if deleting the head, update the head to the next element
    //  if list has length==1, again this does nothing
    if (curr.__head__) {
      const next = node.get(curr.next);
      invariant(next);
      node.set(curr.next, { ...next, __head__: true });
    }

    // delete the element
    node.set(key, { ...curr, __head__: false, value: null });

    if (!State.isValidListNode(node)) {
      console.error('invalid node:', originalNode, prevKey, key);
      throw new Error('invalid list node');
    }
  }

  copy(path: string[], newPath: string[]) {
    const node = this.traverse(path, {
      createIfMissing: false,
      throwIfMissing: true,
    });
    this.setNode(newPath, State._innerClone(node));
  }

  handleChanges(changes: Change[]) {
    for (const change of changes) {
      if (change.operation === 'set') {
        this.set(change.path, change.property);
      } else if (change.operation === 'head') {
        console.log(change);
        this.insert(change.path, change.property, null);
      } else if (change.operation === 'insert') {
        console.log(change);
        this.insert(change.path, change.property, change.prevKey);
      } else if (change.operation === 'delete') {
        this.deletePossiblyNode(change.path, change.property.t);
      } else if (change.operation === 'copy') {
        this.copy(change.path, change.newPath);
      }
    }
  }

  private static _innerClone(node: StateNode): StateNode {
    return new Map(
      [...node].map(([key, node]) => [
        key,
        isProperty(node) ? { ...node } : State._innerClone(node),
      ])
    );
  }

  clone() {
    const clone = new State();
    clone.state = State._innerClone(this.state);
    return clone;
  }

  serialize() {
    const _innerSerialize = (node: StateNode): SerializedStateNode => {
      return Object.fromEntries(
        [...node].map(([key, child]) => [
          key,
          isProperty(child)
            ? { __property__: true, data: child }
            : _innerSerialize(child),
        ])
      );
    };
    return _innerSerialize(this.state);
  }

  static deserialize(serializedState: SerializedStateNode) {
    const _isProperty = (
      value: SerializedStateNode | SerializedStateProperty
    ): value is SerializedStateProperty =>
      Object.prototype.hasOwnProperty.call(value, '__property__');
    const _innerDeserialize = (node: SerializedStateNode): StateNode => {
      return new Map(
        Object.entries(node).map(([key, child]) => [
          key,
          _isProperty(child) ? child.data : _innerDeserialize(child),
        ])
      );
    };
    return new State(_innerDeserialize(serializedState));
  }

  asFlatString() {
    return [...this]
      .map(([path, property]) => `${path.join('.')}: ${property.value}`)
      .join('\n');
  }

  *[Symbol.iterator]() {
    function* _iterate(
      node: StateNode,
      path: string[]
    ): Generator<[string[], StateProperty]> {
      for (const [key, child] of node) {
        if (isProperty(child)) {
          yield [path.concat(key), child];
        } else {
          yield* _iterate(child, path.concat(key));
        }
      }
    }

    yield* _iterate(this.state, []);
  }

  private createHead(
    id: string,
    { value, t }: StatePropertyInput
  ): ListStateProperty {
    return {
      __list__: true,
      __head__: true,
      value,
      t,
      next: id,
    };
  }

  private createListNode(
    next: string,
    { value, t }: StatePropertyInput
  ): ListStateProperty {
    return {
      __list__: true,
      __head__: false,
      value,
      t,
      next,
    };
  }

  private static isListProperty(p: StateProperty): p is ListStateProperty {
    return Object.prototype.hasOwnProperty.call(p, '__list__');
  }

  private static toListNode(node: StateNode) {
    const listNode = new Map(
      [...node]
        .filter(([, p]) => {
          if (!isProperty(p)) return false;
          if (!State.isListProperty(p)) return false;
          return p;
        })
        .map(([key, p]) => [key, p as ListStateProperty])
    );
    return listNode;
  }

  static toList(node: StateLeaf<ListStateProperty>) {
    const MAX_DEPTH = 1000;
    if (node.size === 0) return [];
    const head = [...node].find(([, p]) => p.__head__);
    if (!head) return [];
    invariant(head[1].value !== null);
    const list = [head];
    let current = head;
    let d = 0;
    while (current[1].next !== head[0]) {
      d++;
      invariant(d < MAX_DEPTH);
      const nextKey = current[1].next;
      const next = node.get(nextKey);
      if (!next) return null;
      current = [nextKey, next];
      list.push(current);
    }
    return list;
  }

  static isValidListNode(
    node: StateNode
  ): node is StateLeaf<ListStateProperty> {
    const list = State.toList(State.toListNode(node));
    return list !== null && list.every(([, p]) => p.value !== null);
  }

  equals(other: State) {
    const _isSubset = (a: State, b: State) => {
      for (const [path, aa] of a) {
        const bb = b.get(path, {});
        if (!bb) return false;
        if (aa.value !== bb.value) return false;
        if (aa.t !== bb.t) return false;
      }
      return true;
    };

    return _isSubset(this, other) && _isSubset(other, this);
  }

  /**
   * Computes the difference between this state and another.
   * Let this state be A and the other state be B,
   * `diff` should return the paths of all differences between A and B in a symmetric manner.
   * @param other State to compare with.
   */
  diff(other: State) {
    function _getDiffs(a: State, b: State) {
      const diffs: Set<string> = new Set();
      for (const [path, property] of a) {
        const other = b.get(path, { throwIfMissing: false });
        if (
          !other ||
          property.value !== other.value ||
          property.t !== other.t ||
          (State.isListProperty(property) && !State.isListProperty(other))
        ) {
          diffs.add(path.join('.'));
        }
      }
      return diffs;
    }
    return setLib.map(
      setLib.union(_getDiffs(this, other), _getDiffs(other, this)),
      (p) => p.split('.')
    );
  }
}

export const isProperty = (
  value: StateNode | StateProperty
): value is StateProperty => !(value instanceof Map);

export const getProperty = (node: StateNode, key: string) => {
  const property = node.get(key);
  if (!property) return null;
  invariant(isProperty(property));
  return property;
};

export const FREQUENT_CHANGE_SCHEMA = z.object({
  path: z.array(z.string()),
  property: STATE_PROPERTY_INPUT_SCHEMA,
});
export const UPDATE_SETTING_SCHEMA = z.object({
  key: z.string(),
  value: z.any(), // accept string, number, object etc
});
const CHANGE_SCHEMA_BASE = z.object({
  id: z.string(),
  path: z.array(z.string()),
});
const CHANGE_SCHEMA_SET = CHANGE_SCHEMA_BASE.extend({
  operation: z.literal('set'),
  property: STATE_PROPERTY_INPUT_SCHEMA,
});
const CHANGE_SCHEMA_HEAD = CHANGE_SCHEMA_BASE.extend({
  operation: z.literal('head'),
  property: STATE_PROPERTY_INPUT_SCHEMA,
});
const CHANGE_SCHEMA_INSERT = CHANGE_SCHEMA_BASE.extend({
  property: STATE_PROPERTY_INPUT_SCHEMA,
  operation: z.literal('insert'),
  prevKey: z.string(),
});
const CHANGE_SCHEMA_DELETE = CHANGE_SCHEMA_BASE.extend({
  operation: z.literal('delete'),
  property: z.object({ t: z.number() }),
});
const CHANGE_SCHEMA_COPY = CHANGE_SCHEMA_BASE.extend({
  operation: z.literal('copy'),
  newPath: z.array(z.string()),
});

export const changeOps = {
  set: (
    path: string[],
    property: StatePropertyInput
  ): z.infer<typeof CHANGE_SCHEMA_SET> => ({
    id: idLib.shortId(),
    operation: 'set',
    path,
    property,
  }),
  head: (
    path: string[],
    property: StatePropertyInput
  ): z.infer<typeof CHANGE_SCHEMA_HEAD> => ({
    id: idLib.shortId(),
    operation: 'head',
    path,
    property,
  }),
  insert: (
    path: string[],
    property: StatePropertyInput,
    prevKey: string
  ): z.infer<typeof CHANGE_SCHEMA_INSERT> => ({
    id: idLib.shortId(),
    operation: 'insert',
    path,
    property,
    prevKey,
  }),
  delete: (
    path: string[],
    t: number
  ): z.infer<typeof CHANGE_SCHEMA_DELETE> => ({
    id: idLib.shortId(),
    operation: 'delete',
    path,
    property: { t },
  }),
  copy: (
    path: string[],
    newPath: string[]
  ): z.infer<typeof CHANGE_SCHEMA_COPY> => ({
    id: idLib.shortId(),
    operation: 'copy',
    path,
    newPath,
  }),
};

export const CHANGE_SCHEMA = z.union([
  CHANGE_SCHEMA_SET,
  CHANGE_SCHEMA_HEAD,
  CHANGE_SCHEMA_INSERT,
  CHANGE_SCHEMA_DELETE,
  CHANGE_SCHEMA_COPY,
]);
export type Change = z.infer<typeof CHANGE_SCHEMA>;
export type ChangeInput = Omit<Change, 'id'>;
export type FrequentChange = z.infer<typeof FREQUENT_CHANGE_SCHEMA>;
