import React, { useCallback } from 'react';
import classNames from 'classnames';
import ProductTooltip from '../../../components/mapView/productTooltip/ProductTooltip';
import Container from '../../../components/containers/Container';
import { IconButton } from '../../../components/button';
import { PRODUCT_TYPE } from '../../../constants/product';
import text from '../../../text';
import {
  BASE_DATA_TYPES,
  BaseDataType,
  dataTypeEnum,
  getBaseDataType,
} from '../../lib/dataType';
import { useRealtimeContext } from '../../RealtimeContext';

type AddOnButtonProps = {
  addOnType: 'bim' | '2d';
  header: string;
  description: string;
  tooltip: {
    productType: string;
    title: string;
    description: string;
  };
  className?: string;
};

export const useAddOnButtonProps = (
  addOnType: 'bim' | '2d'
): AddOnButtonProps => {
  if (addOnType === 'bim') {
    return {
      addOnType,
      header: text('getQuoteBim'),
      description: text('getQuoteBimDetails'),
      tooltip: {
        productType: PRODUCT_TYPE.BIM_EXTERIOR,
        title: text('getQuoteBim'),
        description: text('BIMTooltip'),
      },
    };
  }
  if (addOnType === '2d') {
    return {
      addOnType,
      header: text('getQuote2dFeaturePlan'),
      description: text('getQuote2dFeaturePlanDetails'),
      tooltip: {
        productType: PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
        title: text('featurePlans2D'),
        description: text('FeaturePlanToolTip'),
      },
    };
  }
  throw new Error('Unreachable');
};

const AddOnButton = ({
  addOnType,
  header,
  description,
  className,
  tooltip,
}: AddOnButtonProps) => {
  const { shapeDetails, duplicateShape, onMapChange } = useRealtimeContext();

  const handleClick = useCallback(() => {
    const baseShapes = shapeDetails?.filter((shape) =>
      BASE_DATA_TYPES.some((t) => t === shape.dataType)
    );
    if (baseShapes?.length) {
      for (const baseShape of baseShapes) {
        const derivedDataType = (
          addOnType === 'bim'
            ? [
                dataTypeEnum.Values.bim_interior,
                dataTypeEnum.Values.bim_exterior,
              ]
            : [
                dataTypeEnum.Values.twod_plan_interior,
                dataTypeEnum.Values.twod_plan_exterior,
              ]
        ).find((t) => getBaseDataType(t) === baseShape.dataType);
        if (derivedDataType) {
          duplicateShape(baseShape.id, {
            newDataType: derivedDataType,
            newBaseShapeId: baseShape.id,
            inTransaction: true,
          });
        }
      }
      onMapChange();
    }
  }, [addOnType, duplicateShape, onMapChange, shapeDetails]);

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames('add-on-button', className)}
    >
      <Container
        noBorder
        isSecondary
        className='d-flex justify-content-between p-3 align-items-center'
      >
        <div className='text-left'>
          <div className='d-flex flex-row align-items-center'>
            <p className='font-weight-bold m-0 mr-2'>{header}</p>
            <ProductTooltip
              productType={tooltip.productType}
              placement='top'
              title={tooltip.title}
              description={tooltip.description}
            />
          </div>
          <p className='m-0' style={{ fontSize: '12px' }}>
            {description}
          </p>
        </div>
        <div className='d-flex flex-column add-button-container'>
          {/* @ts-expect-error unknown */}
          <IconButton iconProps={{ isOriginal: true }} icon='plus-filled' />
          <p className='green-1 m-0 font-weight-bold'>{text('add')}</p>
        </div>
      </Container>
    </button>
  );
};

export default AddOnButton;
