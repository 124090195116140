import React, { forwardRef } from 'react';
import MeatballDropdown from '../../../../components/dropdown/MeatballDropdown';
import ShapeMenu from '../../ShapeMenu';
import { ShapeDetails } from '../../../lib/shape';

const LayerMeatballDropdown = forwardRef<
  HTMLDivElement,
  { data: ShapeDetails }
>(({ data }, ref) => {
  return (
    <ShapeMenu
      myRef={ref}
      data={data}
      // @ts-expect-error bad props
      DropdownMenuComponent={MeatballDropdown}
      dropdownMenuProps={{
        variant: '',
        btnClassName: 'meatball-dropdown',
        align: 'left',
      }}
      className='layer-meatball-dropdown-menu'
      showShapeName
    />
  );
});
LayerMeatballDropdown.displayName = 'LayerMeatballDropdown';

export default LayerMeatballDropdown;
