import { z } from 'zod';

export const POINT_CLOUD_DATA_TYPES = [
  'aerial',
  'streetscape',
  'drone',
  'exterior',
  'interior',
] as const;
export const DERIVED_DATA_TYPES = [
  'bim_exterior',
  'bim_interior',
  'twod_plan_exterior',
  'twod_plan_interior',
] as const;
const BASE_TO_DERIVED_DATA_TYPES = {
  exterior: ['bim_exterior', 'twod_plan_exterior'],
  interior: ['bim_interior', 'twod_plan_interior'],
} as const satisfies {
  readonly [key in PointCloudDataType]?: DerivedDataType[];
};
export const BASE_DATA_TYPES = Object.keys(
  BASE_TO_DERIVED_DATA_TYPES
) as BaseDataType[];
const DERIVED_TO_BASE_DATA_TYPE = Object.fromEntries(
  Object.entries(BASE_TO_DERIVED_DATA_TYPES).flatMap(([base, derived]) =>
    derived.map((d) => [d, base as keyof typeof BASE_TO_DERIVED_DATA_TYPES])
  )
);

export const dataTypeEnum = z.enum([
  ...POINT_CLOUD_DATA_TYPES,
  ...DERIVED_DATA_TYPES,
  'unknown',
]);

export type PointCloudDataType = (typeof POINT_CLOUD_DATA_TYPES)[number];
export type DerivedDataType = (typeof DERIVED_DATA_TYPES)[number];
export type BaseDataType = keyof typeof BASE_TO_DERIVED_DATA_TYPES;
export type DataType = z.infer<typeof dataTypeEnum>;

export const isPointCloudDataType = (
  dataType: string
): dataType is PointCloudDataType =>
  POINT_CLOUD_DATA_TYPES.includes(dataType as PointCloudDataType);

export const getDerivedDataTypes = (baseDataType: BaseDataType) =>
  BASE_TO_DERIVED_DATA_TYPES[baseDataType];

export const isBaseDataType = (dataType: string): dataType is BaseDataType =>
  Object.keys(BASE_TO_DERIVED_DATA_TYPES).includes(dataType);

export const isDerivedDataType = (
  dataType: string
): dataType is DerivedDataType =>
  DERIVED_DATA_TYPES.some((t) => t === dataType);

export const getBaseDataType = (dataType: DerivedDataType): BaseDataType =>
  DERIVED_TO_BASE_DATA_TYPE[dataType];

export const canChangeDataType = (
  prevDataType: DataType,
  newDataType: DataType
) => {
  if (!isDerivedDataType(newDataType) || !isDerivedDataType(prevDataType))
    return true;
  return getBaseDataType(newDataType) === getBaseDataType(prevDataType);
};
export const AUTOMATIC_DATA_TYPES = [
  'aerial',
  'streetscape',
] as const satisfies readonly DataType[];
export type AutomaticDataType = (typeof AUTOMATIC_DATA_TYPES)[number];
export const isAutomaticDataType = (
  dataType: string
): dataType is AutomaticDataType =>
  AUTOMATIC_DATA_TYPES.some((t) => t === dataType);
export const MANUAL_DATA_TYPES = [
  'drone',
  'exterior',
  'interior',
] as const satisfies readonly (DataType | DerivedDataType)[];
export type ManualDataType = (typeof MANUAL_DATA_TYPES)[number];
export const isManualDataType = (
  dataType: string
): dataType is ManualDataType => MANUAL_DATA_TYPES.some((t) => t === dataType);
export const ADD_ON_DATA_TYPES = [
  'bim_exterior',
  'bim_interior',
  'twod_plan_exterior',
  'twod_plan_interior',
] as const satisfies readonly DerivedDataType[]; // all add-ons must be derived types
export type AddOnDataType = (typeof ADD_ON_DATA_TYPES)[number];
export const isAddOnDataType = (dataType: string): dataType is AddOnDataType =>
  ADD_ON_DATA_TYPES.some((t) => t === dataType);
