import React, { useMemo } from 'react';
import { Badge, Navbar as BSNavbar, Nav, NavDropdown } from 'react-bootstrap';

import routes from '../../../routes/constants';
import UserAvatar from '../../../pages/profile/UserAvatar';
import text from '../../../text';
import useUser from '../../hooks/useUser';
import { useRedirectTo } from '../../../hooks/useRedirect';
import { USER_TYPE, USER_TYPE_TO_DISPLAY_NAME } from '../../../constants/user';
import * as userUtils from '../../../utilities/user';
import * as profileActions from '../../../redux/actions/profile';
import { useDispatch } from 'react-redux';

const UserNav = () => {
  const dispatch = useDispatch();
  const redirect = useRedirectTo();
  const currentUser = useUser();

  const userBadge = useMemo(
    () => currentUser.pretendType ?? currentUser.actualType,
    [currentUser.actualType, currentUser.pretendType]
  );
  const canShowPretendUserTypes = useMemo(
    () => userUtils.isStaff(currentUser.profile?.role),
    [currentUser.profile?.role]
  );
  const pretendUserTypes = useMemo(
    () =>
      canShowPretendUserTypes
        ? [
            USER_TYPE.FREE,
            USER_TYPE.ESSENTIALS,
            USER_TYPE.LARKI_SUPPORT,
          ].filter((type) => {
            if (currentUser.pretendType && type === currentUser.pretendType)
              return false;
            if (
              !currentUser.pretendType &&
              type === USER_TYPE.LARKI_SUPPORT &&
              userUtils.isStaff(currentUser.profile?.role)
            )
              return false;
            return true;
          })
        : [],
    [
      canShowPretendUserTypes,
      currentUser.pretendType,
      currentUser.profile?.role,
    ]
  );

  return (
    <Nav className='UserNav'>
      <BSNavbar.Collapse>
        <NavDropdown
          id='avatar'
          alignRight
          title={
            currentUser.profile && userBadge ? (
              <div className='avatar-wrapper'>
                <UserAvatar profileImage={currentUser.profile?.profile_image} />
                <Badge className={userBadge}>{text(userBadge)}</Badge>
              </div>
            ) : null
          }
        >
          <NavDropdown.Item onClick={() => redirect.push(routes.dashboard)}>
            {text('dashboard')}
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => redirect.push(routes.user.profile)}>
            {text('account')}
          </NavDropdown.Item>
          <NavDropdown.Item href={routes.larkiWebsiteUrl.home} target='_blank'>
            {text('larkiURL')}
          </NavDropdown.Item>

          {canShowPretendUserTypes ? (
            <>
              <NavDropdown.Divider />
              {pretendUserTypes.map((type) => (
                <NavDropdown.Item
                  key={type}
                  onClick={() => dispatch(profileActions.setPretendType(type))}
                >
                  {text('viewAs', { as: USER_TYPE_TO_DISPLAY_NAME[type] })}
                </NavDropdown.Item>
              ))}
            </>
          ) : null}

          <NavDropdown.Divider />

          <NavDropdown.Item
            onClick={() => {
              // also clear it here in case we're on an external domain
              localStorage.clear();
              sessionStorage.clear();
              redirect.push(routes.logout);
            }}
          >
            {text('logout')}
          </NavDropdown.Item>
        </NavDropdown>
      </BSNavbar.Collapse>
    </Nav>
  );
};

export default UserNav;
