import React from 'react';
import { useCartContext } from '../../../CartContext';
import CartEmpty from '../../../../components/RightMenu/CartEmpty';
import Icon from '../../../../components/icon/Icon';
import text from '../../../../text';
import ProductGroup, { useProductGroupProps } from './ProductGroup';

const CartTable = () => {
  const { isCartEmpty, cartItemsAddOn } = useCartContext();
  const automaticGroupProps = useProductGroupProps('automatic');
  const manualGroupProps = useProductGroupProps('manual');
  const addOnGroupProps = useProductGroupProps('addOn');

  return (
    <div className='CartTableV2'>
      {isCartEmpty ? (
        <CartEmpty hasAddOns={cartItemsAddOn.length > 0} />
      ) : (
        <>
          <hr className='my-2' />
          <h3 className='title green-1'>
            <Icon icon='shopping-cart' />
            {text('cart')}
          </h3>
          <hr className='my-2' />
        </>
      )}
      <ProductGroup {...automaticGroupProps} />
      <ProductGroup {...manualGroupProps} />
      <ProductGroup {...addOnGroupProps} />
    </div>
  );
};

export default CartTable;
