import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useOpenBookmark from '../../hooks/useOpenBookmark';
import { alertError } from '../../redux/actions';
import { captureError } from '../../utilities/error';
import { useRedirectTo } from '../../hooks/useRedirect';
import Loading from '../../components/loading/Loading';

const OpenBookmarkPage = () => {
  const dispatch = useDispatch();
  const redirect = useRedirectTo();
  const { shareToken } = useParams();
  const [state, openBookmark] = useOpenBookmark(shareToken, true);

  useEffect(() => {
    (async () => {
      const data = await openBookmark();
      if (data?.redirectUrl) {
        console.log('REDIRECTING TO', data.redirectUrl);
        redirect.push(data.redirectUrl);
      }
    })();
  }, []);

  useEffect(() => {
    if (state.error) {
      captureError(state.error);
      dispatch(alertError(state.error.message));
    }
  }, [state.error]);

  return <Loading />;
};

export default OpenBookmarkPage;
