import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { useRealtimeContext } from '../../RealtimeContext';
import ProductIndicator from '../../../components/productMenu/ProductIndicator';
import Icon from '../../../components/icon/Icon';
import text from '../../../text';
import {
  canChangeDataType,
  DataType,
  DERIVED_DATA_TYPES,
  DerivedDataType,
  getBaseDataType,
  isDerivedDataType,
  POINT_CLOUD_DATA_TYPES,
  PointCloudDataType,
} from '../../lib/dataType';
import { ShapeDetails } from '../../lib/shape';

const ProductMenu = ({
  shape,
  className,
}: {
  shape: ShapeDetails;
  className?: string;
}) => {
  const { changeDataType, availability } = useRealtimeContext();

  const dataTypes = useMemo(() => {
    if (isDerivedDataType(shape.dataType)) {
      return DERIVED_DATA_TYPES;
    } else {
      return POINT_CLOUD_DATA_TYPES;
    }
  }, [shape]);

  const getIsDisabled = useCallback(
    (dataType: PointCloudDataType | DerivedDataType) => {
      if (!canChangeDataType(shape.dataType, dataType)) return true;
      const baseDataType = isDerivedDataType(dataType)
        ? getBaseDataType(dataType)
        : dataType;
      const isAvailable = availability?.get(shape.id)?.get(baseDataType);
      return !isAvailable;
    },
    [shape, availability]
  );

  return (
    <div className={cn('product-menu', className)}>
      {dataTypes?.map((dataType) => {
        const isFocused = shape.dataType === dataType;
        const isDisabled = getIsDisabled(dataType);
        return (
          <Dropdown.Item
            key={dataType}
            disabled={isDisabled}
            onClick={() => changeDataType(shape.id, dataType)}
          >
            <ProductIndicator
              className={cn(dataType, isFocused && 'focus')}
              categoryName={dataType}
              isSecondary={false}
              isDisabled={isDisabled}
            />
            <span className='product-label'>{text(dataType)}</span>
            <div className={cn('product-tick', isFocused && 'focus')}>
              <Icon icon='tick' />
            </div>
          </Dropdown.Item>
        );
      })}
    </div>
  );
};

export default ProductMenu;
