import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { usePreviousDistinct } from 'react-use';

import { PRODUCT_TYPE } from '../../../constants/product';
import { RESET_FORM_DELAY } from '../../../pages/constants';
import { selectionNameSchema } from '../../../validation/job';
import ProductIndicator from '../../productMenu/ProductIndicator';
import Cell from '../../table/Cell';
import LayerMeatballDropdown from '../layerMeatballDropdown/LayerMeatballDropdown';
import { MapViewContext } from '../mapViewContext';
import LayerProductMenu from './LayerProductMenu';

/* eslint-disable react/prop-types */
const Layer = ({ data, index }) => {
  // To be backwards compatible with previous jobs
  const name = data.name ?? `Shape ${index + 1}`;
  const { actions, state } = useContext(MapViewContext);
  const previousName = usePreviousDistinct(name);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { isQuotingJob } = useSelector((reduxState) => ({
    isQuotingJob: reduxState.jobsReducer.isQuotingJob,
  }));
  const inputNameRef = useRef();
  const dropdownRef = useRef();
  const productMenuRef = useRef();

  // wrap in a useCallback to memoize the function
  // React guarantees that setState function identity is stable
  // (i.e. won't change on re-renders) so we can omit it from the
  // dependency list
  // note: debounce() and RESET_FORM_DELAY are also stable so omitted
  // so this whole function can be cached once on mount and reused forever
  const debounceSetIsSuccess = useCallback(
    // debounce delays the given function
    // until after some wait time has elapsed
    debounce((value) => setIsSuccess(value), RESET_FORM_DELAY)
  );

  const handleMouseEnter = useCallback(() => {
    if (data.visible) {
      actions.setHoveredData({ id: data.id, selected: true });
    }
  }, [data]);

  const handleMouseLeave = useCallback(() => {
    if (data.visible) {
      actions.setHoveredData(null);
    }
  }, [data]);

  const handleClick = (event) => {
    if (
      dropdownRef.current?.contains(event.target) ||
      inputNameRef.current?.contains(event.target) ||
      productMenuRef.current?.contains(event.target)
    ) {
      return;
    }

    // TODO: don't register a click if this selection is already active

    if (data.category_name === PRODUCT_TYPE.UNKNOWN) {
      actions.clearProductSelected();
    }

    actions.makeSelectionActive(data);
  };

  const handleSubmitName = useCallback(
    (name) => {
      actions.setSelections(
        state.selections.map((selection) => ({
          ...selection,
          name: selection.id === data.id ? name : selection.name,
        }))
      );
    },
    [data, state.selections]
  );

  useEffect(() => {
    if (previousName && name !== previousName) {
      setIsSuccess(true);
    }
    debounceSetIsSuccess(false);
  }, [previousName, name]);

  return (
    <li
      className={classNames(
        'layer',
        { isFocused: data.focused },
        data.category_name
      )}
      key={data.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {data.focused ? (
        <>
          <Cell
            ref={inputNameRef}
            className='layer-name'
            validationSchema={selectionNameSchema}
            isSuccessful={isSuccess}
            disabled={isQuotingJob}
            value={name}
            onSubmit={handleSubmitName}
            onBlur={() => setIsNameFocused(false)}
            onFocus={() => setIsNameFocused(true)}
          />
          {!isNameFocused && (
            <div className='layer-actions'>
              <LayerMeatballDropdown
                ref={dropdownRef}
                data={data}
                closeDropdownOnSelect={false}
              />
              <LayerProductMenu ref={productMenuRef} data={data} />
            </div>
          )}
        </>
      ) : (
        <>
          <div className='layer-name'>
            <span className='layer-name-text'>{name}</span>
          </div>
          <div className='layer-actions'>
            <div className='indicator-wrapper'>
              <ProductIndicator categoryName={data.category_name} isSecondary />
            </div>
          </div>
        </>
      )}
    </li>
  );
};

export default Layer;
