import { CompositeLayer } from '@deck.gl/core';
import * as turf from '@turf/turf';
import * as fp from 'lodash/fp';
import {
  createTentativeBooleanHandle,
  createTentativePathHandle,
  createTentativePolygonHandle,
  createTentativeRectangleHandle,
  GetPickingInfoParams_,
  oppositeCornersToRectangle,
  pathFeatureFromSequence,
  PickingInfo_,
  TentativeHandle,
  TentativeShape,
} from '../lib/shape';
import { arrayLib } from '../lib/array';
import { GeoJsonLayer } from '@deck.gl/layers';
import { HANDLE_LAYER_PROPS } from './mapShapeLayer';
import { SHAPE_LAYER_PROPS } from './mapShapeLayer';

type TentativeShapeLayerState = {
  shapes: TentativeShape[];
};
export class TentativeShapeLayer extends CompositeLayer {
  declare state: TentativeShapeLayerState;

  initializeState() {
    this.state = {
      shapes: [],
    };
  }

  _setState(_state: Partial<TentativeShapeLayerState>) {
    if (!this.state) return;
    if (!_state.shapes) return;
    this.setState(_state);
  }

  getPickingInfo({ info }: GetPickingInfoParams_): PickingInfo_ {
    const data = info.object;
    if (data?.type === 'Feature') {
      if (
        data.properties?.featureType === 'handle' &&
        data.properties.handleType === 'tentative' &&
        (data.properties.shapeType === 'polygon' ||
          data.properties.shapeType === 'path' ||
          data.properties.shapeType === 'boolean')
      ) {
        info.handle = data.properties;
      }
    }
    return info;
  }

  renderLayers() {
    const shapeLayerData = fp
      .map((shape) => {
        if (shape.type === 'polygon') {
          const { exterior } = shape;
          const n = shape.exterior.length;
          if (n <= 1) return null;
          if (n === 2) return turf.lineString(exterior);
          return turf.polygon([[...exterior, exterior[0]]]);
        } else if (shape.type === 'rectangle') {
          if (!shape.final) return null;
          const positions = fp.values(
            oppositeCornersToRectangle(shape.initial, shape.final)
          );
          return turf.polygon([[...positions, positions[0]]]);
        } else if (shape.type === 'path') {
          return pathFeatureFromSequence(shape.vertices, {}, 5);
        } else if (shape.type === 'boolean') {
          const { vertices } = shape;
          const n = vertices.length;
          if (n <= 1) return null;
          if (n === 2) return turf.lineString(vertices);
          return turf.lineString([...vertices, vertices[0]]);
        }
        return null;
      }, this.state.shapes)
      .filter(arrayLib.filterNonNull);
    const handleLayerData = this.state.shapes
      .flatMap<TentativeHandle>((shape) => {
        if (shape.type === 'polygon') {
          return shape.exterior.slice(0, -1).map((coord) =>
            createTentativePolygonHandle(coord, {
              shapeId: shape.id,
            })
          );
        } else if (shape.type === 'rectangle') {
          if (!shape.final) return [];
          const exterior = oppositeCornersToRectangle(
            shape.initial,
            shape.final
          );
          return [exterior.sw, exterior.se, exterior.ne, exterior.nw].map(
            (coord) =>
              createTentativeRectangleHandle(coord, {
                shapeId: shape.id,
              })
          );
        } else if (shape.type === 'path') {
          return shape.vertices.slice(0, -1).map((coord) =>
            createTentativePathHandle(coord, {
              shapeId: shape.id,
            })
          );
        } else if (shape.type === 'boolean') {
          return shape.vertices.slice(0, -1).map((coord) =>
            createTentativeBooleanHandle(coord, {
              shapeId: shape.id,
              parentIndex: shape.parent_index,
            })
          );
        }
        return [];
      })
      .filter(arrayLib.filterNonNull);
    return [
      new GeoJsonLayer(
        {
          data: shapeLayerData,

          ...SHAPE_LAYER_PROPS,
        },
        this.getSubLayerProps({
          id: 'shapes',
          pickable: false,
        })
      ),
      new GeoJsonLayer(
        {
          data: handleLayerData,
          ...HANDLE_LAYER_PROPS,
        },
        this.getSubLayerProps({
          id: 'shape-handles',
          pickable: true,
        })
      ),
    ];
  }
}
