import React, { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';
import { DataType, DerivedDataType, PointCloudDataType } from './lib/dataType';
import {
  CartCoverage,
  Charge,
  Quote,
} from './services/coverage/workers/quote.worker';
import { MapShape, ShapeDetails } from './lib/shape';

export type DataTypeGroup = 'automatic' | 'manual' | 'addOn';

export const CartContext = createContext<{
  cartRootRef: React.RefObject<HTMLDivElement>;
  isCartLoading: boolean;
  cartItems: ShapeDetails[];
  cartItemsAutomatic: ShapeDetails[];
  cartItemsManual: ShapeDetails[];
  cartItemsAddOn: ShapeDetails[];
  isCartEmpty: boolean;
  selectedDataType: PointCloudDataType | DerivedDataType | null;
  setSelectedDataType: (
    dataType: PointCloudDataType | DerivedDataType | null
  ) => void;
  quote: Quote | null;
  chargesByDataType: Map<DataType | DerivedDataType, Charge> | null;

  cartCoverage: CartCoverage | null;

  isProductModalExpanded: boolean;
  setIsProductModalExpanded: (isProductModalExpanded: boolean) => void;
}>(null!);

export const useCartContext = () => {
  const ctx = useContext(CartContext);
  invariant(ctx, 'CartContext must be used within a CartContext.Provider');
  return ctx;
};
