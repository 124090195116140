import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMount, useSearchParam } from 'react-use';

import Page from '../../components/page/Page';
import { getUserProfile } from '../../redux/actions';
import routes from '../../routes/constants';
import text from '../../text';
import CompanyForm from '../dashboard/components/CompanyForm';
import UserAvatar from './UserAvatar';
import UserForm from './UserForm';
import Tabs, { Tab } from '../../components/containers/Tabs';
import MembershipTab from './MembershipTab';
import Button from '../../components/button/Button';
import { isUserGovernment, isUserStaff } from '../../utilities/user';
import ReferralBar from './ReferralBar';

const UserProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { userProfile } = useSelector((state) => state.profileReducer);
  const focusedTab = useSearchParam('focusedTab') || 'profile';

  useMount(() => {
    dispatch(getUserProfile());
  });

  const handleTabSelect = (tabKey) => {
    if (tabKey === 'profile') {
      history.replace({
        pathname: location.pathname,
        search: '',
      });
    }
  };

  const tabs = [
    {
      component: (
        <div className='d-flex'>
          <div className='left-section'>
            <UserForm />
            <CompanyForm />
          </div>
          <div className='right-section'>
            <UserAvatar
              profileImage={userProfile.profile_image}
              avatarIcon='v1'
            />
          </div>
        </div>
      ),
      title: text('profile'),
      eventKey: 'profile',
    },
    ...(!isUserStaff(userProfile) && !isUserGovernment(userProfile)
      ? [
          {
            component: <MembershipTab />,
            title: text('membership'),
            eventKey: 'membership',
          },
        ]
      : []),
  ];

  return (
    <Page id='UserProfile' title={text('account')}>
      <ReferralBar />
      <Page.Top>
        <h2>{text('account')}</h2>
        <Button
          variant='primary'
          icon='plus'
          onClick={() => history.push(routes.order.root)}
        >
          {text('newProject')}
        </Button>
        <UserAvatar
          className='my-2'
          profileImage={userProfile.profile_image}
          avatarIcon='v1'
        />
      </Page.Top>
      <Tabs defaultActiveKey={focusedTab} onSelect={handleTabSelect}>
        {tabs.map(({ component, title, eventKey }, index) => (
          <Tab key={index} title={title} eventKey={eventKey}>
            {component}
          </Tab>
        ))}
      </Tabs>
    </Page>
  );
};

export default UserProfile;
