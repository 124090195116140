import proj4 from 'proj4';
import React, { useMemo } from 'react';

import { MGA_ZONE_TO_WKT } from '../../constants/mga';
import RightControlsWrapper from './RightControlsWrapper';

export const EASTING_NORTHING_ID = 'easting-northing';

/* eslint-disable react/prop-types */
const EastingNorthing = ({ coord, mgaZone }) => {
  const crsWkt = useMemo(
    () => (mgaZone ? MGA_ZONE_TO_WKT[mgaZone] : null),
    [mgaZone]
  );
  const converter = useMemo(
    () => (crsWkt ? proj4(proj4.WGS84, crsWkt) : null),
    [crsWkt]
  );
  const xy = useMemo(() => {
    if (!coord) {
      return null;
    }
    const [lat, lng] = coord;
    return converter?.forward([lng, lat]);
  }, [coord, converter]);
  return (
    // must have the element with id shown so google.maps can find it
    // otherwise it won't be positioned correctly
    <div id={EASTING_NORTHING_ID}>
      <RightControlsWrapper>
        {xy ? (
          <div className='easting-northing'>
            {`MGA2020 zone ${mgaZone} E: ${xy[0].toFixed(
              3
            )}m N: ${xy[1].toFixed(3)}m`}
          </div>
        ) : null}
      </RightControlsWrapper>
    </div>
  );
};

export default EastingNorthing;
