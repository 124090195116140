import { useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';

import { MEMBERSHIP } from '../../../constants/membership';
import {
  alertWarning,
  assignPayment,
  refreshCustomer,
  setMembershipPriceId,
  showModal,
  subscribe,
} from '../../../redux/actions';
import { isStaff, isUserUnderTrial } from '../../../utilities/user';
import MembershipManagementPaymentStep from './MembershipManagementPaymentStep';

import { PAYMENT_FAILURE_MODAL } from '../../../components/modal/PaymentFailureModal';
import StripeElements from '../../../components/stripe/StripeElements';
import { PAY_MODE } from '../../../constants/order';
import routes from '../../../routes/constants';
import text, { formatCurrency } from '../../../text';
import MembershipStep from '../OrderWizard/MembershipStep';
import { isEmpty } from 'lodash';
import { useQuery } from '../../../hooks/useQuery';

const isAssignedPayment = (selectedPayMode) =>
  selectedPayMode === PAY_MODE.ASSIGNED_PAYMENT;

const MembershipManagementWizard = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const returnPage = routes.user.membershipManagement;
  const returnUrl = `${process.env.LARKI_APP_URL}${returnPage}`;
  const queryParams = useQuery();
  const history = useHistory();
  const location = useLocation();

  const {
    currentUser,
    membershipPriceId,
    membershipPrices,
    selectedPayMode,
    upsertMembership,
    paymentMethodId,
  } = useSelector((state) => ({
    membershipPriceId: state.order.membershipPriceId,
    paymentMethodId: state.order.paymentMethodId,
    selectedPayMode: state.order.selectedPayMode,
    currentUser: state.profileReducer.userProfile,
    membershipPrices: state.profileReducer.membershipPrices,
    upsertMembership: state.profileReducer.upsertMembership,
  }));

  const selectedMembership = useMemo(() => {
    if (membershipPrices.value) {
      return membershipPrices.value.find(
        (membership) => membership.id === membershipPriceId
      );
    }

    return null;
  }, [membershipPriceId, membershipPrices]);

  const handleSubmit = async (payerName, payerEmail, message) => {
    let isSuccessful = false;
    let redirectTo = null;
    if (location.state?.redirectTo) {
      redirectTo = location.state?.redirectTo;
    } else if (queryParams.get('redirectTo')) {
      redirectTo = {
        path: queryParams.get('redirectTo'),
        label: queryParams.get('label'),
      };
    }

    if (isAssignedPayment(selectedPayMode)) {
      isSuccessful = await dispatch(
        assignPayment(
          membershipPriceId,
          undefined,
          payerEmail,
          payerName,
          message
        )
      );
    } else {
      const newMembership = await dispatch(
        subscribe({
          user: currentUser,
          priceId: membershipPriceId,
          paymentMethodId,
          isTrial: false,
        })
      );

      if (newMembership.paymentIntent?.status === 'requires_confirmation') {
        const membershipResult = await stripe.confirmCardPayment(
          newMembership.payment_intent_secret,
          { payment_method: paymentMethodId, return_url: returnUrl },
          { handleActions: false }
        );

        if (membershipResult?.error) {
          console.error(membershipResult.error);
          dispatch(
            showModal(PAYMENT_FAILURE_MODAL, {
              message: text('membershipPaymentFailed'),
            })
          );
        }
      }

      await dispatch(refreshCustomer());

      isSuccessful = true;
    }

    if (isSuccessful) {
      history.push({
        pathname: isAssignedPayment(selectedPayMode)
          ? routes.payment.assignedStatus()
          : routes.user.membershipChangeSuccess,
        ...(isAssignedPayment(selectedPayMode) && {
          state: { payerEmail, payerName, isMembership: true },
        }),
        ...(!isEmpty(redirectTo) && {
          state: { redirectTo },
        }),
      });
    }
  };

  useEffect(() => {
    if (isUserUnderTrial(currentUser)) {
      dispatch(setMembershipPriceId(MEMBERSHIP.MONTHLY.PRICE_ID));
    }
  }, [currentUser, dispatch]);

  return (
    <div className='MembershipManagementWizard'>
      <Wizard>
        <MembershipStep
          includeNoMembership={false}
          membershipPriceId={membershipPriceId}
          onMembershipChange={(membershipPriceIdValue) =>
            dispatch(
              setMembershipPriceId(
                membershipPriceIdValue.target?.value
                  ? membershipPriceIdValue.target?.value
                  : membershipPriceIdValue
              )
            )
          }
          nextButtonProps={{
            onClick: (nextStep) => {
              if (isStaff(currentUser.role)) {
                dispatch(
                  alertWarning(text('disabledPaymentScreenForSupportAlert'), {})
                );
              } else {
                nextStep();
              }
            },
          }}
        />
        <MembershipManagementPaymentStep
          isAssignedPayment={isAssignedPayment(selectedPayMode)}
          nextButtonProps={{
            label: isAssignedPayment(selectedPayMode)
              ? text('sendRequest')
              : // commented to hide for FREE user wanted to subscribe w/o going thru TRIAL
                // : !userHasValidMembership(currentUser) &&
                //   !currentUser.has_used_trial
                // ? text('startTrial')
                text('payAmount', {
                  amount: formatCurrency(
                    selectedMembership?.grand_total,
                    selectedMembership?.currency.name,
                    selectedMembership?.currency.scale_factor,
                    {},
                    true
                  ),
                }),
            loading: upsertMembership.isLoading,
          }}
          onSubmit={({ payerEmail, payerName, message }) =>
            handleSubmit(payerName, payerEmail, message)
          }
        />
      </Wizard>
    </div>
  );
};

const MembershipManagementWizardWithStripe = (props) => {
  return (
    <StripeElements>
      <MembershipManagementWizard {...props} />
    </StripeElements>
  );
};
export default MembershipManagementWizardWithStripe;
